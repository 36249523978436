import { createSvgIcon } from '@mui/material/utils'

export const People = createSvgIcon(
  <svg viewBox='0 0 62 51' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clip-path='url(#clip0_508_2465)'>
      <path d='M30.9988 28.3511C28.0126 28.3511 25.2055 29.53 23.0949 31.669C18.7372 36.0844 18.7372 43.2681 23.0949 47.6826C25.2055 49.8225 28.0126 51.0006 30.9988 51.0006C33.985 51.0006 36.7912 49.8225 38.9023 47.6826C41.0146 45.5444 42.1776 42.7008 42.1776 39.676C42.1776 36.6513 41.0146 33.8072 38.9023 31.669C36.7917 29.53 33.9846 28.3511 30.9988 28.3511ZM37.9319 46.6996C36.0806 48.5768 33.6179 49.6104 30.9988 49.6104C28.3797 49.6104 25.9162 48.5768 24.0648 46.6996C20.2417 42.8266 20.2417 36.5251 24.0648 32.6512C25.9162 30.774 28.3789 29.7404 30.9988 29.7404C33.6188 29.7404 36.0806 30.774 37.9319 32.6512C39.785 34.5276 40.8053 37.0223 40.8053 39.6756C40.8053 42.3289 39.785 44.8237 37.9319 46.6992V46.6996Z' fill='#2E294E' />
      <path d='M31.6859 38.981V33.1109C31.6859 32.7267 31.3793 32.416 31 32.416C30.6208 32.416 30.3141 32.7267 30.3141 33.1109V38.981H24.5204C24.1411 38.981 23.8345 39.2917 23.8345 39.6759C23.8345 40.0601 24.1411 40.3707 24.5204 40.3707H30.3141V46.24C30.3141 46.6242 30.6208 46.9349 31 46.9349C31.3793 46.9349 31.6859 46.6242 31.6859 46.24V40.3707H37.4796C37.8589 40.3707 38.1656 40.0601 38.1656 39.6759C38.1656 39.2917 37.8589 38.981 37.4796 38.981H31.6859Z' fill='#2E294E' />
      <path d='M9.00056 12.4473C4.03754 12.4473 0 16.5375 0 21.5652C0 26.593 4.03754 30.6832 9.00056 30.6832C13.9636 30.6832 18.0007 26.593 18.0007 21.5652C18.0007 16.5375 13.9632 12.4473 9.00056 12.4473ZM5.18957 28.2581V23.9983C5.18957 23.5742 5.45765 23.1998 5.85536 23.0672L7.11268 22.6498C7.63164 23.1037 8.28736 23.3757 9.00056 23.3757C9.71376 23.3757 10.3695 23.1033 10.8884 22.6494L12.1458 23.0663C12.5422 23.1993 12.809 23.5738 12.809 23.9979V28.2594C11.6876 28.9169 10.3867 29.2934 9.00056 29.2934C7.61444 29.2934 6.3118 28.916 5.18957 28.2577V28.2581ZM9.00056 21.9864C8.08221 21.9864 7.33462 21.1117 7.33462 20.0365C7.33462 18.9612 8.05075 18.1996 9.00056 18.1996C9.95037 18.1996 10.6661 18.9897 10.6661 20.0365C10.6661 21.0833 9.91933 21.9864 9.00056 21.9864ZM14.1809 27.2326V23.9983C14.1809 22.9728 13.5357 22.0684 12.5745 21.7467L11.743 21.4709C11.9318 21.0361 12.0379 20.5499 12.0379 20.0365C12.0379 18.2273 10.7034 16.8095 9.00056 16.8095C7.2977 16.8095 5.96276 18.2273 5.96276 20.0365C5.96276 20.5499 6.0689 21.0361 6.25811 21.4713L5.42786 21.7467C4.46504 22.0671 3.81813 22.9724 3.81813 23.9983V27.2305C2.31496 25.8182 1.37228 23.8011 1.37228 21.5656C1.37228 17.3046 4.79479 13.8374 9.00098 13.8374C13.2072 13.8374 16.6288 17.3046 16.6288 21.5656C16.6288 23.8024 15.6853 25.8203 14.1809 27.2326Z' fill='#216FED' />
      <path d='M52.9996 12.4473C48.0361 12.4473 43.999 16.5375 43.999 21.5652C43.999 26.593 48.0366 30.6832 52.9996 30.6832C57.9626 30.6832 62.0001 26.593 62.0001 21.5652C62.0001 16.5375 57.9626 12.4473 52.9996 12.4473ZM49.1886 28.2581V23.9983C49.1886 23.5742 49.4567 23.1998 49.8544 23.0672L51.1117 22.6498C51.6307 23.1037 52.2864 23.3757 52.9996 23.3757C53.7128 23.3757 54.3685 23.1033 54.8875 22.6494L56.1448 23.0663C56.5421 23.1993 56.8085 23.5738 56.8085 23.9979V28.259C55.6871 28.9164 54.3857 29.2934 52.9996 29.2934C51.6135 29.2934 50.3104 28.916 49.1886 28.2577V28.2581ZM52.9996 21.9864C52.0812 21.9864 51.3336 21.1117 51.3336 20.0365C51.3336 18.9612 52.0498 18.1996 52.9996 18.1996C53.9494 18.1996 54.6651 18.9897 54.6651 20.0365C54.6651 21.0833 53.9184 21.9864 52.9996 21.9864ZM58.1803 27.2326V23.9988C58.1803 22.9732 57.5351 22.0688 56.5731 21.7471L55.7416 21.4713C55.9304 21.0365 56.0365 20.5503 56.0365 20.0369C56.0365 18.2277 54.702 16.8099 52.9992 16.8099C51.2963 16.8099 49.9614 18.2277 49.9614 20.0369C49.9614 20.5503 50.0675 21.0365 50.2563 21.4717L49.426 21.7471C48.4632 22.0676 47.8163 22.9728 47.8163 23.9988V27.2309C46.3131 25.8186 45.3705 23.8016 45.3705 21.5661C45.3705 17.305 48.793 13.8379 52.9992 13.8379C57.2054 13.8379 60.6279 17.305 60.6279 21.5661C60.6279 23.8028 59.6844 25.8203 58.1799 27.233L58.1803 27.2326Z' fill='#216FED' />
      <path d='M31 20.0779C36.4644 20.0779 40.9097 15.5746 40.9097 10.0389C40.9097 4.5033 36.4644 0 31 0C25.5357 0 21.0903 4.5033 21.0903 10.0389C21.0903 15.5746 25.5357 20.0779 31 20.0779ZM26.6973 17.5066V12.7381C26.6973 12.234 27.0149 11.7899 27.4877 11.6327L28.9225 11.1571C29.4897 11.6662 30.213 11.9723 31 11.9723C31.787 11.9723 32.5107 11.6663 33.0779 11.1567L34.5127 11.6323C34.9839 11.7904 35.3006 12.2341 35.3006 12.7385V17.5079C34.037 18.2576 32.5674 18.6877 31 18.6877C29.4327 18.6877 27.9614 18.2567 26.6973 17.5066ZM31 10.5825C29.9394 10.5825 29.0773 9.57823 29.0773 8.34317C29.0773 7.10813 29.9038 6.22923 31 6.22923C32.0962 6.22923 32.9227 7.13788 32.9227 8.34317C32.9227 9.54848 32.0606 10.5825 31 10.5825ZM31 1.38975C35.708 1.38975 39.5378 5.27 39.5378 10.0389C39.5378 12.6042 38.4294 14.912 36.6729 16.4972V12.7385C36.6729 11.634 35.9777 10.6586 34.9411 10.3126L33.9388 9.98028C34.1658 9.48812 34.2946 8.9318 34.2946 8.34317C34.2946 6.3784 32.8476 4.83948 31 4.83948C29.1524 4.83948 27.7055 6.3784 27.7055 8.34317C27.7055 8.93223 27.8343 9.48812 28.0612 9.9807L27.0602 10.3126C26.0227 10.6581 25.3251 11.6327 25.3251 12.7385V16.4951C23.5693 14.9099 22.4618 12.6034 22.4618 10.0389C22.4618 5.27 26.2916 1.38975 30.9996 1.38975H31Z' fill='#216FED' />
      <path d='M30.314 22.0865V25.9995C30.314 26.3837 30.6206 26.6943 30.9999 26.6943C31.3791 26.6943 31.6858 26.3837 31.6858 25.9995V22.0865C31.6858 21.7023 31.3791 21.3916 30.9999 21.3916C30.6206 21.3916 30.314 21.7023 30.314 22.0865Z' fill='#2E294E' />
      <path d='M17.3147 38.981H12.7749C11.0721 38.981 9.6868 37.5777 9.6868 35.8517V32.9209C9.6868 32.5367 9.38012 32.2261 9.00087 32.2261C8.62162 32.2261 8.31494 32.5367 8.31494 32.9209V35.8517C8.31494 38.3435 10.3161 40.3708 12.7749 40.3708H17.3147C17.6939 40.3708 18.0006 40.0601 18.0006 39.6759C18.0006 39.2917 17.6939 38.981 17.3147 38.981Z' fill='#2E294E' />
      <path d='M52.9996 32.2261C52.6203 32.2261 52.3137 32.5367 52.3137 32.9209V35.8517C52.3137 37.5777 50.9284 38.981 49.2247 38.981H44.685C44.3057 38.981 43.999 39.2917 43.999 39.6759C43.999 40.0601 44.3057 40.3708 44.685 40.3708H49.2247C51.6844 40.3708 53.6855 38.3435 53.6855 35.8517V32.9209C53.6855 32.5367 53.3788 32.2261 52.9996 32.2261Z' fill='#2E294E' />
    </g>
    <defs>
      <clipPath id='clip0_508_2465'>
        <rect width='62' height='51' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
export const PersonIcon = createSvgIcon(
  <svg viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M1.3999 10.626C2.04078 9.55703 3.44537 8.82542 5.63672 8.82542C7.82807 8.82542 9.23266 9.55703 9.87354 10.626M10.279 8.01484C11.4394 8.59507 12.0197 9.17531 12.5999 10.3358M9.07202 3.57978C9.61807 3.87352 9.98927 4.4503 9.98927 5.11377C9.98927 5.75808 9.63921 6.32063 9.11891 6.6216M7.37743 5.11375C7.37743 6.07512 6.59809 6.85446 5.63672 6.85446C4.67535 6.85446 3.89601 6.07512 3.89601 5.11375C3.89601 4.15239 4.67535 3.37305 5.63672 3.37305C6.59809 3.37305 7.37743 4.15239 7.37743 5.11375Z' stroke='#8181B0' stroke-width='1.4' stroke-linecap='round' />
  </svg>
)
