import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { config } from 'src/config'

const TrialConfirmation = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const encodedEmail = searchParams.get('token')
  const priceId = localStorage.getItem('priceId')
  useEffect(() => {
    // navigate(`/register/verify-email?token=${encodedEmail}`);
    if (priceId && priceId !== config.FREE_USER_PRICE){
    navigate(`/settingsUpdated?priceID=${priceId}`)
      localStorage.removeItem('priceId');
    }
    else if (priceId === config.FREE_USER_PRICE) {
      navigate('/onboardinghub');
    }
    else {
      navigate('/onboardinghub')
    }
  }, [navigate, encodedEmail, window.location, priceId])

  return (
    <></>
  )
}

export default TrialConfirmation
