import { useTheme } from '@mui/material'
export const EmailFilterWarmupActiveIcon = ({ color }) => {
  const theme = useTheme()
  const defaultColor = color || theme.palette.primary.primaryText
  return (
    <>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M9 9.35669C9 8.42062 7.73667 8.1725 7.4015 9.04836C6.59074 11.167 6 13.0623 6 14.087C6 17.3527 8.68629 20 12 20C15.3137 20 18 17.3527 18 14.087C18 12.9861 17.3181 10.8803 16.4141 8.57226C15.2431 5.58242 14.6576 4.0875 13.9348 4.00698C13.7035 3.98121 13.4512 4.02754 13.2449 4.13365C12.6 4.46526 12.6 6.09574 12.6 9.35669C12.6 10.3364 11.7941 11.1306 10.8 11.1306C9.80589 11.1306 9 10.3364 9 9.35669Z'
          stroke={defaultColor}
          stroke-width='2'
        />
      </svg>
    </>
  )
}
