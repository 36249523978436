import React from 'react'
import { Card, Typography, Button, Box, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { CircleContained } from '../icons/CircleContained'
import { CheckBroken } from '../icons/CheckBroken'
import { useNavigate } from 'react-router-dom'

const TypographyText = ({ text }) => (
  <Typography
    sx={{
      fontFamily: 'Inter, sans-serif',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '23px',
      textAlign: 'center',
      color: '#425466'
    }}
  >
    {text}
  </Typography>
)

const PlanUpgradePopup = ({ onClose, icon, cardContent }) => {
  const navigate = useNavigate()

  const showCloseButton = !!onClose

  // Handler for navigating to the billing page
  const handleExplorePlans = () => {
    navigate('/settingsUpdated')
  }

  const [text1, text2, text3, text4] = cardContent.split('|')

  return (
    <Card sx={{ width: showCloseButton ? '394px' : '416px', height: showCloseButton ? '340px' : '287px', borderRadius: '16px', padding: '24px' }}>
      <Box display='flex' flexDirection='column' height='100%' gap='16px'>
        {showCloseButton && (
          <Box display='flex' justifyContent='flex-end'>
            <IconButton onClick={onClose} sx={{ height: '24px', width: '24px' }}>
              <CloseIcon sx={{ color: '#000000' }} />
            </IconButton>
          </Box>
        )}
        <Box display='flex' flexDirection='column' alignItems='center'>
          {icon}
        </Box>
        <Box>
          <Typography
            align='center'
            sx={{
              fontFamily: 'Inter, sans-serif',
              fontWeight: 600,
              fontSize: '18px',
              lineHeight: '23px',
              textAlign: 'center'
            }}
          >
            {text1}
            <Typography
              component='span'
              color='primary'
              sx={{
                fontFamily: 'Inter, sans-serif',
                fontWeight: 600,
                fontSize: '18px',
                lineHeight: '23px',
                textAlign: 'center'
              }}
            >
              {' '}
              {text2}
            </Typography>{' '}
            {text3} <br />
            {text4}
          </Typography>
        </Box>
        <Box
          sx={{
            height: '66px',
            width: showCloseButton ? '366px' : '368px',
            backgroundColor: '#EDF2F7',
            borderRadius: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            ...(showCloseButton && { position: 'relative' }),
            left: showCloseButton ? '-10px' : '0px',
            gap: '10px'
          }}
        >
          <Typography
            align='center'
            sx={{
              fontFamily: 'Inter, sans-serif',
              fontWeight: 500,
              fontSize: '12px',
              lineHeight: '23px',
              textAlign: 'center',
              color: '#425466',
              mt: '5px'
            }}
          >
            FEATURE AVAILABLE IN:
          </Typography>
          <Box display='flex' width='346px' gap='16px' justifyContent='space-between'>
            <Box display='flex' justifyContent='center' alignItems='center' gap='1.5px'>
              <CircleContained sx={{ height: '20px', width: '20px' }} />
              <TypographyText text='Free' />
            </Box>
            <Box display='flex' justifyContent='center' alignItems='center' gap='1.5px'>
              <CircleContained sx={{ height: '20px', width: '20px' }} />
              <TypographyText text='Starter' />
            </Box>
            <Box display='flex' justifyContent='center' alignItems='center' gap='1.5px'>
              <CheckBroken sx={{ height: '20px', width: '20px' }} />
              <TypographyText text='Growth' />
            </Box>
            <Box display='flex' justifyContent='center' alignItems='center' gap='1.5px'>
              <CheckBroken sx={{ height: '20px', width: '20px' }} />
              <TypographyText text='Infinity' />
            </Box>
          </Box>
        </Box>
        <Box sx={{ height: '41px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button
            variant='contained'
            onClick={handleExplorePlans}
            sx={{
              height: '100%',
              width: '133px',
              boxShadow: 'none',
              color: '#0071F6',
              '&:hover': {
                backgroundColor: 'none',
                boxShadow: 'none'
              }
            }}
          >
            <Typography
              align='center'
              sx={{
                fontFamily: 'Inter, sans-serif',
                fontWeight: 700,
                fontSize: '14px',
                lineHeight: '16.09px',
                textAlign: 'center',
                color: '#FFFFFF',
                textTransform: 'none'
              }}
            >
              Explore Plans
            </Typography>
          </Button>
        </Box>
      </Box>
    </Card>
  )
}

export default PlanUpgradePopup
