import { useEffect } from 'react'

const MetaPixel = ({ isFreeUser }) => {
  const scriptInnerHtml = isFreeUser
    ? `!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '1016128543271249');
            fbq('track', 'PageView');`

    : ` !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '1112390873309706');
            fbq('track', 'PageView');`

  const noScriptInnerHtml = !isFreeUser
    ? `<img height="1" width="1" style="display:none"
            src="https://www.facebook.com/tr?id=1112390873309706&ev=PageView&noscript=1" />`
    : `<img height="1" width="1" style="display:none"
            src="https://www.facebook.com/tr?id=1016128543271249&ev=PageView&noscript=1"
            />`

  useEffect(() => {
    // Meta Pixel Code Comment
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.innerHTML = scriptInnerHtml

    const noscript = document.createElement('noscript')
    noscript.innerHTML = noScriptInnerHtml

    document.head.appendChild(script)
    document.head.appendChild(noscript)

    // Cleanup function to remove the script and noscript elements when the component unmounts
    return () => {
      document.head.removeChild(script)
      document.head.removeChild(noscript)
    }
  }, [isFreeUser])

  return null
}

export default MetaPixel
