import React, { useState, useEffect, useCallback } from 'react'
import { Paper, InputBase, IconButton } from '@mui/material'
import { Search as SearchIcon, Close as CloseIcon } from '@mui/icons-material'

const SearchInput = ({ name, onChange, value }) => {
  const [inputValue, setInputValue] = useState(value || '')

  // Update local state when external value changes
  useEffect(() => {
    if (value !== undefined) {
      setInputValue(value)
    } else {
      handleClear()
      // setInputValue('')
      // if (onChange) {
      //   onChange('name', null) // Remove the name field completely
      // }
    }
  }, [value])

  // Handle input change
  const handleInputChange = useCallback((e) => {
    const newValue = e.target.value
    setInputValue(newValue)
    if (onChange) {
      // If the input is empty, remove the name field completely
      if (newValue.trim() === '') {
        onChange('name', null) // Pass null to indicate removal
      } else {
        onChange('name', [newValue]) // Pass the new value as an array
      }
    }
  }, [onChange])

  // Handle clear
  const handleClear = useCallback(() => {
    setInputValue('')
    if (onChange) {
      onChange('name', null) // Remove the name field completely
    }
  }, [onChange])

  return (
    <Paper
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '51px',
        p: 1,
        mb: '13px',
        border: '1px solid #E4E4E5',
        borderRadius: 2,
        bgcolor: 'white',
        boxShadow: '0 1px 2px rgba(0,0,0,0.08)',
        '&:hover': {
          boxShadow: '0 1px 2px rgba(0,0,0,0.10)'
        }
      }}
    >
      <SearchIcon
        sx={{
          color: '#8181B0',
          mr: 1,
          fontSize: 20
        }}
      />
      <InputBase
        value={inputValue}
        onChange={handleInputChange}
        placeholder={name}
        sx={{
          flex: 1,
          fontSize: '14px',
          height: '28px',
          color: '#28287B',
          '& .MuiInputBase-input': {
            padding: 0
          },
          '& .MuiInputBase-input::placeholder': {
            color: '#8181B0',
            opacity: 1
          }
        }}
      />
      {inputValue && (
        <IconButton
          size='small'
          onClick={handleClear}
          sx={{
            p: 0.5,
            '&:hover': {
              bgcolor: 'transparent'
            }
          }}
        >
          <CloseIcon
            sx={{
              fontSize: 20,
              color: '#8181B0'
            }}
          />
        </IconButton>
      )}
    </Paper>
  )
}

export default SearchInput
