import React from 'react';
import { createSvgIcon } from '@mui/material/utils';
import { useTheme, alpha } from '@mui/material/styles';

const EnrichSvg = (props) => {
    const theme = useTheme();
    const strokeColor = theme.palette.primary.main;
    const fillColor = alpha(theme.palette.primary.main, 0.1);
    return (
        <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <rect width="22" height="22" rx="3" fill="#E7F0FF" />
            <path
                d="M16.25 6.91602C16.25 7.88251 13.8995 8.66602 11 8.66602C8.1005 8.66602 5.75 7.88251 5.75 6.91602M16.25 6.91602C16.25 5.94952 13.8995 5.16602 11 5.16602C8.1005 5.16602 5.75 5.94952 5.75 6.91602M16.25 6.91602V15.0827C16.25 16.051 13.9167 16.8327 11 16.8327C8.08333 16.8327 5.75 16.051 5.75 15.0827V6.91602M16.25 10.9993C16.25 11.9677 13.9167 12.7493 11 12.7493C8.08333 12.7493 5.75 11.9677 5.75 10.9993"
                stroke="#3F4FF8"
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const Enrich = createSvgIcon(<EnrichSvg />, 'Enrich');