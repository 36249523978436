import React from 'react'
import { Box, Typography, Divider } from '@mui/material'
import { PhoneBlueIcon } from '../phone'
import { ExportBlueIcon } from 'src/icons/export'
import { Enrich } from 'src/icons/enrich'
import { InfoIcon } from 'src/icons/infoIcon'

const CreditBalance = ({ usage }) => {
  // Helper function to format numbers with commas
  const formatNumber = (num) => {
    return num?.toLocaleString() || '0'
  }
  const stats = [
    { icon: <Enrich />, label: 'Enrich', value: formatNumber(usage?.activeLeads) },
    { icon: <ExportBlueIcon />, label: 'Export', value: formatNumber(usage?.leadsCredits) },
    { icon: <PhoneBlueIcon />, label: 'Phone', value: formatNumber(usage?.leadsPhoneNumberExportsPerMonth) }
  ]

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#fff',
        border: '1px solid #D7D9E6',
        borderRadius: '6px',
        padding: '10px 16px',
        ml: 'auto',
        gap: 1
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flexWrap: 'wrap' }}>
        {stats.map((stat, index) => (
          <React.Fragment key={stat.label}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {stat.icon}
              <Typography
                sx={{
                  fontSize: '13px',
                  fontWeight: 700,
                  color: '#101010',
                  ml: 1
                }}
              >
                {stat.label}: <Box component='span' sx={{ fontWeight: 400 }}>{stat.value}</Box>
              </Typography>
            </Box>
            {index < stats.length - 1 && (
              <Divider orientation='vertical' flexItem sx={{ height: '22px', borderColor: '#D7D9E6' }} />
            )}
          </React.Fragment>
        ))}
      </Box>
      <InfoIcon sx={{ fontSize: '16px' }} />
    </Box>
  )
}

export default CreditBalance
