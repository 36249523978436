import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from 'src/utils/base-query.js'

export const multichannelApi = createApi({
  reducerPath: 'multichannelApi',
  baseQuery,
  endpoints: (builder) => ({
    getAllMultichannelAccounts: builder.mutation({
      query: (data) => {
        return {
          url: 'multichannel/getLinkedAccounts',
          method: 'POST',
          body: data
        }
      },
      providesTags: ['MULTICHANNEL_ACCOUNTS']
    }),
    deleteMultichannelAccount: builder.mutation({
      query: (data) => {
        return {
          url: 'multichannel/deleteLinkedAccount/' + data,
          method: 'DELETE'
        }
      },
      invalidatesTags: ['MULTICHANNEL_ACCOUNTS']
    }),
    storeUserInfoInRedis: builder.mutation({
      query: (data) => {
        return {
          url: 'multichannel/storeWorkSpaceId',
          method: 'POST',
          body: data
        }
      }
    })
  })
})

export const {
  useGetAllMultichannelAccountsMutation,
  useDeleteMultichannelAccountMutation,
  useStoreUserInfoInRedisMutation
} = multichannelApi
