import React from 'react'
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js'
import { Line } from 'react-chartjs-2'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

const SimpleLineChart = ({ row }) => {
  const intentData = row?.intent || []
  // const intentData =  [
  //   {
  //     "date": "2024-07-07T00:00:00.000Z",
  //     "composite_score": 85,
  //     "topic": "Wound Care Products",
  //     "category": "Medical Treatment"
  //       },
  //   {
  //     "date": "2024-05-02T00:00:00.000Z",
  //     "composite_score": 49,
  //     "topic": "Channel Partner Program",
  //     "category": "CRM"
  //       },
  //   {
  //     "date": "2024-04-25T00:00:00.000Z",
  //     "composite_score": 12,
  //     "topic": "Channel Partner Program",
  //     "category": "CRM"
  //   },
  //   {
  //     "date": "2024-01-27T00:00:00.000Z",
  //     "composite_score": 1,
  //     "topic": "Channel Partner Program",
  //     "category": "CRM"
  //   }
  // ]
  // Extract data for the chart
  const labels = intentData.map((item) => new Date(item.date).toLocaleDateString()) // Convert dates to readable format
  const dataValues = intentData.map((item) => item.composite_score) // Extract composite scores

  const data = {
    labels, // Dates as labels
    datasets: [
      {
        label: 'Composite Score',
        data: dataValues, // Values for the line
        borderColor: 'rgb(75, 192, 192)', // Line color
        backgroundColor: 'rgba(75, 192, 192, 0.2)', // Fill under the line
        tension: 0.4 // Smooth curve
      }
    ]
  }

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Intent Composite Score Over Time'
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Composite Score'
        }
      },
      x: {
        title: {
          display: true,
          text: 'Date'
        }
      }
    }
  }

  return <Line data={data} options={options} />
}

export default SimpleLineChart

// ----------------------------------------------------------------
// Line Chart Options

//   // Format dates to show only month
//   const labels = intentData
//     .map(item => new Date(item.date))
//     .map(date => date.toLocaleString('default', { month: 'short' }));

//   // Convert composite scores to Low/Medium/High values
//   const getScoreLevel = (score) => {
//     if (score >= 66) return 3; // High
//     if (score >= 36) return 2; // Medium
//     return 1; // Low
//   };

//   const dataValues = intentData.map(item => getScoreLevel(item.composite_score));

//   const data = {
//     labels,
//     datasets: [
//       {
//         label: 'Intent Score',
//         data: dataValues,
//         borderColor: '#4F46E5',
//         backgroundColor: 'rgba(79, 70, 229, 0.1)',
//         tension: 0.4,
//         pointRadius: 4,
//         pointBackgroundColor: '#4F46E5',
//         pointBorderColor: '#fff',
//         pointBorderWidth: 2,
//         pointHoverRadius: 6,
//       }
//     ]
//   };

//   const options = {
//     responsive: true,
//     maintainAspectRatio: false,
//     plugins: {
//       legend: {
//         display: false
//       },
//       title: {
//         display: false
//       },
//       tooltip: {
//         backgroundColor: '#1F2937',
//         titleColor: '#fff',
//         bodyColor: '#fff',
//         padding: 12,
//         displayColors: false,
//         callbacks: {
//           label: (context) => {
//             const value = context.parsed.y;
//             return value === 3 ? 'High' : value === 2 ? 'Medium' : 'Low';
//           }
//         }
//       }
//     },
//     scales: {
//       y: {
//         min: 0,
//         max: 3,
//         grid: {
//           display: true,
//           color: '#E5E7EB',
//           drawBorder: false,
//         },
//         ticks: {
//           stepSize: 1,
//           callback: (value) => {
//             return value === 3 ? 'High' : value === 2 ? 'Medium' : value === 1 ? 'Low' : '';
//           },
//           font: {
//             family: 'Inter, sans-serif',
//             size: 12
//           },
//           color: '#6B7280'
//         },
//         border: {
//           display: false
//         }
//       },
//       x: {
//         grid: {
//           display: false
//         },
//         ticks: {
//           font: {
//             family: 'Inter, sans-serif',
//             size: 12
//           },
//           color: '#6B7280'
//         },
//         border: {
//           display: false
//         }
//       }
//     }
//   };

//   return (
//     <div style={{
//       background: 'white',
//       borderRadius: '12px',
//       padding: '24px',
//       boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)'
//     }}>
//       <div style={{
//         display: 'flex',
//         alignItems: 'center',
//         marginBottom: '16px'
//       }}>
//         <div style={{
//           width: '32px',
//           height: '32px',
//           backgroundColor: '#EEF2FF',
//           borderRadius: '8px',
//           display: 'flex',
//           alignItems: 'center',
//           justifyContent: 'center',
//           marginRight: '12px'
//         }}>
//           <div style={{
//             width: '16px',
//             height: '16px',
//             backgroundColor: '#4F46E5',
//             borderRadius: '4px'
//           }} />
//         </div>
//         <h2 style={{
//           fontSize: '20px',
//           fontWeight: '600',
//           color: '#1F2937'
//         }}>
//           Artificial Intelligence in Sales
//         </h2>
//       </div>
//       <div style={{ height: '256px' }}>
//         <Line data={data} options={options} />
//       </div>
//     </div>
//   );
// };
