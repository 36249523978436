import React, { useState } from 'react'
import {
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Box,
  CircularProgress,
  Tooltip,
  IconButton
} from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import axios from 'axios'
import { config } from 'src/config.js'
import { Helmet } from 'react-helmet-async'

const EmailSubjectLineGenerator = () => {
  const [response, setResponse] = useState([])
  const validationSchema = () => {
    const schema = Yup.object({
      companyName: Yup.string().max(255).required('Subject Line is required'),
      emailAbout: Yup.string().max(255).required('Tone is required'),
      emailTone: Yup.string().max(255).required('Purpose is required')
    })

    return schema
  }

  const handleDataSubmit = async (values, helpers) => {
    const apiUrl = `${config.API_BASE_URL}/tools/generate-subject-line`
    try {
      const responseData = await axios.post(
        apiUrl,
        {
          prompt: values
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      const data = responseData.data.subject.split('\n')
      setResponse(data)
    } catch (error) {
      console.error('Error :', error)
      setResponse('Something Went Wroung Please Try Again !')
    }
  }

  const formik = useFormik({
    initialValues: {
      companyName: '',
      emailAbout: '',
      emailTone: 'friendly'
    },
    validationSchema,
    onSubmit: async (values, helpers) => {
      try {
        await handleDataSubmit(values, helpers)
      } catch (err) {
        helpers.setErrors({ submit: err.data.error.message })
      }
      formik.resetForm()
    }
  })

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text)
  }

  return (
    <Box sx={{ padding: { sm: '24px 7%', xs: '16px' }, fontFamily: 'Inter' }}>
      <Helmet>
        <title>Free AI-powered Email Subject Line Generator - Boost Open Rates</title>
        <meta name='description' content='Generate unique, compelling email subject lines with our free AI-powered tool, based on OpenAI technology. Improve your open rates & captivate your audience.' />
      </Helmet>
      <Typography variant='h5' sx={{ fontWeight: 'bold', color: 'primary.main', py: 2 }}>
        Free AI-powered Email Subject Line Generator: Craft Unique and Engaging Subject Lines
      </Typography>
      <Typography color='primary'>
        Create unique subject lines with the only free AI email subject line generator based on the
        Open AI technology
      </Typography>

      <form noValidate onSubmit={formik.handleSubmit}>
        <Box sx={{ my: 4 }}>
          <Typography variant='h5' sx={{ fontWeight: 'bold', color: 'primary.main' }}>
            What is the email about?
          </Typography>
          <br />
          <Box
            sx={{
              display: 'flex',
              flexDirection: { sm: 'row', xs: 'column' },
              gap: { sm: 4, xs: 2 },
              my: 4
            }}
          >
            <TextField
              variant='outlined'
              sx={{ width: { md: '22vw', sm: '30vw', xs: '100%' } }}
              onChange={formik.handleChange}
              error={!!(formik.touched.companyName && formik.errors.companyName)}
              helperText={formik.touched.companyName && formik.errors.companyName}
              onBlur={formik.handleBlur}
              name='companyName'
              value={formik?.values?.companyName}
              placeholder='off on christmas'
            />

            <TextField
              label='Your company/product name'
              variant='outlined'
              sx={{ width: { md: '22vw', sm: '30vw', xs: '100%' } }}
              onChange={formik.handleChange}
              error={!!(formik.touched.emailAbout && formik.errors.emailAbout)}
              helperText={formik.touched.emailAbout && formik.errors.emailAbout}
              onBlur={formik.handleBlur}
              name='emailAbout'
              value={formik?.values?.emailAbout}
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: { sm: 'row', xs: 'column' },
              gap: { sm: 4, xs: 2 },
              my: 4
            }}
          >
            <FormControl sx={{ width: { md: '22vw', sm: '30vw', xs: '100%' } }}>
              <InputLabel id='tone-label'>Tone</InputLabel>
              <Select
                labelId='tone-label'
                label='Tone'
                defaultValue='friendly'
                onChange={formik.handleChange}
                error={!!(formik.touched.tone && formik.errors.tone)}
                helperText={formik.touched.tone && formik.errors.tone}
                onBlur={formik.handleBlur}
                name='tone'
                value={formik?.values?.tone}
              >
                <MenuItem value='appreciative'>Appreciative</MenuItem>
                <MenuItem value='assertive'>Assertive</MenuItem>
                <MenuItem value='awestruck'>Awestruck</MenuItem>
                <MenuItem value='candid'>Candid</MenuItem>
                <MenuItem value='casual'>Casual</MenuItem>
                <MenuItem value='cautionary'>Cautionary</MenuItem>
                <MenuItem value='compassionate'>Compassionate</MenuItem>
                <MenuItem value='convincing'>Convincing</MenuItem>
                <MenuItem value='earnest'>Earnest</MenuItem>
                <MenuItem value='enthusiastic'>Enthusiastic</MenuItem>
                <MenuItem value='formal'>Formal</MenuItem>
                <MenuItem value='friendly'>Friendly</MenuItem>
                <MenuItem value='funny'>Funny</MenuItem>
                <MenuItem value='humble'>Humble</MenuItem>
                <MenuItem value='humorous'>Humorous</MenuItem>
                <MenuItem value='informative'>Informative</MenuItem>
                <MenuItem value='inspirational'>Inspirational</MenuItem>
                <MenuItem value='joyful'>Joyful</MenuItem>
                <MenuItem value='passionate'>Passionate</MenuItem>
                <MenuItem value='thoughtful'>Thoughtful</MenuItem>
                <MenuItem value='urgent'>Urgent</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: { sm: 'row', xs: 'column' },
              alignItems: 'flex-end',
              gap: { sm: 4, xs: 2 },
              mt: 4
            }}
          >
            <Button
              variant='contained'
              sx={{
                backgroundColor: '#6E58F1',
                '&:hover': { backgroundColor: '#7c68f1' },
                width: { sm: '12vw', xs: '100%' },
                minHeight: 56,
                padding: '1rem'
              }}
              disabled={!(formik?.values?.companyName && formik?.values?.emailAbout)}
              type='submit'
            >
              {formik.isSubmitting ? <CircularProgress size={20} color='inherit' /> : <>Generate</>}
            </Button>
          </Box>
        </Box>
      </form>

      {response.map((raw, index) => (
        <Box mt={4} key={index}>
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            sx={{ width: { sm: '84%', xs: '100%' } }}
          >
            <Typography variant='h6' fontWeight='bold' color='primary' my={2}>
              Generated Subject Line :
            </Typography>
            <Tooltip title='Copy'>
              <IconButton
                onClick={() => handleCopy(raw)}
                sx={{ color: '#6E58F1', '&:hover': { color: '#7c68f1' } }}
              >
                <ContentCopyIcon fontSize='small' />
                <Typography variant='body2' ml={1} sx={{ cursor: 'pointer' }}>
                  Copy
                </Typography>
              </IconButton>
            </Tooltip>
          </Box>
          <Box
            component='pre'
            sx={{
              backgroundColor: 'grey.50',
              border: '1px solid',
              borderColor: 'grey.300',
              borderRadius: 1,
              p: 2,
              mt: 2,
              width: { sm: '84%', xs: '100%' },
              color: 'grey.800',
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-word'
            }}
          >
            {raw}
          </Box>
        </Box>
      ))}
    </Box>
  )
}

export default EmailSubjectLineGenerator
