import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const TrackPageView = () => {
  const location = useLocation()

  useEffect(() => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'historychange',
        page: location.pathname + location.search
      })
    }
  }, [location])

  return null
}

export default TrackPageView
