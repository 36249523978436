import React, { useState, useEffect } from 'react'
import Pagination from '../Pagination'
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress } from '@mui/material'
import { useGetErrorLogsMutation } from 'src/services/account-service'
import moment from 'moment'

const ErrorTab = ({ account }) => {
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [errors, setErrors] = useState([])
  const [loading, setLoading] = useState(true)

  const [errorLogs] = useGetErrorLogsMutation()

  useEffect(() => {
    fetchData()
  }, [])

  async function fetchData () {
    try {
      const response = await errorLogs({ data: account })
      setErrors(response.data.errors)
    } catch (error) {
      console.error('Error fetching error logs:', error)
    } finally {
      setLoading(false)
    }
  }

  const handleLimitChange = (event) => {
    setLimit(event.target.value)
    setPage(1)
  }

  let offset = 0
  const sortedErrors = [...errors].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))

  offset = limit * (page - 1)
  const latestErrors = sortedErrors.slice(offset, offset + limit)

  const formatTimestamp = (timestamp) => {
    return moment(timestamp).fromNow()
  }

  return (
    <>
      {loading
        ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress size={24} />
          </Box>
          )
        : errors.length > 0
          ? (
            <>
              <Box sx={{ width: '100%' }}>
                <TableContainer sx={{ maxHeight: 520 }}>
                  <Table stickyHeader aria-label='sticky table'>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ textTransform: 'none', fontSize: 'medium' }}>To</TableCell>
                        <TableCell style={{ textTransform: 'none', fontSize: 'medium' }}>Message</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {latestErrors.map((error, index) => (
                        <TableRow key={index}>
                          <TableCell style={{ textTransform: 'none' }}>
                            {error.to}<br />{formatTimestamp(error.timestamp)}<br /><span style={{ fontSize: 'small' }}>{error.timestamp}</span>
                          </TableCell>
                          <TableCell style={{ textTransform: 'none' }}>{error.errorMessage}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Pagination
                  page={page}
                  setPage={setPage}
                  total={sortedErrors.length > 100 ? 100 : sortedErrors.length}
                  length={latestErrors.length}
                  limit={limit}
                  handleLimitChange={handleLimitChange}
                />
              </Box>
            </>
            )
          : (
            <Typography variant='body1' align='center'>No Data Found</Typography>
            )}
    </>
  )
}

export default ErrorTab
