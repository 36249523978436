import data from './spam2.json'

function checkSpamWords (paragraph) {
  const spamArray = data
    ?.map((item) => {
      return new RegExp(`\\b${item}\\b`, 'ig').test(paragraph) ? item : null
    })
    ?.filter((ele) => ele !== null)

  return { spam: spamArray, count: spamArray.length }
}

export default checkSpamWords
