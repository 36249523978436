import { createSvgIcon } from '@mui/material/utils'

export const FundingFilterIcon = createSvgIcon(
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clip-path='url(#clip0_8526_14274)'>
      <path d='M7.08333 12.2216C7.08333 13.2955 7.95389 14.166 9.02778 14.166H10.8333C11.9839 14.166 12.9167 13.2333 12.9167 12.0827C12.9167 10.9321 11.9839 9.99935 10.8333 9.99935H9.16666C8.01607 9.99935 7.08333 9.06661 7.08333 7.91602C7.08333 6.76542 8.01607 5.83268 9.16666 5.83268H10.9722C12.0461 5.83268 12.9167 6.70324 12.9167 7.77713M10 4.58268V5.83268M10 14.166V15.416M18.3333 9.99935C18.3333 14.6017 14.6024 18.3327 10 18.3327C5.39762 18.3327 1.66666 14.6017 1.66666 9.99935C1.66666 5.39698 5.39762 1.66602 10 1.66602C14.6024 1.66602 18.3333 5.39698 18.3333 9.99935Z' stroke-width='1.67' stroke-linecap='round' stroke-linejoin='round' />
    </g>
    <defs>
      <clipPath id='clip0_8526_14274'>
        <rect width='20' height='20' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
