import { createSvgIcon } from '@mui/material/utils'

export const Industry = createSvgIcon(
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M5.83334 9.16732C5.83334 7.59597 5.83334 6.8103 6.32149 6.32214C6.80965 5.83398 7.59532 5.83398 9.16667 5.83398H10.8333C12.4047 5.83398 13.1904 5.83398 13.6785 6.32214C14.1667 6.8103 14.1667 7.59597 14.1667 9.16732V10.834C14.1667 12.4053 14.1667 13.191 13.6785 13.6792C13.1904 14.1673 12.4047 14.1673 10.8333 14.1673H9.16667C7.59532 14.1673 6.80965 14.1673 6.32149 13.6792C5.83334 13.191 5.83334 12.4053 5.83334 10.834V9.16732Z' stroke-width='1.66667' />
    <rect x='8.33334' y='8.33398' width='3.33333' height='3.33333' rx='0.833333' />
    <path d='M8.33317 5.83203V3.33203' stroke-width='1.66667' stroke-linecap='round' />
    <path d='M11.6665 5.83203V3.33203' stroke-width='1.66667' stroke-linecap='round' />
    <path d='M14.1668 8.33203L16.6668 8.33203' stroke-width='1.66667' stroke-linecap='round' />
    <path d='M14.1668 11.666L16.6668 11.666' stroke-width='1.66667' stroke-linecap='round' />
    <path d='M8.33317 16.668V14.168' stroke-width='1.66667' stroke-linecap='round' />
    <path d='M11.6665 16.668V14.168' stroke-width='1.66667' stroke-linecap='round' />
    <path d='M3.33317 8.33203L5.83317 8.33203' stroke-width='1.66667' stroke-linecap='round' />
    <path d='M3.33317 11.666L5.83317 11.666' stroke-width='1.66667' stroke-linecap='round' />
  </svg>

)
