import React, { useState } from 'react'
import WarningIcon from '@mui/icons-material/Warning'
import {
  Alert,
  Box,
  Button,
  Container,
  Paper,
  TextField,
  Typography,
  alpha,
  useTheme
} from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { setCustomDomain, useCreateCustomDomainMutation } from 'src/services/theme-service'
import { useDispatch, useSelector } from 'react-redux'
import toast from 'react-hot-toast'

const validationSchema = Yup.object({
  domain: Yup.string()
    .required('Domain is required')
})

const CustomDomainSetup = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [createDomain] = useCreateCustomDomainMutation()
  const { customDomain } = useSelector((state) => state.partnerDetails)

  const formik = useFormik({
    initialValues: {
      domain: customDomain.name || ''
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true)
      const payload = {
        name: values.domain
      }
      try {
        const { message, customDomain: updatedData } = await createDomain({ customDomain: payload }).unwrap()
        if (updatedData) {
          dispatch(setCustomDomain({ ...updatedData }))
        }
        toast.success(`${message || 'Domain setup updated successfully!'}`)
      } catch ({ data: error }) {
        toast.error(`${error.status || 'Error'}: ${error.message || 'Falied to update Domain metadata!'}`)
        return
      } finally {
        setLoading(false)
      }
    }
  })

  return (
    <Container maxWidth='md'>
      <Box sx={{ mb: 4 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 3
          }}
        >
          <Typography variant='h5' component='h1' sx={{ color: theme.palette.primary.main }}>
            Custom Domain
          </Typography>
          <Button
            variant='contained'
            size='small'
            onClick={formik.handleSubmit}
            disabled={loading}
            sx={{
              bgcolor: theme.palette.primary.main,
              '&:hover': {
                bgcolor: alpha(theme.palette.primary.main, 0.9)
              }
            }}
          >
            {loading ? 'Saving...' : 'Save'}
          </Button>
        </Box>

        <Paper sx={{ p: 3 }}>
          <Typography variant='subtitle1' color='text.secondary' sx={{ mb: 3 }}>
            Give your Data Storefront address a custom domain that incorporates your brand name.
          </Typography>

          {/* Domain Field */}
          <Box sx={{ mb: 3 }}>
            <Typography variant='subtitle2' sx={{ mb: 1, color: theme.palette.primary.main }}>
              Your Domain
            </Typography>
            <TextField
              name='domain'
              placeholder='data-success.ai'
              variant='outlined'
              size='small'
              fullWidth
              value={formik.values.domain}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.domain && Boolean(formik.errors.domain)}
              helperText={formik.touched.domain && formik.errors.domain}
            />
          </Box>

          <Alert severity='warning' icon={<WarningIcon />} sx={{ mb: 4 }}>
            A CNAME record against your domain pointing to <b>whitelabel.success.ai</b>
          </Alert>

          <Typography variant='subtitle2' sx={{ mt: 2 }}>
            After adding the records, it may take up to 24 hours for the changes to take effect. If
            you have any questions, contact support at <b>support@success.ai</b>.
          </Typography>
        </Paper>
      </Box>
    </Container>
  )
}

export default CustomDomainSetup
