import {
  Box,
  Button,
  Card,
  CardContent,
  FormHelperText,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  useTheme
} from '@mui/material'
import { alpha } from '@mui/material/styles'
import { Form, Formik } from 'formik'
import { ColorPicker, createColor } from 'material-ui-color'
import * as Yup from 'yup'
import React from 'react'
import { setTheme, useCreateThemeMutation } from 'src/services/theme-service'
import { useDispatch, useSelector } from 'react-redux'
import toast from 'react-hot-toast'

// Color field configuration
const colorFields = [
  { key: 'light', label: 'Light Color' },
  { key: 'main', label: 'Main Color' },
  { key: 'dark', label: 'Dark Color' },
  { key: 'contrastText', label: 'Contrast Text' },
  { key: 'primaryText', label: 'Primary Text' }
]

// Validation schema with Yup
const validationSchema = Yup.object({
  companyName: Yup.string()
    .trim()
    .required('Company name is a mandatory field')
    .min(3, 'Company name must be at least 3 characters')
    .max(20, 'Company name must not exceed 20 characters'),
  colors: Yup.object().shape({
    light: Yup.string().matches(/^#[0-9A-Fa-f]{6}$/, 'Invalid hex color format'),
    main: Yup.string().matches(/^#[0-9A-Fa-f]{6}$/, 'Invalid hex color format'),
    dark: Yup.string().matches(/^#[0-9A-Fa-f]{6}$/, 'Invalid hex color format'),
    contrastText: Yup.string().matches(/^#[0-9A-Fa-f]{6}$/, 'Invalid hex color format'),
    primaryText: Yup.string().matches(/^#[0-9A-Fa-f]{6}$/, 'Invalid hex color format')
  }),
  logo: Yup.mixed()
    .required('Logo is a mandatory field')
    .test(
      'fileFormat',
      'Logo must be in jpeg, png, svg, or jpg format',
      (value) => {
        if (!value) return false

        // Handle Base64 strings
        if (typeof value === 'string' && value.startsWith('data:')) {
          const mimeType = value.split(';')[0].split(':')[1]
          const validFormats = ['image/jpeg', 'image/png', 'image/svg+xml', 'image/jpg']
          return validFormats.includes(mimeType)
        }

        // Handle file objects
        if (value instanceof File) {
          const validFormats = ['image/jpeg', 'image/png', 'image/svg+xml', 'image/jpg']
          return validFormats.includes(value.type)
        }

        return false
      }
    )
    .test(
      'fileSize',
      'Logo must be less than 4 MB',
      (value) => {
        if (!value) return false

        // Handle Base64 strings
        if (typeof value === 'string' && value.startsWith('data:')) {
          const base64Content = value.split(',')[1]
          const sizeInBytes = (base64Content.length * 3) / 4 - (base64Content.endsWith('==') ? 2 : base64Content.endsWith('=') ? 1 : 0)
          return sizeInBytes <= 4 * 1024 * 1024
        }

        // Handle file objects
        if (value instanceof File) {
          return value.size <= 4 * 1024 * 1024
        }

        return false
      }
    )
})

const ThemeSettings = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const [createTheme] = useCreateThemeMutation()
  const { theme: userTheme } = useSelector((state) => state.partnerDetails)

  const keysToKeep = ['light', 'main', 'dark', 'contrastText', 'primaryText']

  function filterColors (colors) {
    return Object.keys(colors)
      .filter(key => keysToKeep.includes(key))
      .reduce((filteredColors, key) => {
        filteredColors[key] = colors[key]
        return filteredColors
      }, {})
  }

  const initialValues = {
    ...userTheme,
    colors: filterColors(userTheme.colors)
  }

  const handleLogoUpload = (setFieldValue) => (event) => {
    const file = event.target.files[0]

    if (file) {
      // If the file is already a Base64 string (e.g., passed from another part of the app)
      if (file.type === 'data:image/jpeg;base64' || file.type === 'data:image/png;base64') {
        // Directly set the Base64 string
        setFieldValue('logo', file)
      } else {
        // Convert the Blob URL to Base64
        if (typeof window !== 'undefined' && typeof FileReader !== 'undefined') {
          const reader = new window.FileReader()
          reader.onloadend = () => {
            const base64 = reader.result
            setFieldValue('logo', base64) // Set the Base64 string
            event.target.value = ''
          }
          reader.readAsDataURL(file) // Convert to Base64
        }
      }
    }
  }

  const resetTheme = async () => {
    try {
      const themeValues = {
        isReset: true,
        logo: null,
        companyName: '',
        colors: {
          light: '#EBEFFF',
          main: '#216fed',
          dark: '#004EEB',
          contrastText: '#FFFFFF',
          primaryText: '#28287B'
        }
      }
      const { theme: updatedTheme } = await createTheme({ theme: themeValues }).unwrap()
      if (updatedTheme) {
        dispatch(setTheme({ ...updatedTheme, colors: updatedTheme?.colors }))
      }
      toast.success('Theme Reset Successfully')
    } catch ({ data: error }) {
      toast.error(`${error.status || 'Error'}: ${error.message || 'Error Reset theme'}`)
    }
  }

  const handleSubmit = async (values) => {
    try {
      const payload = {
        isReset: false,
        companyName: values.companyName,
        colors: filterColors(values.colors),
        logo: values.logo
      }

      const response = await createTheme({ theme: payload }).unwrap()
      const { message, theme: updatedTheme } = response
      const currentTheme = updatedTheme?.theme ? updatedTheme?.theme : updatedTheme
      if (currentTheme) {
        dispatch(setTheme({ ...currentTheme, colors: currentTheme?.colors }))
      }
      toast.success(message)
    } catch ({ data: error }) {
      toast.error(`${error.status || 'Error'}: ${error.message || 'Error Update/Create theme'}`)
    }
  }

  const handleDrop = (setFieldValue) => (event) => {
    event.preventDefault()

    const file = event.dataTransfer.files[0]
    if (file) {
      const validFormats = ['image/jpeg', 'image/png', 'image/svg+xml', 'image/jpg']
      const maxSize = 4 * 1024 * 1024 // 4 MB

      if (!validFormats.includes(file.type)) {
        toast.error('Invalid file format. Please upload a jpeg, png, svg, or jpg file.')
        return
      }

      if (file.size > maxSize) {
        toast.error('File size exceeds 4 MB. Please upload a smaller file.')
        return
      }

      if (typeof window !== 'undefined' && typeof FileReader !== 'undefined') {
        const reader = new window.FileReader()
        reader.onload = () => {
          setFieldValue('logo', reader.result)
        }
        reader.readAsDataURL(file)
      }
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ setFieldValue, values, errors, touched }) => {
        // Fetch the data on first render
        React.useEffect(() => {
          setFieldValue('companyName', userTheme.companyName || '')
          setFieldValue('companyTagline', userTheme.companyTagline || '')
          setFieldValue('colors', userTheme.colors)
          // setFieldValue('logo', userTheme.logo || null);
        }, [])

        return (
          <Card sx={{ maxWidth: 800, margin: 'auto', mt: 2 }}>
            <CardContent>
              <Typography variant='h5' gutterBottom sx={{ color: theme.palette.primary.main }}>
                Theme Settings
              </Typography>

              <Box sx={{ mt: 4 }}>
                <Form>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label='Company Name *'
                        value={values.companyName}
                        onChange={(e) => setFieldValue('companyName', e.target.value)}
                        variant='outlined'
                        error={touched.companyName && !!errors.companyName}
                        helperText={touched.companyName && errors.companyName}
                      />
                    </Grid>
                  </Grid>

                  {/* Logo Section */}
                  <Box sx={{ mt: 4 }}>
                    <Typography
                      variant='subtitle1'
                      gutterBottom
                      sx={{ color: theme.palette.primary.main, fontWeight: 600 }}
                    >
                      Logo *
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 2,
                        p: 2,
                        border: '2px dashed rgba(0, 0, 0, 0.12)',
                        borderRadius: 1,
                        bgcolor: 'white',
                        position: 'relative'
                      }}
                      onDrop={handleDrop(setFieldValue)}
                      onDragOver={(e) => e.preventDefault()}
                    >
                      {values.logo
                        ? (
                          <Box
                            sx={{
                              width: 64,
                              height: 64,
                              position: 'relative',
                              overflow: 'hidden',
                              borderRadius: 1,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}
                          >
                            <img
                              src={values.logo}
                              alt='Uploaded Logo'
                              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                            />
                            <Box
                              sx={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                borderRadius: '50%',
                                width: 24,
                                height: 24,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                                boxShadow: '0 2px 4px rgba(0,0,0,0.2)'
                              }}
                              onClick={() => setFieldValue('logo', null)}
                            >
                              <Typography sx={{ cursor: 'pointer', fontSize: '1.25rem', fontWeight: 'bold' }}>&times;</Typography>
                            </Box>
                          </Box>
                          )
                        : (
                          <Typography onClick={() => document.getElementById('logoInput').click()} sx={{ cursor: 'pointer', color: theme.palette.primary.primaryText, fontSize: '1rem' }}>
                            Drag & Drop or Click to Upload
                          </Typography>
                          )}
                      <input
                        id='logoInput'
                        type='file'
                        hidden
                        accept='image/jpeg,image/png,image/svg+xml,image/jpg'
                        onChange={handleLogoUpload(setFieldValue)}
                      />
                    </Box>
                    {touched.logo && errors.logo && (
                      <FormHelperText error sx={{ mt: 1 }}>
                        {errors.logo}
                      </FormHelperText>
                    )}
                  </Box>

                  {/* Color Settings */}
                  <Box sx={{ mt: 4 }}>
                    <Typography
                      variant='subtitle1'
                      gutterBottom
                      sx={{ mb: 4, color: theme.palette.primary.main }}
                    >
                      Theme Colors
                    </Typography>
                    <Grid container spacing={3}>
                      {colorFields.map(({ key, label }) => (
                        <Grid item xs={12} sm={6} key={key}>
                          <TextField
                            fullWidth
                            label={label}
                            value={values.colors[key]}
                            onChange={(e) => setFieldValue(`colors.${key}`, e.target.value)}
                            variant='outlined'
                            error={touched.colors?.[key] && !!errors.colors?.[key]}
                            helperText={touched.colors?.[key] && errors.colors?.[key]}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position='end'>
                                  <Box>
                                    <ColorPicker
                                      value={createColor(values.colors[key])}
                                      onChange={(color) =>
                                        setFieldValue(`colors.${key}`, `#${color.hex}`)}
                                      hideTextfield
                                      disableAlpha
                                    />
                                  </Box>
                                </InputAdornment>
                              )
                            }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>

                  {/* Save Button */}
                  <Box sx={{ mt: 4 }}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <Button
                          fullWidth
                          variant='contained'
                          sx={{
                            bgcolor: theme.palette.primary.main,
                            '&:hover': {
                              bgcolor: alpha(theme.palette.primary.main, 0.9)
                            }
                          }}
                          type='button'
                          onClick={resetTheme}
                        >
                          Reset Theme
                        </Button>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Button
                          fullWidth
                          variant='contained'
                          sx={{
                            bgcolor: theme.palette.primary.main,
                            '&:hover': {
                              bgcolor: alpha(theme.palette.primary.main, 0.9)
                            }
                          }}
                          type='submit'
                        >
                          Update Theme
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Form>
              </Box>
            </CardContent>
          </Card>
        )
      }}
    </Formik>
  )
}

export default ThemeSettings
