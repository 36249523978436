import React from 'react'
import { Modal, Box, Typography, Button, CircularProgress } from '@mui/material'

const DeleteVariantDialog = ({
  open,
  onClose,
  onConfirm,
  isLoading,
  status
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='delete-variant-title'
      aria-describedby='delete-variant-description'
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 450,
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: 2,
          p: 3,
          textAlign: 'center'
        }}
      >
        <Typography
          id='delete-variant-title'
          sx={{ fontWeight: 'bold', mb: 2, fontSize: '1.8rem', color: '#595959' }}
        >
          Are you sure?
        </Typography>
        {status !== 'draft' && (
          <>
            <Typography
              id='delete-variant-description'
              sx={{ fontSize: '14px', color: '#333', mb: 2 }}
            >
              You’re trying to delete a variant for a launched campaign.
            </Typography>
            <Typography
              id='delete-variant-description1'
              sx={{ fontSize: '14px', color: '#333', mb: 2 }}
            >
              This can cause issues with the
              campaign’s analytics reporting – are you sure you want to proceed?
            </Typography>
            <Typography
              sx={{
                fontSize: '14px',
                color: 'error.main',
                fontWeight: 'bold',
                mb: 4
              }}
            >
              Warning: This action cannot be undone once confirmed.
            </Typography>
          </>
        )}
        {status === 'draft' && (
          <Typography
            id='delete-variant-description'
            sx={{ fontSize: '14px', color: '#333', mb: 2 }}
          >
            This action will permanently delete the variant. Do you want to proceed?
          </Typography>
        )}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: 2
          }}
        >
          <Button
            variant='contained'
            color='error'
            onClick={onConfirm}
            sx={{
              textTransform: 'none',
              fontWeight: 'bold',
              borderRadius: '8px',
              padding: '8px 16px',
              fontSize: '14px'
            }}
          >
            {isLoading
              ? (
                <CircularProgress size={20} sx={{ color: 'white' }} />
                )
              : (
                  'Delete variant'
                )}
          </Button>
          <Button
            variant='outlined'
            onClick={onClose}
            sx={{
              textTransform: 'none',
              fontWeight: 'bold',
              borderRadius: '8px',
              padding: '8px 16px',
              fontSize: '14px'
            }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default DeleteVariantDialog
