import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, CircularProgress } from '@mui/material'
const DeleteStepDialog = ({ open, onClose, onConfirm, isLoading }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <Box
        sx={{
          width: 500,
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: 2,
          p: 2,
          textAlign: 'center'
        }}
      >
        <DialogTitle sx={{ fontSize: '24px', textAlign: 'center' }}>Are you sure?</DialogTitle>
        <DialogContent>
          Deleting this step may affect the campaign's analytics and reporting accuracy.
        </DialogContent>
        <DialogActions sx={{ mb: 3, justifyContent: 'center' }}>
          <Button
            onClick={onConfirm} variant='contained' color='error'
            sx={{
              textTransform: 'none',
              fontWeight: 'bold',
              borderRadius: '8px',
              padding: '8px 16px',
              fontSize: '14px'
            }}
          >
            {isLoading
              ? (
                <CircularProgress size={20} sx={{ color: '#D32F2F' }} />
                )
              : (
                  'Delete Step'
                )}
          </Button>
          <Button
            variant='outlined'
            onClick={onClose}
            sx={{
              textTransform: 'none',
              fontWeight: 'bold',
              borderRadius: '8px',
              padding: '8px 16px',
              fontSize: '14px'
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default DeleteStepDialog
