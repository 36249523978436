import React from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import RefreshIcon from '@mui/icons-material/Refresh'
import { useTheme } from '@mui/material'

const RefreshButton = ({ onClick, filter }) => {
  const theme = useTheme()
  return (
    <Button
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '13px',
        fontWeight: 700,
        lineHeight: '16.38px',
        color: `${theme.palette.primary.primaryText}`,
        backgroundColor: 'white',
        mr: 2,
        '&:hover': {
          backgroundColor: 'white'
        },
        border: filter ? `1px solid ${theme.palette.primary.main}` : '1px solid #E4E4E5',
        height: '40px',
        px: 2
      }}
      onClick={onClick}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mr: 1 }}>
        <RefreshIcon sx={{ fontSize: 22 }} />
      </Box>
      Refresh
    </Button>
  )
}

export default RefreshButton
