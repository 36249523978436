export const OfRadioCustomIcon = () => {
  return (
    <>
      <svg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <rect x='1' y='1' width='19' height='19' rx='9.5' fill='white' />
        <rect x='1' y='1' width='19' height='19' rx='9.5' stroke='#D0D5DD' />
      </svg>
    </>
  )
}
