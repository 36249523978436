import React from 'react'
import { Button, useTheme } from '@mui/material'
import Logout from '@mui/icons-material/Logout'

const LogoutButton = ({ onClick }) => {
  const theme = useTheme()
  return (
    <Button
      sx={{
        mt: 2,
        backgroundColor: `${theme.palette.primary.main}`,
        color: 'white',
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '18px',
        borderRadius: '8px',
        px: 4,
        py: 1.8,
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
          boxShadow: 10
        }
      }}
      onClick={onClick}
    >
      Logout
      <Logout fontSize='small' sx={{ ml: 1, color: 'white' }} />
    </Button>
  )
}

export default LogoutButton
