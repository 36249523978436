import { useState, useEffect } from 'react'
import {
  Box,
  Button,
  Grid,
  IconButton,
  CircularProgress,
  Popover,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  useTheme,
  alpha
} from '@mui/material'
import CampaignBlock from 'src/components/campaigns/campaignBlock'
import { CalendarIcon } from 'src/assets/general/CalendarIcon'
import { DropDown } from 'src/assets/general/DropDown'
import { FilterIcon } from 'src/assets/general/FilterIcon'
import { PlayIcon } from 'src/assets/general/PlayIcon'
import { DraftIcon } from 'src/assets/general/DraftIcon'
import { PauseIcon } from 'src/assets/general/PauseIcon'
import { CompletedIcon } from 'src/assets/general/CompletedIcon'
import { ErrorIcon } from 'src/assets/general/ErrorIcon'
import { EACloseIcon } from 'src/assets/emailAccounts/EACloseIcon'
import * as Yup from 'yup'
import toast from 'react-hot-toast'
import { useFormik } from 'formik'
import {
  useCreateCampaignMutation,
  useGetCampaignsPaginationMutation
} from 'src/services/campaign-service'
import { EDSCancelIconBlue } from 'src/assets/emailAccounts/emailDrawer/EDSettingsTab/EDSCancelIcon'
import { ArrowRightLong } from 'src/assets/general/ArrowRightLong'
import { AiOutlineClose } from 'react-icons/ai'
import NotificationSearchAdd from 'src/components/notificationSearchAdd'
import Pagination from 'src/components/Pagination'
import AddNewCampaignBlock from 'src/components/campaigns/addNewCampaignBlock'
import RefreshButton from 'src/components/RefreshButton'
import HeaderWithPopover from 'src/components/HeaderWithPopover'
import { useSelector } from 'react-redux'
import { useAuth } from 'src/auth/auth'
import { useGetMeAndWorkspaceQuery } from 'src/services/user-service'
import { timezoneArray } from 'src/assets/data.js'
import { useGetCustomAttributesQuery } from 'src/services/intercom-service'

const filterButtons = (theme) => [
  {
    name: 'Active Status',
    value: 'active',
    icon: (active) => <PlayIcon color={active ? `${theme.palette.primary.main}` : `${theme.palette.primary.primaryText}`} />
  },
  {
    name: 'In Draft',
    value: 'draft',
    icon: (active) => <DraftIcon color={active ? `${theme.palette.primary.main}` : `${theme.palette.primary.primaryText}`} />
  },
  {
    name: 'On Pause',
    value: 'paused',
    icon: (active) => <PauseIcon color={active ? `${theme.palette.primary.main}` : `${theme.palette.primary.primaryText}`} />
  },
  {
    name: 'Error Detected',
    value: 'error',
    icon: (active) => <ErrorIcon color={active ? `${theme.palette.primary.main}` : `${theme.palette.primary.primaryText}`} />
  },
  {
    name: 'Completed',
    value: 'completed',
    icon: (active) => <CompletedIcon color={active ? `${theme.palette.primary.main}` : `${theme.palette.primary.primaryText}`} />
  }
]

const sortButtons = [
  {
    name: 'Sort by Name',
    value: 'name'
  },
  {
    name: 'Sort by Date Created',
    value: '-createdAt'
  }
]

const Page = () => {
  const [createCampaign] = useCreateCampaignMutation()
  const [campaign, setCampaign] = useState([])
  const [campaignCreate, setCampaignCreate] = useState(true)
  const [isCampaignsLoading, setIsCampaignsLoading] = useState(false)
  const workspace = useSelector((state) => state.workspace)
  const [workspaceId, setWorkspaceId] = useState()
  const { canEdit } = useAuth()
  const { refetch } = useGetCustomAttributesQuery()

  useEffect(() => {
    setWorkspaceId(workspace?._id)
  }, [workspace])

  // const getTimezoneOffset = () => {
  //   function z (n) {
  //     return (n < 10 ? '0' : '') + n
  //   }
  //   let offset = new Date().getTimezoneOffset()
  //   const sign = offset < 0 ? '+' : '-'
  //   offset = Math.abs(offset)
  //   return sign + z((offset / 60) | 0) + ':' + z(offset % 60)
  // }
  const getUserTimezone = (isOffset) => {
    // const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // const userTimezone =  Intl.DateTimeFormat('en-US', { timeZone: 'America/New_York' }).resolvedOptions().timeZone;
    // const userTimezone = workspace?.timezone
    const userTimezone = 'America/New_York'
    // const offset = getTimezoneOffset()
    const defaultTimeZone = 'America/New_York (GMT-05:00)'
    // return userTimezone;
    return isOffset ? defaultTimeZone : userTimezone
  }

  const formik = useFormik({
    initialValues: {
      name: 'Your Campaign Title'
    },
    validationSchema: Yup.object({
      name: Yup.string().max(255).required('Name is required')
    }),
    onSubmit: async (values, helpers) => {
      try {
        const trimmedName = values.name.trim()
        if (!trimmedName) {
          helpers.setFieldError('name', 'Campaign Name is required')
          setOpenAddCampaignDialog(true)
          return
        }
        values.name = trimmedName
        values.tzFormat = getUserTimezone(true)
        values.tz = getUserTimezone(false)
        values.workspaceId = workspaceId
        const { message } = await createCampaign(values).unwrap()
        refetch()
        if (message === 'Campaign with same name already exist') {
          helpers.setFieldError('name', message)
          setOpenAddCampaignDialog(true)
        } else {
          toast.success(message)
          setOpenAddCampaignDialog(false)
          setCampaignCreate(true)
          formik.setFieldValue('name', 'Your Campaign Title')
          window.Intercom('trackEvent', 'Campaign created')
        }
      } catch (err) {
        helpers.setErrors({ submit: err.data.error.message })
        setOpenAddCampaignDialog(true)
      }
    }
  })

  const [openAddCampaignDialog, setOpenAddCampaignDialog] = useState(false)

  const handleClickOpenAddDialog = () => {
    setOpenAddCampaignDialog(true)
  }

  const handleCloseAddDialog = () => {
    setOpenAddCampaignDialog(false)
  }

  const [search, setSearch] = useState('')
  const [filter, setFilter] = useState(null)
  const [sort, setSort] = useState(sortButtons[1])
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [isLoadingMoreCampaign] = useState(false)
  const [campaignChange, setCampaignChange] = useState(false)
  const [getCampaigns] = useGetCampaignsPaginationMutation()
  const offset = campaign.length
  const [refresh, setRefresh] = useState(false)
  // const limit = 15;

  const onCampaignChange = () => {
    setCampaignChange(true)
  }

  const handleSearchChange = (event) => {
    setSearch(event.target.value)
    setPage(1)
  }

  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  useEffect(() => {
    if (page === 1 && workspaceId) {
      setCampaignCreate(false)
      setCampaignChange(false)
      setIsCampaignsLoading(true)
      const timer = setTimeout(async () => {
        setIsCampaignsLoading(true)
        const { docs, totalDocs } = await getCampaigns({
          search,
          filter: filter?.value,
          sortBy: sort?.value,
          limit,
          zone: userTimezone,
          workspaceId
        }).unwrap()
        setTotal(totalDocs)
        window.Intercom('update', {
          campaigns_created: totalDocs
        })
        setCampaign(docs)
        setIsCampaignsLoading(false)
      }, 500)

      return () => clearTimeout(timer)
    }
  }, [search, filter, sort, campaignChange, campaignCreate, getCampaigns, page, limit, refresh, workspaceId])

  useEffect(() => {
    if (offset < total && page > 1 && workspaceId) {
      const timer = setTimeout(async () => {
        setIsCampaignsLoading(true)
        const { docs, totalDocs } = await getCampaigns({
          search,
          filter: filter?.value,
          sortBy: sort?.value,
          offset: offset * (page - 1),
          limit,
          zone: userTimezone,
          workspaceId
        }).unwrap()
        setTotal(totalDocs)
        setCampaign(docs)
        setIsCampaignsLoading(false)
      }, 500)
      return () => clearTimeout(timer)
    }
  }, [page, search, filter, sort, refresh, workspaceId])

  const handleLimitChange = (event) => {
    setLimit(event.target.value)
    setPage(1)
  }

  const handleSelectFilter = (filter) => {
    setFilter(filter)
    setPage(1)
  }

  const [anchorEl, setAnchorEl] = useState(null)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const [anchorEl2, setAnchorEl2] = useState(null)
  const handleClickSort = (event) => {
    setAnchorEl2(event.currentTarget)
  }
  const handleCloseSort = () => {
    setAnchorEl2(null)
  }
  const open2 = Boolean(anchorEl2)
  const id2 = open ? 'simple-popover' : undefined
  const [anchorEl3, setAnchorEl3] = useState(null)
  const handleClick3 = (event) => {
    setAnchorEl3(event.currentTarget)
  }
  const handleClose3 = () => {
    setAnchorEl3(null)
  }
  const open3 = Boolean(anchorEl3)
  const theme = useTheme()

  const handleRefresh = () => {
    setRefresh(!refresh)
  }

  // const [workspace, setWorkspace] = useState(null);

  const handleWorkspaceChange = (newWorkspace) => {
    // setWorkspace(newWorkspace);
  }
  const { data } = useGetMeAndWorkspaceQuery()

  function getLabelFromValue (value) {
    const timezone = timezoneArray.find(item => item.value.startsWith(value))
    return timezone ? timezone.label.split(' ')[1] : 'Label not found'
  }

  const title = (data?.workspace?.enterprise && data?.workspace?.campaign?.uiForCampaign)
    ? 'Campaigns'
    : 'Campaigns' // Changed by Mubashar

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          width: '100%',
          height: '100%'
        }}
      >
        <Box
          sx={{
            width: '90%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center'
          }}
        >
          <HeaderWithPopover title={title} onWorkspaceChange={handleWorkspaceChange} selectedTimeline={data?.workspace} />
          {workspace?.enterprise && !workspace?.campaign?.uiForCampaign && (
            <Typography
              sx={{
                fontSize: '15px',
                fontWeight: 700,
                lineHeight: '16.38px',
                color: `${theme.palette.primary.main}`,
                mt: 3
              }}
            >
              Purchase the Campaign UI Plan to manage your campaign process effectively.
            </Typography>
          )}
          {workspace._id && ((workspace?.campaign?.uiForCampaign && workspace?.enterprise) || !workspace?.enterprise) && (
            <>
              <Box sx={{ display: 'flex', alignItems: 'flex-end', width: '100%' }}>
                <Box sx={{ marginLeft: 'auto' }}>
                  <NotificationSearchAdd
                    handleNotificationClick={handleClick3}
                    handleSearch={handleSearchChange}
                    handleAdd={handleClickOpenAddDialog}
                    canEdit={canEdit}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  mt: 2
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Button
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'left',
                      fontSize: '13px',
                      fontWeight: 700,
                      lineHeight: '16.38px',
                      color: `${theme.palette.primary.primaryText}`,
                      backgroundColor: '#fff',
                      px: 1.5,
                      mr: 2,
                      '&:hover': {
                        backgroundColor: '#fff'
                      },
                      border: '1px solid #E4E4E5',
                      height: '40px'
                    }}
                    color='inherit'
                    onClick={handleClickSort}
                  >
                    <Box
                      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mr: 1 }}
                    >
                      <CalendarIcon />
                    </Box>

                    {sort.name}
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        ml: 1,
                        transform: open2 && 'rotate(-180deg)'
                      }}
                    >
                      <DropDown />
                    </Box>
                  </Button>
                  <RefreshButton onClick={handleRefresh} />
                  <Button
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: '13px',
                      fontWeight: 700,
                      lineHeight: '16.38px',
                      color: `${theme.palette.primary.primaryText}`,
                      backgroundColor: 'white',
                      mr: 2,
                      '&:hover': {
                        backgroundColor: 'white'
                      },
                      border: filter ? `1px solid ${theme.palette.primary.main}` : '1px solid #E4E4E5',
                      height: '40px',
                      px: 2
                    }}
                  >
                    Timezone: {getLabelFromValue(workspace?.timezone?.split(' ')[0])}
                  </Button>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {filter && (
                    <Button
                      sx={{
                        display: { xs: 'none', sm: 'flex' },
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '13px',
                        fontWeight: 700,
                        lineHeight: '16.38px',
                        color: theme.palette.primary.main,
                        backgroundColor: alpha(theme.palette.primary.main, 0.1),

                        mr: 2,
                        '&:hover': {
                          backgroundColor: alpha(theme.palette.primary.main, 0.2)
                          // boxShadow: 10,
                        },

                        height: '40px',
                        px: 2
                      }}
                      onClick={() => handleSelectFilter(null)}
                    >
                      {filter.name}
                      <Box
                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', ml: 1 }}
                      >
                        <AiOutlineClose />
                      </Box>
                    </Button>
                  )}
                  <Button
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: '13px',
                      fontWeight: 700,
                      lineHeight: '16.38px',
                      color: `${theme.palette.primary.primaryText}`,
                      backgroundColor: 'white',
                      // mr: 2,
                      '&:hover': {
                        backgroundColor: 'white'
                      },
                      border: filter ? `1px solid ${theme.palette.primary.main}` : '1px solid #E4E4E5',
                      height: '40px',
                      px: 2
                    }}
                    onClick={handleClick}
                  >
                    <Box
                      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mr: 1 }}
                    >
                      <FilterIcon />
                    </Box>
                    Filter
                  </Button>
                </Box>
              </Box>
              {filter && (
                <Box sx={{ display: { xs: 'block', sm: 'none' }, width: '100%', mt: 2 }}>
                  <Button
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: '13px',
                      fontWeight: 700,
                      lineHeight: '16.38px',
                      color: theme.palette.primary.main,
                      backgroundColor: alpha(theme.palette.primary.main, 0.1),

                      mr: 2,
                      '&:hover': {
                        backgroundColor: alpha(theme.palette.primary.main, 0.2)
                        // boxShadow: 10,
                      },

                      height: '40px',
                      px: 2
                    }}
                    onClick={() => handleSelectFilter(null)}
                  >
                    {filter.name}
                    <Box
                      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', ml: 1 }}
                    >
                      <AiOutlineClose />
                    </Box>
                  </Button>
                </Box>
              )}

              {isCampaignsLoading && !isLoadingMoreCampaign
                ? (
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 10 }}>
                    <CircularProgress size={25} thickness={5} />
                    <Typography sx={{ fontSize: '16px', fontWeight: 600, color: `${theme.palette.primary.main}`, ml: 2 }}>
                      Loading...
                    </Typography>
                  </Box>
                  )
                : (
                  <Grid container sx={{ mt: 1 }} spacing={3}>
                    {campaign?.map((campaign) => {
                      return (
                        <Grid item key={campaign._id} xs={12} sm={6} md={4}>
                          <CampaignBlock campaign={campaign} onCampaignChange={onCampaignChange} canEdit={canEdit} />
                        </Grid>
                      )
                    })}
                    {canEdit && (
                      <Grid item key={0} xs={12} sm={6} md={4}>
                        <AddNewCampaignBlock onClick={handleClickOpenAddDialog} />
                      </Grid>
                    )}
                    {Math.ceil(total / limit) > 0 && (
                      <Grid
                        item
                        xs={12}
                        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                      >
                        <Pagination
                          page={page}
                          setPage={setPage}
                          total={total}
                          length={campaign?.length}
                          limit={limit}
                          handleLimitChange={handleLimitChange}
                        />
                      </Grid>
                    )}
                  </Grid>
                  )}
            </>
          )}
        </Box>
      </Box>
      <Popover
        id={id}
        open={open3}
        anchorEl={anchorEl3}
        onClose={handleClose3}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: 200
          }}
        >
          <Typography sx={{ p: 2, fontSize: '14px', fontWeight: 600 }}>No notifications</Typography>
        </Box>
      </Popover>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{ mt: 0.5 }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            p: 1,
            width: '200px'
          }}
        >
          {filterButtons(theme).map((item, i) => {
            return (
              <Button
                key={i}
                fullWidth
                sx={{
                  py: 1,
                  px: 1,

                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  color: '#101828',
                  fontSize: '13px',
                  backgroundColor: filter?.name === item.name && '#F2F4F6'
                }}
                onClick={() => {
                  setFilter(item)
                  setPage(1)
                  handleClose()
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                  {item.icon(filter?.name === item.name)}
                  <Typography
                    sx={{
                      color: filter?.name === item.name ? `${theme.palette.primary.main}` : `${theme.palette.primary.primaryText}`,
                      fontSize: '13px',
                      fontWeight: 700,
                      linHeight: '16px',
                      letterSpacing: '0px',
                      ml: 2
                    }}
                  >
                    {item.name}
                  </Typography>
                </Box>
              </Button>
            )
          })}
        </Box>
      </Popover>

      <Popover
        id={id2}
        open={open2}
        anchorEl={anchorEl2}
        onClose={handleCloseSort}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        sx={{ mt: 0.5 }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            p: 1.1,
            width: '220px'
          }}
        >
          {sortButtons.map((item, i) => {
            return (
              <Button
                key={i}
                fullWidth
                sx={{
                  py: 1.1,
                  px: 2,
                  borderRadius: '5px',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  color: '#101828',
                  fontSize: '13px',
                  backgroundColor: sort?.name === item.name && 'rgb(33, 111, 237, 0.1)'
                }}
                onClick={() => {
                  setSort(item)
                  handleCloseSort()
                  setPage(1)
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                  {item.name}
                </Box>
              </Button>
            )
          })}
        </Box>
      </Popover>

      <Dialog
        open={openAddCampaignDialog}
        onClose={handleCloseAddDialog}
        fullWidth
        maxWidth='sm'
        sx={{ backgroundColor: 'rgba(4, 4, 30, 0.5)' }}
      >
        <DialogTitle>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              width: '100%'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                flexDirection: 'column'
              }}
            >
              <Typography
                sx={{
                  fontSize: '20px',
                  fontWeight: 700,
                  lineHeight: '28px',
                  color: `${theme.palette.primary.primaryText}`
                }}
              >
                Let's launch a fresh campaign! 🔥
              </Typography>
              <Typography
                sx={{
                  fontSize: '13px',
                  fontWeight: 400,
                  lineHeight: '20px',
                  color: '#8181B0'
                }}
              >
                What name do you have in mind?
              </Typography>
            </Box>
            <IconButton
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              onClick={handleCloseAddDialog}
            >
              <EACloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <form noValidate onSubmit={formik.handleSubmit}>
          <DialogContent>
            <TextField
              fullWidth
              placeholder='Enter campaign name'
              variant='outlined'
              defaultValue='Your Campaign Title'
              sx={{
                width: '100%',
                height: 40,
                backgroundColor: 'white',
                '& div': { pl: 0.3 },
                '& div fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
                '& div input': {
                  py: 2,
                  fontSize: '13px',
                  fontWeight: 400,
                  lineHeight: '16px',
                  letterSpacing: '0em',
                  '&::placeholder': {
                    color: 'rgba(40, 40, 123, 0.5)'
                  }
                },
                mt: 0,
                mb: 6
              }}
              error={!!(formik.touched.name && formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              name='name'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.name}
            />
            {formik.errors.submit && (
              <Typography
                color='error'
                sx={{ mt: 3, textAlign: 'center', width: '100%' }}
                variant='body2'
              >
                {formik.errors.submit}
              </Typography>
            )}
            {formik.isSubmitting
              ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 10 }}>
                  <CircularProgress size={25} thickness={5} />
                  <Typography sx={{ fontSize: '16px', fontWeight: 600, color: `${theme.palette.primary.main}`, ml: 2 }}>
                    Loading...
                  </Typography>
                </Box>
                )
              : (
                <Grid container columnSpacing={2}>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      variant='outlined'
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '14px',
                        fontWeight: 700,
                        lineHeight: '14px',
                        py: 1.5
                      }}
                      onClick={handleCloseAddDialog}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          mr: 1
                        }}
                      >
                        <EDSCancelIconBlue />
                      </Box>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      variant='contained'
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '14px',
                        fontWeight: 700,
                        lineHeight: '14px',
                        py: 1.5
                      }}
                      disabled={!formik.isValid}
                      type='submit'
                    >
                      <>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            mr: 1
                          }}
                        >
                          <ArrowRightLong />
                        </Box>
                        Continue
                      </>
                    </Button>
                  </Grid>
                </Grid>
                )}
          </DialogContent>
        </form>
      </Dialog>
    </>
  )
}

export default Page
