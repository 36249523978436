import React, { useState } from 'react'
import { Drawer, Box } from '@mui/material'
import { PersonCompanyTab } from './PersonCompanyTab'
const LeadDetailModal = ({
  isOpen, onClose, user, labels, onUpdateComplete,
  handleAddToListClick, isConfirmingLeads,
  handleAddToCampaignClick,
  tab
}) => {
  const [activeTab, setActiveTab] = useState('person')
  return (
    <Drawer
      anchor='right'
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: '600px',
          maxWidth: '600px',
          height: '90%',
          marginTop: '40px',
          borderRadius: '16px',
          boxShadow: '-26px -22px 9px -12px #E1E3EC'
        }
      }}
      ModalProps={{
        keepMounted: true,
        BackdropProps: {
          sx: {
            background: 'none'
          }
        }
      }}
    >
      <Box sx={{ padding: '15px 20px 0px 20px' }}>
        <Box
          sx={{
            display: 'flex',
            borderBottom: '1px solid #E5E7EB',
            backgroundColor: '#E1E3EC',
            borderRadius: '8px',
            overflow: 'hidden',
            marginBottom: '16px'
          }}
        >
          {(tab === 'companies' ? ['company'] : ['person', 'company']).map((tab) => (
            <Box
              key={tab}
              onClick={() => setActiveTab(tab)}
              sx={{
                flex: 1,
                textAlign: 'center',
                padding: '8px 0',
                fontWeight: activeTab === tab ? 'bold' : 'normal',
                color: activeTab === tab ? '#111827' : '#8181B0',
                backgroundColor: activeTab === tab ? '#FFFFFF' : 'transparent',
                cursor: 'pointer',
                transition: 'all 0.3s ease',
                margin: '6px',
                borderRadius: '8px',
                fontSize: '14px',
                boxShadow: activeTab === tab ? '0px 2px 4px rgba(0, 0, 0, 0.1)' : 'none'
              }}
            >
              {tab.charAt(0).toUpperCase() + tab.slice(1)}
            </Box>
          ))}
        </Box>
      </Box>
      <PersonCompanyTab
        user={user}
        activeTab={activeTab}
        companiesTab={tab === 'companies'}
        labels={labels}
        onUpdateComplete={onUpdateComplete}
        handleAddToListClick={handleAddToListClick}
        handleAddToCampaignClick={handleAddToCampaignClick}
        isConfirmingLeads={isConfirmingLeads}
      />
    </Drawer>
  )
}
export default LeadDetailModal
