import React, { useState } from 'react'
import {
  Box,
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress
} from '@mui/material'
import DataNotFoundImage from '../../assets/no_data_found_image.png'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useDmarkLookUpMutation } from 'src/services/tool-service.js'
import { Helmet } from 'react-helmet-async'

const DmarkCheckerPage = () => {
  const [domainDetails, setDomainDetails] = useState([])
  const [dmarkLookUp] = useDmarkLookUpMutation()
  const validationSchema = () => {
    const schema = Yup.object({
      domain: Yup.string()
        .max(255)
        .required('Domain is required')
        .matches(/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/, 'Please add a valid Domain address')
    })

    return schema
  }

  const handleDomainDataSubmit = async (values, helpers) => {
    try {
      const result = await dmarkLookUp(values).unwrap()

      if (result.message === 'Fetched Error') {
        setDomainDetails([{ noDomainFound: `${formik?.values?.domain}: DMARC Record not available ` }])
      } else {
        const dmarkParts = result.dmarcRecord[0].join(' ').split(' ')
        const dmarkDataInArray = []

        dmarkParts.forEach((part) => {
          if (part.startsWith('v=')) {
            dmarkDataInArray.push({
              tag: 'v',
              value: part.split('=')[1],
              name: 'version',
              description: 'The v tag is required and represents the protocol version. An example is v=DMARC1'
            })
          } else if (part.startsWith('p=')) {
            dmarkDataInArray.push({
              tag: 'p',
              value: part.split('=')[1],
              name: 'policy',
              description: 'The required p tag demonstrates the policy for domain (or requested handling policy). It directs the receiver to report, quarantine, or reject emails that fail authentication checks. Policy options are: 1) None 2) Quarantine or 3) Reject.'
            })
          } else if (part.startsWith('pct=')) {
            dmarkDataInArray.push({
              tag: 'pct',
              value: part.split('=')[1],
              name: 'Percentage',
              description: 'This DMARC tag specifies the percentage of email messages subjected to filtering. For example, pct=25 means a quarter of your company’s emails will be filtered by the recipient.'
            })
          } else if (part.startsWith('rua=')) {
            dmarkDataInArray.push({
              tag: 'rua',
              value: part.split('=')[1],
              name: 'Receivers',
              description: 'This optional tag is designed for reporting URI(s) for aggregate data. An rua example is rua=mailto:CUSTOMER@for.example.com.'
            })
          } else if (part.startsWith('ruf=')) {
            dmarkDataInArray.push({
              tag: 'ruf',
              value: part.split('=')[1],
              name: 'Forensic Receivers',
              description: 'Like the rua tag, the ruf designation is an optional tag. It directs addresses to which message-specific forensic information is to be reported (i.e., comma-separated plain-text list of URIs). An ruf example is ruf=mailto:CUSTOMER@for.example.com.'
            })
          } else if (part.startsWith('fo=')) {
            dmarkDataInArray.push({
              tag: 'fo',
              value: part.split('=')[1],
              name: 'Forensic Receivers',
              description: 'The FO tag pertains to how forensic reports are created and presented to DMARC users.'
            })
          } else if (part.startsWith('adkim=')) {
            dmarkDataInArray.push({
              tag: 'adkim',
              value: part.split('=')[1],
              name: 'ADKIM Tag',
              description: 'Similar to aspf, the optional adkim tag is the alignment mode for the DKIM protocol. A sample tag is adkim=r.'
            })
          } else if (part.startsWith('aspf=')) {
            dmarkDataInArray.push({
              tag: 'aspf',
              value: part.split('=')[1],
              name: 'ASPF Tag',
              description: 'The aspf tag represents alignment mode for SPF. An optional tag, aspf=r is a common example of its configuration.'
            })
          } else if (part.startsWith('rf=')) {
            dmarkDataInArray.push({
              tag: 'rf',
              value: part.split('=')[1],
              name: 'forensic reports',
              description: 'defines the format of forensic reports. An rf example is rf=afrf'
            })
          } else if (part.startsWith('ri=')) {
            dmarkDataInArray.push({
              tag: 'ri',
              value: part.split('=')[1],
              name: 'Reporting Interval',
              description: 'sets the interval for sending reports in seconds. An ri example is ri=3600'
            })
          }
        })
        setDomainDetails([{ dmarkRecord: result.dmarcRecord[0], dmarkRecordArray: dmarkDataInArray }])
      }
    } catch (err) {
      helpers.setErrors({ submit: err.data.error.message })
    }
  }

  const formik = useFormik({
    initialValues: {
      domain: ''
    },
    validationSchema,
    onSubmit: async (values, helpers) => {
      try {
        await handleDomainDataSubmit(values, helpers, true)
      } catch (err) {
        helpers.setErrors({ submit: err.data.error.message })
      }
      formik.resetForm()
    }
  })

  return (
    <Box sx={{ px: { sm: '7%' }, py: { sm: 5 }, p: 4, fontFamily: 'Inter' }}>
      <Helmet>
        <title> DMARC Check Tool - Enforce Email Authentication</title>
        <meta name='description' content='Identify DMARC record issues for your domain and strengthen email authentication policies. Check your DMARC status now!' />
      </Helmet>
      <Typography variant='h5' sx={{ fontWeight: 'bold', color: 'primary.main' }}>
        DMARC Check Tool: Verify Your Domain's DMARC Records for Enhanced Security
      </Typography>
      <Typography color='primary.main' sx={{ fontSize: '0.8rem' }}>
        Find DMARC record issues for specific domains, helping enforce email authentication policies.
      </Typography>

      {/* Dmark Form */}
      <form noValidate onSubmit={formik.handleSubmit}>
        <Box sx={{ display: 'flex', alignItems: 'baseline', flexDirection: { xs: 'column', sm: 'row' }, mt: { xs: '24px', sx: '24px', md: 3, lg: 3, xl: 3 } }}>
          <Box sx={{ display: 'flex', gap: { sm: 2 }, flexDirection: { xs: 'column', sm: 'row' }, mb: { xs: 0, sm: 0 }, width: { xs: '100%', sm: 'auto' } }}>
            <TextField
              variant='outlined'
              placeholder='Enter a domain address'
              onChange={formik.handleChange}
              error={!!(formik.touched.domain && formik.errors.domain)}
              helperText={formik.touched.domain && formik.errors.domain}
              onBlur={formik.handleBlur}
              name='domain'
              value={formik?.values?.domain}
              sx={{
                borderColor: 'gray.300',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  width: { md: '35vw', sm: 'auto', xs: 'auto' },
                  maxHeight: '44px',
                  color: 'primary.main'
                },
                mb: { xs: 0, sm: 0 },
                padding: { xs: '8px', sm: '11px' }
              }}
            />

          </Box>
          <Button
            variant='contained'
            color='primary'
            sx={{
              ml: [0, 4],
              px: 4,
              py: 2,
              width: { sm: 'fit-content', xs: '100%' },
              padding: '10px',
              marginLeft: { xs: '7px', sm: '4' },
              marginTop: { xs: 2, sm: 0 },
              maxHeight: '44px',
              cursor: formik?.values?.domain ? 'pointer' : 'not-allowed'
            }}
            disabled={!formik?.values?.domain}
            type='submit'
          >
            {formik.isSubmitting
              ? (
                <CircularProgress size={20} color='inherit' />
                )
              : (
                <>
                  Look up
                </>
                )}
          </Button>
        </Box>

        {formik.errors.submit && (
          <Box sx={{ display: 'flex', alignItems: 'flex-end', mt: 3 }}>
            <Typography color='error' sx={{ mt: 3, textAlign: 'right' }} variant='body2'>
              {formik.errors.submit}
            </Typography>
          </Box>
        )}
      </form>

      {domainDetails.length > 0 && domainDetails[0] &&
        <Box mt={4} sx={{ mt: { sm: 6 }, my: 2, backgroundColor: domainDetails[0].noDomainFound ? '#ffcbd1' : '#d6e9c6', p: 3, borderRadius: '8px', mb: 4, color: '#3c763d' }}>
          <Typography sx={{
            backgroundColor: domainDetails[0].noDomainFound ? '#ffcbd1' : '#d6e9c6',
            padding: '8px',
            borderRadius: '8px',
            color: domainDetails[0].noDomainFound ? '#EF4444' : '#3c763d'
          }}
          >
            {domainDetails[0].dmarkRecord ? domainDetails[0].dmarkRecord : domainDetails[0].noDomainFound ? domainDetails[0].noDomainFound : ''}
          </Typography>
        </Box>}

      {/* result Table */}
      <Box
        sx={{
          height: 'auto',
          mt: { sm: 6, xs: 6 },
          border: 8,
          borderColor: '#ECEDF5',
          borderRadius: '8px'
        }}
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow sx={{ backgroundColor: '#F6F6FA', color: '#9295A7', fontSize: '1.2rem', fontWeight: 'medium' }}>
                <TableCell sx={{ px: 4, py: 2 }}>TAG</TableCell>
                <TableCell sx={{ px: 4, py: 2 }}>VALUE</TableCell>
                <TableCell sx={{ px: 4, py: 2 }}>NAME</TableCell>
                <TableCell sx={{ px: 4, py: 2 }}>DESCRIPTION</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {domainDetails.length !== 0 && domainDetails[0].dmarkRecordArray
                ? (
                    domainDetails[0].dmarkRecordArray.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>{row.tag}</TableCell>
                        <TableCell>{row.value}</TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.description}</TableCell>
                      </TableRow>
                    ))
                  )
                : (
                  <TableRow>
                    <TableCell colSpan={5} sx={{ px: 4, py: 2, textAlign: 'center' }}>
                      <Typography sx={{ mt: { sm: '5vh', xs: '2vh', fontSize: '0.8rem' } }}>
                        {domainDetails[0]?.noDomainFound ? domainDetails[0].noDomainFound : 'Simply enter a domain or IP address, click "Look up", and get instant results.'}
                      </Typography>
                      <Box sx={{ display: 'flex', justifyContent: 'center', mt: '10vh' }}>
                        <img src={DataNotFoundImage} alt='Doc Icon' />
                      </Box>
                    </TableCell>
                  </TableRow>
                  )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  )
}

export default DmarkCheckerPage
