import { CloudUploadTwoTone, TaskAlt } from '@mui/icons-material'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
  , useTheme
} from '@mui/material'
import { Google } from 'src/assets/Google.js'
import FileUploadCsv from '../emailAccounts/FileUploadCsv.js'
import { useEffect, useState } from 'react'
import { fetchGoogleSheet } from 'src/utils/util.js'
import { toast } from 'react-hot-toast'
import { OffCheckboxCustomIcon } from 'src/assets/general/OffCheckboxCustomIcon.js'
import { OnCheckboxCustomIcon } from 'src/assets/general/OnCheckboxCustomIcon.js'
import { useCreateLeadsMutation, useDuplicateCheckMutation } from 'src/services/campaign-service.js'
import {
  handleDialogUploadAllClick,
  handleUploadAllClick,
  handleSelectTypeChange,
  updateLeads,
  selections,
  selectionsFilters,
  filterLeads
} from './commonFunctions.js'
import { useSelector } from 'react-redux'
import { useBillingUsageQuery } from 'src/services/billing-service.js'
import { useGetCustomAttributesQuery } from 'src/services/intercom-service.js'

const GoogleSheetImport = ({ campaign, onLeadsCreate, setSnackbarMsg, setSnackbarOpen, filter = null }) => {
  const theme = useTheme()
  const [googleSheetLink, setGoogleSheetLink] = useState('')
  const [isImporting, setIsImporting] = useState(false)
  const [csvData, setCsvData] = useState([])
  const [leads, setLeads] = useState([])
  const [checkDuplicates, setCheckDuplicates] = useState(true)
  const [verifyLeads, setVerifyLeads] = useState(false)
  const [open, setOpen] = useState(false)
  const [stats, setStats] = useState({})
  const [fileColumns, setFileColumns] = useState([])
  const [samples, setSamples] = useState([])
  const [selectionValue, setSelectionValue] = useState([])

  const [createLeads, { isLoading: isUploading }] = useCreateLeadsMutation()
  const { data: usage } = useBillingUsageQuery()
  const [duplicateCheck, { isLoading: isDupUploading }] = useDuplicateCheckMutation()
  const [workspaceId, setWorkspaceId] = useState()
  const workspace = useSelector((state) => state.workspace)
  const bounceCredits = usage?.bounceCredit
  const { refetch } = useGetCustomAttributesQuery()

  useEffect(() => {
    setWorkspaceId(workspace?._id)
  }, [workspace])

  const handleImportClick = async () => {
    try {
      setIsImporting(true)
      const data = await fetchGoogleSheet(googleSheetLink)
      if (data?.error) {
        toast.error(data.message)
        return
      }
      const { columns, rows } = data

      if (!columns || !rows[0]) {
        return toast.error('Found 0 rows - ensure the sheet is publicly accessible and has data.')
      }

      setFileColumns(columns)
      const samples = {}
      columns.forEach((col, colIndex) => {
        samples[col] = rows[0][colIndex]
      })

      setSamples(samples)
      const sheetData = []
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i]
        const sheetRow = []

        for (let j = 0; j < row.length; j++) {
          const type =
            selections.find((s) => s.label === columns[j])?.value ||
            selections[selections.length - 1].value

          if (!row[j]) continue

          sheetRow.push({
            type,
            column: columns[j],
            value: row[j]
          })
        }
        sheetData.push(sheetRow)
      }

      setCsvData(sheetData)
      onLeadsUpdate(sheetData)
    } catch (error) {
      toast.error('Invalid link - ensure the sheet is publicly accessible and has data.')
    } finally {
      setIsImporting(false)
    }
  }
  const onLeadsUpdate = (data) => {
    updateLeads({
      data,
      selections,
      filter,
      setLeads
    })
  }

  const handleInitialSelection = (selection) => {
    setSelectionValue((prevSelections) => [...prevSelections, selection])
  }

  const onTypeChange = (column, selection, index) => {
    handleSelectTypeChange({
      column,
      selection,
      index,
      selectionValue,
      setSelectionValue,
      csvData,
      filter,
      setCsvData,
      onLeadsUpdate
    })
  }

  const onUploadClick = () => {
    const { validLeads, invalidEmails } = filterLeads(leads)
    handleDialogUploadAllClick({
      setOpen,
      isUploading,
      setSnackbarOpen,
      setSnackbarMsg,
      leads: validLeads,
      checkDuplicates,
      verifyLeads,
      bounceCredits,
      stats,
      onLeadsCreate,
      campaign,
      createLeads,
      invalidEmails,
      totalLeads: leads,
      workspaceId
    })
    refetch()
  }

  const onUploadAllClick = async () => {
    const { validLeads, invalidEmails } = filterLeads(leads)
    await handleUploadAllClick({
      checkDuplicates,
      verifyLeads,
      bounceCredits,
      leads: validLeads,
      duplicateCheck,
      setStats,
      setOpen,
      campaign,
      invalidEmails,
      totalLeads: leads,
      workspaceId
    })
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
          width: '100%'
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mr: 1 }}>
            <Google />
          </Box>
          <Typography
            sx={{
              fontSize: '20px',
              fontWeight: 700,
              lineHeight: '25px',
              color: `${theme.palette.primary.primaryText}`
            }}
          >
            Google Sheets
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            flexDirection: 'column',
            backgroundColor: '#F2F4F6',
            p: 2,
            borderRadius: '12px',
            mt: 2,
            width: '100%'
          }}
        >
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '18px'
            }}
          >
            💡 Make sure your Google Sheet is publicly accessible
          </Typography>
        </Box>
        <Typography
          sx={{
            fontSize: '13px',
            fontWeight: 400,
            lineHeight: '20px',
            color: '#8181B0',
            mt: 3
          }}
        >
          Paste your Google Sheets link here
        </Typography>
        <TextField
          fullWidth
          variant='outlined'
          sx={{
            mt: 1,
            backgroundColor: 'white',
            '& div': { pl: 1 },
            '& div fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
            '& div input': {
              p: 1.3,
              fontSize: '13px',
              fontWeight: 400,
              lineHeight: '16px',
              letterSpacing: '0em',
              '&::placeholder': {
                color: 'rgba(40, 40, 123, 0.5)'
              }
            }
          }}
          disabled={isUploading}
          value={googleSheetLink}
          onChange={(e) => setGoogleSheetLink(e.target.value)}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            mt: 2
          }}
        >
          <Button
            variant='contained'
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            disabled={!googleSheetLink.trim() || isUploading}
            onClick={handleImportClick}
          >
            {isImporting ? <CircularProgress size={20} sx={{ color: 'white' }} /> : 'Import'}
          </Button>
        </Box>
      </Box>
      {leads.length !== 0 && (
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              mt: 2
            }}
          >
            <TaskAlt sx={{ color: 'rgb(33, 111, 237)', mr: 1 }} />
            <Typography sx={{ fontWeight: 600, color: 'rgb(33, 111, 237)' }}>
              File Processed
            </Typography>
          </Box>
          <TableContainer sx={{ mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width={360}>Column Name</TableCell>
                  <TableCell width={360}>Select Type</TableCell>
                  <TableCell>Samples</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fileColumns.map((column, index) => (
                  <FileUploadCsv
                    key={index}
                    column={column}
                    sample={samples[column]}
                    selections={filter === true ? selectionsFilters : selections}
                    onChange={(s) => onTypeChange(column, s, index)}
                    isUploading={isUploading}
                    alreadySelectionValues={selectionValue}
                    setSelectionValue={setSelectionValue}
                    filter={filter}
                    isSelected={csvData?.length > 0 && Boolean(csvData[0]?.find(cur => cur.type !== 'DO_NOT_IMPORT' && cur.column === column))}
                    samples={samples}
                    onInitialSelection={handleInitialSelection}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{
              textAlign: 'center'
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  icon={<OffCheckboxCustomIcon />}
                  checkedIcon={<OnCheckboxCustomIcon />}
                  checked={checkDuplicates}
                  onChange={(e, value) => setCheckDuplicates(value)}
                />
              }
              label='Check for duplicates across all campaigns'
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontSize: '13px',
                  fontWeight: 500,
                  lineHeight: '16px',
                  color: `${theme.palette.primary.primaryText}`
                },
                mt: 2
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  icon={<OffCheckboxCustomIcon />}
                  checkedIcon={<OnCheckboxCustomIcon />}
                  checked={verifyLeads}
                  onChange={(e, value) => setVerifyLeads(value)}
                />
              }
              label='Validate Emails'
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontSize: '13px',
                  fontWeight: 500,
                  lineHeight: '16px',
                  color: `${theme.palette.primary.primaryText}`
                },
                mt: 2
              }}
            />
          </Box>
          <Button
            variant='contained'
            sx={{
              fontSize: '16px',
              px: 3,
              py: 1.5,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mt: 1,
              mx: 'auto'
            }}
            onClick={checkDuplicates ? onUploadAllClick : onUploadClick}
          >
            {isUploading
              ? (
                <CircularProgress size={20} sx={{ color: 'white', mr: 1 }} />
                )
              : (
                <CloudUploadTwoTone sx={{ mr: 1 }} />
                )}
            Upload All
          </Button>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle sx={{ fontSize: '25px', color: '#595959', textAlign: 'center' }}>
              Are you sure?
            </DialogTitle>
            <DialogContent>
              <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                This will upload{' '}
                <Typography component='span' color='blue'>
                  {stats.uploadedCount}{' '}
                </Typography>{' '}
                contacts to your campaign .{' '}
                <Typography component='span' color='blue'>
                  {' '}
                  {(stats?.emailCampaignCount ?? 0) + (stats?.duplicateEmailsCount ?? 0) + (stats?.invalidEmailCount ?? 0)}{' '}
                </Typography>{' '}
                contacts had errors.
              </Typography>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
              <Button
                onClick={onUploadClick}
                variant='contained'
                sx={{
                  fontSize: '16px',
                  borderRadius: '12px',
                  px: 2.5,
                  py: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mt: 1
                }}
              >
                {isUploading || isDupUploading
                  ? (
                      'Uploading...'
                    )
                  : (
                    <>
                      <CloudUploadTwoTone sx={{ mr: 1 }} />
                      Upload
                    </>
                    )}
              </Button>
              <Button
                variant='outlined'
                onClick={handleClose}
                sx={{
                  // backgroundColor: "#595959",
                  color: 'Black',
                  fontSize: '16px',
                  borderRadius: '12px',
                  px: 3.2,
                  py: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mt: 1,
                  '&:hover': {
                    backgroundColor: '#787878'
                  }
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      )}
    </>
  )
}

export default GoogleSheetImport
