import React from 'react'
import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

const ConnectMicrosoftAccount = ({ activeStepMAcc, setActiveStepMAcc }) => {
  const theme = useTheme()

  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          pb: 3,
          borderBottom: `1px solid ${theme.palette.grey[300]}`
        }}
      >
        <Typography
          sx={{
            fontSize: '13px',
            fontWeight: 500,
            lineHeight: '20px',
            color: `${theme.palette.primary.primaryText}`
          }}
        >
          Before moving forward, <span style={{ fontWeight: 700 }}>please enable IMAP</span> access on your Microsoft account.
        </Typography>
      </Box>
      <Box sx={{ width: '100%', pb: 1, borderBottom: `1px solid ${theme.palette.grey[300]}` }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start'
          }}
        >
          <Typography
            sx={{
              fontSize: '13px',
              fontWeight: 500,
              lineHeight: '20px',
              color: `${theme.palette.primary.primaryText}`
            }}
          >
            <ol style={{ paddingLeft: '12px' }}>
              <li style={{ marginTop: '16px' }}>Open Outlook on your computer.</li>
              <li style={{ marginTop: '16px' }}>
                Go to your
                <a
                  style={{
                    color: theme.palette.primary.main,
                    cursor: 'pointer',
                    textDecoration: 'none',
                    fontSize: '13px',
                    fontWeight: '700'
                  }}
                  href='https://admin.exchange.microsoft.com/' target='_blank' rel='noopener noreferrer'
                >Exchange admin center
                </a>. It can be also accessed via the Office 365 home page ➡️ All Apps ➡️ Admin ➡️ Show all ➡️ Exchange Admin center.
              </li>
              <li style={{ marginTop: '16px' }}>
                Click <span style={{ fontWeight: 700 }}> Mailboxes and select the Mailbox where you want to enable IMAP access. </span>
              </li>
              <li style={{ marginTop: '16px' }}>
                On the right panel, click on <span style={{ fontWeight: 700 }}>Manage email apps settings.</span>
              </li>
              <li style={{ marginTop: '20px' }}>
                Finally  <span style={{ fontWeight: 700 }}> enable IMAP and click "Save" .</span>
              </li>
            </ol>
          </Typography>
        </Box>
      </Box>
    </>
  )
}

export default ConnectMicrosoftAccount
