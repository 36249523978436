import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from 'src/utils/base-query.js'
import _ from 'lodash'

export const listsApi = createApi({
  reducerPath: 'listsApi',
  tagTypes: ['LISTS', 'LIST_LEADS'],
  baseQuery,
  endpoints: (builder) => ({
    // Create a new list
    createList: builder.mutation({
      query: (data) => ({
        method: 'POST',
        url: 'lists',
        body: data
      }),
      invalidatesTags: ['LISTS'],
      transformResponse: (response) => response.data
    }),

    // Get all lists with pagination
    getLists: builder.query({
      query: (queryParams) => {
        const params = new URLSearchParams(_.pickBy(queryParams))
        return {
          method: 'GET',
          url: '/lists',
          params
        }
      },
      transformResponse: (response) => response.data,
      providesTags: ['LISTS']
    }),

    // Get a single list by ID
    getList: builder.query({
      query: (id) => ({
        method: 'GET',
        url: `lists/${id}`
      }),
      transformResponse: (response) => response.data,
      providesTags: (_, __, arg) => [`LIST_${arg}`]
    }),

    // Update a list
    updateList: builder.mutation({
      query: ({ id, data }) => ({
        method: 'PUT',
        url: `lists/${id}`,
        body: data
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: (result) => [
        'LISTS',
        `LIST_${result?.list._id}`
      ]
    }),

    // Delete a list
    deleteList: builder.mutation({
      query: (id) => ({
        method: 'DELETE',
        url: `lists/${id}`
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ['LISTS']
    }),

    // Add leads to a list
    addLeadsToList: builder.mutation({
      query: ({ listId, leads, workspaceId }) => ({
        method: 'POST',
        url: `lists/${listId}/leads`,
        body: { leads, workspaceId }
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: (_, __, arg) => [
        'LISTS',
        `LIST_${arg.listId}`,
        'LIST_LEADS'
      ]
    }),

    // Get leads from a list
    getListLeads: builder.query({
      query: ({ listId, params }) => ({
        method: 'GET',
        url: `lists/${listId}/leads`,
        params
      }),
      transformResponse: (response) => response.data,
      providesTags: (_, __, arg) => [
        `LIST_${arg.listId}`,
        'LIST_LEADS'
      ]
    }),

    // Remove leads from a list
    removeLeadsFromList: builder.mutation({
      query: ({ listId, leadIds }) => ({
        method: 'DELETE',
        url: `lists/${listId}/leads`,
        body: { leadIds }
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: (_, __, arg) => [
        'LISTS',
        `LIST_${arg.listId}`,
        'LIST_LEADS'
      ]
    }),

    // Get lists by workspace
    getListsByWorkspace: builder.query({
      query: (workspaceId) => ({
        method: 'GET',
        url: `lists/workspace/${workspaceId}`
      }),
      transformResponse: (response) => response.data,
      providesTags: ['LISTS']
    }),

    // Get list statistics
    getListStats: builder.query({
      query: (listId) => ({
        method: 'GET',
        url: `lists/${listId}/stats`
      }),
      transformResponse: (response) => response.data,
      providesTags: (_, __, arg) => [`LIST_${arg}`]
    }),

    // Search lists
    searchLists: builder.query({
      query: (params) => ({
        method: 'GET',
        url: 'lists/search',
        params
      }),
      transformResponse: (response) => response.data,
      providesTags: ['LISTS']
    }),

    // Duplicate a list
    duplicateList: builder.mutation({
      query: (id) => ({
        method: 'POST',
        url: `lists/${id}/duplicate`
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ['LISTS']
    })
  })
})

// Export hooks for usage in functional components
export const {
  useCreateListMutation,
  useGetListsQuery,
  useGetListQuery,
  useUpdateListMutation,
  useDeleteListMutation,
  useAddLeadsToListMutation,
  useGetListLeadsQuery,
  useRemoveLeadsFromListMutation,
  useGetListsByWorkspaceQuery,
  useGetListStatsQuery,
  useSearchListsQuery,
  useDuplicateListMutation,
  // Lazy query variants
  useLazyGetListsQuery,
  useLazyGetListQuery,
  useLazyGetListLeadsQuery,
  useLazyGetListsByWorkspaceQuery,
  useLazyGetListStatsQuery,
  useLazySearchListsQuery
} = listsApi
