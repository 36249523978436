import { useTheme } from '@mui/material'
export const Total = () => {
  const theme = useTheme()
  return (
    <>
      <svg
        width='32'
        height='33'
        viewBox='0 0 32 33'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect y='0.5' width='32' height='32' rx='16' fill='#EBEFFF' />
        <path
          d='M9.59998 20.6445C10.3324 19.4229 11.9376 18.5868 14.4421 18.5868C16.9465 18.5868 18.5517 19.4229 19.2841 20.6445M19.7475 17.6604C21.0737 18.3235 21.7368 18.9866 22.4 20.3129M18.3681 12.5917C18.9922 12.9274 19.4164 13.5866 19.4164 14.3449C19.4164 15.0812 19.0163 15.7241 18.4217 16.0681M16.4314 14.3448C16.4314 15.4436 15.5408 16.3342 14.4421 16.3342C13.3433 16.3342 12.4527 15.4436 12.4527 14.3448C12.4527 13.2461 13.3433 12.3555 14.4421 12.3555C15.5408 12.3555 16.4314 13.2461 16.4314 14.3448Z'
          stroke={theme.palette.primary.main}
          stroke-width='1.5'
          stroke-linecap='round'
        />
      </svg>
    </>
  )
}
