import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from 'src/utils/base-query.js'

export const partneruserApi = createApi({
  reducerPath: 'partneruserApi',
  tagTypes: ['PartnerUser'],
  baseQuery,
  endpoints: (builder) => ({
    getAllPartnerUsers: builder.query({
      query: (params) => {
        return {
          method: 'GET',
          url: '/partners-user-details/getAllUsers',
          params
        }
      }
    }),
    updateUserEmail: builder.mutation({
      query: ({ id, ...body }) => {
        return {
          method: 'PUT',
          url: `partners-user-details/updateUser/${id}`,
          body
        }
      }
    }),
    updateAssignedPlan: builder.mutation({
      query: (body) => {
        return {
          method: 'POST',
          url: 'partners-user-details/assign-plan',
          body
        }
      }
    }),
    updateUserPassword: builder.mutation({
      query: ({ id, ...body }) => {
        return {
          method: 'PUT',
          url: `partners-user-details/updateUserPasswordByAdmin/${id}`,
          body
        }
      }
    }),
    getUserAuthToken: builder.query({
      query: (data) => {
        return {
          method: 'POST',
          url: 'partners-user-details/token',
          body: data
        }
      }
    }),
    getDeleteUser: builder.mutation({
      query: (userId) => {
        return {
          method: 'POST',
          url: 'partners-user-details/delete-user',
          body: userId
        }
      }
    }),
    disableUser: builder.mutation({
      query: (data) => {
        return {
          method: 'POST',
          url: '/admin/disable-user',
          body: data
        }
      }
    }),
    enableUser: builder.mutation({
      query: (data) => {
        return {
          method: 'POST',
          url: '/admin/enable-user',
          body: data
        }
      }
    })
  })
})

export const {
  useGetAllPartnerUsersQuery,
  useUpdateUserEmailMutation,
  useUpdateAssignedPlanMutation,
  useUpdateUserPasswordMutation,
  useLazyGetUserAuthTokenQuery,
  useGetDeleteUserMutation,
  useDisableUserMutation,
  useEnableUserMutation
} = partneruserApi
