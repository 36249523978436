import React, { useEffect } from 'react'

import {
  Autocomplete,
  IconButton,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Checkbox,

  Box,
  Typography,
  Stack,
  TextField
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { useTheme } from '@emotion/react'

import { OffCheckboxCustomIcon } from 'src/assets/general/OffCheckboxCustomIcon'
import { OnCheckboxCustomIcon } from 'src/assets/general/OnCheckboxCustomIcon'
import { OnRadioCustomIcon } from 'src/assets/general/OnRadioCustomIcon'
import { OfRadioCustomIcon } from 'src/assets/general/OfRadioCustomIcon'

const AddToCampaignDialog = ({
  open,
  onClose,
  dailogType,
  campaignsList,
  selectedCampaign,
  setSelectedCampaign,
  handleAddToCampaignDialogSave,
  verifyLeads,
  setVerifyLeads,
  setLeadDuplicate,
  leadDuplicate
}) => {
  const theme = useTheme()

  // Helper function to get campaign objects from IDs
  // const getSelectedCampaignObjects = () => {
  //   return campaignsList.filter(campaign => selectedCampaign.includes(campaign._id))
  // }

  const handleCampaignChange = (event, newValue) => {
    // Extract IDs from the selected campaign objects
    // if (!newValue) return
    // const newSelectedIds = newValue.map(campaign => campaign._id)
    // // console.log('Selected Campaign IDs:', newSelectedIds)
    // // console.log('Selected Campaign Objects:', newValue)
    // setSelectedCampaign((prev) => [...new Set([...prev, ...newSelectedIds])])
    setSelectedCampaign(newValue)
  }

  useEffect(() => {
    // console.log('Updated selectedCampaign:', selectedCampaign, leadDuplicate)
    // if (selectedCampaign && selectedCampaign.length < 2) {
    //   setLeadDuplicate(true)
    // } else {
    //   setLeadDuplicate(false)
    // }
  }, [selectedCampaign])

  const handleChange = (event, value) => {
    // Convert string 'true'/'false' to boolean
    const boolValue = value === 'true'
    setVerifyLeads(boolValue)
  }
  console.log('selectedCampaign>>', selectedCampaign)
  return (
    <Box
      open={open}
      onClose={onClose}
      width='503px'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        padding: '8px 16px 15px 16px'
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center', pt: 2 }}>
        <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
          {dailogType === 'list' ? 'Add to List' : 'Add to campaign(s)'}
        </Typography>
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{ height: '20px', width: '20px' }}
        >
          <CloseIcon sx={{ color: '#101010' }} />
        </IconButton>
      </Box>

      <Stack spacing='16px'>
        {/* Campaign Selection */}
        <Autocomplete
          // multiple
          freeSolo
          id='checkboxes-tags-demo'
          options={campaignsList}
          // value={getSelectedCampaignObjects()}
          value={selectedCampaign}
          getOptionLabel={(option) => option?.name}
          onChange={handleCampaignChange}
          // onChange={(event, newValue) => {
          //     setSelectedCampaign(newValue);
          // }}
          renderOption={(props, option) => (
            <li
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                px: 0
              }}
              {...props}
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 500,
                  color: `${theme.palette.primary.primary}`
                }}
              >
                {option?.name}
              </Typography>
            </li>
          )}
          // renderTags={(value, getTagProps) =>
          //   value.map((option, index) => (
          //     <Chip
          //       {...getTagProps({ index })}
          //       key={option._id}
          //       label={option.name}
          //       onDelete={() => handleDelete(option)}
          //       deleteIcon={<CloseIcon />}
          //       sx={{
          //         height: '24px',
          //         border: '1px solid #3F4FF836',
          //         borderRadius: '43px',
          //         backgroundColor: '#E6F0FF',
          //         '& .MuiChip-label': {
          //           fontSize: '14px',
          //           fontWeight: 500,
          //           color: '#101010',
          //           textTransform: 'capitalize'
          //         },
          //         '& .MuiChip-deleteIcon': {
          //           color: '#101010',
          //           fontSize: '16px'
          //         }
          //       }}
          //     />
          //   ))}
          renderInput={(params) => (
            <TextField
              {...params}
              label={dailogType === 'list' ? 'Select List' : 'Select Campaign'}
              variant='outlined'
              sx={{
                backgroundColor: 'white',
                '& div': { pl: 0.3 },
                '& div fieldset': { borderRadius: '8px', border: '1px solid #D7D9E6' },
                '& div input': {
                  fontSize: '13px',
                  fontWeight: 400,
                  '&::placeholder': {
                    color: `${theme.palette.primary.main}`
                  }
                },
                '& label': {
                  fontSize: '14px',
                  fontWeight: 500,
                  color: `${theme.palette.primary.main}`
                }
              }}
              name='location'
            />
          )}
          sx={{ width: '100%' }}
        />

        {/* Email Validation */}
        <FormControl>
          <FormLabel sx={{
            fontSize: '13px',
            fontWeight: 500,
            color: '#101010',
            '&.Mui-focused': {
              color: '#101010'
            }
          }}
          >Would you like to validate the email addresses?
          </FormLabel>
          <RadioGroup
            row
            value={verifyLeads.toString()} // Convert boolean to string for RadioGroup
            onChange={handleChange}
          >
            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2, width: '100%', mt: '1rem' }}>
              <FormControlLabel
                value='true'
                control={<Radio icon={<OfRadioCustomIcon />} checkedIcon={<OnRadioCustomIcon />} />}
                label='Yes'
                sx={{
                  border: '1px solid #D7D9E6',
                  height: '45px',
                  borderRadius: '8px',
                  margin: 0,
                  padding: '8px 16px',

                  '& .MuiFormControlLabel-label': {
                    fontSize: '14px',
                    fontWeight: 500
                  }
                }}
              />
              <FormControlLabel
                value='false'
                control={<Radio icon={<OfRadioCustomIcon />} checkedIcon={<OnRadioCustomIcon />} />}
                label='No'
                sx={{
                  border: '1px solid #D7D9E6',
                  height: '45px',
                  borderRadius: '8px',
                  margin: 0,
                  padding: '8px 16px',

                  '& .MuiFormControlLabel-label': {
                    fontSize: '14px',
                    fontWeight: 500
                  }
                }}
              />
            </Box>
          </RadioGroup>
        </FormControl>

        {/* Status Selection */}
        {/* {
          verifyLeads && (
            <FormControl>
              <FormLabel sx={{
                fontSize: '13px',
                fontWeight: 500,
                color: '#101010',
                '&.Mui-focused': {
                  color: '#101010'
                }
              }}
              >
                Which statuses would you like to automatically enroll into your campaign(s)?
              </FormLabel>
              <Stack spacing='8px'>
                {statuses.map((status) => (
                  <StatusButton
                    key={status.id}
                    selected={selectedStatuses.includes(status.id)}
                    onClick={() => handleStatusToggle(status.id)}
                    variant='outlined'
                    sx={{
                      maxHeight: '45px',
                      mt: '0.5rem',
                      border: '1px solid #D7D9E6',
                      borderRadius: '8px'
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Checkbox
                        icon={<OffCheckboxCustomIcon />}
                        checkedIcon={<OnCheckboxCustomIcon />}
                        checked={selectedStatuses.includes(status.id)}
                        sx={{ p: 0, mr: '0.5rem' }}
                      />
                      {status.icon}
                      <Chip
                        label={status.label}
                        sx={{
                          height: '24px',
                          backgroundColor: status.color,
                          border: `1px solid ${status.borderColor}`,
                          borderRadius: '42px',
                          '& .MuiChip-label': {
                            fontSize: '13px',
                            fontWeight: 600,
                            color: status.textColor,
                            textTransform: 'capitalize'
                          }
                        }}
                      />
                    </Box>
                  </StatusButton>
                ))}
              </Stack>
            </FormControl>
          )
        } */}
        {dailogType === 'campaign' && <FormControlLabel
          control={
            <Checkbox
              icon={<OffCheckboxCustomIcon />}
              checkedIcon={<OnCheckboxCustomIcon />}
              checked={leadDuplicate}
              onChange={(e, value) => setLeadDuplicate(value)}
            />
          }
          label='Check for duplicates across all campaigns'
          sx={{
            '& .MuiFormControlLabel-label': {
              fontSize: '13px',
              fontWeight: 500,
              lineHeight: '16px'
              // color: alpha(theme.palette.primary.main, 0.8)
            },
            mt: 2
          }}
                                      />}
      </Stack>

      {/* Buttons  */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          variant='outlined' onClick={onClose} sx={{
            height: '48px',
            width: '76px',
            border: '1px solid #E4E4E5',
            borderRadius: '12px'
          }}
        >
          <Typography sx={{
            fontSize: '14px',
            fontWeight: 600,
            color: '#101010'
          }}
          >Cancel
          </Typography>
        </Button>
        <Button
          onClick={handleAddToCampaignDialogSave}
          variant='contained'
          sx={{
            height: '48px',
            width: '208px',
            borderRadius: '12px',
            backgroundColor: `${theme.palette.primary.main}`,
            '&:hover': {
              backgroundColor: `${theme.palette.primary.dark}`
            }
          }}
          disabled={selectedCampaign === null}
        >
          <Typography sx={{
            fontSize: '14px',
            fontWeight: 600
          }}
          >
            {dailogType === 'list' ? ' Add to List' : 'Add leads'}
          </Typography>
        </Button>
      </Box>

    </Box>
  )
}

export default AddToCampaignDialog
