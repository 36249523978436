import {
  Box,
  Drawer,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
  CircularProgress
} from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'
import FavoriteIcon from '@mui/icons-material/Favorite'
import { EditIcon } from 'src/assets/general/EditIcon'
import { DeleteIcon } from 'src/assets/general/DeleteIcon'
import { toast } from 'react-hot-toast'
import { useDeleteSearchMutation } from 'src/services/leads-service.js'

const SavedSearchDrawer = ({
  isOpen,
  onClose,
  savedSearches,
  isFetching,
  onSearchClick,
  onEditSearchClick
}) => {
  const [deleteSearch, { isLoading: isDeleteSearchLoading }] = useDeleteSearchMutation()

  const formatQueryString = (query) => {
    if (typeof query === 'string') return query
    if (typeof query === 'object') {
      const parts = []
      if (query.current_title) parts.push(query.current_title)
      if (query.geo) parts.push(query.geo)
      return parts.join(', ') || 'No description'
    }
    return 'No description'
  }

  const handleSearchDelete = async (searchId, event) => {
    event.stopPropagation()
    try {
      const { message } = await deleteSearch(searchId).unwrap()
      toast.success(message)
    } catch (error) {
      console.error('Error deleting search:', error)
      toast.error('Failed to delete search')
    }
  }

  const handleSearchEdit = (searchId, event) => {
    event.stopPropagation()
    onEditSearchClick(searchId)
  }

  return (
    <Drawer
      anchor='right'
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: '480px',
          padding: '24px'
        }
      }}
    >
      {/* Header */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <FavoriteIcon sx={{ marginRight: '12px', color: '#101010' }} />
          <Typography variant='h6' sx={{ fontWeight: 700, color: '#101010' }}>
            All Saved Searches
          </Typography>
        </Box>
        <IconButton onClick={onClose} size='small'>
          <CloseIcon />
        </IconButton>
      </Box>

      {/* Content */}
      {isFetching
        ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', py: 2 }}>
            <CircularProgress size={25} thickness={5} />
          </Box>
          )
        : (
          <List sx={{ width: '100%', p: 0 }}>
            {!savedSearches?.length
              ? (
                <Typography
                  sx={{
                    fontSize: '14px',
                    color: '#667085',
                    textAlign: 'center',
                    py: 2
                  }}
                >
                  No saved searches
                </Typography>
                )
              : (
                  savedSearches.map((search, index) => (
                    <div key={search._id}>
                      <ListItem
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          padding: '8px 0',
                          cursor: 'pointer'
                        }}
                        onClick={() => onSearchClick(search.query)}
                      >
                        <ListItemText
                          primary={
                            <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                              <Typography
                                component='span'
                                sx={{
                                  color: 'blue',
                                  fontWeight: 600,
                                  fontSize: '14px',
                                  marginRight: 2
                                }}
                              >
                                {search.name}:
                              </Typography>{' '}
                              {formatQueryString(search.query)}
                            </Typography>
                      }
                        />
                        <Box sx={{ display: 'flex', gap: 1, ml: 2 }}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              width: 18,
                              height: 18
                            }}
                          >
                            <IconButton
                              size='small'
                              onClick={(e) => handleSearchEdit(search._id, e)}
                            >
                              <EditIcon />
                            </IconButton>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              width: 18,
                              height: 18
                            }}
                          >
                            {isDeleteSearchLoading
                              ? (
                                <CircularProgress size={20} sx={{ color: 'rgb(253, 30, 54)' }} />
                                )
                              : (
                                <IconButton
                                  size='small'
                                  onClick={(e) => handleSearchDelete(search._id, e)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                                )}
                          </Box>
                        </Box>
                      </ListItem>
                      {index < savedSearches.length - 1 && (
                        <Divider sx={{ my: '5px', borderColor: '#E4E4E5' }} />
                      )}
                    </div>
                  ))
                )}
          </List>
          )}
    </Drawer>
  )
}

export default SavedSearchDrawer
