export const SBOnboardingHub = ({ color = '#28287B', size }) => {
  return (
    <>
      <svg width={size} height={size} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M10.6653 5.33398V3.33398L12.6653 1.33398L13.332 2.66732L14.6653 3.33398L12.6653 5.33398H10.6653ZM10.6653 5.33398L7.99868 8.00062M14.6654 8.00065C14.6654 11.6826 11.6806 14.6673 7.9987 14.6673C4.3168 14.6673 1.33203 11.6826 1.33203 8.00065C1.33203 4.31875 4.3168 1.33398 7.9987 1.33398M11.332 8.00065C11.332 9.8416 9.83965 11.334 7.9987 11.334C6.15775 11.334 4.66536 9.8416 4.66536 8.00065C4.66536 6.1597 6.15775 4.66732 7.9987 4.66732' stroke={color} stroke-width='1.4' stroke-linecap='round' stroke-linejoin='round' />
      </svg>
    </>
  )
}
export const SBOnboardingHubUpgrade = ({ color = '#28287B' }) => {
  return (
    <>
      <svg width='24' height='24' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M2.9987 14.6673V11.334M2.9987 4.66732V1.33398M1.33203 3.00065H4.66536M1.33203 13.0007H4.66536M8.66536 2.00065L7.50925 5.00656C7.32124 5.49538 7.22723 5.73979 7.08105 5.94537C6.95149 6.12758 6.7923 6.28678 6.61009 6.41634C6.4045 6.56252 6.16009 6.65652 5.67127 6.84453L2.66536 8.00065L5.67127 9.15677C6.16009 9.34478 6.4045 9.43878 6.61009 9.58496C6.7923 9.71452 6.95149 9.87372 7.08105 10.0559C7.22723 10.2615 7.32124 10.5059 7.50925 10.9947L8.66537 14.0007L9.82148 10.9947C10.0095 10.5059 10.1035 10.2615 10.2497 10.0559C10.3792 9.87372 10.5384 9.71452 10.7206 9.58496C10.9262 9.43878 11.1706 9.34478 11.6595 9.15677L14.6654 8.00065L11.6595 6.84453C11.1706 6.65652 10.9262 6.56252 10.7206 6.41634C10.5384 6.28678 10.3792 6.12758 10.2497 5.94537C10.1035 5.73979 10.0095 5.49538 9.82148 5.00656L8.66536 2.00065Z' stroke='#3F4FF8' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round' />
      </svg>
    </>
  )
}
