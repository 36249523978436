import { Box, Typography, Tabs, Tab, CircularProgress } from '@mui/material'
import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import CustomizeConnector from 'src/components/enterpriseAccess/CustomizeConnector'
import ApiKey from 'src/components/enterpriseAccess/ApiKey'
import ClientsAccounts from 'src/components/enterpriseAccess/ClientsAccounts'
import HeaderWithPopover from 'src/components/HeaderWithPopover'
import { useGetMeAndWorkspaceQuery } from 'src/services/user-service'
function CustomTabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ width: '100%' }}
    >
      {value === index && (
        <Box sx={{}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

function a11yProps (index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}
const Page = ({ tab }) => {
  const [value, setValue] = useState(
    tab === 'connector' ? 0 : tab === 'apikey' ? 1 : tab === 'clientaccounts' ? 2 : 0
  )
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  useEffect(() => {
    setValue(tab === 'connector' ? 0 : tab === 'apikey' ? 1 : tab === 'clientaccounts' ? 2 : 0)
  }, [tab])

  const [, setWorkspace] = useState(null)

  const handleWorkspaceChange = (newWorkspace) => {
    setWorkspace(newWorkspace)
  }
  const { data, isLoading } = useGetMeAndWorkspaceQuery()

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh'
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center ',
        width: '100%',
        height: '100%',
        flexDirection: 'column'
      }}
    >
      <Box
        sx={{
          width: '90%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center'
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'flex-start'
          }}
        >

          <HeaderWithPopover title='Warmup Connector' onWorkspaceChange={handleWorkspaceChange} selectedTimeline={data?.workspace} />

        </Box>
        <Box
          sx={{
            boxShadow: '0px 12px 15px 0px #4B71970D',
            borderRadius: '12px',
            backgroundColor: 'white',
            width: '100%',
            p: { xs: 1, sm: 2, md: 3 },
            mt: 3
          }}
        >
          {' '}
          <Box
            sx={{
              width: '100%',
              border: '1px solid rgba(228, 228, 229, 1)',
              borderRadius: '8px'
            }}
          >
            {' '}
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label='basic tabs example'
              variant='fullWidth'
              sx={{ borderRadius: '8px' }}
            >
              <Tab
                label='Customize Connector'
                sx={{
                  fontSize: '14px',
                  fontWeight: 700,
                  lineHeight: '20px'
                }}
                {...a11yProps(0)}
              />
              <Tab
                label='API Key'
                sx={{
                  fontSize: '14px',
                  fontWeight: 700,
                  lineHeight: '20px'
                }}
                {...a11yProps(1)}
              />
              <Tab
                label='Client Accounts'
                sx={{
                  fontSize: '14px',
                  fontWeight: 700,
                  lineHeight: '20px'
                }}
                {...a11yProps(2)}
              />
            </Tabs>
          </Box>
        </Box>
        <Box
          sx={{
            mb: 4,
            mt: 3,
            width: '100%'
          }}
        >
          <CustomTabPanel value={value} index={0}>
            <CustomizeConnector value={value} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <ApiKey value={value} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <ClientsAccounts />
          </CustomTabPanel>
        </Box>
      </Box>
    </Box>
  )
}

export default Page
