import { useTheme } from '@mui/material'

export const AllCheckboxCustomIcon = () => {
  const theme = useTheme()
  return (
    <>
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect x='0.5' y='0.5' width='19' height='19' rx='5.5' fill={theme.palette.primary.light} />
        <path
          d='M5.91669 10H14.0834'
          stroke={theme.palette.primary.main}
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <rect x='0.5' y='0.5' width='19' height='19' rx='5.5' stroke={theme.palette.primary.main} />
      </svg>
    </>
  )
}
