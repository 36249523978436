import { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Stack, Typography, RadioGroup, Button } from '@mui/material'
import { ComboBox, Tag, FilterDropdown } from '../filterComponents'
import { industries } from 'src/assets/newFiltersData'
import { Industry } from 'src/icons/industry'
import { useTheme } from '@emotion/react'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'

export default function IndustryFilter ({ onChange, type, isOpen, onToggle }) {
  const theme = useTheme()
  const [selectedMethod, setSelectedMethod] = useState('')
  const [includedIndustries, setIncludedIndustries] = useState([])
  const [excludedIndustries, setExcludedIndustries] = useState([])
  const [isInputExcludeBoxOpen, setIsInputExcludeBoxOpen] = useState(false)

  const ref = useRef({ internalStateChange: false })
  const industryData = useSelector((state) => state.search[type === 'company' ? 'companyFilter' : 'filter'].industry_name)
  const excludedIndustriesData = useSelector((state) => state.search[type === 'company' ? 'companyFilter' : 'filter'].excludeIndustryName)

  const handleIncludedIndustries = useCallback((newValue, updateRedux = true) => {
    setIncludedIndustries(newValue)
    if (updateRedux) onChange('industry_name', newValue)
    ref.current.internalStateChange = true
  }, [onChange])

  const handleExcludedIndustries = useCallback((newValue, updateRedux = true) => {
    setExcludedIndustries(newValue)
    if (updateRedux) onChange('excludeIndustryName', newValue)
    ref.current.internalStateChange = true
  }, [onChange])

  const handleRadioBtn = useCallback((e) => {
    setSelectedMethod(e.target.value)
    handleIncludedIndustries([])
    handleExcludedIndustries([])
  }, [handleIncludedIndustries, handleExcludedIndustries])

  const clearAll = useCallback(() => {
    setSelectedMethod('')
    handleIncludedIndustries([])
    handleExcludedIndustries([])
    onChange('industry_name', null) // Clear in parent state
    onChange('excludeIndustryName', null)
  }, [handleIncludedIndustries, handleExcludedIndustries, onChange])

  useEffect(() => {
    if (ref.current.internalStateChange) {
      ref.current.internalStateChange = false
      return
    }

    setSelectedMethod(industryData?.length > 0 ? 'include' : '')
    setIncludedIndustries(industryData ?? [])
    setExcludedIndustries(excludedIndustriesData ?? [])
  }, [industryData])

  const preview = (
    <>
      {(selectedMethod === 'isUnKnown' || selectedMethod === 'isKnown' || includedIndustries.length > 0 || excludedIndustries.length > 0) &&
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 1 }}>
          {includedIndustries.length > 0 &&
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, alignItems: 'center' }}>
              <Box sx={{ width: '95%', height: '0px', border: '0.5px solid', borderColor: '#E4E4E5', mx: 'auto' }} />
              <Typography variant='body2' sx={{ color: '#8181B0', fontSize: '12px', fontWeight: 600, paddingLeft: 1 }}>Include</Typography>
              {includedIndustries.map((ind, index) => <Tag key={index} label={ind} onDelete={() => handleIncludedIndustries(includedIndustries.filter(i => i !== ind))} />)}
            </Box>}
          {excludedIndustries.length > 0 &&
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, alignItems: 'center' }}>
              <Box sx={{ width: '95%', height: '0px', border: '0.5px solid', borderColor: '#E4E4E5', mx: 'auto' }} />
              <Typography variant='body2' sx={{ color: '#8181B0', fontSize: '12px', fontWeight: 600, paddingLeft: 1 }}>Exclude</Typography>
              {excludedIndustries.map((ind, index) => <Tag key={index} label={ind} onDelete={() => handleExcludedIndustries(excludedIndustries.filter(l => l !== ind))} isExclude />)}
            </Box>}
          {selectedMethod === 'isKnown' &&
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, alignItems: 'center' }}>
              <Box sx={{ width: '95%', height: '0px', border: '0.5px solid', borderColor: '#E4E4E5', mx: 'auto' }} />
              <Typography variant='body2' sx={{ color: '#8181B0', fontSize: '12px', fontWeight: 600, paddingLeft: 1 }}>Is known</Typography>
            </Box>}
          {selectedMethod === 'isUnKnown' &&
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, alignItems: 'center' }}>
              <Box sx={{ width: '95%', height: '0px', border: '0.5px solid', borderColor: '#E4E4E5', mx: 'auto' }} />
              <Typography variant='body2' sx={{ color: '#8181B0', fontSize: '12px', fontWeight: 600, paddingLeft: 1 }}>Is unknown</Typography>
            </Box>}
        </Box>}
    </>
  )

  return (
    <FilterDropdown
      title='Industry'
      filterCount={includedIndustries.length + excludedIndustries.length}
      clearAll={clearAll}
      icon={<Industry sx={{ stroke: `${theme.palette.primary.main}` }} />}
      preview={preview}
      isOpen={isOpen}
      onToggle={onToggle}
    >
      <Stack direction='column' gap={2} mt={2}>
        <RadioGroup
          value={selectedMethod}
          onChange={handleRadioBtn}
        >
          <Stack direction='column' gap={1}>
            {/* Include */}
            {/* <RadioBtnCollapse selected={selectedMethod} value='include' label='Include'> */}
            <Typography sx={{ fontWeight: 700, fontSize: '14px' }}>Include Industries</Typography>
            <ComboBox
              placeholder='Search industries to include'
              options={industries}
              value={includedIndustries}
              onChange={(e, newValue) => { handleIncludedIndustries(newValue) }}
            />
            {/* Exclude Locations Section */}
            <Box sx={{ mt: 2 }}>
              <Button
                endIcon={isInputExcludeBoxOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                sx={{
                  textTransform: 'none',
                  fontWeight: 700,
                  fontSize: '13px',
                  p: 0,
                  minWidth: 0,
                  mb: 1
                }}
                onClick={() => {
                  setIsInputExcludeBoxOpen(!isInputExcludeBoxOpen)
                }}
              >
                Exclude locations
              </Button>
            </Box>
            {isInputExcludeBoxOpen && (<ComboBox
              placeholder='Search industries to exclude'
              options={industries}
              value={excludedIndustries}
              onChange={(e, newValue) => { handleExcludedIndustries(newValue) }}
              isExclude
                                       />)}
            {/* </RadioBtnCollapse> */}

            {/* Exclude */}
            {/* <RadioBtnCollapse selected={selectedMethod} value='exclude' label='Exclude'>
              <ComboBox
                placeholder='Search industries to exclude'
                options={industries}
                value={excludedIndustries}
                onChange={(e, newValue) => { handleExcludedIndustries(newValue) }}
                isExclude
              />
            </RadioBtnCollapse> */}

            {/* <RadioBtnCollapse selected={selectedMethod} value='isKnown' label='Is known' />

            <RadioBtnCollapse selected={selectedMethod} value='isUnKnown' label='Is unknown' /> */}
          </Stack>
        </RadioGroup>
      </Stack>
    </FilterDropdown>
  )
}
