import { useTheme } from '@mui/material'
export const SBChart = ({ color }) => {
  const theme = useTheme()
  const defaultColor = color || theme.palette.primary.primaryText
  return (
    <>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M21 6L15.7071 11.2929C15.3166 11.6834 14.6834 11.6834 14.2929 11.2929L12.7071 9.70711C12.3166 9.31658 11.6834 9.31658 11.2929 9.70711L7 14'
          stroke={defaultColor}
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M3 3V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21H21'
          stroke={defaultColor}
          stroke-width='2'
          stroke-linecap='round'
        />
      </svg>
    </>
  )
}
