import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from 'src/config.js'

const getAuthToken = () => localStorage.getItem("auth_token")

export const baseQuery = fetchBaseQuery({
  baseUrl: config.API_BASE_URL,
  prepareHeaders: (headers) => {
    const token = getAuthToken()
    if (token) {
      headers.set('Authorization', `Bearer ${token}`)
    }
    return headers
  }
})
