import React, { useEffect } from 'react'
import {
  Box,
  TextField,
  Typography,
  Grid,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  useTheme,
  IconButton,
  Switch
} from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Divider from '@mui/material/Divider'
import { EACloseIcon } from 'src/assets/emailAccounts/EACloseIcon'

const defaultValues = {
  planName: '',
  activeLeads: 0,
  monthlyEmails: 0,
  aiWriterCredits: 0,
  leadsCredits: 0,
  bounceCredit: 0,
  perDayApiLimit: 0,
  perHourApiLimit: 0,
  perMinApiLimit: 0,
  linkedinCredits: 0,
  dailyLeadLimit: 0,
  leadsPhoneNumberExportsPerMonth: 0,
  emailAccountsLimit: 0,
  verificationCredits: 0,
  buyerIntendData: false,
  advancedApis: false,
  multiChannelLinkedinOutreach: false
}

const PlanSetting = ({ onClose, open, title, handleSubmit, usageData, isSubmitting }) => {
  const theme = useTheme()
  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      ...defaultValues
    },
    validationSchema: Yup.object({
      planName: Yup.string()
        .trim()
        .required('Plan name is a mandatory field')
        .min(3, 'Plan name must be at least 3 characters')
        .max(20, 'Plan name must not exceed 20 characters'),
      activeLeads: Yup.number().integer().min(0, 'Active Leads cannot be negative').required('Active Leads is required'),
      monthlyEmails: Yup.number().integer().min(0, 'Monthly Emails cannot be negative').required('Monthly Emails is required'),
      aiWriterCredits: Yup.number().integer().min(0, 'AI Writer credits cannot be negative').required('AI Writer credits is required'),
      leadsCredits: Yup.number().integer().min(0, 'Leads Credits cannot be negative').required('Leads Credits is required'),
      bounceCredit: Yup.number().integer().min(0, 'Bounce Credit cannot be negative').required('Bounce Credit is required'),
      perDayApiLimit: Yup.number().integer().min(0, 'API perDay limit cannot be negative').required('API perDay limit is required'),
      perHourApiLimit: Yup.number().integer().min(0, 'API perHour limit cannot be negative').required('API perHour limit is required'),
      perMinApiLimit: Yup.number().integer().min(0, 'API perMin limit cannot be negative').required('API perMin limit is required'),
      linkedinCredits: Yup.number().integer().min(0, 'Linkedin Credits cannot be negative').required('Linkedin Credits is required'),
      dailyLeadLimit: Yup.number().integer().min(0, 'Daily Lead Limit cannot be negative').required('Daily Lead Limit is required'),
      verificationCredits: Yup.number().integer().min(0, 'Verification Credits cannot be negative').required('Verification Credits is required'),
      leadsPhoneNumberExportsPerMonth: Yup.number().integer().min(0, 'PhoneNumber Export cannot be negative').required('PhoneNumber Export is required'),
      emailAccountsLimit: Yup.number().integer().min(0, 'Email Accounts Limit cannot be negative').required('Email Accounts Limit is required'),
      buyerIntendData: Yup.boolean().required('Intend Data is required'),
      advancedApis: Yup.boolean().required('Advanced Apis is required'),
      multiChannelLinkedinOutreach: Yup.boolean().required('Channel outreach Apis is required')
    }),
    onSubmit: async (values) => {
      const response = await handleSubmit(values)
      if (response) {
        formik.setValues({
          ...defaultValues
        })
        formik.resetForm()
      }
    }
  })

  useEffect(() => {
    if (usageData && open) {
      formik.setValues(usageData)
    } else {
      formik.setValues({
        ...defaultValues
      })
    }
  }, [usageData, open])

  const handleClose = () => {
    onClose()
    formik.setValues({
      ...defaultValues
    })
  }

  const handleKeyDown = (e) => {
    // Prevent entering invalid characters
    const regex = /^[+-.]*$/ // Only allow digits
    if (regex.test(e.key)) {
      e.preventDefault()
    }
  }

  return (
    <>
      <Dialog onClose={handleClose} open={open} maxWidth='lg'>
        <DialogTitle>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              width: '100%'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                flexDirection: 'column'
              }}
            >
              <Typography
                sx={{
                  fontSize: '20px',
                  fontWeight: 700,
                  lineHeight: '28px',
                  color: `${theme.palette.primary.primaryText}`
                }}
              >
                {title}
              </Typography>
            </Box>
            <IconButton
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              onClick={() => {
                formik.resetForm()
                onClose()
              }}
            >
              <EACloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <form
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <DialogContent>
            <Box
              maxWidth='lg'
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: 'full',
                bgcolor: 'white'
              }}
            >
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: 'full'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: 700,
                        lineHeight: '26px',
                        color: 'rgb(129, 129, 176)'
                      }}
                    >
                      Plan Name :
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    />
                  </Box>

                  <Box
                    sx={{
                      width: 'full',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-end',
                      alignItems: 'center'
                    }}
                  >
                    <Grid container spacing={2} sx={{ my: 0.5 }}>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          variant='outlined'
                          name='planName'
                          placeholder='Plan Name'
                          value={formik.values.planName}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          onKeyDown={handleKeyDown}
                          error={
                            !!(
                              formik.touched.planName &&
                              formik.errors.planName
                            )
                          }
                          helperText={
                            formik.touched.planName &&
                            formik.errors.planName
                          }
                          sx={{
                            '& div': {
                              pl: 0.3,
                              width: '20rem',
                              mt: '-0.2rem'
                            },
                            '& div fieldset': {
                              border: 'none'
                            },
                            '& div input': {
                              py: 1.3,
                              fontSize: '13px',
                              fontWeight: 400,
                              lineHeight: '16px',
                              letterSpacing: '0em',
                              '&::placeholder': {
                                color: 'rgba(40, 40, 123, 0.5)'
                              },
                              borderRadius: '0.7rem',
                              border: '1px solid #E4E4E5'
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: 'full'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: 700,
                        lineHeight: '26px',
                        color: 'rgb(129, 129, 176)'
                      }}
                    >
                      Active Leads :
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    />
                  </Box>

                  <Box
                    sx={{
                      width: 'full',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-end',
                      alignItems: 'center'
                    }}
                  >
                    <Grid container spacing={2} sx={{ my: 0.5 }}>
                      <Grid item xs={6}>
                        <TextField
                          type='number'
                          fullWidth
                          variant='outlined'
                          name='activeLeads'
                          placeholder='Active Leads'
                          value={formik.values.activeLeads}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          onKeyDown={handleKeyDown}
                          InputProps={{
                            inputProps: { min: 0 }
                          }}
                          error={
                            !!(
                              formik.touched.activeLeads &&
                              formik.errors.activeLeads
                            )
                          }
                          helperText={
                            formik.touched.activeLeads &&
                            formik.errors.activeLeads
                          }
                          sx={{
                            '& div': {
                              pl: 0.3,
                              width: '20rem',
                              mt: '-0.2rem'
                            },
                            '& div fieldset': {
                              border: 'none'
                            },
                            '& div input': {
                              py: 1.3,
                              fontSize: '13px',
                              fontWeight: 400,
                              lineHeight: '16px',
                              letterSpacing: '0em',
                              '&::placeholder': {
                                color: 'rgba(40, 40, 123, 0.5)'
                              },
                              borderRadius: '0.7rem',
                              border: '1px solid #E4E4E5'
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'start',
                    flexDirection: 'column'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: 'full'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: 700,
                        lineHeight: '26px',
                        color: 'rgb(129, 129, 176)'
                      }}
                    >
                      Monthly Emails :
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    />
                  </Box>

                  <Box
                    sx={{
                      width: 'full',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Grid container spacing={2} sx={{ my: 0.5 }}>
                      <Grid item xs={6}>
                        <TextField
                          type='number'
                          fullWidth
                          variant='outlined'
                          name='monthlyEmails'
                          placeholder='Monthly Emails'
                          value={formik.values.monthlyEmails}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          onKeyDown={handleKeyDown}
                          InputProps={{
                            inputProps: { min: 0 }
                          }}
                          error={
                            !!(
                              formik.touched.monthlyEmails &&
                              formik.errors.monthlyEmails
                            )
                          }
                          helperText={
                            formik.touched.monthlyEmails &&
                            formik.errors.monthlyEmails
                          }
                          sx={{
                            '& div': {
                              pl: 0.3,
                              width: '20rem',
                              mt: '-0.2rem'
                            },
                            '& div fieldset': {
                              border: 'none'
                            },
                            '& div input': {
                              py: 1.3,
                              fontSize: '13px',
                              fontWeight: 400,
                              lineHeight: '16px',
                              letterSpacing: '0em',
                              '&::placeholder': {
                                color: 'rgba(40, 40, 123, 0.5)'
                              },
                              borderRadius: '0.7rem',
                              border: '1px solid #E4E4E5'
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'start',
                    flexDirection: 'column'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: 'full'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: 700,
                        lineHeight: '26px',
                        color: 'rgb(129, 129, 176)'
                      }}
                    >
                      AI Writer Credits :
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    />
                  </Box>

                  <Box
                    sx={{
                      width: 'full',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Grid container spacing={2} sx={{ my: 0.5 }}>
                      <Grid item xs={6}>
                        <TextField
                          type='number'
                          fullWidth
                          variant='outlined'
                          name='aiWriterCredits'
                          placeholder='AI Writer Credits'
                          value={formik.values.aiWriterCredits}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          onKeyDown={handleKeyDown}
                          InputProps={{
                            inputProps: { min: 0 }
                          }}
                          error={
                            !!(
                              formik.touched.aiWriterCredits &&
                              formik.errors.aiWriterCredits
                            )
                          }
                          helperText={
                            formik.touched.aiWriterCredits &&
                            formik.errors.aiWriterCredits
                          }
                          sx={{
                            '& div': {
                              pl: 0.3,
                              width: '20rem',
                              mt: '-0.2rem'
                            },
                            '& div fieldset': {
                              border: 'none'
                            },
                            '& div input': {
                              py: 1.3,
                              fontSize: '13px',
                              fontWeight: 400,
                              lineHeight: '16px',
                              letterSpacing: '0em',
                              '&::placeholder': {
                                color: 'rgba(40, 40, 123, 0.5)'
                              },
                              borderRadius: '0.7rem',
                              border: '1px solid #E4E4E5'
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'start',
                    justifyContent: 'flex-end',
                    flexDirection: 'column'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: 'full'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: 700,
                        lineHeight: '26px',
                        color: 'rgb(129, 129, 176)'
                      }}
                    >
                      Leads Credits :
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    />
                  </Box>

                  <Box
                    sx={{
                      width: 'full',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Grid container spacing={2} sx={{ my: 0.5 }}>
                      <Grid item xs={6}>
                        <TextField
                          type='number'
                          fullWidth
                          variant='outlined'
                          name='leadsCredits'
                          placeholder='Leads Credits'
                          value={formik.values.leadsCredits}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          onKeyDown={handleKeyDown}
                          InputProps={{
                            inputProps: { min: 0 }
                          }}
                          error={
                            !!(
                              formik.touched.leadsCredits &&
                              formik.errors.leadsCredits
                            )
                          }
                          helperText={
                            formik.touched.leadsCredits &&
                            formik.errors.leadsCredits
                          }
                          sx={{
                            '& div': {
                              pl: 0.3,
                              width: '20rem',
                              mt: '-0.2rem'
                            },
                            '& div fieldset': {
                              border: 'none'
                            },
                            '& div input': {
                              py: 1.3,
                              fontSize: '13px',
                              fontWeight: 400,
                              lineHeight: '16px',
                              letterSpacing: '0em',
                              '&::placeholder': {
                                color: 'rgba(40, 40, 123, 0.5)'
                              },
                              borderRadius: '0.7rem',
                              border: '1px solid #E4E4E5'
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'start',
                    flexDirection: 'column'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: 'full'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: 700,
                        lineHeight: '26px',
                        color: 'rgb(129, 129, 176)'
                      }}
                    >
                      Verification Credits :
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      width: 'full',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Grid container spacing={2} sx={{ my: 0.5 }}>
                      <Grid item xs={6}>
                        <TextField
                          type='number'
                          fullWidth
                          variant='outlined'
                          name='verificationCredits'
                          placeholder='Verification Credits'
                          value={formik.values.verificationCredits}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          onKeyDown={handleKeyDown}
                          error={!!(formik.touched.verificationCredits && formik.errors.verificationCredits)}
                          helperText={formik.touched.verificationCredits && formik.errors.verificationCredits}
                          InputProps={{
                            inputProps: { min: 0 }
                          }}
                          sx={{
                            '& div': {
                              pl: 0.3,
                              width: '20rem',
                              mt: '-0.2rem'
                            },
                            '& div fieldset': {
                              border: 'none'
                            },
                            '& div input': {
                              py: 1.3,
                              fontSize: '13px',
                              fontWeight: 400,
                              lineHeight: '16px',
                              letterSpacing: '0em',
                              '&::placeholder': {
                                color: 'rgba(40, 40, 123, 0.5)'
                              },
                              borderRadius: '0.7rem',
                              border: '1px solid #E4E4E5'
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'start',
                    flexDirection: 'column'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: 'full'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: 700,
                        lineHeight: '26px',
                        color: 'rgb(129, 129, 176)'
                      }}
                    >
                      Linkedin Credits :
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      width: 'full',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Grid container spacing={2} sx={{ my: 0.5 }}>
                      <Grid item xs={6}>
                        <TextField
                          type='number'
                          fullWidth
                          variant='outlined'
                          name='linkedinCredits'
                          placeholder='Linkedin Credits'
                          value={formik.values.linkedinCredits}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          onKeyDown={handleKeyDown}
                          error={!!(formik.touched.linkedinCredits && formik.errors.linkedinCredits)}
                          helperText={formik.touched.linkedinCredits && formik.errors.linkedinCredits}
                          InputProps={{
                            inputProps: { min: 0 }
                          }}
                          sx={{
                            '& div': {
                              pl: 0.3,
                              width: '20rem',
                              mt: '-0.2rem'
                            },
                            '& div fieldset': {
                              border: 'none'
                            },
                            '& div input': {
                              py: 1.3,
                              fontSize: '13px',
                              fontWeight: 400,
                              lineHeight: '16px',
                              letterSpacing: '0em',
                              '&::placeholder': {
                                color: 'rgba(40, 40, 123, 0.5)'
                              },
                              borderRadius: '0.7rem',
                              border: '1px solid #E4E4E5'
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'start',
                    flexDirection: 'column'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: 'full'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: 700,
                        lineHeight: '26px',
                        color: 'rgb(129, 129, 176)'
                      }}
                    >
                      DailyLead Limit :
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      width: 'full',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Grid container spacing={2} sx={{ my: 0.5 }}>
                      <Grid item xs={6}>
                        <TextField
                          type='number'
                          fullWidth
                          variant='outlined'
                          name='dailyLeadLimit'
                          placeholder='Daily Lead Limit'
                          value={formik.values.dailyLeadLimit}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          onKeyDown={handleKeyDown}
                          error={!!(formik.touched.dailyLeadLimit && formik.errors.dailyLeadLimit)}
                          helperText={formik.touched.dailyLeadLimit && formik.errors.dailyLeadLimit}
                          InputProps={{
                            inputProps: { min: 0 }
                          }}
                          sx={{
                            '& div': {
                              pl: 0.3,
                              width: '20rem',
                              mt: '-0.2rem'
                            },
                            '& div fieldset': {
                              border: 'none'
                            },
                            '& div input': {
                              py: 1.3,
                              fontSize: '13px',
                              fontWeight: 400,
                              lineHeight: '16px',
                              letterSpacing: '0em',
                              '&::placeholder': {
                                color: 'rgba(40, 40, 123, 0.5)'
                              },
                              borderRadius: '0.7rem',
                              border: '1px solid #E4E4E5'
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'start',
                    flexDirection: 'column'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: 'full'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: 700,
                        lineHeight: '26px',
                        color: 'rgb(129, 129, 176)'
                      }}
                    >
                      Email Accounts Limit :
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      width: 'full',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Grid container spacing={2} sx={{ my: 0.5 }}>
                      <Grid item xs={6}>
                        <TextField
                          type='number'
                          fullWidth
                          variant='outlined'
                          name='emailAccountsLimit'
                          placeholder='Email Accounts Limit'
                          value={formik.values.emailAccountsLimit}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          onKeyDown={handleKeyDown}
                          error={!!(formik.touched.emailAccountsLimit && formik.errors.emailAccountsLimit)}
                          helperText={formik.touched.emailAccountsLimit && formik.errors.emailAccountsLimit}
                          InputProps={{
                            inputProps: { min: 0 }
                          }}
                          sx={{
                            '& div': {
                              pl: 0.3,
                              width: '20rem',
                              mt: '-0.2rem'
                            },
                            '& div fieldset': {
                              border: 'none'
                            },
                            '& div input': {
                              py: 1.3,
                              fontSize: '13px',
                              fontWeight: 400,
                              lineHeight: '16px',
                              letterSpacing: '0em',
                              '&::placeholder': {
                                color: 'rgba(40, 40, 123, 0.5)'
                              },
                              borderRadius: '0.7rem',
                              border: '1px solid #E4E4E5'
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'start',
                    flexDirection: 'column'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: 'full'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: 700,
                        lineHeight: '26px',
                        color: 'rgb(129, 129, 176)'
                      }}
                    >
                      Bounce Credits :
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      width: 'full',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Grid container spacing={2} sx={{ my: 0.5 }}>
                      <Grid item xs={6}>
                        <TextField
                          type='number'
                          fullWidth
                          variant='outlined'
                          name='bounceCredit'
                          placeholder='Bounce Credits'
                          value={formik.values.bounceCredit}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          onKeyDown={handleKeyDown}
                          error={!!(formik.touched.bounceCredit && formik.errors.bounceCredit)}
                          helperText={formik.touched.bounceCredit && formik.errors.bounceCredit}
                          InputProps={{
                            inputProps: { min: 0 }
                          }}
                          sx={{
                            '& div': {
                              pl: 0.3,
                              width: '20rem',
                              mt: '-0.2rem'
                            },
                            '& div fieldset': {
                              border: 'none'
                            },
                            '& div input': {
                              py: 1.3,
                              fontSize: '13px',
                              fontWeight: 400,
                              lineHeight: '16px',
                              letterSpacing: '0em',
                              '&::placeholder': {
                                color: 'rgba(40, 40, 123, 0.5)'
                              },
                              borderRadius: '0.7rem',
                              border: '1px solid #E4E4E5'
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'start',
                    flexDirection: 'column'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: 'full'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: 700,
                        lineHeight: '26px',
                        color: 'rgb(129, 129, 176)'
                      }}
                    >
                      Phone Number Exports :
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      width: 'full',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Grid container spacing={2} sx={{ my: 0.5 }}>
                      <Grid item xs={6}>
                        <TextField
                          type='number'
                          fullWidth
                          variant='outlined'
                          name='leadsPhoneNumberExportsPerMonth'
                          placeholder='Phone Number Exports/month'
                          value={formik.values.leadsPhoneNumberExportsPerMonth}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          onKeyDown={handleKeyDown}
                          error={!!(formik.touched.leadsPhoneNumberExportsPerMonth && formik.errors.leadsPhoneNumberExportsPerMonth)}
                          helperText={formik.touched.leadsPhoneNumberExportsPerMonth && formik.errors.leadsPhoneNumberExportsPerMonth}
                          InputProps={{
                            inputProps: { min: 0 }
                          }}
                          sx={{
                            '& div': {
                              pl: 0.3,
                              width: '20rem',
                              mt: '-0.2rem'
                            },
                            '& div fieldset': {
                              border: 'none'
                            },
                            '& div input': {
                              py: 1.3,
                              fontSize: '13px',
                              fontWeight: 400,
                              lineHeight: '16px',
                              letterSpacing: '0em',
                              '&::placeholder': {
                                color: 'rgba(40, 40, 123, 0.5)'
                              },
                              borderRadius: '0.7rem',
                              border: '1px solid #E4E4E5'
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'start',
                    flexDirection: 'column'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: 'full'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: 700,
                        lineHeight: '26px',
                        color: 'rgb(129, 129, 176)'
                      }}
                    >
                      Buyer Intend Data :
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      width: 'full',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Grid container spacing={2} sx={{ my: 0.5 }}>
                      <Grid item xs={6}>
                        <Switch
                          checked={formik.values.buyerIntendData}
                          onChange={({ target: { checked } }) => {
                            formik.setFieldValue('buyerIntendData', checked)
                          }}
                          name='buyerIntendData'
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'start',
                    flexDirection: 'column'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: 'full'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: 700,
                        lineHeight: '26px',
                        color: 'rgb(129, 129, 176)'
                      }}
                    >
                      Advanced Apis :
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      width: 'full',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Grid container spacing={2} sx={{ my: 0.5 }}>
                      <Grid item xs={6}>
                        <Switch
                          checked={formik.values.advancedApis}
                          onChange={({ target: { checked } }) => {
                            formik.setFieldValue('advancedApis', checked)
                          }}
                          name='advancedApis'
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'start',
                    flexDirection: 'column'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: 'full'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: 700,
                        lineHeight: '26px',
                        color: 'rgb(129, 129, 176)'
                      }}
                    >
                      Multi Channel Linkedin Outreach :
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      width: 'full',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Grid container spacing={2} sx={{ my: 0.5 }}>
                      <Grid item xs={6}>
                        <Switch
                          checked={formik.values.multiChannelLinkedinOutreach}
                          onChange={({ target: { checked } }) => {
                            formik.setFieldValue('multiChannelLinkedinOutreach', checked)
                          }}
                          name='multiChannelLinkedinOutreach'
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
              <Divider sx={{ mt: 3, mb: 2 }} textAlign='center'>
                <Typography variant='h6' color='textSecondary'>
                  API Rate Limits
                </Typography>
              </Divider>
              <Box sx={{ display: 'flex', pt: '0.5rem', gap: 2 }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: 'full'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: 700,
                        lineHeight: '26px',
                        color: 'rgb(129, 129, 176)'
                      }}
                    >
                      Per Day :
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    />
                  </Box>

                  <Box
                    sx={{
                      width: 'full',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-end',
                      alignItems: 'center'
                    }}
                  >
                    <Grid container spacing={2} sx={{ my: 0.5 }}>
                      <Grid item xs={6}>
                        <TextField
                          type='number'
                          fullWidth
                          variant='outlined'
                          name='perDayApiLimit'
                          placeholder='Per Day'
                          value={formik.values.perDayApiLimit}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          onKeyDown={handleKeyDown}
                          InputProps={{
                            inputProps: { min: 0 }
                          }}
                          error={
                            !!(
                              formik.touched.perDayApiLimit &&
                              formik.errors.perDayApiLimit
                            )
                          }
                          helperText={
                            formik.touched.perDayApiLimit &&
                            formik.errors.perDayApiLimit
                          }
                          sx={{
                            '& div': {
                              pl: 0.3,
                              width: '20rem',
                              mt: '-0.2rem'
                            },
                            '& div fieldset': {
                              border: 'none'
                            },
                            '& div input': {
                              py: 1.3,
                              fontSize: '13px',
                              fontWeight: 400,
                              lineHeight: '16px',
                              letterSpacing: '0em',
                              '&::placeholder': {
                                color: 'rgba(40, 40, 123, 0.5)'
                              },
                              borderRadius: '0.7rem',
                              border: '1px solid #E4E4E5'
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'start',
                    flexDirection: 'column'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: 'full'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: 700,
                        lineHeight: '26px',
                        color: 'rgb(129, 129, 176)'
                      }}
                    >
                      Per Hour :
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    />
                  </Box>

                  <Box
                    sx={{
                      width: 'full',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Grid container spacing={2} sx={{ my: 0.5 }}>
                      <Grid item xs={6}>
                        <TextField
                          type='number'
                          fullWidth
                          variant='outlined'
                          name='perHourApiLimit'
                          placeholder='Per Hour'
                          value={formik.values.perHourApiLimit}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          onKeyDown={handleKeyDown}
                          InputProps={{
                            inputProps: { min: 0 }
                          }}
                          error={
                            !!(
                              formik.touched.perHourApiLimit &&
                              formik.errors.perHourApiLimit
                            )
                          }
                          helperText={
                            formik.touched.perHourApiLimit &&
                            formik.errors.perHourApiLimit
                          }
                          sx={{
                            '& div': {
                              pl: 0.3,
                              width: '20rem',
                              mt: '-0.2rem'
                            },
                            '& div fieldset': {
                              border: 'none'
                            },
                            '& div input': {
                              py: 1.3,
                              fontSize: '13px',
                              fontWeight: 400,
                              lineHeight: '16px',
                              letterSpacing: '0em',
                              '&::placeholder': {
                                color: 'rgba(40, 40, 123, 0.5)'
                              },
                              borderRadius: '0.7rem',
                              border: '1px solid #E4E4E5'
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', pt: '0.5rem', gap: 2 }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: 'full'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: 700,
                        lineHeight: '26px',
                        color: 'rgb(129, 129, 176)'
                      }}
                    >
                      Per Min :
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    />
                  </Box>

                  <Box
                    sx={{
                      width: 'full',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-end',
                      alignItems: 'center'
                    }}
                  >
                    <Grid container spacing={2} sx={{ my: 0.5 }}>
                      <Grid item xs={6}>
                        <TextField
                          type='number'
                          fullWidth
                          variant='outlined'
                          name='perMinApiLimit'
                          placeholder='Per Minute'
                          value={formik.values.perMinApiLimit}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          onKeyDown={handleKeyDown}
                          InputProps={{
                            inputProps: { min: 0 }
                          }}
                          error={
                            !!(
                              formik.touched.perMinApiLimit &&
                              formik.errors.perMinApiLimit
                            )
                          }
                          helperText={
                            formik.touched.perMinApiLimit &&
                            formik.errors.perMinApiLimit
                          }
                          sx={{
                            '& div': {
                              pl: 0.3,
                              width: '20rem',
                              mt: '-0.2rem'
                            },
                            '& div fieldset': {
                              border: 'none'
                            },
                            '& div input': {
                              py: 1.3,
                              fontSize: '13px',
                              fontWeight: 400,
                              lineHeight: '16px',
                              letterSpacing: '0em',
                              '&::placeholder': {
                                color: 'rgba(40, 40, 123, 0.5)'
                              },
                              borderRadius: '0.7rem',
                              border: '1px solid #E4E4E5'
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'start',
                    flexDirection: 'column'
                  }}
                />
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'end', mt: '2rem' }}>
                <SaveButton
                  onClick={formik.handleSubmit}
                  isSubmitting={isSubmitting}
                  title={title}
                />
              </Box>
            </Box>
          </DialogContent>
        </form>
      </Dialog>
    </>
  )
}

export default PlanSetting

const SaveButton = ({ onClick, isSubmitting, title }) => {
  return (
    <Button
      sx={{
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '18px',
        py: 1,
        px: 2
      }}
      variant='contained'
      type='submit'
      onClick={onClick}
    >
      {isSubmitting
        ? (
          <>
            <CircularProgress
              color='inherit'
              size={20}
              thickness={5}
              sx={{ mr: 1 }}
            />
            Saving
          </>
          )
        : (
          <>
            {title === 'Add Plan' ? 'Save' : 'Update'}
          </>
          )}
    </Button>
  )
}
