import { useTheme } from '@mui/material'
export const EATotalEmailsSent = () => {
  const theme = useTheme()
  return (
    <>
      <svg
        width='49'
        height='48'
        viewBox='0 0 49 48'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect x='0.5' width='48' height='48' rx='24' fill='#EBEFFF' />
        <path
          d='M33.5708 14.9295L22.9068 25.5935M15.7715 20.2352L32.3774 14.4739C33.4 14.1191 34.3811 15.1003 34.0264 16.1229L28.2651 32.7288C27.8704 33.8664 26.273 33.8976 25.8342 32.7763L23.1973 26.0375C23.0656 25.701 22.7993 25.4347 22.4627 25.303L15.724 22.6661C14.6027 22.2273 14.6339 20.6299 15.7715 20.2352Z'
          stroke={theme.palette.primary.main}
          stroke-width='2'
          stroke-linecap='round'
        />
      </svg>
    </>
  )
}
