import { useTheme } from '@mui/material'

export const EAEditIcon = () => {
  const theme = useTheme()
  return (
    <>
      <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M8.35808 12.8288L8.85293 12.2742C9.46011 11.5937 10.5495 11.6822 11.0389 12.4517C11.4948 13.1686 12.4854 13.3045 13.1175 12.7369L14.0145 11.9315M1.98633 12.9802L4.89698 12.3937C5.0515 12.3626 5.19338 12.2865 5.30481 12.175L11.8206 5.65563C12.133 5.34306 12.1328 4.8364 11.8201 4.52409L10.4398 3.14537C10.1273 2.83319 9.6209 2.83341 9.30863 3.14585L2.79217 9.66588C2.68096 9.77715 2.60503 9.91874 2.57386 10.0729L1.98633 12.9802Z'
          stroke={theme.palette.primary.primaryText}
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </svg>
    </>
  )
}
