import { useTheme } from '@mui/material'
export const IHCampaignsIcon = () => {
  const theme = useTheme()
  return (
    <>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M21.0688 2.92949L10.4049 13.5935M3.26953 8.23517L19.8754 2.47394C20.8981 2.11915 21.8792 3.10028 21.5244 4.12291L15.7632 20.7288C15.3685 21.8664 13.771 21.8976 13.3322 20.7763L10.6953 14.0375C10.5636 13.701 10.2974 13.4347 9.96079 13.303L3.22207 10.6661C2.10073 10.2273 2.13192 8.62985 3.26953 8.23517Z'
          stroke={theme.palette.primary.primaryText}
          stroke-width='2'
          stroke-linecap='round'
        />
      </svg>
    </>
  )
}
