import {
  Box,
  CircularProgress,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  IconButton,
} from "@mui/material";
import { EditIcon } from "src/assets/general/EditIcon";
import { DeleteIcon } from "src/assets/general/DeleteIcon";
import { toast } from "react-hot-toast";
import { useDeleteSearchMutation } from "src/services/leads-service.js";
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded';
import { useTheme } from "@emotion/react";

const SavedSearchBlock = ({
  searches,
  isLoading,
  onSearchClick,
  onEditSearchClick,
  onAllSavedClick,
  showLess,
}) => {
  const theme = useTheme()
  const calculatedSearches = showLess ? searches?.saved?.slice(0, 3) : searches?.saved;
  const [deleteSearch, { isLoading: isDeleteSearchLoading }] = useDeleteSearchMutation();

  const formatQueryString = (query) => {
    if (typeof query === "string") return query;
    if (typeof query === "object") {
      const parts = [];
      if (query.current_title) parts.push(query.current_title);
      if (query.geo) parts.push(query.geo);
      return parts.join(", ") || "No description";
    }
    return "No description";
  };

  const handleSearchDelete = async (searchId, event) => {
    event.stopPropagation();
    try {
      const { message } = await deleteSearch(searchId).unwrap();
      toast.success(message);
    } catch (error) {
      console.error("Error deleting search:", error);
      toast.error("Failed to delete search");
    }
  };

  const handleSearchEdit = (searchId, event) => {
    event.stopPropagation();
    onEditSearchClick(searchId);
  };

  return (
    <Box
      sx={{
        backgroundColor: "white",
        borderRadius: "8px",
        padding: "16px",
        boxShadow: "0px 8px 34px 0px #28287B1A",
        width: "100%",
      }}
    >
      {isLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center", py: 2 }}>
          <CircularProgress size={25} thickness={5} />
        </Box>
      ) : (
        <Box sx={{ width: "440px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems:'center'
              }}
            >
              <FavoriteBorderRoundedIcon sx={{ fontSize: '16px', color: '#8181B0' }} />
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 700,
                  fontSize: "16px",
                  color: `${theme.palette.primary.primaryText}`,
                  ml:'6px'
                }}
              >
                Saved Searches
              </Typography>
            </Box>
            {calculatedSearches?.length && (
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: "14px",
                  color: `${theme.palette.primary.main}`,
                  cursor: "pointer",
                  "&:hover": {
                    color: `${theme.palette.primary.dark}`, // Darker blue for hover
                  },
                }}
                onClick={onAllSavedClick}
              >
                See All Saved
              </Typography>
            )}
          </Box>

          <List sx={{ width: "100%", p: 0 }}>
            {!calculatedSearches?.length ? (
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#667085",
                  textAlign: "center",
                  py: 2,
                }}
              >
                No saved searches
              </Typography>
            ) : (
              calculatedSearches.map((search, index) => (
                <div key={search._id}>
                  <ListItem
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "8px 0",
                      position: "relative",
                      "&:hover .action-buttons": {
                        opacity: 1,
                        transform: "translateX(0)",
                      },
                      cursor: "pointer",
                    }}
                    onClick={() => onSearchClick(search.query)}
                  >
                    {/* Action buttons that appear on hover */}
                    <Box
                      className="action-buttons"
                      sx={{
                        position: "absolute",
                        left: "-50px",
                        display: "flex",
                        flexDirection: "column",
                        opacity: 0,
                        transform: "translateX(10px)",
                        transition: "all 0.2s ease-in-out",
                      }}
                    >
                      <IconButton
                        size="small"
                        onClick={(e) => handleSearchEdit(search._id, e)}
                        sx={{
                          mb: 1,
                          width: 26,
                          height: 26,
                          backgroundColor: "#f5f5f5",
                          "&:hover": {
                            backgroundColor: "#e0e0e0",
                          },
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      {isDeleteSearchLoading ? (
                        <CircularProgress size={20} sx={{ color: "rgb(253, 30, 54)" }} />
                      ) : (
                        <IconButton
                          size="small"
                          onClick={(e) => handleSearchDelete(search._id, e)}
                          sx={{
                            width: 26,
                            height: 26,
                            backgroundColor: "#f5f5f5",
                            "&:hover": {
                              backgroundColor: "#e0e0e0",
                            },
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </Box>

                    <ListItemText
                      primary={
                        <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                          <Typography
                            component="span"
                            sx={{
                              color: `${theme.palette.primary.main  }`,
                              fontWeight: 600,
                              fontSize: "14px",
                              marginRight: 2,
                            }}
                          >
                            {search.name}:
                          </Typography>{" "}
                          {formatQueryString(search.query)}
                        </Typography>
                      }
                    />
                  </ListItem>
                  {index < calculatedSearches.length - 1 && (
                    <Divider sx={{ my: "5px", borderColor: "#E4E4E5" }} />
                  )}
                </div>
              ))
            )}
          </List>
        </Box>
      )}
    </Box>
  );
};

export default SavedSearchBlock;
