import React, { useState, useEffect, useRef } from 'react'
import {
  Box,
  Typography,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  Grid,
  Dialog,
  DialogContent
} from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
// import CancelIcon from "@mui/icons-material/Cancel";
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import CloseIcon from '@mui/icons-material/Close'
import VisibilityIcon from '@mui/icons-material/Visibility'

import {
  useGenerateTwofaSecretMutation,
  useVerifyOtpMutation
} from 'src/services/auth-service'
import { useSendCodeMutation } from 'src/services/tfa-service'
import { useGetMeQuery, useUpdatePasswordMutation, useUpdateUserMutation } from 'src/services/user-service'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import toast from 'react-hot-toast'
import { Save } from '@mui/icons-material'
import { useTheme } from '@emotion/react'

const MyAccountPage = (canEdit) => {
  const theme = useTheme()
  const inputRefs = useRef([])
  const [show2FABox, setShow2FABox] = useState(false)
  const [open, setOpen] = useState(false)
  const [showCurrentPassword, setShowCurrentPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const handleOpen = () => setOpen(true)

  const togglePasswordVisibility = (field) => {
    switch (field) {
      case 'current':
        setShowCurrentPassword(!showCurrentPassword)
        break
      case 'new':
        setShowNewPassword(!showNewPassword)
        break
      case 'confirm':
        setShowConfirmPassword(!showConfirmPassword)
        break
    }
  }
  const handleClose = () => {
    formikUpdatePassword.resetForm()
    setOpen(false)
  }

  const [updateUser] = useUpdateUserMutation()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [showNameSave, setShowNameSave] = useState(false)
  const [qrString, setQrString] = useState(null)
  const [generateQrcode] = useGenerateTwofaSecretMutation()
  const [verifyOtp] = useVerifyOtpMutation()
  const [updatePassword] = useUpdatePasswordMutation()
  const { data: user, refetch: refetchUser } = useGetMeQuery()
  const [sendCode] = useSendCodeMutation()
  const [inputs, setInputs] = useState(['', '', '', '-', '', '', ''])

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value)
    if (e.target.value !== user?.name?.first) {
      setShowNameSave(true)
    } else {
      setShowNameSave(false)
    }
  }

  const handleLastNameChange = (e) => {
    setLastName(e.target.value)
    if (e.target.value !== user?.name?.last) {
      setShowNameSave(true)
    } else {
      setShowNameSave(false)
    }
  }

  const handleSaveNameClick = async () => {
    await updateUser({
      name: {
        first: firstName,
        last: lastName
      }
    }).unwrap()
    setShowNameSave(false)
    toast.success('Name Updated!')
    refetchUser()
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(qrString?.secret)
  }

  const { setValues: setEmailFormValues, ...emailForm } = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Must be a valid email').max(255).required('Email is required')
    }),
    onSubmit: async () => {
      await sendCode().unwrap()
    }
  })

  const formikUpdatePassword = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: ''
    },
    validationSchema: Yup.object().shape({
      currentPassword: Yup.string().required('Current password is required'),
      newPassword: Yup.string()
        .min(8, 'Must be at least 8 characters')
        .max(255)
        .matches(/[A-Z]/, 'Must include an uppercase letter')
        .matches(/[a-z]/, 'Must include a lowercase letter')
        .matches(/\d/, 'Must include a number')
        .matches(/[@$!%*?&]/, 'Must include a special character')
        .required('New password is required'),
      confirmNewPassword: Yup.string()
        .required('Confirm new password is required')
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    }),
    onSubmit: async (values, helpers) => {
      try {
        const { message } = await updatePassword(values).unwrap()
        handleClose()
        toast.success(message)
        helpers.resetForm()
      } catch (err) {
        toast.error(err.data.error.message)
      }
    }
  })

  useEffect(() => {
    setFirstName(user?.name?.first)
    setLastName(user?.name?.last)
    setEmailFormValues({ email: user?.email })
  }, [user, setEmailFormValues])

  const EnableTwofa = async () => {
    setShow2FABox(true)
    const response = await generateQrcode({ email: user?.email })
    setQrString(response.data.message)
  }

  const handleEnableClick = async () => {
    const twoFa = inputs.join('')
    const response = await verifyOtp({ email: user?.email, token: twoFa })
    if (response.data.message.twofaEnabled) {
      toast.success(response.data.message.message)
      toast.success('Two-factor Authentication Enabled')
      setQrString(null)
      refetchUser()
    } else {
      toast.error(response.data.message.message)
    }
  }

  const handleInputChange = (index, value) => {
    if (inputs[index] === '-') {
      return
    }

    if (value.match(/^\d?$/)) { // Allow only digits or empty string
      setInputs((prev) => {
        const updated = [...prev]
        updated[index] = value
        return updated
      })

      // Move to next input field if a digit is entered
      if (value !== '') {
        setTimeout(() => {
          let nextIndex = index + 1

          // Skip over "-" values
          while (nextIndex < inputs.length && inputs[nextIndex] === '-') {
            nextIndex++
          }

          // Focus on next available input
          if (nextIndex < inputs.length && inputRefs.current[nextIndex]) {
            inputRefs.current[nextIndex].focus()
          }
        }, 0)
      }
    }
  }

  const handleKeyDown = (index, e) => {
    // Handle backspace
    if (e.key === 'Backspace' && !inputs[index] && index > 0) {
      let prevIndex = index - 1
      // Skip backwards over "-" values
      while (prevIndex >= 0 && inputs[prevIndex] === '-') {
        prevIndex--
      }
      if (prevIndex >= 0 && inputRefs.current[prevIndex]) {
        inputRefs.current[prevIndex].focus()
      }
    }
  }

  // const handleConfirm = async () => {
  //   const response = await generateQrcode({ email: user?.email, disable2fa: true })
  //   setQrString(null)
  //   setTwofa('')
  //   toast.success(response.data.message.message)
  //   refetchUser()
  //   SetopenConfirmationDialog(false)
  // }

  return (
    <Box
      sx={{
        padding: 2,
        backgroundColor: '#f9f9f9',
        minHeight: '100vh'
      }}
    >
      <Grid container spacing={3}>
        {/* My Profile Section  */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              backgroundColor: '#fff',
              padding: { xs: 2, sm: 3 },
              borderRadius: '8px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
            }}
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: { xs: '14px', md: '16px' },
                marginBottom: '8px'
              }}
            >
              My profile
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: '12px', md: '14px' },
                marginBottom: '20px',
                color: '#8181B0'
              }}
            >
              This information is general and applies across the entire platform.
            </Typography>

            <Box
              sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 2, mt: 4 }}
            >
              <TextField
                label='First name*'
                value={firstName}
                fullWidth
                variant='outlined'
                InputProps={{ style: { fontSize: '14px' } }}
                onChange={handleFirstNameChange}
              />
              <TextField
                label='Last name*'
                value={lastName}
                fullWidth
                variant='outlined'
                InputProps={{ style: { fontSize: '14px' } }}
                onChange={handleLastNameChange}
              />
            </Box>
            <Grid item xs={12} sx={{ px: 0 }}>
              <Box
                sx={{
                  display: showNameSave ? 'flex' : 'none',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                <Button
                  size='small'
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: 1,
                    px: 2,
                    marginTop: '10px'
                  }}
                  onClick={handleSaveNameClick}
                  variant='contained'
                  disabled={!canEdit}
                >
                  <Save fontSize='small' />
                  <Typography sx={{ fontSize: '13px', ml: 0.5, fontWeight: '500' }}>
                    Save
                  </Typography>
                </Button>
              </Box>
            </Grid>

            <Box sx={{ marginBottom: 3, mt: 4 }}>
              <TextField
                label='Email*'
                value={emailForm.values.email}
                fullWidth
                InputProps={{
                  style: { fontSize: '14px' },
                  endAdornment: user?.emailVerified && (
                    <InputAdornment position='end'>
                      <Button
                        startIcon={<CheckCircleOutlineIcon />}
                        sx={{
                          fontSize: '12px',
                          backgroundColor: '#e0f7ea',
                          color: '#4caf50',
                          borderRadius: '20px',
                          padding: '2px 12px',
                          textTransform: 'none',
                          cursor: 'default',
                          '&:hover': { backgroundColor: '#e0f7ea' }
                        }}
                      >
                        Verified
                      </Button>
                    </InputAdornment>
                  )
                }}
                variant='outlined'
              />
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', mt: 4 }}>
              <TextField
                label='Password*'
                type='password'
                defaultValue='***********'
                fullWidth
                disabled
                // InputProps={{
                //   endAdornment: (
                //     <InputAdornment position="end">
                //       <VisibilityOffIcon sx={{ cursor: "pointer", color: "#757575" }} />
                //     </InputAdornment>
                //   ),
                // }}
                variant='outlined'
              />
              <Button
                sx={{
                  fontSize: '12px',
                  marginLeft: { xs: 0, md: '8px' },
                  marginTop: { xs: '8px', md: 0 },
                  textTransform: 'none',
                  color: `${theme.palette.primary.main}`
                }}
                onClick={handleOpen}
              >
                Change password
              </Button>
            </Box>
          </Box>
        </Grid>

        <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '16px',
              borderBottom: '1px solid #E0E0E0'
            }}
          >
            <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
              Change Password
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>

          <DialogContent sx={{ padding: '24px' }}>
            <TextField
              label='Current Password'
              type={showCurrentPassword ? 'text' : 'password'}
              fullWidth
              variant='outlined'
              margin='normal'
              name='currentPassword'
              value={formikUpdatePassword.values.currentPassword}
              onChange={formikUpdatePassword.handleChange}
              onBlur={formikUpdatePassword.handleBlur}
              error={
                formikUpdatePassword.touched.currentPassword &&
                Boolean(formikUpdatePassword.errors.currentPassword)
              }
              helperText={
                formikUpdatePassword.touched.currentPassword &&
                formikUpdatePassword.errors.currentPassword
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton onClick={() => togglePasswordVisibility('current')}>
                      {showCurrentPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />

            <TextField
              label='New Password'
              type={showNewPassword ? 'text' : 'password'}
              fullWidth
              variant='outlined'
              margin='normal'
              name='newPassword'
              value={formikUpdatePassword.values.newPassword}
              onChange={formikUpdatePassword.handleChange}
              onBlur={formikUpdatePassword.handleBlur}
              error={
                formikUpdatePassword.touched.newPassword &&
                Boolean(formikUpdatePassword.errors.newPassword)
              }
              helperText={
                formikUpdatePassword.touched.newPassword &&
                formikUpdatePassword.errors.newPassword
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton onClick={() => togglePasswordVisibility('new')}>
                      {showNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />

            <TextField
              label='Confirm New Password'
              type={showConfirmPassword ? 'text' : 'password'}
              fullWidth
              variant='outlined'
              margin='normal'
              name='confirmNewPassword'
              value={formikUpdatePassword.values.confirmNewPassword}
              onChange={formikUpdatePassword.handleChange}
              onBlur={formikUpdatePassword.handleBlur}
              error={
                formikUpdatePassword.touched.confirmNewPassword &&
                Boolean(formikUpdatePassword.errors.confirmNewPassword)
              }
              helperText={
                formikUpdatePassword.touched.confirmNewPassword &&
                formikUpdatePassword.errors.confirmNewPassword
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton onClick={() => togglePasswordVisibility('confirm')}>
                      {showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 2,
                marginTop: '24px'
              }}
            >
              <Button
                onClick={handleClose}
                sx={{
                  textTransform: 'none',
                  color: '#6c757d',
                  border: '1px solid #D7D9E6',
                  borderRadius: '8px',
                  padding: '8px 16px'
                }}
              >
                Cancel
              </Button>
              <Button
                type='submit'
                onClick={formikUpdatePassword.handleSubmit}
                variant='contained'
                sx={{
                  textTransform: 'none',
                  bgcolor: `${theme.palette.primary.main}`,
                  color: '#FFFFFF',
                  borderRadius: '8px',
                  padding: '8px 16px',
                  '&:hover': { bgcolor: `${theme.palette.primary.main}` }
                }}
              >
                Change Password
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
        {/* Two-Factor Authentication Section */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              backgroundColor: '#fff',
              padding: { xs: 2, sm: 3 },
              borderRadius: '8px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
            }}
          >
            {!show2FABox
              ? (
                <Box>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: { xs: '16px', md: '18px' },
                      marginBottom: '8px'
                    }}
                  >
                    Two-Factor Authentication
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: '12px', md: '14px' },
                      color: '#757575',
                      marginBottom: '20px'
                    }}
                  >
                    Activate Two-Factor Authentication to protect your account with an extra layer of
                    security, requiring both your password and a code from your device.
                  </Typography>
                  <Button
                    variant='contained'
                    onClick={EnableTwofa}
                    sx={{
                      backgroundColor: `${theme.palette.primary.main}`,
                      color: '#fff',
                      textTransform: 'none',
                      padding: '10px 20px',
                      borderRadius: '8px',
                      '&:hover': {
                        backgroundColor: `${theme.palette.primary.main}`
                      }
                    }}
                  >
                    Set up Two-Factor Authentication
                  </Button>
                </Box>
                )
              : (
                <Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                    <Typography sx={{ fontSize: '18px', fontWeight: '600' }}>
                      Two-Factor Authentication
                    </Typography>
                    <Button
                      sx={{ border: '1px solid #E4E4E5', color: '#101010' }}
                      onClick={() => {
                        setInputs(['', '', '', '-', '', '', ''])
                        setShow2FABox(false)
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>

                  {/* QR Code Section */}
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Box
                      component='img'
                      src={qrString?.qrImageDataUrl || '/assets/SettingsImages/imageQR-CODE.png'}
                      alt='QR Code'
                      sx={{
                        width: '110px',
                        height: '110px',
                        backgroundColor: '#eee',
                        marginRight: 2,
                        objectFit: 'contain'
                      }}
                    />
                    <Box>
                      <TextField
                        sx={{ bgcolor: '#E4E4E5', width: '50%' }}
                        value={qrString?.secret}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <IconButton onClick={handleCopy}>
                                <ContentCopyIcon />
                                <Typography>Copy</Typography>
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: '13px',
                          fontWeight: 400,
                          color: '#757575',
                          mt: 2
                        }}
                      >
                        Scan the QR code with Google Authenticator or Authy, or enter the secret key below to
                        start receiving secure 2FA codes.
                      </Typography>
                    </Box>
                  </Box>

                  {/* 2FA Code Input */}
                  <Typography sx={{ fontSize: '14px', fontWeight: '500', marginBottom: 1 }}>
                    2FA Code from Authentication App
                  </Typography>
                  <Grid
                    container
                    spacing={1}
                    alignItems='center'
                    sx={{ mb: 2, flexWrap: 'nowrap', overflowX: 'auto' }}
                  >
                    {inputs.map((digit, index) => (
                      <Grid item key={index}>
                        <TextField
                          id={`input-${index}`}
                          inputRef={(el) => (inputRefs.current[index] = el)}
                          value={digit}
                          onChange={(e) => handleInputChange(index, e.target.value)}
                          onKeyDown={(e) => handleKeyDown(index, e)}
                          disabled={digit === '-'}
                          inputProps={{
                            maxLength: 1,
                            style: {
                              textAlign: 'center',
                              width: '20px',
                              backgroundColor: digit === '-' ? '#f5f5f5' : 'white'
                            }
                          }}
                          variant='outlined'
                          sx={{ minWidth: '20px' }}
                        />
                      </Grid>
                    ))}
                  </Grid>

                  <Button
                    variant='contained'
                    onClick={() => handleEnableClick(inputs.join(''))}
                    sx={{
                      backgroundColor: '#3F4FF8',
                      color: '#fff',
                      textTransform: 'none',
                      padding: '10px 20px',
                      borderRadius: '8px',
                      '&:hover': {
                        backgroundColor: '#1565c0'
                      }
                    }}
                  >
                    Enable Authentication
                  </Button>
                </Box>
                )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default MyAccountPage
