import { useTheme } from '@mui/material'
export const LFJobTitles = () => {
  const theme = useTheme()
  return (
    <>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M2.40142 8.41673L2.40133 17.941C2.40131 19.0456 3.29675 19.9411 4.40132 19.9411L19.6001 19.9411C20.7046 19.9411 21.6 19.0457 21.6001 17.9412L21.6004 8.0125C21.6004 7.46021 21.1527 7.01247 20.6004 7.01247H12.0841L9.31905 4.05884H3.40077C2.84833 4.05884 2.40056 4.50605 2.40073 5.05848C2.40103 6.0093 2.40143 7.43621 2.40142 8.41673Z'
          stroke={theme.palette.primary.primaryText}
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </svg>
    </>
  )
}
