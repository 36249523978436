import {
  Box,
  Button,
  TextField,
  Typography,
  Stack,
  useMediaQuery,
  useTheme,
  alpha,
  CircularProgress,
  InputAdornment,
  IconButton
} from '@mui/material'
import { useResetPasswordUserMutation } from 'src/services/user-service.js'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import toast from 'react-hot-toast'
import LoginImage from '../../assets/auth/login-new.png'
import { Logo } from 'src/components/logo'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useState } from 'react'
import { Visibility, VisibilityOff } from '@mui/icons-material'

export function ResetPassword () {
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const theme = useTheme()

  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')

  const [resetPasswordUser, { isLoading: isResetingPassword }] = useResetPasswordUserMutation()

  const formikUpdatePassword = useFormik({
    initialValues: {
      newPassword: '',
      confirmNewPassword: ''
    },
    validationSchema: Yup.object().shape({
      newPassword: Yup.string().min(8, 'Password must be at least 8 characters')
        .max(255)
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
        .matches(/\d/, 'Password must contain at least one number')
        .matches(/[@$!%*?&]/, 'Password must contain at least one special character')
        .required('New password is required'),
      confirmNewPassword: Yup.string()
        .required('Confirm new password is required')
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    }),
    onSubmit: async (values, helpers) => {
      try {
        const { message } = await resetPasswordUser({ ...values, id: token }).unwrap()
        toast.success(message)
        helpers.resetForm()
        if (typeof window !== 'undefined' && typeof window.localStorage !== 'undefined') {
          window.localStorage.clear()
        }
        navigate('/login')
      } catch (err) {
        toast.error(err.data.error.message)
      }
    }
  })

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',

        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: '#FFFFFF'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          width: isMdUp ? '500px' : '100%',
          p: 4,
          backgroundColor: '#FFFFFF'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '160px',
            height: '30px',
            mb: 5
          }}
        >
          <Logo />
        </Box>

        <Box
          sx={{
            width: '100%',
            mt: 3,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '12px'
          }}
        >
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '100%'
              }}
            >
              <Typography
                sx={{
                  width: '100%',
                  fontSize: '24px',
                  fontWeight: 700,
                  lineHeight: '30px',
                  color: `${theme.palette.primary.primaryText}`
                }}
              >
                Reset your password
              </Typography>
            </Box>

            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: isMdUp ? 'column' : 'column',
                justifyContent: 'space-between',
                gap: '5px'
              }}
            >
              <Stack sx={{ width: '100%' }}>
                <TextField
                  fullWidth
                  type={showPassword ? 'text' : 'password'}
                  placeholder='new password'
                  id='newPassword'
                  name='newPassword'
                  variant='outlined'
                  onChange={formikUpdatePassword.handleChange}
                  onBlur={formikUpdatePassword.handleBlur}
                  value={formikUpdatePassword.values.newPassword}
                  error={
                    formikUpdatePassword.touched.newPassword &&
                    Boolean(formikUpdatePassword.errors.newPassword)
                  }
                  helperText={
                    formikUpdatePassword.touched.newPassword &&
                    formikUpdatePassword.errors.newPassword
                  }
                  sx={{
                    mt: 2,
                    width: '100%',
                    height: 40,
                    backgroundColor: 'white',
                    '& div': { pl: 0.3 },
                    '& div fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
                    '& div input': {
                      py: 1.3,
                      fontSize: '13px',
                      fontWeight: 400,
                      lineHeight: '16px',
                      letterSpacing: '0em',
                      '&::placeholder': {
                        color: 'rgba(40, 40, 123, 0.5)'
                      }
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge='end'
                          aria-label='toggle password visibility'
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Stack>
              <Stack sx={{ width: '100%' }}>
                <TextField
                  fullWidth
                  type={showConfirmPassword ? 'text' : 'password'}
                  placeholder='confirm new password'
                  id='confirmNewPassword'
                  name='confirmNewPassword'
                  variant='outlined'
                  onChange={formikUpdatePassword.handleChange}
                  onBlur={formikUpdatePassword.handleBlur}
                  value={formikUpdatePassword.values.confirmNewPassword}
                  error={
                    formikUpdatePassword.touched.confirmNewPassword &&
                    Boolean(formikUpdatePassword.errors.confirmNewPassword)
                  }
                  helperText={
                    formikUpdatePassword.touched.confirmNewPassword &&
                    formikUpdatePassword.errors.confirmNewPassword
                  }
                  sx={{
                    mt: 2,
                    width: '100%',
                    height: 40,
                    backgroundColor: 'white',
                    '& div': { pl: 0.3 },
                    '& div fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
                    '& div input': {
                      py: 1.3,
                      fontSize: '13px',
                      fontWeight: 400,
                      lineHeight: '16px',
                      letterSpacing: '0em',
                      '&::placeholder': {
                        color: 'rgba(40, 40, 123, 0.5)'
                      }
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                          edge='end'
                          aria-label='toggle password visibility'
                        >
                          {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Stack>
              <Button
                onClick={formikUpdatePassword.handleSubmit}
                variant='contained'
                fullWidth
                sx={{ p: 1, mt: 3 }}
              >
                {isResetingPassword ? <CircularProgress size={20} sx={{ color: 'white' }} /> : 'Reset Password'}
              </Button>
            </Box>
          </>
        </Box>
      </Box>
      {isMdUp && (
        <Box
          sx={{
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: 'calc(100vw - 500px)',
            height: '100%',
            overflow: 'hidden',
            backgroundColor: theme.palette.primary.main
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: -300,
              left: -150,
              width: 600,
              height: 800,
              background: `linear-gradient(to bottom,${alpha(
                theme.palette.background.paper,
                0.2
              )},${alpha(theme.palette.background.paper, 0.1)},transparent)`,
              rotate: '-45deg'
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              bottom: -300,
              right: -150,
              width: 600,
              height: 800,
              background: `linear-gradient(to top,${alpha(
                theme.palette.background.paper,
                0.2
              )},${alpha(theme.palette.background.paper, 0.1)},transparent)`,
              rotate: '-45deg'
            }}
          />
          <Box sx={{ zIndex: 99 }}>
            {' '}
            <img src={LoginImage} alt='login page image' style={{ transform: 'scale(0.85)' }} />
          </Box>
        </Box>
      )}
    </Box>
  )
}
