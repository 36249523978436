import { Navigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setWorkspace, useGetWorkSpacesMutation } from 'src/services/workSpace-service'
import { useEffect, useState } from 'react'
import { useAuth } from 'src/auth/auth'

const EnterpriseRoute = ({ element: Component, ...rest }) => {
  const [getWorkSpaces] = useGetWorkSpacesMutation()
  const [timelineButtons, setTimeLineButtons] = useState([])
  const dispatch = useDispatch()
  const [activeWorkspace, setActiveWorkspace] = useState({})
  const { canEdit } = useAuth()

  useEffect(() => {
    const allWorkSpaces = async () => {
      const docs = await getWorkSpaces().unwrap()
      setTimeLineButtons(docs?.uniqueWorkspaces)
      setActiveWorkspace(docs?.currentWorkspace)
    }
    allWorkSpaces()
  }, [getWorkSpaces])

  useEffect(() => {
    dispatch(setWorkspace(activeWorkspace))
    if (activeWorkspace && typeof window !== 'undefined' && typeof window.localStorage !== 'undefined') {
      window.localStorage.setItem('workspace', JSON.stringify(activeWorkspace))
    }
  }, [timelineButtons, activeWorkspace, dispatch])
  if ((activeWorkspace?._id && !activeWorkspace?.enterprise)) {
    return <Navigate to='/accounts' />
  }
  if ((activeWorkspace?._id && activeWorkspace?.enterprise) && canEdit) {
    return <Component {...rest} />
  }
}

export default EnterpriseRoute
