import React, { useState } from 'react'
import { TextField, Button, Select, MenuItem, InputLabel, FormControl, Typography, Box, CircularProgress, Tooltip, IconButton } from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { config } from 'src/config.js'
import { Helmet } from 'react-helmet-async'

const EmailCopyWriterForm = () => {
  const [response, setResponse] = useState('')
  const validationSchema = () => {
    const schema = Yup.object({
      subjectLine: Yup.string()
        .max(255)
        .required('Subject Line is required'),
      tone: Yup.string()
        .max(255)
        .required('Tone is required'),
      purpose: Yup.string()
        .max(255)
        .required('Purpose is required')
    })

    return schema
  }

  const handleDataSubmit = async (values, helpers) => {
    const apiUrl = `${config.API_BASE_URL}/tools/generate-email`
    try {
      const response = await axios.post(
        apiUrl,
        {
          prompt: values
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      setResponse(response.data.email)
    } catch (error) {
      console.error('Error :', error)
      setResponse('Something Went Wroung Please Try Again !')
    }
  }

  const formik = useFormik({
    initialValues: {
      recipient: '',
      sender: '',
      subjectLine: '',
      tone: 'friendly',
      purpose: ''
    },
    validationSchema,
    onSubmit: async (values, helpers) => {
      try {
        await handleDataSubmit(values, helpers)
      } catch (err) {
        helpers.setErrors({ submit: err.data.error.message })
      }
      formik.resetForm()
    }
  })

  const handleCopy = () => {
    navigator.clipboard.writeText(response)
  }

  return (
    <Box sx={{ padding: { sm: '24px 7%', xs: '16px' }, fontFamily: 'Inter' }}>
      <Helmet>
        <title>Free AI-powered Email Copy Writer - Craft Unique Emails</title>
        <meta name='description' content='Create unique emails effortlessly with our free AI-powered Email Copy Writer, based on OpenAI technology. Start writing engaging emails now!' />
      </Helmet>
      <Typography variant='h5' sx={{ fontWeight: 'bold', color: 'primary.main', py: 2 }}>
        Free AI-powered Email Copy Writer: Generate Unique and Engaging Emails
      </Typography>
      <Typography color='primary'>
        Create unique emails with the only free AI Email Copy Writer based on the Open AI technology
      </Typography>

      <form noValidate onSubmit={formik.handleSubmit}>
        <Box sx={{ my: 4 }}>
          <Box sx={{ display: 'flex', flexDirection: { sm: 'row', xs: 'column' }, gap: { sm: 4, xs: 2 }, my: 4 }}>
            <TextField
              label='Recipient (optional)'
              variant='outlined'
              sx={{ width: { sm: '21vw', xs: '90vw' } }}
              onChange={formik.handleChange}
              error={!!(formik.touched.recipient && formik.errors.recipient)}
              helperText={formik.touched.recipient && formik.errors.recipient}
              onBlur={formik.handleBlur}
              name='recipient'
              value={formik?.values?.recipient}
            />

            <TextField
              label='Sender (optional)'
              variant='outlined'
              sx={{ width: { sm: '21vw', xs: '90vw' } }}
              onChange={formik.handleChange}
              error={!!(formik.touched.sender && formik.errors.sender)}
              helperText={formik.touched.sender && formik.errors.sender}
              onBlur={formik.handleBlur}
              name='sender'
              value={formik?.values?.sender}
            />
          </Box>

          <Box sx={{ display: 'flex', flexDirection: { sm: 'row', xs: 'column' }, gap: { sm: 4, xs: 2 }, my: 4 }}>
            <TextField
              label='Subject Line'
              variant='outlined'
              sx={{ width: { sm: '21vw', xs: '90vw' } }}
              onChange={formik.handleChange}
              error={!!(formik.touched.subjectLine && formik.errors.subjectLine)}
              helperText={formik.touched.subjectLine && formik.errors.subjectLine}
              onBlur={formik.handleBlur}
              name='subjectLine'
              value={formik?.values?.subjectLine}
            />

            <FormControl sx={{ width: { sm: '21vw', xs: '90vw' } }}>
              <InputLabel id='tone-label'>Tone</InputLabel>
              <Select
                labelId='tone-label'
                label='Tone'
                onChange={formik.handleChange}
                error={!!(formik.touched.tone && formik.errors.tone)}
                helperText={formik.touched.tone && formik.errors.tone}
                onBlur={formik.handleBlur}
                name='tone'
                value={formik?.values?.tone}
              >
                <MenuItem value='appreciative'>Appreciative</MenuItem>
                <MenuItem value='assertive'>Assertive</MenuItem>
                <MenuItem value='awestruck'>Awestruck</MenuItem>
                <MenuItem value='candid'>Candid</MenuItem>
                <MenuItem value='casual'>Casual</MenuItem>
                <MenuItem value='cautionary'>Cautionary</MenuItem>
                <MenuItem value='compassionate'>Compassionate</MenuItem>
                <MenuItem value='convincing'>Convincing</MenuItem>
                <MenuItem value='earnest'>Earnest</MenuItem>
                <MenuItem value='enthusiastic'>Enthusiastic</MenuItem>
                <MenuItem value='formal'>Formal</MenuItem>
                <MenuItem value='friendly'>Friendly</MenuItem>
                <MenuItem value='funny'>Funny</MenuItem>
                <MenuItem value='humble'>Humble</MenuItem>
                <MenuItem value='humorous'>Humorous</MenuItem>
                <MenuItem value='informative'>Informative</MenuItem>
                <MenuItem value='inspirational'>Inspirational</MenuItem>
                <MenuItem value='joyful'>Joyful</MenuItem>
                <MenuItem value='passionate'>Passionate</MenuItem>
                <MenuItem value='thoughtful'>Thoughtful</MenuItem>
                <MenuItem value='urgent'>Urgent</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: { sm: 'row', xs: 'column' }, alignItems: 'flex-end', gap: { sm: 4, xs: 2 }, mt: 4 }}>
            <TextField
              placeholder='Example: I am writing to propose a new marketing campaign that I believe will be effective for your business.'
              multiline
              rows={4}
              variant='outlined'
              sx={{ width: { sm: '21vw', xs: '90vw' } }}
              onChange={formik.handleChange}
              error={!!(formik.touched.purpose && formik.errors.purpose)}
              helperText={formik.touched.purpose && formik.errors.purpose}
              onBlur={formik.handleBlur}
              name='purpose'
              value={formik?.values?.purpose}
            />

            <Button
              variant='contained'
              sx={{ backgroundColor: '#6E58F1', '&:hover': { backgroundColor: '#7c68f1' }, width: { sm: '8vw', xs: '90vw' }, minHeight: 56 }}
              disabled={!(formik?.values?.purpose && formik?.values?.tone && formik?.values?.subjectLine)}
              type='submit'
            >
              {formik.isSubmitting
                ? (
                  <CircularProgress size={20} color='inherit' />
                  )
                : (
                  <>
                    Generate
                  </>
                  )}
            </Button>
          </Box>
        </Box>

        {formik.errors.submit && (
          <Box sx={{ display: 'flex', alignItems: 'flex-end', mt: 3 }}>
            <Typography color='error' sx={{ mt: 3, textAlign: 'right' }} variant='body2'>
              {formik.errors.submit}
            </Typography>
          </Box>
        )}
      </form>

      {response !== '' &&
        <Box mt={4}>
          <Box display='flex' justifyContent='space-between' alignItems='center' sx={{ width: { sm: '84%', xs: '100%' } }}>
            <Typography variant='h6' fontWeight='bold' color='primary' my={2}>
              AI Generated Email Is Here :
            </Typography>
            <Tooltip title='Copy'>
              <IconButton onClick={handleCopy} sx={{ color: '#6E58F1', '&:hover': { color: '#7c68f1' } }}>
                <ContentCopyIcon fontSize='small' />
                <Typography variant='body2' ml={1} sx={{ cursor: 'pointer' }}>
                  Copy
                </Typography>
              </IconButton>
            </Tooltip>
          </Box>
          <Box
            component='pre'
            sx={{
              backgroundColor: 'grey.50',
              border: '1px solid',
              borderColor: 'grey.300',
              borderRadius: 1,
              p: 2,
              mt: 2,
              width: { sm: '84%', xs: '100%' },
              color: 'grey.800',
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-word'
            }}
          >
            {response}
          </Box>
        </Box>}
    </Box>
  )
}

export default EmailCopyWriterForm
