import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  Link,
  Stack,
  TextField,
  Typography,
  useTheme,
  InputAdornment
} from '@mui/material'
import { useFormik } from 'formik'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import SuccessAiBlueLogo from 'src/assets/successAiBlueLogo.svg'
import {
  setAuthToken,
  useForgotPasswordMutation,
  useLoginMutation,
  useResendVerifyLinkMutation
} from 'src/services/auth-service.js'
import * as Yup from 'yup'
import toast from 'react-hot-toast'
import { VisibilityOff, Visibility } from '@mui/icons-material'
import { setSEODetails, useCheckDomainQuery } from 'src/services/theme-service'
import { useLazyGetMeQuery } from 'src/services/user-service'
import { useDispatch } from 'react-redux'

const WhiteLabelLoginPage = () => {
  let currentDomain
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const theme = useTheme()
  const [login] = useLoginMutation()
  const [forgotPassword] = useForgotPasswordMutation()
  const [getme, { data }] = useLazyGetMeQuery()
  const [resendVerifyLink, { isLoading: isResendLinkVerifyLinkLoading }] = useResendVerifyLinkMutation()
  const [, setOpenForgotPasswordDialog] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [whiteLabelTheme, setWhiteLabelTheme] = useState(null)

  // for localhost
  // const [searchParams] = useSearchParams();
  // currentDomain = searchParams.get('domain')

  if (typeof window !== 'undefined') {
    currentDomain = window.location.hostname
  }

  const { data: whiteLabelUser } = useCheckDomainQuery({ domain: currentDomain })

  useEffect(() => {
    if (reloadDomOnce === 'true') {
      // Reload the page
      window.location.reload()
      // Remove the item from localStorage
      if (typeof window !== 'undefined' && typeof window.localStorage !== 'undefined') {
        window.localStorage.removeItem('reloadDom')
      }
    }
  }, [])

  useEffect(() => {
    setWhiteLabelTheme(whiteLabelUser?.data?.theme)
    if (whiteLabelUser?.data?.seo) {
      dispatch(setSEODetails({ ...whiteLabelUser?.data?.seo }))
    }
  }, [whiteLabelUser])

  useEffect(() => {
    if (data) {
      toast.success('Successfully logged in')
      navigate('/accounts')
    }
  }, [data])

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
      password: Yup.string().max(255).required('Password is required')
    }),
    onSubmit: async (values, helpers) => {
      try {
        const response = await login(values).unwrap()
        if (response?.authToken?.twofaEnabled) {
          navigate('/two-factor-authentication', { state: { responseData: response.authToken } })
        } else {
          setAuthToken(response?.authToken?.authToken)
          await getme()
        }
      } catch (err) {
        helpers.setErrors({ submit: err.data.error.message })
      }
    }
  })

  const formikForgotPassword = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Please enter a valid email address')
        .max(255)
        .required('This field is mandatory.')
    }),
    onSubmit: async (values, helpers) => {
      try {
        const { message } = await forgotPassword(values).unwrap()
        toast.success(message)
        setOpenForgotPasswordDialog(false)
        formikForgotPassword.resetForm()
      } catch (err) {
        helpers.setErrors({ submit: err.data.error.message })
      }
    }
  })

  const handleResendVerifyLinkClick = async () => {
    await resendVerifyLink({ email: formik.values.email }).unwrap()
    navigate('/register/verify-email')
  }

  const handleClickOpenResetPasswordDialog = () => {
    setOpenForgotPasswordDialog(true)
  }

  let reloadDomOnce
  if (typeof window !== 'undefined' && typeof window.localStorage !== 'undefined') {
    reloadDomOnce = window.localStorage.getItem('reloadDom')
  }

  return (
    <>
      <Box
        sx={{
          width: '100%',
          minHeight: '100vh',
          display: 'flex',
          justifyContent: 'flex-start',
          backgroundColor: '#F2F4F6'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            paddingBlock: '24px',
            paddingInline: '5%',
            backgroundColor: theme.palette.background.default
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '24px',
              alignItems: 'flex-start',
              justifyContent: 'center',
              maxWidth: '550px',
              width: '100%',
              marginInline: 'auto'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                gap: '16px',
                paddingTop: '16px',
                justifyContent: 'center'
              }}
            >
              <Box sx={{ display: 'flex', alignSelf: 'center' }}>
                {whiteLabelTheme?.logo
                  ? <img src={whiteLabelTheme?.logo} style={{ height: '36px', width: 'auto' }} />
                  : <img src={SuccessAiBlueLogo} style={{ height: '36px', width: 'auto' }} />}
              </Box>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                {isResendLinkVerifyLinkLoading
                  ? (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        height: 300
                      }}
                    >
                      <CircularProgress />
                      <Typography sx={{ mt: 3, fontSize: '20px', fontWeight: 500, color: whiteLabelTheme?.colors ? whiteLabelTheme?.colors?.main : '#216fed' }}>
                        Please wait...
                      </Typography>
                    </Box>
                    )
                  : (
                    <>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '100%'
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: '26px',
                            fontWeight: 600,
                            color: '#101010'
                          }}
                        >
                          Sign In
                        </Typography>
                      </Box>
                      <form noValidate onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
                        <Stack
                          sx={{ alignItems: 'center', justifyContent: 'center', width: '100%' }}
                        >
                          <Typography
                            sx={{
                              width: '100%',
                              textAlign: 'left',
                              fontSize: '14px',
                              fontWeight: 500,
                              lineHeight: '20px',
                              color: '#101010',
                              mt: 2
                            }}
                          >
                            Email
                          </Typography>
                          <TextField
                            error={!!(formik.touched.email && formik.errors.email)}
                            fullWidth
                            helperText={formik.touched.email && formik.errors.email}
                            name='email'
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            type='email'
                            value={formik.values.email}
                            placeholder='Enter email'
                            variant='outlined'
                            sx={{
                              mt: 1,
                              width: '100%',
                              backgroundColor: 'white',
                              '& div': { pl: 0.3 },
                              '& div fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
                              '& div input': {
                                py: 1.5,
                                fontSize: '13px',
                                fontWeight: 400,
                                letterSpacing: '0em',
                                '&::placeholder': {
                                  color: 'rgba(40, 40, 123, 0.5)'
                                }
                              }
                            }}
                          />
                          <Typography
                            sx={{
                              width: '100%',
                              textAlign: 'left',
                              fontSize: '14px',
                              fontWeight: 500,
                              lineHeight: '20px',
                              color: '#101010',
                              mt: 2
                            }}
                          >
                            Password
                          </Typography>
                          <TextField
                            error={!!(formik.touched.password && formik.errors.password)}
                            fullWidth
                            helperText={formik.touched.password && formik.errors.password}
                            name='password'
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            type={showPassword ? 'text' : 'password'}
                            value={formik.values.password}
                            placeholder='Enter password'
                            variant='outlined'
                            sx={{
                              mt: 1,
                              width: '100%',
                              backgroundColor: 'white',
                              '& div': { pl: 0.3 },
                              '& div fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
                              '& div input': {
                                py: 1.5,
                                fontSize: '13px',
                                fontWeight: 400,
                                letterSpacing: '0em',
                                '&::placeholder': {
                                  color: 'rgba(40, 40, 123, 0.5)'
                                }
                              },
                              '& .MuiFormHelperText-root': { textAlign: 'right', mx: 0 }
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position='end'>
                                  <IconButton
                                    onClick={() => setShowPassword(!showPassword)}
                                    edge='end'
                                    aria-label='toggle password visibility'
                                  >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                          />
                        </Stack>
                        {formik.errors.submit && (
                          <Typography
                            color='error'
                            sx={{ mt: 3, textAlign: 'right' }}
                            variant='body2'
                          >
                            {formik.errors.submit}
                          </Typography>
                        )}
                        {formik.errors.submit === 'Email not verified' && (
                          <Typography
                            sx={{
                              textAlign: 'center',
                              fontWeight: 600,
                              fontSize: 16,
                              cursor: 'pointer'
                            }}
                            onClick={handleResendVerifyLinkClick}
                          >
                            Need another verification link?
                          </Typography>
                        )}
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mt: 2
                          }}
                        >
                          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                            <Checkbox sx={{ padding: 0 }} />
                            <Typography
                              sx={{
                                color: '#101010',
                                cursor: 'pointer',
                                fontSize: '14px',
                                fontWeight: 500
                              }}
                              onClick={handleClickOpenResetPasswordDialog}
                            >
                              Remember me
                            </Typography>
                          </Box>
                          <Typography
                            sx={{
                              color: whiteLabelTheme?.colors ? whiteLabelTheme?.colors?.main : '#0071F6',
                              cursor: 'pointer',
                              mt: 1,
                              '&:hover': {
                                color: '#164694'
                              },
                              mr: 0.5,
                              fontSize: '13px',
                              fontWeight: 700,
                              lineHeight: '16px'
                            }}
                            onClick={handleClickOpenResetPasswordDialog}
                          >
                            <Link
                              href='/forget-password/?isWhiteLabel=true' sx={{
                                textDecoration: 'none',
                                color: whiteLabelTheme?.colors ? whiteLabelTheme?.colors?.main : '#0071F6',
                                '&:hover': {
                                  color: whiteLabelTheme?.colors ? whiteLabelTheme?.colors?.dark : '#164694'
                                }
                              }}
                            >
                              {' '}
                              Forgot password?
                            </Link>
                          </Typography>
                        </Box>
                        <Button
                          fullWidth
                          sx={{
                            mt: 2,
                            py: 2,
                            fontSize: '14px',
                            fontWeight: 700,
                            borderRadius: '14px',
                            bgcolor: whiteLabelTheme?.colors && whiteLabelTheme?.colors?.main,
                            '&:hover': {
                              bgcolor: whiteLabelTheme?.colors && whiteLabelTheme?.colors?.dark
                            }
                          }}
                          type='submit'
                          variant='contained'
                          disabled={!formik.isValid}
                        >
                          {formik.isSubmitting
                            ? (
                              <CircularProgress size={20} color='inherit' />
                              )
                            : (
                              <>
                                <Typography sx={{ fontWeight: 600, fontSize: '14px' }}>
                                  Sign in to {whiteLabelTheme?.companyName ? whiteLabelTheme?.companyName : 'Success.ai'}
                                </Typography>
                              </>
                              )}
                        </Button>

                      </form>
                    </>
                    )}
              </Box>
            </Box>
            <Box sx={{ display: 'flex', alignSelf: 'center' }}>
              <Typography
                sx={{
                  color: '#101010',
                  fontSize: '14px',
                  fontWeight: 500
                }}
              >
                Don't have an account?
                <Link
                  href='/white-label-register'
                  sx={{
                    textDecoration: 'none',
                    '&:hover': {
                      color: whiteLabelTheme?.colors ? whiteLabelTheme?.colors?.dark : '#164694'
                    },
                    color: whiteLabelTheme?.colors ? whiteLabelTheme?.colors?.main : '#0071F6',
                    ml: 0.3,
                    fontWeight: 700
                  }}
                >
                  Create an account
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

    </>
  )
}

export default WhiteLabelLoginPage
