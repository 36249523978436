import { Box, Typography, Tabs, Tab } from '@mui/material'
import PropTypes from 'prop-types'

import { useLocation } from 'react-router-dom'

import { useState, useEffect } from 'react'
import HeaderWithPopover from 'src/components/HeaderWithPopover'
import { useAuth } from 'src/auth/auth'
import { useGetMeAndWorkspaceQuery } from 'src/services/user-service'
import BilingPage from 'src/components/settings/BillingPage'
import UsagePage from 'src/components/settings/UsagePage'
import MyAccountPage from 'src/components/settings/MyAccountPage'
import WorkspacePage from 'src/components/settings/WorkspacePage'
import BlocklistPage from 'src/components/settings/BlocklistPage'
import { useTheme } from '@mui/material/styles'

function CustomTabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ width: '100%' }}
    >
      {value === index && (
        <Box sx={{}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

const SettingsUpdated = ({ tab }) => {
  const location = useLocation()
  const fromCreateWorkspace = location.state?.fromCreateWorkspace || false

  const { canEdit, canDelete } = useAuth()

  if (fromCreateWorkspace) {
    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <BilingPage fromCreateWorkspace={fromCreateWorkspace} canEdit={canEdit} canDelete={canDelete} />
      </Box>
    )
  }

  // Original implementation for the regular settings page
  const theme = useTheme()
  const { data, refetch } = useGetMeAndWorkspaceQuery()
  const [value, setValue] = useState(
    tab === 'billing' ? 0 : tab === 'account' ? 1 : tab === 'blocklist' ? 2 : 0
  )
  const [selectedTimeline, setSelectedTimeline] = useState(data?.workspace)

  const handleChange = (event, newValue) => {
    setValue(newValue)
    if (newValue === 0) {
      refetch()
    }
  }

  const handleWorkspaceChange = (timeline) => {
    setSelectedTimeline(timeline)
  }

  useEffect(() => {
    setValue(tab === 'billing' ? 0 : tab === 'account' ? 1 : tab === 'blocklist' ? 2 : 0)
  }, [tab])

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        flexDirection: 'column'
      }}
    >
      <Box
        sx={{
          width: '90%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center'
        }}
      >
        <HeaderWithPopover
          title='Settings'
          onWorkspaceChange={handleWorkspaceChange}
          selectedTimeline={selectedTimeline}
        />
        <Box
          sx={{
            boxShadow: '0px 12px 15px 0px #4B71970D',
            borderRadius: '12px',
            width: '100%',
            p: { xs: 1, sm: 2, md: 3 },
            mt: 3
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              background: '#E1E3EC',
              border: '1px solid #D7D9E6',
              borderRadius: '8px',
              width: { xs: '100%', sm: '100%', md: '870px' }
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              textColor='black'
              sx={{
                display: 'flex',
                justifyContent: 'center',
                background: '#E1E3EC',
                borderRadius: '8px',
                '& .MuiTab-root': {
                  textTransform: 'none',
                  fontWeight: 600,
                  color: '#6c757d',
                  borderRadius: '8px'
                },
                '& .Mui-selected': {
                  backgroundColor: '#ffffff',
                  color: theme.palette.primary.main,
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                },
                '& .MuiTabs-indicator': {
                  display: 'none'
                }
              }}
            >
              <Tab sx={{ my: '5px', mx: '10px', px: 6, py: '4px' }} label='Billing' />
              <Tab sx={{ my: '5px', mx: '10px', px: 6, py: '4px' }} label='Usage' />
              <Tab sx={{ my: '5px', mx: '10px', px: 6, py: '4px' }} label='My Account' />
              <Tab sx={{ my: '5px', mx: '10px', px: 6, py: '4px' }} label='Workspace' />
              <Tab sx={{ my: '5px', mx: '10px', px: 6, py: '4px' }} label='Blocklist' />
            </Tabs>
          </Box>
        </Box>
        <Box
          sx={{
            mb: 4,
            mt: 3,
            width: '100%'
          }}
        >
          <CustomTabPanel value={value} index={0}>
            <BilingPage
              fromCreateWorkspace={fromCreateWorkspace}
              canEdit={canEdit}
              canDelete={canDelete}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <UsagePage setValue={setValue} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <MyAccountPage />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            <WorkspacePage setSelectedTimeline={setSelectedTimeline}/>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={4}>
            <BlocklistPage campaign={[]} canEdit={canEdit} />
          </CustomTabPanel>
        </Box>
      </Box>
    </Box>
  )
}

SettingsUpdated.propTypes = {
  tab: PropTypes.string.isRequired
}

export default SettingsUpdated
