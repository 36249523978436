// VerificationLink.js
import { useState, useEffect } from 'react'
import { Tooltip, useTheme, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Box } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import { useResendVerifyLinkMutation } from 'src/services/auth-service'
import toast from 'react-hot-toast'
import { useGetMeQuery, useUpdateUserNameMutation } from 'src/services/user-service.js'

const useSharedVerificationLinkState = () => {
  const [isDisabled, setIsDisabled] = useState(false)

  useEffect(() => {
    const checkStatus = () => {
      if (typeof window !== 'undefined' && typeof window.localStorage !== 'undefined') {
        const lastClicked = window.localStorage.getItem('resendVerificationLinkLastClicked')
        if (lastClicked) {
          const timeElapsed = Date.now() - parseInt(lastClicked, 10)
          if (timeElapsed < 3600000) {
            setIsDisabled(true)
            const timeoutId = setTimeout(() => {
              setIsDisabled(false)
              window.localStorage.removeItem('resendVerificationLinkLastClicked')
              window.dispatchEvent(new Event('resendVerificationLinkUpdated'))
            }, 3600000 - timeElapsed)
            return () => clearTimeout(timeoutId)
          }
        }
      }
    }

    checkStatus()
    window.addEventListener('resendVerificationLinkUpdated', checkStatus)

    return () => {
      window.removeEventListener('resendVerificationLinkUpdated', checkStatus)
    }
  }, [])

  const disableLink = () => {
    if (typeof window !== 'undefined' && typeof window.localStorage !== 'undefined') {
      const now = Date.now()
      window.localStorage.setItem('resendVerificationLinkLastClicked', now)
      setIsDisabled(true)
      window.dispatchEvent(new Event('resendVerificationLinkUpdated'))
      setTimeout(() => {
        setIsDisabled(false)
        window.localStorage.removeItem('resendVerificationLinkLastClicked')
        window.dispatchEvent(new Event('resendVerificationLinkUpdated'))
      }, 3600000)
    }
  }

  return { isDisabled, disableLink }
}

const VerificationLink = ({ handleDrawerToggle, isMobile, props }) => {
  const theme = useTheme()
  const { isDisabled, disableLink } = useSharedVerificationLinkState()
  const [searchParams] = useSearchParams()
  const [resendVerifyLink] = useResendVerifyLinkMutation()
  const [updateUserName] = useUpdateUserNameMutation()
  const secondary = true
  const { data: user } = useGetMeQuery()
  const [open, setOpen] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [companyName, setCompanyName] = useState('')

  const handleClose = () => {
    setOpen(false)
  }
  
  const handleSubmit = () => {
    const isExceedingLength = (value) => value && value.length > 15

    if ([firstName, lastName, companyName].some(isExceedingLength)) {
      toast.error('First name, last name, and company name cannot exceed 15 characters.')
      return
    }

    const emptyFields = [
      !firstName?.trim() && 'First name',
      !lastName?.trim() && 'Last name',
      !companyName?.trim() && 'Company name'
    ].filter(Boolean)

    if (emptyFields.length > 0) {
      toast.error(`${emptyFields.join(', ')} cannot be empty.`)
      return
    }

    const userId = user?._id
    updateUserName({ firstName, lastName, companyName, userId })
      .then((response) => {
        toast.success('User updated successfully')
        handleClose()
      })
  }

  const sendVerificationLink = async () => {
    let email
    if (!secondary) {
      const token = searchParams.get('token')
      email = window.atob(token)
    } else {
      email = user.email
    }
    const { data } = await resendVerifyLink({ email })
    toast.success(data.message)
    disableLink()
    const timeout = setTimeout(() => {
      const firstName = user?.name?.first?.trim() || '' || null
      const lastName = user?.name?.last?.trim() || '' || null
      if (!firstName && !lastName) {
        setOpen(true)
      }
    }, 3000)
    return () => clearTimeout(timeout)
  }

  return (
    <>
      <Tooltip
        title={
          isDisabled
            ? 'Resend Verification link has already been sent. Please wait 60 minutes before requesting another.'
            : ''
        }
        arrow
      >
        <span
          onClick={!isDisabled ? sendVerificationLink : null}
          style={{
            fontWeight: 700,
            fontSize: '13px',
            cursor: isDisabled ? 'not-allowed' : 'pointer',
            color: `${theme.palette.primary.main}`,
            paddingLeft: '4px',
            whiteSpace: 'nowrap'
          }}
        >
          Resend verification link
        </span>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Welcome to Success.ai</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <TextField
              autoFocus
              margin='dense'
              id='firstName'
              label='First Name'
              type='text'
              fullWidth
              variant='outlined'
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <TextField
              margin='dense'
              id='lastName'
              label='Last Name'
              type='text'
              fullWidth
              variant='outlined'
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <TextField
              margin='dense'
              id='companyName'
              label='Company Name'
              type='text'
              fullWidth
              variant='outlined'
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
          <Button onClick={handleSubmit} color='primary' variant='contained'>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default VerificationLink
