import React from 'react'
import { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import CodeIcon from '@mui/icons-material/Code'
import DOMPurify from 'dompurify'
import toast from 'react-hot-toast'
import ImageResize from 'quill-image-resize'

class CustomImageResize extends ImageResize {
  constructor (quill, options) {
    super(quill, options)

    // Add event listeners for mouse events
    this.quill.root.addEventListener('mousedown', this.handleMouseDown)
    this.quill.root.addEventListener('mousemove', this.handleMouseMove)
    this.quill.root.addEventListener('mouseup', this.handleMouseUp)
  }

  destroy () {
    this.quill.root.removeEventListener('mousedown', this.handleMouseDown)
    this.quill.root.removeEventListener('mousemove', this.handleMouseMove)
    this.quill.root.removeEventListener('mouseup', this.handleMouseUp)
    super.destroy()
  }
}

// Register the extended module
Quill.register('modules/imageResize', CustomImageResize)

function undoChange () {
  this.quill.history.undo()
}
function redoChange () {
  this.quill.history.redo()
}

// Add sizes and fonts to the whitelist and register them
const Size = Quill.import('formats/size')
Size.whitelist = ['small', 'extra-small', 'medium', 'large']
Quill.register(Size, true)

const Font = Quill.import('formats/font')
Font.whitelist = ['arial', 'comic-sans', 'courier-new', 'georgia', 'helvetica', 'lucida']
Quill.register(Font, true)

const FILE_SIZE_LIMIT = 4.5 * 1024 * 1024

const handleImageUpload = function () {
  const input = document.createElement('input')
  input.setAttribute('type', 'file')
  input.setAttribute('accept', 'image/*')
  input.click()

  input.onchange = () => {
    const file = input.files[0]
    if (file && file.size > FILE_SIZE_LIMIT) {
      toast.error('File size exceeds 4.5 MB. Please choose a smaller file.')
      return
    }
    if (file) {
      if (typeof window !== 'undefined' && typeof FileReader !== 'undefined') {
        const reader = new window.FileReader()
        reader.onload = (event) => {
          const img = new window.Image()
          img.src = event.target.result

          img.onload = () => {
            // Resize image if its dimensions exceed the max constraints
            const canvas = document.createElement('canvas')
            const ctx = canvas.getContext('2d')
            const MAX_WIDTH = 400
            const MAX_HEIGHT = 600

            let width = img.width
            let height = img.height

            // Check if either dimension exceeds the max constraints
            if (width > MAX_WIDTH || height > MAX_HEIGHT) {
              if (width > height) {
                height = (height * MAX_WIDTH) / width
                width = MAX_WIDTH
              } else {
                width = (width * MAX_HEIGHT) / height
                height = MAX_HEIGHT
              }
            }

            canvas.width = width
            canvas.height = height
            ctx.drawImage(img, 0, 0, width, height)

            // Convert the canvas back to a base64 image
            const resizedImage = canvas.toDataURL('image/png')

            const range = this.quill.getSelection()
            this.quill.insertEmbed(range.index, 'image', resizedImage)
          }
        }
        reader.readAsDataURL(file)
      }
    }
  }
}

const sanitizeHtml = (html) => {
  return DOMPurify.sanitize(html, {
    ALLOWED_TAGS: ['p', 'b', 'i', 'strong', 'em', 'u', 'a', 'ul', 'ol', 'li', 'br', 'span', 'style'],
    ALLOWED_ATTR: ['href', 'target', 'rel', 'class', 'id', 'style', 'width', 'height']
  })
}

export const modules = {
  toolbar: {
    container: '#toolbar',
    handlers: {
      undo: undoChange,
      redo: redoChange,
      customButton: function () {
        if (typeof window !== 'undefined' && typeof alert !== 'undefined') {
          window.alert('Custom button clicked!')
        }
      },
      showHtml: function () {
        const txtArea = document.querySelector('.ql-html-editor')
        if (!txtArea) {
          console.error('Error: .ql-html-editor element not found.')
          return
        }
        const quillEditor = this.quill.root.innerHTML
        txtArea.value = quillEditor
        txtArea.style.display = txtArea.style.display === 'none' ? '' : 'none'
        txtArea.addEventListener('input', () => {
          const sanitizedHtml = sanitizeHtml(txtArea.value)
          const delta = this.quill.clipboard.convert(sanitizedHtml)
          this.quill.setContents(delta)
        })
      },
      image: handleImageUpload
    }
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true
  },
  clipboard: {
    matchVisual: false
  },
  imageResize: {} // Use the custom image resizing module
}

// Formats objects for setting up the Quill editor
export const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'align',
  'strike',
  'script',
  'blockquote',
  'background',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'color',
  'code-block'
]

// Quill Toolbar component
const QuillToolbar = () => (
  <>
    <div id='toolbar'>
      <span className='ql-formats'>
        <button className='ql-bold' />
        <button className='ql-italic' />
        <button className='ql-underline' />
        <button className='ql-strike' />
      </span>
      <span className='ql-formats'>
        <select className='ql-align' />
        <select className='ql-color' />
        <select className='ql-background' />
      </span>
      <span className='ql-formats'>
        <button className='ql-list' value='ordered' />
        <button className='ql-list' value='bullet' />
      </span>
      <span className='ql-formats'>
        <select className='ql-size custom-dropdown' defaultValue='medium' style={{ width: '40px' }}>
          <option value='small'>1</option>
          <option value='extra-small'>2</option>
          <option value='medium'>3</option>
          <option value='large'>4</option>
        </select>
      </span>
      <span className='ql-formats'>
        <button className='ql-script' value='super' />
        <button className='ql-script' value='sub' />
        <button className='ql-blockquote' />
      </span>
      <span className='ql-formats'>
        <button className='ql-showHtml'>
          <CodeIcon />
        </button>
      </span>
      <span className='ql-formats'>
        <button className='ql-link' />
        <button className='ql-image' />
      </span>
    </div>
  </>
)

export default QuillToolbar
