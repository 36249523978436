import React, { useState } from 'react'
import { Box, Button, Popover, TextField, useTheme } from '@mui/material'
import { Search, Add } from '@mui/icons-material'
import { StarNew } from 'src/icons/starnew'

const PopoverMenu = ({ anchorEl, handleClose, timelineButtons, setTimeline, navigateToCreateWorkspace, timeline }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const theme = useTheme()
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const filteredWorkspaces = timelineButtons.filter(item =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  )

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      sx={{ mt: 0.5 }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          p: 1.1,
          width: '250px'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            backgroundColor: '#fff',
            mb: 1,
            px: 1
          }}
        >
          <Search sx={{ color: '#9E9E9E' }} />
          <TextField
            placeholder='Search'
            variant='standard'
            size='large'
            fullWidth
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              disableUnderline: true,
              sx: { fontSize: '14px' }
            }}
            sx={{
              '& fieldset': { border: 'none' },
              ml: 1
            }}
          />
        </Box>
        {filteredWorkspaces.map((item, i) => (
          <Button
            key={i}
            fullWidth
            sx={{
              py: 1.1,
              px: 2,
              borderRadius: '5px',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              color: `${theme.palette.primary.primaryText}`,
              fontSize: '14px',
              backgroundColor: item?._id === timeline?._id ? '#edf4fe' : 'transparent',
              '&:hover': {
                backgroundColor: item?._id === timeline?._id ? '#f2f8ff' : '#f5f5f5'
              }
            }}
            onClick={() => {
              setTimeline(item)
              handleClose()
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              {item?.name}
            </Box>
          </Button>
        ))}
        <Button
          fullWidth
          sx={{
            py: 1.1,
            px: 2,
            borderRadius: '5px',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            color: '#101828',
            fontSize: '14px',
            backgroundColor: theme.palette.primary.light,
            mt: 1
          }}
          onClick={navigateToCreateWorkspace}
        >
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            <Add sx={{ color: `${theme.palette.primary.main}` }} />
            Create Workspace
          </Box>
          <StarNew sx={{ ml: '1rem' }} />
        </Button>
      </Box>
    </Popover>
  )
}

export default PopoverMenu
