import { Box, CircularProgress, Typography } from '@mui/material'
import { useCallback, useEffect } from 'react'
// import { useCreateMicrosoftAccountMutation } from "src/services/account-service.js";
// import toast from "react-hot-toast";

import { useConnectMicrosoftAccountMutation } from 'src/services/account-service.js'
import toast from 'react-hot-toast'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

const MicrosoftRedirect = () => {
  const [searchParams] = useSearchParams()
  const [connectMicrosoftAccount] = useConnectMicrosoftAccountMutation()
  const navigate = useNavigate()
  // const [workspaceId, setWorkspaceId] = useState();
  const workspace = useSelector((state) => state.workspace)
  useEffect(() => {
    if (typeof window !== 'undefined' && typeof window.localStorage !== 'undefined') {
      const storedWorkspace = JSON.parse(window.localStorage.getItem('workspace'))
      if (storedWorkspace && storedWorkspace._id) {
        // setWorkspaceId(storedWorkspace._id);
      }
    }
  }, [workspace])

  const createAccount = useCallback(async () => {
    const code = searchParams.get('code')
    const encodedState = searchParams.get('state')
    const decodedState = decodeURIComponent(encodedState)
    const state = JSON.parse(decodedState)

    const workspaceId = state?.workspaceId
    const iframe = state?.iframe
    let reconnect
    if (typeof window !== 'undefined' && typeof window.localStorage !== 'undefined') {
      reconnect = window.localStorage.getItem('reconnect')
    }
    // const message = { provider: 'microsoft_oauth', code }
    try {
      if (workspaceId) {
        const { message: successMessage } = await connectMicrosoftAccount({ code, reconnect, workspaceId, iframe }).unwrap()
        toast.success(successMessage, { duration: 2000 })

        if (reconnect) {
          if (typeof window !== 'undefined' && typeof window.localStorage !== 'undefined') {
            window.localStorage.removeItem('reconnect')
          }
          navigate('/accountMessage/reconnected')
        } else {
          navigate('/accountMessage/created')
        }
      }
    } catch (error) {
      toast.error(error.data.error.message, { duration: 2000 })
      navigate(`/accountMessage/${error.data.error.message}`)
    }
  }, [searchParams])

  useEffect(() => {
    createAccount()
  }, [createAccount])

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          mt: 10
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >
          <CircularProgress color='primary' />
          <Typography
            sx={{
              mt: 3,
              fontWeight: 600,
              fontSize: '18px',
              color: 'rgba(0,0,0,0.5)',
              textAlign: 'center'
            }}
          >
            Adding your account....
          </Typography>
        </Box>
      </Box>
    </>
  )
}

export default MicrosoftRedirect
