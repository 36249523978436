import { DialogTitle, DialogContent, Button, DialogActions, DialogContentText, Dialog, CircularProgress } from '@mui/material'

import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { useGetCustomAttributesQuery, useUpdateOnboardingAttributesMutation } from 'src/services/intercom-service'

const StepCompletitionConfirmation = ({ open, setOpen, attribute, attributeValue }) => {
  const [updateOnboardingAttributes] = useUpdateOnboardingAttributesMutation()
  const { refetch } = useGetCustomAttributesQuery()

  const [waiting, setWaiting] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const handleClickYes = async () => {
    try {
      setWaiting(true)
      await updateOnboardingAttributes({ attribute, attributeValue }).unwrap()
      refetch()
      setWaiting(false)
      setOpen(false)
      toast.success('Updated')
    } catch (e) {
      setWaiting(false)
      setOpen(false)
      toast.error(e?.data?.error?.message ?? 'Something went wrong')
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Onboarding Step Completion
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to mark this step {attributeValue ? 'completed' : 'incomplete'}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {waiting
            ? (
              <CircularProgress size='30px' />
              // <Typography sx={{ display: 'flex', justifyContent: 'center' }}>Please Wait</Typography>
              )
            : (
              <>
                <Button sx={{ color: 'red', opacity: '0.7' }} onClick={() => { handleClose() }}>No</Button>
                <Button onClick={() => { handleClickYes() }} autoFocus>
                  Yes
                </Button>
              </>
              )}
        </DialogActions>
      </Dialog>
    </>
  )
}

export default StepCompletitionConfirmation
