import React, { useEffect, useState } from 'react'
import { Container, Box, CircularProgress, Typography } from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { config } from 'src/config.js'
import axios from 'axios'
import toast from 'react-hot-toast'

const HubspotIntegrationPage = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const code = searchParams.get('code')
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')

  const saveIntegration = async () => {
    setLoading(true)
    setMessage('')

    try {
      // Send data to backend
      const response = await axios.post(`${config.API_BASE_URL}/integrations/hubspot-authorize-user`, { code })

      // Show success message if integration is successful
      if (response.status === 200) {
        setMessage('HubSpot integration successful! Saving details... You Will Redirected To Home Page Soon')
        toast.success('HubSpot integration successful Done !')
        navigate('/accounts')
      } else {
        setMessage('HubSpot integration failed. Please try again.')
      }
    } catch (error) {
      setMessage('An error occurred. Please try again later.')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    saveIntegration()
  }, [])

  return (
    <Container maxWidth='sm'>
      <Box sx={{ mt: 5, display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography variant='h4' gutterBottom>
          HubSpot Integration
        </Typography>

        {loading && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <CircularProgress />
            <Typography>Processing HubSpot integration...</Typography>
          </Box>
        )}

        {message && (
          <Typography sx={{ mt: 2 }} color='primary'>
            {message}
          </Typography>
        )}
      </Box>
    </Container>
  )
}

export default HubspotIntegrationPage
