import React, { useEffect, useState } from 'react'
import { Accordion, AccordionSummary, AccordionDetails, Typography, List, ListItemIcon, ListItemText, ListItemButton, alpha, useTheme } from '@mui/material'
import { ExpandMoreOutlined, Inbox, Markunread, Send } from '@mui/icons-material'

const MoreAccordion = ({
  setShowAll,
  setCurrentCampaign,
  setCurrentAccount,
  setCurrentStatus,
  setInboxTabsOpen,
  unread,
  setUnread,
  sent,
  setSent
}) => {
  const theme = useTheme()
  const [currentItem, setCurrentItem] = useState(null)

  useEffect(() => {
    if (
      (currentItem === 'unread' && !unread) ||
      (currentItem === 'sent' && !sent)
    ) {
      setCurrentItem(null)
    }
  }, [unread, sent])

  const handleListItemClick = (id) => {
    const resetState = () => {
      setShowAll(false)
      setSent(false)
      setUnread(false)
      setCurrentCampaign(null)
      setCurrentAccount(null)
      setCurrentStatus(null)
      setInboxTabsOpen(false)
    }

    setCurrentItem(id)
    resetState()

    switch (id) {
      case 'inbox':
        setShowAll(true)
        break
      case 'unread':
        setUnread(true)
        break
      case 'sent':
        setSent(true)
        break
      default:
        break
    }
  }

  const listItems = [
    { id: 'inbox', icon: <Inbox fontSize='small' />, text: 'Inbox' },
    { id: 'unread', icon: <Markunread fontSize='small' />, text: 'Unread Only' },
    { id: 'sent', icon: <Send fontSize='small' />, text: 'Sent' }
  ]

  return (
    <Accordion sx={{ boxShadow: 'none', border: 'none' }}>
      <AccordionSummary
        expandIcon={<ExpandMoreOutlined />}
        aria-controls='panel1bh-content'
        id='panel1bh-header'
      >
        <Typography sx={{ fontWeight: 600 }}>
          More
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <List>
          {listItems.map((item) => (
            <ListItemButton
              key={item.id}
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                px: 1.5,
                py: 0.5,
                mb: 1,
                width: '100%',
                borderRadius: '8px',
                fontSize: '14px',
                fontWeight: 700,
                lineHeight: '18px',
                color: currentItem === item.id ? alpha(theme.palette.primary.main, 0.8) : `${theme.palette.primary.primaryText}`,
                '&:hover': {
                  backgroundColor: currentItem === item.id ? '#fff' : '#F2F4F6',
                  color: alpha(theme.palette.primary.main, 0.8)
                },
                border: currentItem === item.id ? `1px solid ${alpha(theme.palette.primary.main, 0.8)}` : '1px solid #fff'
              }}
              onClick={() => handleListItemClick(item.id)}
            >
              <ListItemIcon
                sx={{
                  minWidth: '30px',
                  color: currentItem === item.id ? alpha(theme.palette.primary.main, 0.8) : `${theme.palette.primary.primaryText}`
                }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.text}
                primaryTypographyProps={{
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '18px'
                }}
              />
            </ListItemButton>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  )
}

export default MoreAccordion
