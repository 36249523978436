import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  useTheme,
  Stack,
  useMediaQuery,
  Popover,
  Tabs,
  Tab,
  Snackbar,
} from "@mui/material";
import { Delete, Download } from "@mui/icons-material";

import { SBSearch } from "src/assets/sidebar/SBSearch";
import { OffCheckboxCustomIcon } from "src/assets/general/OffCheckboxCustomIcon";
import { AllCheckboxCustomIcon } from "src/assets/general/AllCheckboxCustomIcon";
import { OnCheckboxCustomIcon } from "src/assets/general/OnCheckboxCustomIcon";

import { EACloseIcon } from "src/assets/emailAccounts/EACloseIcon";
import { BulkUploadIcon } from "src/assets/campaignDetailsLeads/BulkUploadIcon";

import { ManualEmailIcon } from "src/assets/campaignDetailsLeads/ManualEmailIcon";
import { Google } from "src/assets/Google";
import { ArrowLeftIconBlue } from "src/assets/emailAccounts/connect/ArrowLeftIconBlue";
import { ArrowRight } from "src/assets/general/ArrowRight";

import GoogleSheetImport from "./GoogleSheetImport";
import ManualImport from "./ManualImport";
import CsvImport from "./CsvImport";

import { toast } from "react-hot-toast";
import _ from "lodash";
import { downloadCsv } from "src/utils/util.js";
import {
  useGetBlocklistMutation,
  useDeleteBlocklistMutation,
} from "src/services/account-service.js";
import Pagination from "../Pagination";
import { useSelector } from "react-redux";
import { useGetMeAndWorkspaceQuery } from "src/services/user-service";


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ width: "100%" }}
    >
      {value === index && (
        <Box sx={{}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const BlockList = ({ campaign, canEdit }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [leads, setLeads] = useState([]);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState(0);
  const [isLoadingBlocklist, setIsLoadingBlocklist] = useState(false);
  const [blockListCreate, setBlockListCreate] = useState(false);
  const [listType, setListType] = useState("email");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [selectedTab, setSelectedTab] = useState(listType === 'email' ? 0 : 1);
  const [getBlocklist] = useGetBlocklistMutation();
  const [deleteBlocklist] = useDeleteBlocklistMutation();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [leadCreated, setLeadCreated] = useState(false);
  const offset = leads?.length;

  const [selectType, setSelectType] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [workspaceId, setWorkspaceId] = useState();
  const {data: data} = useGetMeAndWorkspaceQuery();
  const workspace = useSelector((state) => state.workspace);
  useEffect(() => {
    setWorkspaceId(workspace?._id ?? data?.workspace?._id)
  },[workspace])

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  useEffect(() => {
    setBlockListCreate(false);

    const fetchBlocklist = async (offset) => {
      setIsLoadingBlocklist(true);
      const { docs, total } = await getBlocklist({
        params: _.pickBy({ search, offset, limit, listType, workspaceId }),
      }).unwrap();
      setTotal(total);
      setLeads(docs);
      setIsLoadingBlocklist(false);
    };

    const timer = setTimeout(() => {
      if (page === 1 && workspaceId) {
        fetchBlocklist(0);
      } else if (offset < total && page > 1 && workspaceId) {
        fetchBlocklist(limit * (page - 1));
      }
    }, 500);

    return () => clearTimeout(timer);

  }, [search, blockListCreate, getBlocklist, page, limit, leadCreated, listType, workspaceId]);

  useEffect(() => {
    if (leadCreated && workspaceId) {
      const timer = setTimeout(async () => {
        const { docs } = await getBlocklist({
          id: campaign._id,
          params: _.pickBy({ search, offset: 0, limit, workspaceId }),
        }).unwrap();
        setLeads(docs);
      }, 500);
      setPage(1);
      setLeadCreated(false);
      return () => clearTimeout(timer);
    }
  }, [leadCreated]);


  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(1);
  };

  const handleSelectLeadChange = (lead, checked) => {
    let updatedSelectedLeads = [...selectedLeads];
    if (checked) {
      updatedSelectedLeads.push(lead);
    } else {
      if (checkedAll) {
        setCheckedAll(false);
        setSelectType(null);
      }
      updatedSelectedLeads = selectedLeads.filter((e) => e._id !== lead._id);
    }
    setSelectedLeads(updatedSelectedLeads);
  };

  const handleSelectAllLeadsChange = async (checked, type) => {
    if (checked) {
      setSelectType(type);
      setCheckedAll(true);
      if (type === "all" && workspaceId) {
        const { docs } = await getBlocklist({
          params: {
            search,
            offset: 0,
            limit: total,
            listType,
            workspaceId
          },
        }).unwrap();
        setSelectedLeads(docs);
      } else {
        setSelectedLeads(leads);
      }
    } else {
      setSelectedLeads([]);
      setCheckedAll(false);
      setSelectType(null);
    }
  };

  const handleDeleteClick = async () => {
    try {
      const data = selectedLeads.map((lead) => {
        return lead._id;
      });
      const { message } = await deleteBlocklist({ lists: data, workspaceId }).unwrap();
      // setSelectedLeads([]);
      toast.success(message);
      //
      if (selectType === "all") {
        setLeads([]);
        setSelectedLeads([]);
        setCheckedAll(false);
        setSelectType(null);
        setTotal(0);
      } else {
        let offset = 0;
        let pageNum = page;
        const pageCount = Math.ceil((total - selectedLeads.length) / limit);
        if (selectType === "page") {
          offset = limit * (page - 1);
          pageNum = page > 1 ? (page > pageCount ? page - 1 : page) : 1;
        } else {
          offset = limit * (page - 1);
          pageNum =
            selectedLeads.length === leads?.length
              ? page > 1
                ? page > pageCount
                  ? page - 1
                  : page
                : 1
              : page;
        }
        if (page === pageNum && workspaceId) {
          const { docs, total } = await getBlocklist({
            params: _.pickBy({ search, offset, limit, listType, workspaceId }),
          }).unwrap();

          setLeads(docs);
          setSelectedLeads([]);
          setCheckedAll(false);
          setSelectType(null);
          setTotal(total);
        } else {
          setPage(pageNum);
          setSelectedLeads([]);
          setCheckedAll(false);
          setSelectType(null);
        }
      }
    } catch (err) {
      toast.error(err.data.error.message);
    }
  };

  const handleDownloadLeadsClick = () => {
    if (listType === 'email') {
      const data = selectedLeads.map((lead) => {
        return { email: lead.email };
      });
      downloadCsv("Blocklist", data);
    } else {
      const data = selectedLeads.map((lead) => {
        return { domain: lead.domain };
      });
      downloadCsv("Blocklist", data);
    } 
  };

  const [isImportLeadsDialogOpen, setIsImportLeadsDialogOpen] = useState(false);

  const handleClickOpenImportLeadsDialog = () => {
    setIsImportLeadsDialogOpen(true);
  };

  const handleCloseOpenImportLeadsDialog = () => {
    setIsImportLeadsDialogOpen(false);
    setActiveStep(0);
  };

  const onLeadsCreate = (createdLeads) => {
    if (createdLeads) setLeadCreated(true);
    setActiveStep(0);
    setIsImportLeadsDialogOpen(false);
  };

  const [activeStep, setActiveStep] = useState(0);

  const [value, setValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setListType(newValue === 0 ? 'email' : 'domain');
  };

  return (
    <>
      <Grid container width={"100%"}>
        <Grid
          item
          xs={12}
          sm={3}
          display={"flex"}
          flexDirection={{ xs: "row", sm: "column" }}
          pt={"30px"}
        >
          <Button
            sx={{
              justifyContent: "flex-start",
              color: value === 0 ? theme.palette.primary.main : "#000000",
              fontWeight: value === 0 ? "700" : "400",
            }}
            onClick={() => setValue(0)}
          >
            Default Blocklist
          </Button>
        </Grid>
        <Grid item xs={12} sm={9} pt={"30px"}>
          <CustomTabPanel value={value} index={0}>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: { xs: "center", sm: "space-between" },
                alignItems: { xs: "flex-start", sm: "center" },

                pr: 0.5,
                pl: 0.5,
                rowGap: 1,
              }}
            >
              <Box sx={{ display: "flex", width: { xs: "100%", sm: "fit-content" } }}>
                <Box
                  aria-owns={open ? "mouse-over-popover" : undefined}
                  aria-haspopup="true"
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}
                >
                  <Tooltip
                    title={selectedLeads.length ? "Uncheck All" : "Select"}
                    arrow
                    placement="top"
                  >
                    <Checkbox
                      inputProps={{ "aria-label": "controlled" }}
                      size="small"
                      icon={<OffCheckboxCustomIcon />}
                      checkedIcon={<AllCheckboxCustomIcon />}
                      checked={checkedAll}
                      onChange={(event, checked) => handleSelectAllLeadsChange(checked, "page")}
                    />
                  </Tooltip>
                </Box>
                <Popover
                  id="mouse-over-popover"
                  sx={{
                    pointerEvents: "none",
                    "& .MuiPopover-paper": {
                      pointerEvents: "auto",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "center",
                      p: 1,
                    },
                  }}
                  open={open}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  PaperProps={{
                    onMouseEnter: () => setAnchorEl(anchorEl),
                    onMouseLeave: handlePopoverClose,
                  }}
                  onClose={handlePopoverClose}
                  disableRestoreFocus
                >
                  <Button
                    fullWidth
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Checkbox
                      inputProps={{ "aria-label": "controlled" }}
                      size="small"
                      checked={selectType === "page"}
                      onChange={(event, checked) => handleSelectAllLeadsChange(checked, "page")}
                    />
                    <Typography>Select page</Typography>
                  </Button>
                  <Button
                    fullWidth
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Checkbox
                      inputProps={{ "aria-label": "controlled" }}
                      size="small"
                      checked={selectType === "all"}
                      onChange={(event, checked) => handleSelectAllLeadsChange(checked, "all")}
                    />
                    <Typography>Select All</Typography>
                  </Button>
                </Popover>

                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton sx={{ width: 32, height: 32 }}>
                          <SBSearch color="rgba(40, 40, 123, 0.5)" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  sx={{
                    width: { xs: "100%", sm: 300 },
                    height: 40,
                    backgroundColor: "white",
                    "& div": { pl: 0.3 },
                    "& div fieldset": { borderRadius: "8px", border: "1px solid #E4E4E5" },
                    "& div input": {
                      py: 1.3,
                      fontSize: "13px",
                      fontWeight: 400,
                      lineHeight: "16px",
                      letterSpacing: "0em",
                      "&::placeholder": {
                        color: "rgba(40, 40, 123, 0.5)",
                      },
                    },
                  }}
                  placeholder="Search by email"
                  value={search}
                  onChange={(event) => {
                    setSearch(event.target.value);
                    setPage(1);
                  }}
                />
              </Box>
              <Box
                display={"flex"}
                gap={2}
                sx={{
                  width: { xs: "100%", sm: "fit-content" },
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                {selectedLeads.length > 0 && (
                  <Tooltip
                    title={"Erase the Selected Leads"}
                    arrow
                    placement="top"
                  >
                     <IconButton onClick={(canEdit) ? handleDeleteClick : null} disabled={!canEdit}>
                        <Delete sx={{ color: "red" }} fontSize="small" />
                      </IconButton>
                  </Tooltip>
                )}
                {selectedLeads.length > 0 && (
                  <Tooltip title="Download block list" arrow placement="top">
                    <IconButton onClick={(canEdit) ? handleDownloadLeadsClick : null} disabled={!canEdit}>
                      <Download sx={{ color: "rgba(0,0,0,0.6)" }} fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
                <Button
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    "&:hover": {
                      backgroundColor: theme.palette.primary.dark,
                      boxShadow: 10,
                    },
                    textAlign: "left",
                    fontSize: "14px",
                    fontWeight: 700,
                    lineHeight: "18px",
                    letterSpacing: "0em",
                    color: "white",
                    backgroundColor: `${theme.palette.primary.main}`,
                    borderRadius: "8px",
                    px: 1.5,
                  }}
                  disabled={!canEdit}
                  variant="outlined"
                  size="large"
                  onClick={handleClickOpenImportLeadsDialog}
                >
                  Import
                </Button>
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
              }}
            >
              {" "}
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                aria-label="basic tabs example"
                // variant="fullWidth"
                sx={{ borderRadius: "4px" }}
              >
                <Tab
                  label="Emails"
                  sx={{
                    fontSize: "14px",
                    fontWeight: 700,
                    lineHeight: "20px",
                  }}
                />
                <Tab
                  label="Domains"
                  sx={{
                    fontSize: "14px",
                    fontWeight: 700,
                    lineHeight: "20px",
                  }}
                />
              </Tabs>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                flexDirection: "column",

                py: 2,
                px: 0.5,
              }}
            >
              {isLoadingBlocklist ? (
                <CircularProgress />
              ) : leads?.length !== 0 ? (
                <Box sx={{ width: "100%" }}>
                  {leads?.map((lead) => (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        width: "100%",
                        borderTop: `1px solid ${theme.palette.grey[300]}`,
                        py: 1,
                      }}
                    >
                      <Checkbox
                        size="small"
                        icon={<OffCheckboxCustomIcon />}
                        checkedIcon={<OnCheckboxCustomIcon />}
                        checked={selectedLeads.some((selected) => selected._id === lead._id)}
                        onClick={(event) => event.stopPropagation()}
                        onChange={(event, checked) => handleSelectLeadChange(lead, checked)}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "250px",
                          justifyContent: "center",
                          alignItems: "flex-start",
                          ml: 1,
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: 600,
                            color: "#000000",
                            lineHeight: "16.38px",
                          }}
                        >
                          {lead.email ? lead.email : lead.domain}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                  <Box sx={{ mt: 2 }}>
                    <Pagination
                      page={page}
                      setPage={setPage}
                      total={total}
                      length={leads?.length}
                      limit={limit}
                      handleLimitChange={handleLimitChange}
                    />
                  </Box>
                </Box>
              ) : (
                "No Results"
              )}
            </Box>
            <Dialog
              open={isImportLeadsDialogOpen}
              onClose={handleCloseOpenImportLeadsDialog}
              fullWidth
              maxWidth="md"
              sx={{ backgroundColor: "rgba(4, 4, 30, 0.5)" }}
              fullScreen={isMobile}
            >
              <DialogTitle>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      flexDirection: "column",
                    }}
                  >
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <Typography
                        sx={{
                          fontSize: "20px",
                          fontWeight: 700,
                          lineHeight: "28px",
                          color: `${theme.palette.primary.primaryText}`,
                        }}
                      >
                        {listType === "email" ? "Import Leads" : "Import Domains"}
                      </Typography>
                      {activeStep !== 0 && (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              mr: 0.5,
                            }}
                          >
                            <ArrowRight />
                          </Box>
                        </>
                      )}
                      {activeStep === 1 ? (
                        <>
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontWeight: 700,
                              lineHeight: "28px",
                              color: "#8181B0",
                            }}
                          >
                            Import CSV File
                          </Typography>
                        </>
                      ) : activeStep === 2 ? (
                        <>
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontWeight: 700,
                              lineHeight: "28px",
                              color: "#8181B0",
                            }}
                          >
                            {listType === 'email' ? 'Input Emails Manually' : 'Input Domains Manually'}
                          </Typography>
                        </>
                      ) : activeStep === 3 ? (
                        <>
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontWeight: 700,
                              lineHeight: "28px",
                              color: "#8181B0",
                            }}
                          >
                            Utilize Google Sheets
                          </Typography>
                        </>
                      ) : null}
                    </Box>

                    {activeStep === 0 ? (
                      <>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            fontWeight: 400,
                            lineHeight: "20px",
                            color: "#8181B0",
                            mt: 1,
                          }}
                        >
                          {listType === 'email' ? 'Choose one of the methods listed below to effortlessly import emails.' : 'Choose one of the methods listed below to effortlessly import domains.'}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            fontWeight: 400,
                            lineHeight: "20px",
                            color: "#8181B0",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mt: 1,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setActiveStep(0);
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              mr: 1,
                            }}
                          >
                            <ArrowLeftIconBlue color="#8181B0" />
                          </Box>
                          Select a Different Method
                        </Typography>
                      </>
                    )}
                  </Box>
                  <IconButton
                    sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                    onClick={handleCloseOpenImportLeadsDialog}
                  >
                    <EACloseIcon />
                  </IconButton>
                </Box>
              </DialogTitle>
              <DialogContent>
                {activeStep === 0 ? (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "column", sm: "row" },
                        justifyContent: "space-between",
                        alignItems: "center",
                        rowGap: 2,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "flex-start",
                          cursor: "pointer",
                          width: "100%",
                          p: 3,
                          border: "1px solid #00AA38",
                          flexDirection: "column",
                          height: "100%",
                          backgroundColor: "#fff",
                          borderRadius: "12px",
                          boxShadow: "0px 12px 15px 0px #4B71970D",
                        }}
                        onClick={() => {
                          setActiveStep(1);
                        }}
                      >
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                          <BulkUploadIcon />
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: 700,
                              lineHeight: "26px",
                              color: `${theme.palette.primary.primaryText}`,
                              mt: 1.5,
                            }}
                          >
                            Upload CSV
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "flex-start",
                          cursor: "pointer",
                          width: "100%",
                          p: 3,
                          border: "1px solid #CECECE",
                          flexDirection: "column",
                          height: "100%",
                          backgroundColor: "#fff",
                          borderRadius: "12px",
                          boxShadow: "0px 12px 15px 0px #4B71970D",
                          mx: 2,
                        }}
                        onClick={() => {
                          setActiveStep(2);
                        }}
                      >
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                          <ManualEmailIcon />
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: 700,
                              lineHeight: "26px",
                              color: `${theme.palette.primary.primaryText}`,
                              mt: 1.5,
                            }}
                          >
                            {listType === 'email' ? 'Enter Emails Manually' : 'Enter Domains Manually'}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "flex-start",
                          cursor: "pointer",
                          width: "100%",
                          p: 3,
                          border: `1px solid ${theme.palette.primary.main}`,
                          flexDirection: "column",
                          height: "100%",
                          backgroundColor: "#fff",
                          borderRadius: "12px",
                          boxShadow: "0px 12px 15px 0px #4B71970D",
                        }}
                        onClick={() => {
                          setActiveStep(3);
                        }}
                      >
                        <Google />
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: 700,
                              lineHeight: "26px",
                              color: `${theme.palette.primary.primaryText}`,
                              mt: 1.5,
                            }}
                          >
                            Use Google Sheets
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </>
                ) : activeStep === 1 ? (
                  <CsvImport listType={listType} setSnackbarOpen={setSnackbarOpen} setSnackbarMsg={setSnackbarMsg} onLeadsCreate={onLeadsCreate} workspaceId={workspaceId}/>
                ) : activeStep === 2 ? (
                  <ManualImport listType={listType} setSnackbarOpen={setSnackbarOpen} setSnackbarMsg={setSnackbarMsg} onLeadsCreate={onLeadsCreate} workspaceId={workspaceId}/>
                ) : activeStep === 3 ? (
                  <GoogleSheetImport listType={listType} setSnackbarOpen={setSnackbarOpen} setSnackbarMsg={setSnackbarMsg} onLeadsCreate={onLeadsCreate} workspaceId={workspaceId}/>
                ) : null}
              </DialogContent>
            </Dialog>
          </CustomTabPanel>
        </Grid>
      </Grid>
      <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={snackbarOpen}
      message={snackbarMsg}
      key={"csv-import"}
      ContentProps={{
        sx: {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
        },
      }}
      />
    </>
  );
};

export default BlockList;
