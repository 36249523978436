import React, { useState } from 'react'
import { Box, Typography, TextField, Button, Paper, CircularProgress } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useSSLLookUpMutation } from 'src/services/tool-service.js'
import { Helmet } from 'react-helmet-async'

const SSLChecker = () => {
  const [domainDetails, setDomainDetails] = useState([])
  const [SSLLookUp] = useSSLLookUpMutation()
  const validationSchema = () => {
    const schema = Yup.object({
      domain: Yup.string()
        .max(255)
        .required('Domain is required')
        .matches(
          /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/,
          'Please add a valid Domain address'
        )
    })

    return schema
  }

  const handleDomainDataSubmit = async (values, helpers) => {
    try {
      const result = await SSLLookUp(values).unwrap()
      if (result.message === 'Fetched Error') {
        console.log('error')
        setDomainDetails([{ noDomainFound: 'Domain provided does not exist or is invalid' }])
      } else {
        const today = new Date()
        const validToDate = new Date(result.sslDetails.sslDetails.valid_to)
        const diffInMs = validToDate - today
        const msInOneDay = 1000 * 60 * 60 * 24
        const daysleft = Math.ceil(diffInMs / msInOneDay)

        setDomainDetails([
          {
            noDomainFound: '',
            hostname: formik?.values?.domain,
            daysleft,
            serverType: result.sslDetails.serverType
          }
        ])
      }
    } catch (err) {
      helpers.setErrors({ submit: err.data.error.message })
    }
  }

  const formik = useFormik({
    initialValues: {
      domain: ''
    },
    validationSchema,
    onSubmit: async (values, helpers) => {
      try {
        await handleDomainDataSubmit(values, helpers, true)
      } catch (err) {
        helpers.setErrors({ submit: err.data.error.message })
      }
      formik.resetForm()
    }
  })

  return (
    <Box sx={{ px: { sm: '7%' }, py: { sm: 5 }, p: 4, fontFamily: 'Inter' }}>
      <Helmet>
        <title>SSL Checker - Get SSL Certificate Information</title>
        <meta name='description' content="Insert the server's public hostname to retrieve detailed SSL certificate information. Check your SSL status now!" />
      </Helmet>
      <Typography variant='h5' sx={{ fontWeight: 'bold', color: 'primary.main' }}>
        SSL Checker: Verify Your Server's SSL Certificate Information
      </Typography>
      <Typography color='primary.main' sx={{ fontSize: '0.8rem' }}>
        Insert the server's public hostname to get SSL certificate information.
      </Typography>

      {/* SSL Checker Form */}
      <form noValidate onSubmit={formik.handleSubmit}>
        <Box sx={{ display: 'flex', alignItems: 'baseline', flexDirection: { xs: 'column', sm: 'row' }, mt: { xs: '24px', sx: '24px', md: 3, lg: 3, xl: 3 } }}>
          <Box
            sx={{ display: 'flex', gap: { sm: 2 }, flexDirection: { xs: 'column', sm: 'row' }, mb: { xs: 0, sm: 0 }, width: { xs: '100%', sm: 'auto' } }}
          >
            <TextField
              variant='outlined'
              placeholder='Enter a hostname'
              onChange={formik.handleChange}
              error={!!(formik.touched.domain && formik.errors.domain)}
              helperText={formik.touched.domain && formik.errors.domain}
              onBlur={formik.handleBlur}
              name='domain'
              value={formik?.values?.domain}
              sx={{
                borderColor: 'gray.300',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  width: { md: '35vw', sm: 'auto', xs: 'auto' },
                  maxHeight: '44px',
                  color: 'primary.main'
                },
                mb: { xs: 0, sm: 0 },
                paddingBottom: { xs: '8px', sm: '11px' }
              }}
            />
          </Box>
          <Button
            variant='contained'
            color='primary'
            sx={{
              ml: [0, 4],
              px: 4,
              py: 2,
              width: { sm: 'fit-content', xs: '100%' },
              padding: '10px',
              marginLeft: { xs: '7px', sm: '4' },
              marginTop: { xs: 2, sm: 0 },
              maxHeight: '44px',
              cursor: formik?.values?.domain ? 'pointer' : 'not-allowed'
            }}
            disabled={!formik?.values?.domain}
            type='submit'
          >
            {formik.isSubmitting ? <CircularProgress size={20} color='inherit' /> : <>Look up</>}
          </Button>
        </Box>
      </form>

      {/* result Table */}
      {domainDetails.length > 0 && domainDetails[0].noDomainFound === ''
        ? (
          <Box
            sx={{
              py: { xs: 2, sm: 5 },
              display: 'flex',
              flexDirection: 'column',
              width: { xs: '100%', sm: '70%' },
              justifyContent: 'center',
              alignItems: 'flex-start',
              border: '6px solid #ECEDF5',
              p: { xs: 2, sm: 4 },
              mt: 8,
              borderRadius: '10px'
            }}
          >
            <Typography variant='h5' sx={{ fontWeight: 'bold', mb: 2 }}>
              Domain Information
            </Typography>
            <Paper sx={{ width: { xs: '100%', sm: '55%' }, borderRadius: '8px', p: 2 }}>
              <Box sx={{ borderBottom: '1px solid #ECEDF5', px: { xs: 2, sm: 4 }, py: 2 }}>
                <Typography variant='body1'>
                  Hostname is <strong>{domainDetails[0].hostname}</strong>
                </Typography>
              </Box>

              <Box
                sx={{
                  backgroundColor: '#F9F9FC',
                  px: { xs: 2, sm: 4 },
                  py: 3,
                  borderBottom: '1px solid #ECEDF5'
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <CheckCircleIcon sx={{ color: '#34B24E' }} />
                  <Typography>Server Type: {domainDetails[0].serverType}</Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  backgroundColor: '#F9F9FC',
                  px: { xs: 2, sm: 4 },
                  py: 3,
                  borderBottom: '1px solid #ECEDF5'
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <CheckCircleIcon sx={{ color: '#34B24E' }} />
                  <Typography>
                    This certificate will expire in {domainDetails[0].daysleft} days.
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  backgroundColor: '#F9F9FC',
                  px: { xs: 2, sm: 4 },
                  py: 3,
                  borderBottom: '1px solid #ECEDF5'
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <CheckCircleIcon sx={{ color: '#34B24E' }} />
                  <Typography>
                    The hostname ({domainDetails[0].hostname}) is correctly listed in the certificate.
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Box>
          )
        : (
            domainDetails.length > 0 && (
              <Box
                mt={4}
                sx={{
                  mt: { sm: 6 },
                  backgroundColor: '#ffcbd1',
                  p: 3,
                  borderRadius: '8px',
                  mb: 4,
                  color: '#3c763d'
                }}
              >
                <Typography
                  sx={{
                    backgroundColor: '#ffcbd1',
                    padding: '8px',
                    borderRadius: '8px',
                    color: '#EF4444'
                  }}
                >
                  Domain provided does not exist or is invalid
                </Typography>
              </Box>
            )
          )}
    </Box>
  )
}

export default SSLChecker
