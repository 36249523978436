import { createSvgIcon } from '@mui/material/utils'

export const Send = createSvgIcon(
  <svg viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clip-path='url(#clip0_7749_46416)'>
      <path d='M7.00001 9.00012L14 2.00012M7.08506 9.21883L8.83714 13.7242C8.99148 14.1211 9.06866 14.3195 9.17986 14.3774C9.27626 14.4277 9.39109 14.4277 9.48755 14.3776C9.59882 14.3198 9.67623 14.1215 9.83105 13.7247L14.2246 2.46625C14.3644 2.10813 14.4342 1.92907 14.396 1.81465C14.3628 1.71528 14.2848 1.6373 14.1855 1.60411C14.0711 1.56588 13.892 1.63576 13.5339 1.77552L2.27538 6.16908C1.87866 6.32389 1.6803 6.4013 1.6225 6.51257C1.57239 6.60903 1.57245 6.72386 1.62268 6.82026C1.68062 6.93146 1.87906 7.00864 2.27596 7.16299L6.7813 8.91506C6.86186 8.94639 6.90215 8.96206 6.93607 8.98625C6.96613 9.0077 6.99242 9.03399 7.01387 9.06406C7.03806 9.09798 7.05373 9.13826 7.08506 9.21883Z' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round' />
    </g>
    <defs>
      <clipPath id='clip0_7749_46416'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
