import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from 'src/utils/base-query.js'

// Define a service using a base URL and expected endpoints
export const pipelineApi = createApi({
  reducerPath: 'pipelineApi',
  tagTypes: ['PIPELINES'],
  baseQuery,
  endpoints: (builder) => ({
    getPipelinesForWorkspace: builder.query({
      query: (workspaceId) => {
        return {
          method: 'GET',
          url: `deal-pipelines/${workspaceId}`
        }
      },
      providesTags: ['PIPELINES']
    }),
    getPipelinesForUser: builder.query({
      query: ({ workspaceId, userId }) => {
        return {
          method: 'GET',
          url: `deal-pipelines/${workspaceId}/user/${userId}`
        }
      },
      providesTags: ['PIPELINES']
    }),
    createPipeline: builder.mutation({
      query: (data) => {
        return {
          method: 'POST',
          url: 'deal-pipelines',
          body: data
        }
      },
      invalidatesTags: ['PIPELINES']
    }),
    updatePipeline: builder.mutation({
      query: ({ id, data }) => {
        return {
          method: 'PUT',
          url: `deal-pipelines/${id}`,
          body: data
        }
      },
      invalidatesTags: ['PIPELINES']
    }),
    deletePipeline: builder.mutation({
      query: (id) => {
        return {
          method: 'DELETE',
          url: `deal-pipelines/${id}`
        }
      },
      invalidatesTags: ['PIPELINES']
    })
  })
})

// Export hooks for usage in functional components
export const {
  useGetPipelinesForWorkspaceQuery,
  useGetPipelinesForUserQuery,
  useCreatePipelineMutation,
  useUpdatePipelineMutation,
  useDeletePipelineMutation
} = pipelineApi
