import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
  , useTheme
} from '@mui/material'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { OffCheckboxCustomIcon } from 'src/assets/general/OffCheckboxCustomIcon.js'
import { OnCheckboxCustomIcon } from 'src/assets/general/OnCheckboxCustomIcon.js'
import { useAddBlocklistMutation, useDuplicateCheckMutation } from 'src/services/account-service.js'

const ManualImport = ({ listType, setSnackbarOpen, setSnackbarMsg, onLeadsCreate, workspaceId }) => {
  const theme = useTheme()
  const [manualImportText, setManualImportText] = useState('')
  const [checkDuplicates, setCheckDuplicates] = useState(true)
  const [stats, setStats] = useState()
  const [skippedLeads, setSkippedLeads] = useState(0)
  const [open, setOpen] = useState(false)
  const [addBlocklist, { isLoading: isLoadingBlocklist }] = useAddBlocklistMutation()
  const [duplicateCheck] = useDuplicateCheckMutation()

  const handleImportEmailsClick = async () => {
    const lines = manualImportText.trim().split('\n')
    const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/
    const domainRegex = /^[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/
    const blockData = []
    let skippedCount = 0

    for (const line of lines) {
      const [item] = line
        .trim()
        .replaceAll(/["'<>]/g, '')
        .split(' ')
        .map((v) => v.trim())

      const isValid = listType === 'email'
        ? emailRegex.test(item)
        : domainRegex.test(item)

      if (!isValid) {
        skippedCount++
        continue
      }

      blockData.push(listType === 'email' ? { email: item } : { domain: item })
    }

    if (!blockData.length) {
      toast.success('0 uploaded successfully!')
      showInvalidLeadsToast(skippedCount)
      onLeadsCreate(true)
      return
    }

    try {
      if (checkDuplicates) {
        const data = {
          blockData,
          checkDuplicates,
          stats: true,
          listType,
          workspaceId
        }
        const { createdEmails } = await duplicateCheck({ data }).unwrap()
        setOpen(true)
        setStats(createdEmails)
        setSnackbarMsg('')
        setSkippedLeads(skippedCount)
      }
      if (!checkDuplicates) {
        const { totalAdded } = await addBlocklist({
          data: {
            blockData,
            type: 'Manual',
            listType,
            workspaceId
          }
        }).unwrap()
        toast.success(`${totalAdded.length} uploaded successfully!`)
        showInvalidLeadsToast(skippedCount)
        onLeadsCreate(true)
      }
    } catch (error) {
      // toast.error(error.data.error.message);
    }
  }

  const leadGenerator = async () => {
    try {
      if (!stats?.leads || stats.leads.length === 0) {
        toast.success('0 uploaded successfully.')
        showInvalidLeadsToast(skippedLeads)
        onLeadsCreate(true)
        return
      }
      const { totalAdded } = await addBlocklist({
        data: {
          blockData: stats?.leads,
          type: 'Manual',
          listType,
          workspaceId
        }
      }).unwrap()
      toast.success(`${totalAdded.length} uploaded successfully!`)
      showInvalidLeadsToast(skippedLeads)
      onLeadsCreate(true)
    } catch (error) {
      // toast.error(error.data.error.message);
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  const showInvalidLeadsToast = (skippedCount) => {
    if (skippedCount > 0) {
      toast.error(`${skippedCount} invalid ${listType === 'email' ? 'email(s)' : 'domain(s)'} skipped.`)
    }
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
          width: '100%'
        }}
      >
        <Typography
          sx={{
            fontSize: '20px',
            fontWeight: 700,
            lineHeight: '25px',
            color: `${theme.palette.primary.primaryText}`
          }}
        >
          {listType === 'email' ? 'Bulk Add Emails by Hand' : 'Bulk Add Domains by Hand'}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            flexDirection: 'column',
            backgroundColor: '#F2F4F6',
            p: 2,
            borderRadius: '12px',
            mt: 2,
            width: '100%'
          }}
        >
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '18px'
            }}
          >
            {listType === 'email' ? '💡 For adding emails with associated names, use any of these formats:' : '💡 For adding domains with associated names, use any of these formats:'}
          </Typography>
          <Typography
            sx={{
              width: '100%',
              fontSize: '14px',
              fontWeight: 700,
              lineHeight: '20px',
              p: 2,
              pb: 0
            }}
          >
            {listType === 'email' ? 'jan@smith.com' : 'smith.com'}
          </Typography>
        </Box>
        <Typography
          sx={{
            fontSize: '13px',
            fontWeight: 400,
            lineHeight: '20px',
            color: '#8181B0',
            mt: 3
          }}
        >
          {listType === 'email' ? 'Input or paste email IDs - enter one email per line' : 'Input or paste domain - enter one domain per line'}
        </Typography>
        <TextField
          multiline
          rows={6}
          fullWidth
          variant='outlined'
          sx={{
            mt: 1,
            backgroundColor: 'white',
            '& div': { pl: 2 },
            '& div fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
            '& div input': {
              p: 1.3,
              px: 3,
              fontSize: '13px',
              fontWeight: 400,
              lineHeight: '16px',
              letterSpacing: '0em',
              '&::placeholder': {
                color: 'rgba(40, 40, 123, 0.5)'
              }
            }
          }}
          value={manualImportText}
          onChange={(e) => setManualImportText(e.target.value)}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            mt: 2
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                icon={<OffCheckboxCustomIcon />}
                checkedIcon={<OnCheckboxCustomIcon />}
                checked={checkDuplicates}
                onChange={(e, v) => setCheckDuplicates(v)}
              />
            }
            label='Ensure there are no duplicates across your blocklist.'
            sx={{
              '& .MuiFormControlLabel-label': {
                fontSize: '13px',
                fontWeight: 500,
                lineHeight: '16px',
                color: `${theme.palette.primary.primaryText}`
              }
            }}
          />
          <Button
            variant='contained'
            disabled={!manualImportText.trim()}
            onClick={() => {
              handleImportEmailsClick()
            }}
          >
            {isLoadingBlocklist
              ? (
                <CircularProgress size={20} sx={{ color: 'white' }} />
                )
              : (
                  listType === 'email' ? 'Import Emails' : 'Import Domains'
                )}
          </Button>
        </Box>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ fontSize: '25px', color: '#595959', textAlign: 'center' }}>
          Are you sure?
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
            This will upload{' '}
            <Typography component='span' color='blue'>
              {stats?.uploadedCount}{' '}
            </Typography>{' '}
            records to your blocklist .{' '}
            <Typography component='span' color='blue'>
              {stats?.duplicateItemsCount}{' '}
            </Typography>{' '}
            duplicate records and{' '}
            <Typography component='span' color='blue'>
              {skippedLeads}{' '}
            </Typography>{' '}
            records had errors.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <Button
            onClick={leadGenerator}
            variant='contained'
            sx={{
              fontSize: '16px',
              borderRadius: '12px',
              px: 2.5,
              py: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mt: 1
            }}
          >
            {isLoadingBlocklist
              ? (
                <CircularProgress size={20} sx={{ color: 'white' }} />
                )
              : (
                  'Import'
                )}
          </Button>
          <Button
            variant='outlined'
            onClick={handleClose}
            sx={{
              // backgroundColor: "#595959",
              color: 'Black',
              fontSize: '16px',
              borderRadius: '12px',
              px: 3.2,
              py: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mt: 1,
              '&:hover': {
                backgroundColor: '#787878'
              }
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ManualImport
