import { useTheme } from '@mui/material'
export const LFEmployees = () => {
  const theme = useTheme()
  return (
    <>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M2.40039 18.2168C3.49903 16.3843 5.9069 15.1301 9.66351 15.1301C13.4201 15.1301 15.828 16.3843 16.9266 18.2168M17.6216 13.7406C19.611 14.7352 20.6057 15.7299 21.6004 17.7193M15.5526 6.1376C16.4887 6.64115 17.125 7.62991 17.125 8.7673C17.125 9.87183 16.5249 10.8362 15.633 11.3522M12.6476 8.76727C12.6476 10.4153 11.3116 11.7513 9.66351 11.7513C8.01545 11.7513 6.67944 10.4153 6.67944 8.76727C6.67944 7.11922 8.01545 5.7832 9.66351 5.7832C11.3116 5.7832 12.6476 7.11922 12.6476 8.76727Z'
          stroke={theme.palette.primary.primaryText}
          stroke-width='2'
          stroke-linecap='round'
        />
      </svg>
    </>
  )
}
