import { useState, useCallback } from 'react'
import {
  Typography,
  Box,
  DialogTitle,
  DialogContent,
  Stack,
  Grid,
  FormControlLabel,
  Radio,
  RadioGroup,
  Chip,
  ButtonBase,
  Fade,
  useMediaQuery,
  DialogActions
} from '@mui/material'
import { Link } from 'react-router-dom'
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { useBillingUsageQuery, useGetCurrentPlanQuery, useUpdatePlanMutation } from 'src/services/billing-service'
import { config } from 'src/config'
import BuyingIntentIcon2 from 'src/assets/leadFinder/LFFilterNew/buyingIntentIcons/buying-intent2.svg'
import UpgradePlanIcon from 'src/assets/leadFinder/LFFilterNew/buyingIntentIcons/upgrade-plan.svg'
import UpgradePlanIconWhite from 'src/assets/leadFinder/LFFilterNew/buyingIntentIcons/upgrade-plan-white.svg'
import UpgradePlanIconGrey from 'src/assets/leadFinder/LFFilterNew/buyingIntentIcons/upgrade-plan-grey.svg'
import StarterPlanIcon from 'src/assets/leadFinder/LFFilterNew/buyingIntentIcons/starter-plan-icon.svg'
import GrowthPlanIcon from 'src/assets/leadFinder/LFFilterNew/buyingIntentIcons/growth-plan-icon.svg'
import InfinityPlanIcon from 'src/assets/leadFinder/LFFilterNew/buyingIntentIcons/infinity-plan-icon.svg'
import { Close } from '@mui/icons-material'

export default function UpgradeModal ({ currentPlan, closeModal }) {
  const [selectedPlan, setSelectedPlan] = useState(currentPlan)
  const workspace = useSelector((state) => state.workspace)
  const { refetch: refetchCurrentPlan } = useGetCurrentPlanQuery()
  const { refetch: refetchUsage } = useBillingUsageQuery()
  const [updatePlan] = useUpdatePlanMutation()

  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const handleRadioBtn = useCallback((e) => {
    setSelectedPlan(e.target.value)
  }, [])

  const handleUpdatePlanClick = async () => {
    if (selectedPlan === 'free' || selectedPlan === currentPlan) return

    let priceId
    switch (selectedPlan) {
      case 'starter':
        priceId = config.SENDING_WARMUP_MONTHLY_GROWTH_PRICE_ID
        break
      case 'growth':
        priceId = config.SENDING_WARMUP_MONTHLY_SKYROCKET_PRICE_ID
        break
      case 'infinity':
        priceId = config.SENDING_WARMUP_MONTHLY_SCALE_PRICE_ID
        break
      default:
        break
    }

    const toastId = toast.loading('Updating...', { duration: Infinity })
    const session = await updatePlan({ priceId, workspaceId: workspace?._id }).unwrap()
    if (session.url) {
      window.location.href = session.url
    } else if (session.errorMsg) {
      toast.error(session.errorMsg, { id: toastId, duration: 5000 })
    } else {
      toast.success('Plan Updated', { id: toastId, duration: 2000 })
      await refetchCurrentPlan()
      refetchUsage()
    }
  }

  const isUgradeBtnDisabled = currentPlan === selectedPlan || selectedPlan === 'free'

  return (
    <Grid container position='relative'>
      {isMdUp &&
        <DialogActions sx={{ position: 'absolute', top: '24px', right: '24px', zIndex: '10000' }}>
          <ButtonBase onClick={closeModal} sx={{ padding: '6px', backgroundColor: '#FFFFFF20', borderRadius: '6px', boxShadow: '0px 0px 1px 1px #ffffff75' }}>
            <Close fontSize='medium' sx={{ color: 'white' }} />
          </ButtonBase>
        </DialogActions>}
      <Grid item xs={isMdUp ? 6 : 12} p={isMdUp ? 4 : 2}>
        <DialogTitle>
          <Stack direction='row' gap={2} alignItems='center'>
            <img src={UpgradePlanIcon} style={{ width: '48px', height: '48px' }} />
            <Typography variant='h4' fontWeight='700'>Your free plan doesn't support buying intent signals.</Typography>
          </Stack>
        </DialogTitle>

        <DialogContent>
          <RadioGroup
            value={selectedPlan}
            onChange={handleRadioBtn}
          >
            <Stack direction='column' gap='9px' paddingBlock='38px'>
              <PlanRadioBtn
                title='Free Plan'
                subtitle='A quick and free way to discover Success.ai and its various features.'
                value='free'
                priceTag='Free'
                currentPlan={currentPlan}
                selectedPlan={selectedPlan}
                setSelectedPlan={setSelectedPlan}
              />
              <PlanRadioBtn
                title='Starter Plan'
                subtitle='Adapted for small businesses that want to explore tools and optimize their tasks.'
                value='starter'
                priceTag='$35/Month'
                currentPlan={currentPlan}
                selectedPlan={selectedPlan}
                icon={StarterPlanIcon}
                setSelectedPlan={setSelectedPlan}
              />
              <PlanRadioBtn
                title='Growth Plan'
                subtitle="Maximize teamwork. Elevate your team's productivity."
                value='growth'
                priceTag='$59/Month'
                currentPlan={currentPlan}
                selectedPlan={selectedPlan}
                icon={GrowthPlanIcon}
                setSelectedPlan={setSelectedPlan}
              />
              <PlanRadioBtn
                title='Infinity Plan'
                subtitle='Unlock the full power of Success.ai to boost your productivity and team collaboration.'
                value='infinity'
                priceTag='$89/Month'
                currentPlan={currentPlan}
                selectedPlan={selectedPlan}
                icon={InfinityPlanIcon}
                setSelectedPlan={setSelectedPlan}
              />
            </Stack>
          </RadioGroup>

          <ButtonBase
            onClick={handleUpdatePlanClick}
            disabled={isUgradeBtnDisabled}
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '8px',
              backgroundColor: isUgradeBtnDisabled ? '#FFF' : '#3F4FF8',
              padding: '12px',
              borderRadius: '10px',
              border: isUgradeBtnDisabled ? '1px solid #3F4FF836' : '1px solid transparent',
              transition: 'all .3s'
            }}
          >
            <Box>
              <img src={isUgradeBtnDisabled ? UpgradePlanIconGrey : UpgradePlanIconWhite} style={{ width: '32px', height: '32px' }} />
            </Box>
            <Box>
              <Typography variant='body2' color={isUgradeBtnDisabled ? '#8181B0' : '#FFF'} fontWeight='600'>
                Upgrade & Pay
              </Typography>
            </Box>
          </ButtonBase>

          <Link to='/settings/billing'>
            <Typography variant='subtitle2' fontWeight='600' color='GrayText' textAlign='center' mt={2}>
              View Plan Comparison
            </Typography>
          </Link>
        </DialogContent>
      </Grid>

      {isMdUp &&
        <Grid item xs={6} p={1}>
          <Box
            sx={{
              background: 'radial-gradient(260% 245% at -140% -95%, #81DDFA 6%, #3F5DFA 60%, #2C316E 100%)',
              width: '100%',
              height: '100%',
              borderRadius: '6px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative'
            }}
          >
            <Fade in={selectedPlan === 'free'} mountOnEnter unmountOnExit timeout={400}>{freePlanFeatureBox}</Fade>
            <Fade in={selectedPlan === 'starter'} mountOnEnter unmountOnExit timeout={400}>{starterPlanFeatureBox}</Fade>
            <Fade in={selectedPlan === 'growth'} mountOnEnter unmountOnExit timeout={400}>{freePlanFeatureBox}</Fade>
            <Fade in={selectedPlan === 'infinity'} mountOnEnter unmountOnExit timeout={400}>{freePlanFeatureBox}</Fade>
          </Box>
        </Grid>}
    </Grid>
  )
}

function PlanRadioBtn ({ currentPlan, value, selectedPlan, setSelectedPlan, title, subtitle, icon, priceTag }) {
  return (
    <Box
      onClick={() => setSelectedPlan(value)}
      sx={{
        cursor: 'pointer',
        border: '1px solid #3F4FF836',
        padding: '12px',
        borderRadius: '6px',
        boxShadow: (selectedPlan === value && selectedPlan !== currentPlan) ? '0px 0px 2px 2px #3F4FF8' : 'none',
        backgroundColor: (selectedPlan === value && selectedPlan !== currentPlan) ? '#E7F0FF' : value === currentPlan ? '#F2F4F6' : 'transparent',
        transition: 'all .3s'
      }}
    >
      <Stack direction='row' justifyContent='space-between'>
        <Stack direction='row' gap={1} alignItems='center'>
          {icon && <img src={icon} style={{ width: '16px', height: '16px', color: '#3F4FF8' }} />}
          <Typography variant='h5'>{title}</Typography>
          {currentPlan === value
            ? <Chip label='Current' size='small' variant='filled' sx={{ backgroundColor: '#3F4FF8', color: 'white', fontWeight: '600' }} />
            : <Typography variant='subtitle2' color='GrayText'>{priceTag}</Typography>}
        </Stack>
        <FormControlLabel value={value} control={<Radio />} sx={{ '& .Mui-checked': { color: '#3F4FF8' }, '& .MuiFormControlLabel-label': { fontSize: '14px', fontWeight: '600' } }} />
      </Stack>
      <Typography variant='body2' color='GrayText' maxWidth='380px'>{subtitle}</Typography>
    </Box>
  )
}

function FeatureBox ({ backgroundColor, children }) {
  return (
    <Box
      sx={{
        backgroundColor,
        padding: '12px',
        borderRadius: '16px',
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
        width: 1,
        boxShadow: '0px 0px 1px 1px #ffffff75',
        minHeight: '100px'
      }}
    >
      {children}
    </Box>
  )
}

const freePlanFeatureBox = (
  <Stack
    direction='column'
    gap='16px'
    alignItems='center'
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      width: 1,
      paddingInline: '4px',
      paddingBlock: '2px',
      mask: 'linear-gradient(to bottom, transparent, white 30%, white 70%, transparent)',
      maxWidth: '400px'
    }}
  >
    <FeatureBox backgroundColor='transparent' />

    <FeatureBox backgroundColor='#FFFFFF20'>
      <Box width='48px' height='48px' flexShrink={0}>
        <img src={BuyingIntentIcon2} width='100%' height='100%' />
      </Box>
      <Stack>
        <Typography variant='h6' fontWeight='600' color='#FFF'>Buying intent</Typography>
        <Typography variant='subtitle2' color='#FFFFFF80'>Capture intent when a company engages with these topics</Typography>
      </Stack>
    </FeatureBox>

    <FeatureBox backgroundColor='transparent' />
  </Stack>
)

const starterPlanFeatureBox = (
  <Stack
    direction='column'
    gap='16px'
    alignItems='center'
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      width: 1,
      paddingInline: '4px',
      paddingBlock: '2px',
      maxWidth: '400px'
    }}
  >
    <Typography color='white' fontWeight='600'>Starter Plan</Typography>

    <FeatureBox backgroundColor='#FFFFFF20'>
      <Box width='48px' height='48px' flexShrink={0}>
        <img src={BuyingIntentIcon2} width='100%' height='100%' />
      </Box>
      <Stack>
        <Typography variant='h6' fontWeight='600' color='#FFF'>Buying intent</Typography>
        <Typography variant='subtitle2' color='#FFFFFF80'>Capture intent when a company engages with these topics</Typography>
      </Stack>
    </FeatureBox>

    <FeatureBox backgroundColor='#FFFFFF20'>
      <Box width='48px' height='48px' flexShrink={0}>
        <img src={BuyingIntentIcon2} width='100%' height='100%' />
      </Box>
      <Stack>
        <Typography variant='h6' fontWeight='600' color='#FFF'>Intent Topics</Typography>
        <Typography variant='subtitle2' color='#FFFFFF80'>+1 additional Intent Topic</Typography>
      </Stack>
    </FeatureBox>
  </Stack>
)
