import { Accordion, AccordionDetails, Grid, AccordionSummary, ListItemIcon, Paper, Stack, Typography, useTheme, LinearProgress, linearProgressClasses, styled, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material'
import { Box, Container } from '@mui/system'
import React, { useContext, useEffect, useState } from 'react'
import { OnboardingHub } from 'src/layouts/dashboard/config'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { ChevronDownArrow, ClockIcon, OnboardingHubCheckedIcon, OnboardingHubUncheckedIcon } from 'src/icons/OnboardingHubIcons'
import { useGetCustomAttributesQuery } from 'src/services/intercom-service'
import { useUpdateUserNameMutation } from 'src/services/user-service'
import { useSelector } from 'react-redux'
import { toast } from 'react-hot-toast'
import StepCompletitionConfirmation from 'src/components/onboardingHub/StepCompletitionConfirmation'
import { SidebarContext } from 'src/layouts/dashboard/layout'

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[300],
    ...theme.applyStyles('dark', {
      backgroundColor: theme.palette.grey[800]
    })
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary,
    ...theme.applyStyles('dark', {
      backgroundColor: '#308fe8'
    })
  }
}))

const Page = () => {
  const theme = useTheme()
  const totalPendingTasks = 0
  const { completionPercentage, onboardingData } = useContext(SidebarContext)
  const [isHovering, setIsHovering] = useState(null)
  const [openTaskCompletedConfirmation, setOpenTaskCompletedConfirmation] = useState(false)
  const [attributeToMark, setAttributeToMark] = useState('')
  const [attributeValue, setAttributeValue] = useState(false)
  const [tasks] = useState(totalPendingTasks)
  const { data } = useGetCustomAttributesQuery()
  const [updateUserName] = useUpdateUserNameMutation()
  const navigate = useNavigate()

  const [onBoardingTasks, setOnBoardingTasks] = useState([...onBoardingTasksDefault])

  // onBoardingTasks.forEach((tasks) => {
  //   totalPendingTasks += tasks.subtasks.filter(subtasks => !subtasks.isCompleted).length
  // })

  const handleClickTaskCompleted = (attribute, currentAttributeValue) => {
    setAttributeToMark(attribute)
    setAttributeValue(!currentAttributeValue)
    setOpenTaskCompletedConfirmation(true)
  }

  useEffect(() => {
    const customAttributes = onboardingData?.IntercomCustomAttributes ?? {}

    if (customAttributes.email_account_linked === 'true') {
      onBoardingTasks[0].subtasks[0].isCompleted = true
    } else {
      onBoardingTasks[0].subtasks[0].isCompleted = false
    }
    if (customAttributes.contacts_added === 'true') {
      onBoardingTasks[1].subtasks[0].isCompleted = true
    } else {
      onBoardingTasks[1].subtasks[0].isCompleted = false
    }
    if (customAttributes.sequences_created === 'true') {
      onBoardingTasks[1].subtasks[1].isCompleted = true
    } else {
      onBoardingTasks[1].subtasks[1].isCompleted = false
    }
    if (customAttributes.sending_schedule_created === 'true') {
      onBoardingTasks[1].subtasks[2].isCompleted = true
    } else {
      onBoardingTasks[1].subtasks[2].isCompleted = false
    }
    if (customAttributes.campaign_reviewed === 'true') {
      onBoardingTasks[1].subtasks[3].isCompleted = true
    } else {
      onBoardingTasks[1].subtasks[3].isCompleted = false
    }
    if (customAttributes.campaign_launched === 'true') {
      onBoardingTasks[1].subtasks[4].isCompleted = true
    } else {
      onBoardingTasks[1].subtasks[4].isCompleted = false
    }
    if (customAttributes.team_members_invited === 'true') {
      onBoardingTasks[2].subtasks[0].isCompleted = true
    } else {
      onBoardingTasks[2].subtasks[0].isCompleted = false
    }

    setOnBoardingTasks([...onBoardingTasks])
  }, [data])

  const user = useSelector((state) => state.user)
  const [open, setOpen] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [companyName, setCompanyName] = useState('')

  useEffect(() => {
    const timeout = setTimeout(() => {
      const firstName = user?.name?.first?.trim() || '' || null
      const lastName = user?.name?.last?.trim() || '' || null
      if (!firstName && !lastName) {
        setOpen(true)
      }
    }, 3000)
    return () => clearTimeout(timeout)
  }, [user])

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = () => {
    const isExceedingLength = (value) => value && value.length > 15

    if ([firstName, lastName, companyName].some(isExceedingLength)) {
      toast.error('First name, last name, and company name cannot exceed 15 characters.')
      return
    }

    const emptyFields = [
      !firstName?.trim() && 'First name',
      !lastName?.trim() && 'Last name',
      !companyName?.trim() && 'Company name'
    ].filter(Boolean)

    if (emptyFields.length > 0) {
      toast.error(`${emptyFields.join(', ')} cannot be empty.`)
      return
    }

    const userId = user?._id
    updateUserName({ firstName, lastName, companyName, userId })
      .then((response) => {
        toast.success('User updated successfully')
        handleClose()
      })
  }

  return (
    <>
      <Box
        sx={{
          px: '2%',
          width: '100%',
          height: '100%'
        }}
      >
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderBottom: '1px solid #E4E4E5',
              pb: 2,
              mb: { xs: 0, md: 3 }
            }}
          >
            <Typography
              sx={{
                color: '#28287B',
                fontSize: '32px',
                fontWeight: 700,
                lineHeight: '40px',
                letterSpacing: '0px'
              }}
            >
              Onboarding Hub
            </Typography>
          </Box>
          {/* <Typography display={{ xs: 'none', md: 'block' }} fontWeight={700}>{`Tasks(${tasks})`}</Typography> */}
        </Box>

        <Grid container spacing={4} my={1} direction={{ xs: 'column-reverse', md: 'row' }}>
          <Grid item xs={12} md={8.5}>
            <Stack direction='column' spacing={1}>
              {
                onBoardingTasks.map((task, i) => {
                  const inCompletedTasks = task.subtasks.filter((subtask) => (!subtask.isCompleted))
                  return (
                    <Accordion key={i} defaultExpanded sx={{ borderRadius: '10px', border: '1px solid #D7D9E6' }}>
                      <AccordionSummary
                        // expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel1-content'
                        id='panel1-header'
                        sx={{
                          borderBottom: '1px solid',
                          borderColor: theme.palette.grey[200],
                          py: '5px'
                        }}
                      >
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                          <Stack direction='row' spacing={2} sx={{ alignItems: 'center' }}>
                            <ChevronDownArrow />
                            <Typography fontWeight={700}>{task.title}</Typography>
                          </Stack>
                          <Typography fontWeight={400} color={theme.palette.grey[500]}>{inCompletedTasks.length} task(s) left</Typography>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        {
                          task.subtasks.map((subtask, j) => (
                            <Stack
                              key={j}
                              direction={{
                                xs: 'column',
                                md: 'row'
                              }}
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                borderBottom: '1px solid #D7D9E6',
                                py: 2,
                                cursor: 'pointer'
                              }}
                              spacing={{ xs: 3, md: 0 }}
                              onClick={(e) => {
                                navigate(subtask.href)
                              }}
                            >
                              <Stack direction='row' spacing={2} sx={{ display: 'flex', alignItems: 'center', p: '1%' }}>
                                <div
                                  onClick={(e) => {
                                    handleClickTaskCompleted(subtask.attribute, subtask.isCompleted)
                                    e.stopPropagation()
                                  }}
                                  onMouseEnter={() => { setIsHovering(Number(`${i}${j}`)) }}
                                  onMouseLeave={() => { setIsHovering(null) }}
                                >
                                  <ListItemIcon
                                    sx={{
                                      minWidth: '0',
                                      color: theme.palette.primary.contrastText,
                                      py: '0px',
                                      '&:active': {
                                        transform: 'scale(0.95)' // Shrink on active
                                      }
                                    }}

                                  >
                                    {subtask.isCompleted || isHovering === Number(`${i}${j}`) ? OnboardingHubCheckedIcon() : OnboardingHubUncheckedIcon()}
                                  </ListItemIcon>
                                </div>
                                <Box>
                                  <Typography sx={{ textDecoration: subtask.isCompleted && 'line-through' }} fontWeight={700}>{subtask.title}</Typography>
                                  <Typography fontWeight={400}>{subtask.subtitle}</Typography>
                                </Box>
                              </Stack>
                              {
                                !subtask.isCompleted &&
                                  <Button
                                    component={RouterLink}
                                    to={subtask.href}
                                    variant='contained'
                                    sx={{
                                      width: { xs: '100%', md: '15%' },
                                      textWrap: 'nowrap'
                                    }}
                                    onClick={(e) => { e.stopPropagation() }}
                                  >
                                    {subtask.buttonTitle}
                                  </Button>
                              }
                              {
                                subtask.isCompleted &&
                                  <Typography
                                    fontWeight={600}
                                    fontSize={14}
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      py: { xs: '2%', md: '0.2%' },
                                      px: '1%',
                                      backgroundColor: '#DAEFDF',
                                      color: '#00AA38',
                                      borderRadius: '5px',
                                      width: { xs: '100%', md: '10%' }
                                    }}
                                  >
                                    Completed
                                  </Typography>
                              }
                            </Stack>
                          )
                          )
                        }
                      </AccordionDetails>
                    </Accordion>
                  )
                })
              }
            </Stack>
          </Grid>
          <Grid item xs={12} md={3.5}>
            <Paper elevation={0} sx={{ p: '5%', border: '1px solid #D7D9E6' }}>
              <Stack direction='column' spacing={2}>
                <Stack direction='column' display={{ xs: 'none', md: 'contents' }}>
                  <Paper elevation={3} sx={{ px: '2%', py: '4%' }}>
                    <Stack direction='row' spacing={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Stack direction='row' sx={{ display: 'flex', alignItems: 'center' }}>
                        {OnboardingHub.icon(true, '#8181B0', 20)}
                        <Typography fontWeight={600} fontSize={14} mb={1} noWrap>Your Onboarding progress</Typography>
                      </Stack>
                      <Typography color={theme.palette.grey[500]} fontWeight={400} fontSize={14} mb={1} noWrap>{completionPercentage}% complete</Typography>
                    </Stack>
                    <BorderLinearProgress variant='determinate' value={completionPercentage} />
                  </Paper>
                </Stack>
                <Container sx={{ overflow: 'hidden', display: 'flex', justifyContent: 'center', borderRadius: '10px' }}>
                  {/* <img src='/assets/OnboardingHub-VideoThumbnail.jpeg' width='100%' style={{ scale: '1.2', objectFit: 'fill' }} /> */}
                </Container>
                <Stack spacing={1.2}>
                  <Stack direction='row' spacing={1} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography fontWeight={600} fontSize={14} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', py: '1%', px: '5%', backgroundColor: '#E7F0FF', color: '#3F4FF8', borderRadius: '5px' }}>
                      Recommended
                    </Typography>
                    <Stack spacing={0.5} direction='row' sx={{ display: 'flex', alignItems: 'center' }}>
                      <ClockIcon />
                      <Typography>15 mins</Typography>
                    </Stack>
                  </Stack>
                  <Typography fontSize={24} lineHeight={1.2} fontWeight={700}>
                    Getting Started: Master Your New Tool in Minutes!
                  </Typography>
                  <Typography color={theme.palette.grey[500]}>
                    This short guide covers all you need to quickly master the tool and work with confidence.
                  </Typography>
                </Stack>
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={12} display={{ xs: 'block', md: 'none' }}>
            <Typography fontSize={20} fontWeight={700} mb={-2}>{`Tasks(${tasks})`}</Typography>
          </Grid>
          <Grid item xs={12} display={{ xs: 'block', md: 'none' }}>
            <Stack direction='column'>
              <Paper elevation={0} sx={{ p: '4%', border: '1px solid #D7D9E6' }}>
                <Stack
                  direction='column'
                  // spacing={1}
                  sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', mb: '10px' }}
                >
                  <Stack direction='row' sx={{ display: 'flex', alignItems: 'center' }}>
                    {OnboardingHub.icon(true, '#8181B0', 20)}
                    <Typography fontWeight={600} fontSize={16} mb={1} noWrap>Your Onboarding Progress</Typography>
                  </Stack>
                  <Typography color={theme.palette.grey[500]} fontWeight={400} fontSize={14} noWrap>{completionPercentage}% complete</Typography>
                </Stack>
                <BorderLinearProgress variant='determinate' value={completionPercentage} />
              </Paper>
            </Stack>
          </Grid>
        </Grid>
        <Dialog open={open}>
          <DialogTitle>Welcome to Success.ai</DialogTitle>
          <DialogContent>
            <Box sx={{ mt: 2 }}>
              <TextField
                autoFocus
                margin='dense'
                id='firstName'
                label='First Name'
                type='text'
                fullWidth
                variant='outlined'
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <TextField
                margin='dense'
                id='lastName'
                label='Last Name'
                type='text'
                fullWidth
                variant='outlined'
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
              <TextField
                margin='dense'
                id='companyName'
                label='Company Name'
                type='text'
                fullWidth
                variant='outlined'
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </Box>
          </DialogContent>
          <DialogActions>

            <Button onClick={handleSubmit} color='primary' variant='contained'>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <StepCompletitionConfirmation open={openTaskCompletedConfirmation} setOpen={setOpenTaskCompletedConfirmation} attribute={attributeToMark} attributeValue={attributeValue} />
    </>
  )
}

export default Page

const onBoardingTasksDefault = [
  {
    title: 'Connect Mailboxes',
    subtasks: [{
      title: 'Link and existing email box to your account',
      subtitle: 'Securely integrate your email to start sending and tracking communications.',
      isCompleted: false,
      buttonTitle: 'Connect Mailboxes',
      href: '/accounts',
      attribute: 'email_account_linked'
    }]
  },
  {
    title: 'Create Campaign',
    subtasks: [
      {
        title: 'Add your contacts',
        subtitle: 'Import or add your list of leads or clients to begin your outreach.',
        isCompleted: false,
        buttonTitle: 'Add Now',
        href: '/campaigns',
        attribute: 'contacts_added'
      },
      {
        title: 'Create a sequence',
        subtitle: 'Plan and build your automated email flow to engage your audience effectively.',
        isCompleted: false,
        buttonTitle: 'Create Now',
        href: '/campaigns',
        attribute: 'sequences_created'
      },
      {
        title: 'Set your sending schedule',
        subtitle: 'Choose when and how often your campaign emails should be sent.',
        isCompleted: false,
        buttonTitle: 'Schedule Now',
        href: '/campaigns',
        attribute: 'sending_schedule_created'
      },
      {
        title: 'Finalize your campaign options',
        subtitle: 'Review your setup and customize key details before launch.',
        isCompleted: false,
        buttonTitle: 'Check Now',
        href: '/campaigns',
        attribute: 'campaign_reviewed'
      },
      {
        title: 'Hit launch button!',
        subtitle: 'Go live and begin your outreach with your configured email sequence.',
        isCompleted: false,
        buttonTitle: 'Launch Now',
        href: '/campaigns',
        attribute: 'campaign_launched'
      }
    ]
  },
  {
    title: 'Invite Team Members (optional)',
    subtasks: [{
      title: 'Invite your team members to join your workspace',
      subtitle: 'Collaborate by adding team members to manage and execute campaigns together.',
      isCompleted: false,
      buttonTitle: 'Invite Now',
      href: '/settingsUpdated',
      attribute: 'team_members_invited'
    }]
  }
]
