import { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Checkbox, FormControlLabel, Stack, TextField, Typography } from '@mui/material'
import { FilterDropdown, LockedFilter, Tag } from '../filterComponents'
import { Currency } from 'src/icons/currency'
import { useTheme } from '@emotion/react'
import { OffCheckboxCustomIcon } from 'src/assets/general/OffCheckboxCustomIcon'
import { OnCheckboxCustomIcon } from 'src/assets/general/OnCheckboxCustomIcon'

const checkboxOfIcon = <OffCheckboxCustomIcon />
const checkboxOnIcon = <OnCheckboxCustomIcon />

export default function RevenueFilter ({ isLocked, onChange, type, plan, isOpen, onToggle }) {
  const theme = useTheme()
  const [range, setRange] = useState({ min: '', max: '' })
  const [unknownRange, setUnknownRange] = useState(false)
  const ref = useRef({ internalStateChange: false })
  const revenueData = useSelector((state) => state.search[type === 'company' ? 'companyFilter' : 'filter'].revenue)

  const handleSelectedRange = useCallback((newValue, type, updateRedux = true) => {
    if (type !== 'both') {
      setRange({ ...range, [type]: newValue })
      if (updateRedux) onChange('revenue', [type === 'min' ? newValue : range.min, type === 'max' ? newValue : range.max])
    } else {
      const value = { min: newValue.min, max: newValue.max }
      setRange(value)
      if (updateRedux) onChange('revenue', [])
    }
    ref.current.internalStateChange = true
  }, [onChange, range])

  const clearAll = useCallback(() => {
    const value = { min: '', max: '' }
    handleSelectedRange(value, 'both')
  }, [handleSelectedRange])

  useEffect(() => {
    if (ref.current.internalStateChange) {
      ref.current.internalStateChange = false
      return
    }

    setRange({ min: revenueData?.at(0) ?? '', max: revenueData?.at(1) ?? '' })
  }, [revenueData])

  const preview = (
    <>
      {(range.min || range.max) &&
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 1 }}>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, alignItems: 'center' }}>
            <Box sx={{ width: '95%', height: '0px', border: '0.5px solid', borderColor: '#E4E4E5', mx: 'auto' }} />
            <Typography variant='body2' sx={{ color: '#8181B0', fontSize: '12px', fontWeight: 600, paddingLeft: 1 }}>Custom range</Typography>
            <Tag label={`${range.min}-${range.max}`} onDelete={clearAll} />
          </Box>
        </Box>}
    </>
  )

  if (isLocked) {
    return (
      <LockedFilter
        title='Revenue'
        icon={<Currency sx={{ stroke: `${theme.palette.primary.main}` }} />}
        plan={plan}
      />
    )
  }

  return (
    <FilterDropdown
      title='Revenue'
      filterCount={range.min || range.max ? 1 : 0}
      clearAll={clearAll}
      icon={<Currency sx={{ stroke: `${theme.palette.primary.main}` }} />}
      preview={preview}
      isOpen={isOpen}
      onToggle={onToggle}
    >
      <Stack direction='column' gap={2} mt={2}>
        <Stack direction='column' gap={1}>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Typography variant='button' sx={{ fontSize: '14px', fontWeight: 700 }}>Custom range</Typography>
          </Stack>

          <Stack direction='row' gap={1} mt={1}>
            <TextField type='number' placeholder='Min' value={range.min} onChange={(e) => handleSelectedRange(e.target.value, 'min')} />
            <span>-</span>
            <TextField type='number' placeholder='Max' value={range.max} onChange={(e) => handleSelectedRange(e.target.value, 'max')} />
          </Stack>

          <FormControlLabel
            label='Include Unknown revenue'
            control={
              <Checkbox
                icon={checkboxOfIcon}
                checkedIcon={checkboxOnIcon}
                style={{ marginRight: 8 }}
                checked={unknownRange}
                onChange={() => setUnknownRange(!unknownRange)}
              />
            }
            sx={{
              '& .MuiFormControlLabel-label': {
                fontSize: '13px', // Adjust font size
                fontWeight: 500,
                lineHeight: '16.38px' // Adjust line height
              }
            }}
          />
        </Stack>
      </Stack>
    </FilterDropdown>
  )
}

// import React, { useEffect, useState } from "react";
// import {
//   Box,
//   Typography,
//   TextField,
//   IconButton,
//   Paper,
//   Collapse,
//   Checkbox,
//   FormControlLabel,
//   Chip,
//   Divider,
// } from "@mui/material";
// import CloseIcon from "@mui/icons-material/Close";
// import { KeyboardArrowDown, KeyboardArrowUp, Close } from "@mui/icons-material/";
// import Dollar from "src/assets/leadFinder/LFFilter/currency-dollar.svg";

// const Revenue = ({ handleFilterChange, clear, setClear, value }) => {
//   const [isPanelOpen, setIsPanelOpen] = useState(false);
//   const [includeUnknown, setIncludeUnknown] = useState(false);
//   const [minValue, setMinValue] = useState(() => {
//     if (!value.revenue) return "";
//     return value.revenue[0]
//   });
//   const [maxValue, setMaxValue] = useState(() => {
//     if (!value.revenue) return "";
//     return value.revenue[1] ?? ""
//   });
//   const [hasRange, setHasRange] = useState(false);
//   const [isInternalChange, setIsInternalChange] = useState(false);

//   useEffect(() => {
//     if (isInternalChange) {
//       setIsInternalChange(false)
//       return
//     }
//     if (!value.revenue) return;
//     const min = value.revenue.at(0);
//     const max = value.revenue.at(1);
//     setMinValue(min)
//     setMinValue(max ?? '')
//     // updateRangeStatus(min, max);
//     setHasRange(min !== "" || max !== "");
//   }, [value])

//   const handleMinChange = (event) => {
//     setIsInternalChange(true)
//     const value = event.target.value.replace(/[^0-9]/g, "");
//     setMinValue(value);
//     updateRangeStatus(value, maxValue);
//   };

//   const handleMaxChange = (event) => {
//     setIsInternalChange(true)
//     const value = event.target.value.replace(/[^0-9]/g, "");
//     setMaxValue(value);
//     updateRangeStatus(minValue, value);
//   };

//   const convertToFullNumber = (value) => {
//     if (!value) return null;
//     // Convert the value to string
//     return (parseFloat(value) * 1000000).toString();
//   };

//   const updateRangeStatus = (min, max) => {
//     setHasRange(min !== "" || max !== "");

//     // Convert both min and max values
//     const convertedMin = convertToFullNumber(min);
//     const convertedMax = convertToFullNumber(max);

//     // Create array
//     let revenueRange = null;
//     if (convertedMin && convertedMax) {
//       revenueRange = [convertedMin, convertedMax];
//     } else if (convertedMin) {
//       revenueRange = [convertedMin];
//     } else if (convertedMax) {
//       revenueRange = [convertedMax];
//     }

//     handleFilterChange("revenue", revenueRange);
//   };

//   useEffect(() => {
//     if (clear) {
//       clearRange();
//       setClear(false);
//     }
//   }, [clear, setClear]);

//   const clearRange = () => {
//     setMinValue("");
//     setMaxValue("");
//     setHasRange(false);
//     handleFilterChange("revenue", null); // Clear filter when range is cleared
//   };

//   const formatNumber = (num) => {
//     return Number(num).toLocaleString();
//   };

//   const getRangeText = () => {
//     if (minValue && maxValue) {
//       return `${formatNumber(minValue)}–${formatNumber(maxValue)}`;
//     } else if (minValue) {
//       return `${formatNumber(minValue)}+`;
//     } else if (maxValue) {
//       return `0–${formatNumber(maxValue)}`;
//     }
//     return "";
//   };

//   return (
//     <Paper
//       sx={{
//         width: "100%",
//         p: 1,
//         boxShadow: "0 1px 3px rgba(0,0,0,0.12)",
//         mb: "13px",
//         border: "1px solid var(--Border-Light, #E4E4E5)",
//         borderRadius: 2,
//       }}
//     >
//       <Box
//         sx={{
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "space-between",
//           cursor: "pointer",
//         }}
//         onClick={() => setIsPanelOpen(!isPanelOpen)}
//       >
//         <Box sx={{ display: "flex", alignItems: "center", gap: "8px", flexGrow: 1 }}>
//           <img src={Dollar} alt="Dollar Icon" sx={{ fontSize: 22, color: "#3F4FF8" }} />
//           <Typography variant="h6" sx={{ fontWeight: 700, fontSize: "15px" }}>
//             Revenue
//           </Typography>
//           {hasRange && (
//             <Box
//               onClick={(e) => {
//                 e.stopPropagation();
//                 clearRange(e);
//               }}
//               sx={{
//                 background: " #3F4FF8",

//                 display: "flex",
//                 alignItems: "center",

//                 color: "white",
//                 borderRadius: "6px",
//                 padding: "4px 8px",
//                 fontSize: "12px",
//               }}
//             >
//               1
//               <IconButton
//                 size="small"
//                 onClick={clearRange}
//                 sx={{
//                   color: "white",
//                   p: 0,
//                   ml: 0.5,
//                   "&:hover": { color: "#90caf9" },
//                 }}
//               >
//                 <Close sx={{ fontSize: 14 }} />
//               </IconButton>
//             </Box>
//           )}
//         </Box>

//         <IconButton
//           size="small"
//           onClick={(e) => {
//             e.stopPropagation();
//             setIsPanelOpen(!isPanelOpen);
//           }}
//         >
//           {isPanelOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
//         </IconButton>
//       </Box>
//       {hasRange && !isPanelOpen && <Divider sx={{ my: 1, bgcolor: "#  D4D4D4" }} />}
//       {/* Display selected range below the main title */}
//       {hasRange && !isPanelOpen && (
//         <Box sx={{ mt: 1, display: "flex", alignItems: "center" }}>
//           <Typography variant="body2" sx={{ color: "text.secondary", mr: 1 }}>
//             Custom Range
//           </Typography>
//           <Chip
//             label={getRangeText()}
//             onDelete={clearRange}
//             deleteIcon={<CloseIcon fontSize="small" />}
//             size="small"
//             sx={{
//               borderRadius: 1,
//               background: "#E6F0FF",
//               fontWeight: 500,
//               fontSize: "14px",
//               borderRadius: "43px",
//               border: "1px solid #3F4FF836",

//               color: "black",
//               "& .MuiChip-deleteIcon": {
//                 color: "black",
//               },
//             }}
//           />
//         </Box>
//       )}

//       <Collapse in={isPanelOpen}>
//         <Box sx={{ mt: 2, mx: "10px" }}>
//           <Typography sx={{ mb: "15px", fontWeight: 700, fontSize: "14px" }}>
//             Custom Range
//           </Typography>
//           <Box
//             sx={{
//               display: "flex",
//               gap: 1,
//               alignItems: "center",
//               mb: 2,
//             }}
//           >
//             <TextField
//               placeholder="Min"
//               value={minValue}
//               onChange={handleMinChange}
//               size="small"
//               sx={{
//                 flex: 1,
//                 "& .MuiOutlinedInput-root": {
//                   borderRadius: 1,
//                 },
//               }}
//             />
//             <Box sx={{ color: "text.secondary" }}>-</Box>
//             <TextField
//               placeholder="Max"
//               value={maxValue}
//               onChange={handleMaxChange}
//               size="small"
//               sx={{
//                 flex: 1,
//                 "& .MuiOutlinedInput-root": {
//                   borderRadius: 1,
//                 },
//               }}
//             />
//           </Box>
//           <FormControlLabel
//             control={
//               <Checkbox
//                 checked={includeUnknown}
//                 onChange={(e) => setIncludeUnknown(e.target.checked)}
//                 size="small"
//               />
//             }
//             label="Include Unknown revenue"
//             sx={{
//               "& .MuiFormControlLabel-label": {
//                 fontSize: "0.875rem",
//                 fontWeight: 500,
//                 fontSize: "13px",
//               },
//             }}
//           />
//         </Box>
//       </Collapse>
//     </Paper>
//   );
// };

// export default Revenue;
