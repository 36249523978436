// material-ui
import { Box, FormControl, InputAdornment, OutlinedInput } from '@mui/material'
// assets
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { debounce } from 'lodash'
import { useLocation } from 'react-router-dom'
import { searchByEmail } from 'src/store/reducers/search'
import { Search } from 'src/icons/search'

const UserSearch = () => {
  const [searchValue, setSearchValue] = useState('')
  const dispatch = useDispatch()
  const location = useLocation()

  useEffect(() => {
    const debouncedSearch = debounce((searchValue) => {
      dispatch(searchByEmail({ searchData: searchValue }))
    }, 300)

    debouncedSearch(searchValue)
    return () => {
      debouncedSearch.cancel()
    }
  }, [searchValue, dispatch])

  useEffect(() => {
    setSearchValue('')
    dispatch(searchByEmail({ searchByEmail: searchValue }))
  }, [location])

  const handleChange = (e) => {
    setSearchValue(e.target.value)
  }

  return (
    <Box sx={{ width: '100%', ml: 1 }}>
      <FormControl sx={{ width: '100%' }}>
        <OutlinedInput
          size='small'
          id='header-search'
          startAdornment={
            <InputAdornment position='start'>
              <Search sx={{ fontSize: 18 }} />
            </InputAdornment>
          }
          aria-describedby='header-search-text'
          inputProps={{
            'aria-label': 'weight'
          }}
          placeholder='Search'
          value={searchValue}
          onChange={handleChange}
        />
      </FormControl>
    </Box>
  )
}

export default UserSearch
