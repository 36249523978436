import React from 'react'
import { Select, MenuItem, Typography, Button } from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { useTheme } from '@emotion/react'

const CustomPaginationFooter = ({ paginationModel, onPaginationModelChange, rowCount }) => {
  const theme = useTheme()
  const { page, pageSize } = paginationModel

  const handleRowsPerPageChange = (event) => {
    onPaginationModelChange({
      ...paginationModel,
      pageSize: event.target.value,
      page: 0 // Reset to the first page
    })
  }

  const handlePageChange = (direction) => {
    onPaginationModelChange({
      ...paginationModel,
      page: paginationModel.page + direction
    })
  }

  const startRow = page * pageSize + 1
  const endRow = Math.min((page + 1) * pageSize, rowCount)

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '8px',
        backgroundColor: '#F2F4F6',
        // border: '1px solid #E4E4E5',
        borderBottomRightRadius: '8px',
        borderBottomLeftRadius: '8px'
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography sx={{ fontSize: '13px', lineHeight: '16.38px', fontWeight: 700, display: 'flex', gap: '2px', color: `${theme?.palette.primary.primaryText}` }}>
          {`${startRow} - ${endRow}`} <span style={{ fontWeight: 500, color: `${theme?.palette.primary.primaryText}` }}>{`of ${Intl.NumberFormat().format(rowCount)}`}</span>
        </Typography>

        <Button
          onClick={() => handlePageChange(-1)} disabled={page === 0}
          startIcon={<ChevronLeftIcon />}
          sx={{
            minHeight: '34px',
            minWidth: '34px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid #E4E4E5',
            borderRadius: '6px',
            cursor: 'pointer',
            ml: '10px',
            bgcolor: '#fff',
            '& .MuiButton-startIcon': {
              margin: 0, // Remove the default margin for the icon
              color: `${theme?.palette.primary.primaryText}`
            },
            '&.Mui-disabled .MuiButton-startIcon': {
              color: '#e1e3ec' // Grey color when disabled
            }
          }}
        />
        <Button
          onClick={() => handlePageChange(1)} disabled={endRow >= rowCount}
          startIcon={<ChevronRightIcon />}
          sx={{
            minHeight: '34px',
            minWidth: '34px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid #E4E4E5',
            borderRadius: '6px',
            cursor: 'pointer',
            ml: '4px',
            bgcolor: '#fff',
            '& .MuiButton-startIcon': {
              margin: 0, // Remove the default margin for the icon
              color: `${theme?.palette.primary.primaryText}`
            },
            '&.Mui-disabled .MuiButton-startIcon': {
              color: '#e1e3ec' // Grey color when disabled
            }
          }}
        />

      </div>

      <div style={{ display: 'flex', alignItems: 'center' }}>

        <Typography style={{ fontSize: '13px', fontWeight: 500, marginRight: '8px', marginLeft: '8px', color: `${theme?.palette.primary.primaryText}` }}>
          Rows per page
        </Typography>
        <Select
          value={pageSize}
          onChange={handleRowsPerPageChange}
          variant='standard'
          sx={{
            height: '34px',
            width: '80px',
            display: 'flex',
            bgcolor: '#fff',
            borderRadius: '6px',
            '& .MuiSelect-select': {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '14px',
              fontWeight: 600,
              bgcolor: '#fff',
              border: '1px solid #E4E4E5',
              borderRadius: '6px',
              color: `${theme?.palette.primary.primaryText}`
            },
            '& .MuiMenuItem-root': {
              justifyContent: 'center'
            },
            '&::before, &::after': { // Remove underline
              display: 'none'
            },
            '& .MuiSelect-icon': {
              color: `${theme?.palette.primary.primaryText}` // Set the dropdown arrow icon color
            }
          }}
        >
          <MenuItem value={25} sx={{ fontSize: '13px', fontWeight: 500, color: `${theme?.palette.primary.primaryText}` }}>25</MenuItem>
          <MenuItem value={50} sx={{ fontSize: '13px', fontWeight: 500, color: `${theme?.palette.primary.primaryText}` }}>50</MenuItem>
          <MenuItem value={100} sx={{ fontSize: '13px', fontWeight: 500, color: `${theme?.palette.primary.primaryText}` }}>100</MenuItem>
        </Select>
      </div>
    </div>
  )
}

export default CustomPaginationFooter
