import React from 'react'
import {
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
  IconButton,
  Box
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useTheme } from '@emotion/react'

const ExportCsvDialog = ({ openDialog, handleCloseDialog, handleDownloadCsvClick, verifyLeads, setVerifyLeads }) => {
  const theme = useTheme()
  const handleChange = (event, value) => {
    // Convert string 'true'/'false' to boolean
    const boolValue = value === 'true'
    setVerifyLeads(boolValue)
  }

  return (
    <Box
      open={openDialog}
      onClose={handleCloseDialog}
      minWidth='503px'
      fullWidth
      sx={{
        border: '1px solid #E4E4E5',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        padding: '16px'
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
        <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
          Export as CSV
        </Typography>
        <IconButton
          aria-label='close'
          onClick={handleCloseDialog}
          sx={{ height: '20px', width: '20px' }}
        >
          <CloseIcon sx={{ color: '#101010' }} />
        </IconButton>
      </Box>

      <Typography sx={{ fontSize: '13px', fontWeight: 500 }}>
        Would you like to validate the email addresses?
      </Typography>

      <RadioGroup
        value={verifyLeads.toString()} // Convert boolean to string for RadioGroup
        onChange={handleChange}
        sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
      >
        <FormControlLabel
          value='true'
          control={<Radio />}
          label='Yes'
          sx={{
            border: '1px solid #D7D9E6',
            height: '45px',
            borderRadius: '8px',
            mb: 1,
            width: '48%',
            margin: 0,
            padding: '8px 16px',

            '& .MuiFormControlLabel-label': {
              fontSize: '14px',
              fontWeight: 500
            }
          }}
        />

        <FormControlLabel
          value='false'
          control={<Radio />}
          label='No'
          sx={{
            height: '45px',
            border: '1px solid #D7D9E6',
            borderRadius: '8px',
            width: '48%',
            margin: 0,
            padding: '8px 16px',
            '& .MuiFormControlLabel-label': {
              fontSize: '14px',
              fontWeight: 500
            }
          }}
        />
      </RadioGroup>

      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          onClick={handleCloseDialog} variant='outlined'
          sx={{
            height: '48px',
            width: '76px',
            border: '1px solid #E4E4E5',
            borderRadius: '12px'

          }}
        >
          <Typography sx={{
            fontSize: '14px',
            fontWeight: 600,
            color: '#101010'
          }}
          >Cancel
          </Typography>
        </Button>

        <Button
          onClick={handleDownloadCsvClick}
          variant='contained'
          sx={{
            height: '48px',
            width: '208px',
            borderRadius: '12px',
            backgroundColor: `${theme.palette.primary.main}`,
            '&:hover': {
              backgroundColor: `${theme.palette.primary.dark}`
            }
          }}
        >
          <Typography sx={{
            fontSize: '14px',
            fontWeight: 600
          }}
          >Export leads
          </Typography>
        </Button>
      </Box>

    </Box>
  )
}

export default ExportCsvDialog
