import { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Stack, Typography, RadioGroup } from '@mui/material'
import { ComboBox, FilterDropdown, Tag, RadioBtnCollapse } from '../filterComponents'
// import LanguageIcon from "src/assets/leadFinder/LFFilterNew/language.svg";
import { languages } from 'src/assets/newFiltersData'
import { LanguageIcon } from 'src/icons/language'
import { useTheme } from '@emotion/react'

export default function LanguageFilter ({ onChange, type, isOpen, onToggle }) {
  const theme = useTheme()
  const [selectedLanguagesAny, setSelectedLanguagesAny] = useState([])
  const [selectedLanguagesAtleast, setSelectedLanguagesAtleast] = useState([])
  const ref = useRef({ internalStateChange: false })
  const languageData = useSelector((state) => state.search[type === 'company' ? 'companyFilter' : 'filter'].language)
  const mustLanguageData = useSelector((state) => state.search[type === 'company' ? 'companyFilter' : 'filter'].must_languages)
  const [selectedMethod, setSelectedMethod] = useState('any')

  const handleSelectedLanguagesAny = useCallback((newValue, updateRedux = true) => {
    setSelectedLanguagesAny(newValue)
    if (updateRedux) onChange('language', newValue)
    ref.current.internalStateChange = true
  }, [onChange])

  const handleSelectedLanguagesAtleast = useCallback((newValue, updateRedux = true) => {
    setSelectedLanguagesAtleast(newValue)
    if (updateRedux) onChange('must_languages', newValue)
    ref.current.internalStateChange = true
  }, [onChange])

  const clearAll = useCallback(() => {
    handleSelectedLanguagesAny([])
    handleSelectedLanguagesAtleast([])
  }, [handleSelectedLanguagesAny, handleSelectedLanguagesAtleast])

  useEffect(() => {
    if (ref.current.internalStateChange) {
      ref.current.internalStateChange = false
      return
    }
    setSelectedLanguagesAny(languageData ?? [])
    setSelectedLanguagesAtleast(mustLanguageData ?? [])

    // Set the correct radio based on which data exists
    if (mustLanguageData?.length > 0) {
      setSelectedMethod('atleast')
    } else if (languageData?.length > 0) {
      setSelectedMethod('any')
    }
  }, [languageData, mustLanguageData])

  const preview = (
    <>
      {(selectedLanguagesAny.length > 0 || selectedLanguagesAtleast.length > 0) &&
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 1 }}>
          {selectedLanguagesAny.length > 0 &&
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, alignItems: 'center' }}>
              <Box sx={{ width: '95%', height: '0px', border: '0.5px solid', borderColor: '#E4E4E5', mx: 'auto' }} />
              <Typography variant='body2' sx={{ fontSize: '12px', fontWeight: 600, color: '#8181B0', paddingLeft: 1 }}>Person speaks ANY of these</Typography>
              {selectedLanguagesAny.map((lang, index) => <Tag key={index} label={lang} onDelete={() => handleSelectedLanguagesAny(selectedLanguagesAny.filter(l => l !== lang))} />)}
            </Box>}
          {selectedLanguagesAtleast.length > 0 &&
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, alignItems: 'center' }}>
              <Box sx={{ width: '95%', height: '0px', border: '0.5px solid', borderColor: '#E4E4E5', mx: 'auto' }} />
              <Typography variant='body2' sx={{ fontSize: '12px', fontWeight: 600, color: '#8181B0', paddingLeft: 1 }}>Person speaks AT LEAST</Typography>
              {selectedLanguagesAtleast.map((lang, index) => <Tag key={index} label={lang} onDelete={() => handleSelectedLanguagesAtleast(selectedLanguagesAtleast.filter(l => l !== lang))} />)}
            </Box>}
        </Box>}
    </>
  )

  return (
    <FilterDropdown
      title='Languages'
      filterCount={selectedLanguagesAny.length + selectedLanguagesAtleast.length}
      clearAll={clearAll}
      icon={<LanguageIcon sx={{ stroke: `${theme.palette.primary.main}` }} />}
      preview={preview}
      isOpen={isOpen}
      onToggle={onToggle}
    >
      <Stack direction='column' gap={2} mt={2}>
        <RadioGroup
          value={selectedMethod}
          onChange={(e) => {
            setSelectedMethod(e.target.value)
            // Clear both fields when switching
            handleSelectedLanguagesAny([])
            handleSelectedLanguagesAtleast([])
          }}
        >
          <Stack direction='column' gap={1}>
            {/* ANY languages section */}
            <RadioBtnCollapse selected={selectedMethod} value='any' label='Person speaks ANY of these'>
              <Stack sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 1 }}>
                <ComboBox
                  placeholder='Enter languages'
                  options={languages}
                  value={selectedLanguagesAny}
                  onChange={(e, newValue) => { handleSelectedLanguagesAny(newValue) }}
                  isDisabled={selectedMethod !== 'any'}
                />
              </Stack>
            </RadioBtnCollapse>

            {/* AT LEAST languages section */}
            <RadioBtnCollapse selected={selectedMethod} value='atleast' label='Person speaks AT LEAST'>
              <Stack sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 1 }}>
                <ComboBox
                  placeholder='Enter languages'
                  options={languages}
                  value={selectedLanguagesAtleast}
                  onChange={(e, newValue) => { handleSelectedLanguagesAtleast(newValue) }}
                  isDisabled={selectedMethod !== 'atleast'}
                />
              </Stack>
            </RadioBtnCollapse>
          </Stack>
        </RadioGroup>
      </Stack>
    </FilterDropdown>
  )
}
