import { useTheme } from '@mui/material'
export const PlayIcon = ({ color }) => {
  const theme = useTheme()
  const defaultColor = color || theme.palette.primary.primaryText
  return (
    <>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M17.9733 10.9373C19.3397 11.6988 19.3447 12.6568 17.9733 13.5177L7.37627 20.6645C6.04478 21.3751 5.14046 20.9556 5.04553 19.418L5.00057 4.45982C4.97059 3.04355 6.13721 2.6434 7.24887 3.32244L17.9733 10.9373Z'
          stroke={defaultColor}
          stroke-width='2'
        />
      </svg>
    </>
  )
}
