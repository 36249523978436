import React, { useEffect, useState } from 'react'
import { Box, Grid, TextField, Typography, Button, Popover, IconButton, useTheme } from '@mui/material'
import { HexColorPicker } from 'react-colorful'
import { useGetApiKeyMutation } from 'src/services/integration-service'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import toast from 'react-hot-toast'
import { config } from 'src/config.js'
import { useSelector } from 'react-redux'

const CustomizeConnector = ({ value }) => {
  const theme = useTheme()
  const [buttonText, setButtonText] = useState('Add Account')
  const [buttonColor, setButtonColor] = useState(theme.palette.primary.main)
  const [fontColor, setFontColor] = useState('#ffffff')
  const [anchorEl, setAnchorEl] = useState(null)
  const [colorPickerType, setColorPickerType] = useState('')
  const [apiKey, setApiKey] = useState('')
  const [getApiKey] = useGetApiKeyMutation()
  const workspace = useSelector((state) => state.workspace)

  const getApiKeyForCopy = async () => {
    const { apiKey } = await getApiKey({ workspace: workspace?._id }).unwrap()
    setApiKey(apiKey)
  }

  useEffect(() => {
    getApiKeyForCopy()
  }, [value])

  const handleColorButtonClick = (event, type) => {
    setAnchorEl(event.currentTarget)
    setColorPickerType(type)
  }

  const handleColorChange = (color) => {
    if (colorPickerType === 'button') {
      setButtonColor(color)
    } else if (colorPickerType === 'font') {
      setFontColor(color)
    }
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
    setColorPickerType('')
  }

  const open = Boolean(anchorEl)
  const id = open ? 'color-picker-popover' : undefined

  const generateHtmlCode = () => {
    return `
      <script>
        (function() {
            var button = document.createElement("button");
            button.innerHTML = "${buttonText}";
            button.style.position = "fixed";
            button.style.bottom = "15px";
            button.style.right = "15px";
            button.style.backgroundColor = "${buttonColor}";
            button.style.padding = "10px 20px";
            button.style.color = "${fontColor}";
            button.style.border = "none";
            button.style.borderRadius = "4px";
            button.style.cursor = "pointer";

            document.body.appendChild(button);

            button.addEventListener("click", function() {
                var apiKey = "${apiKey}";

                fetch('${config.API_BASE_URL}/authenticate', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'api_key': apiKey
                    },
                })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        showModal(data.pageUrl, data.token);
                    } else {
                        alert(data.error);
                    }
                })
                .catch(error => console.error('Error:', error));
            });

            function showModal(pageUrl, authToken) {
                const url = new URL(pageUrl);

                url.searchParams.append("iframe", "true");
                url.searchParams.append("token", authToken);
                var modal = document.createElement("div");
                modal.id = "customModal";
                modal.style.position = "fixed";
                modal.style.top = "0";
                modal.style.left = "0";
                modal.style.width = "100%";
                modal.style.height = "100%";
                modal.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
                modal.style.display = "flex";
                modal.style.justifyContent = "center";
                modal.style.alignItems = "center";

                var modalContent = document.createElement("div");
                modalContent.style.backgroundColor = "white";
                modalContent.style.padding = "20px";
                modalContent.style.borderRadius = "5px";
                modalContent.style.boxShadow = "0px 4px 8px rgba(0, 0, 0, 0.2)";
                modalContent.style.width = "80%";
                modalContent.style.height = "80%";

                var iframe = document.createElement("iframe");
                iframe.src = url.toString();
                iframe.style.width = "100%";
                iframe.style.height = "100%";
                iframe.style.border = "none";

                modalContent.appendChild(iframe);

                modal.appendChild(modalContent);

                document.body.appendChild(modal);

                modal.addEventListener("click", function(event) {
                    if (event.target === modal) {
                        document.body.removeChild(modal);
                    }
                });
            }
        })();
      </script>
    `
  }

  const copyToClipboard = async () => {
    try {
      if (!apiKey) {
        toast.error('Generate Your API Key First')
        return
      }
      await navigator.clipboard.writeText(generateHtmlCode())
      toast.success('HTML code copied to clipboard!')
    } catch (err) {
      console.error('Failed to copy text: ', err)
    }
  }

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '12px',
        p: 3,
        mt: 3,
        backgroundColor: '#ffffff',
        border: '1px solid #F2F4F7',
        boxShadow: 3
      }}
    >
      <Typography variant='h5' gutterBottom>Customize Button</Typography>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <Typography variant='h6' gutterBottom>Select Text</Typography>
          <TextField
            fullWidth
            value={buttonText}
            onChange={(e) => setButtonText(e.target.value)}
            sx={{ borderRadius: '6px', height: '56px' }}
            InputProps={{
              style: { borderRadius: '6px', height: '100%' }
            }}
          />
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 3 }}>
            <Button
              variant='contained'
              onClick={(event) => handleColorButtonClick(event, 'button')}
              sx={{
                backgroundColor: buttonColor,
                color: '#fff',
                marginRight: 2,
                '&:hover': {
                  color: '#ffffff'
                }
              }}
            >
              Button Background Color
            </Button>
            <Button
              variant='contained'
              onClick={(event) => handleColorButtonClick(event, 'font')}
              sx={{
                backgroundColor: fontColor,
                color: '#000',
                '&:hover': {
                  color: '#fff'
                }
              }}
            >
              Font Color
            </Button>
          </Box>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
          >
            <HexColorPicker color={colorPickerType === 'button' ? buttonColor : fontColor} onChange={handleColorChange} />
          </Popover>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant='h6' gutterBottom>Preview</Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100px',
              border: '1px dashed grey',
              marginBottom: 2,
              backgroundColor: '#fafafa',
              borderRadius: '8px'
            }}
          >
            <Button
              sx={{
                backgroundColor: buttonColor,
                color: fontColor
              }}
              variant='contained'
            >
              {buttonText}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography sx={{ lineHeight: '10px' }} variant='h6' gutterBottom>HTML/JS Code to Place</Typography>
          <Typography
            sx={{
              fontSize: '13px',
              fontWeight: 400,
              color: '#8181B0',
              marginBottom: '8px'
            }}
          >
            Place this script tag in the body of your HTML document.
          </Typography>
          <Box
            sx={{
              padding: 2,
              border: '1px dashed grey',
              backgroundColor: '#fafafa',
              borderRadius: '8px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Box sx={{ wordBreak: 'break-all' }}>{generateHtmlCode()}</Box>
            <IconButton onClick={copyToClipboard} sx={{ marginLeft: 1 }}>
              <ContentCopyIcon />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default CustomizeConnector
