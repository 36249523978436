import { config } from 'src/config'
import toast from 'react-hot-toast'
import { ArkoseCaptchaIntegration } from './ArkoseCaptchaIntegration'
import axios from 'axios'

const API_SERVICE_BACKEND = config.API_BASE_URL
const solveCodeCheckpoint = async (accountId, token) => {
  const options = {
    method: 'POST',
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      'X-API-KEY': config.UNIPILE_ACCESS_TOKEN
    },
    body: JSON.stringify({ provider: 'LINKEDIN', account_id: accountId, code: token })
  }

  const resp = await fetch(`${config.UNIPILE_DSN}/api/v1/accounts/checkpoint`, options)
  const response = await resp.json()
  return response
}
const handleStoreWorkspaceId = async (linkedInEmail, workspaceId, userEmail) => {
  const response = await axios.post(`${API_SERVICE_BACKEND}/multichannel/storeWorkSpaceId`, {
    email: linkedInEmail,
    workspace_id: workspaceId,
    userEmail
  })
  if (response.data.success) {
    return true
  }
  return false
}
export const linkedinConnector = async (
  setIsLinkedInConnecting,
  linkedInEmail,
  linkedInPassword,
  setOpenConnectLinkedInAccountModal,
  clearFields,
  workspaceId,
  userEmail,
  isReconnect,
  reconnectingAccountId
) => {
  setIsLinkedInConnecting(true)

  if (!isReconnect) {
    const userInfoStored = await handleStoreWorkspaceId(linkedInEmail, workspaceId, userEmail)
    if (!userInfoStored) {
      toast.error('Error storing workspace id')
      return
    }
  }
  const connectionSuccessString = isReconnect ? 'AccountReconnected' : 'AccountCreated'
  const connectionSuccessMessage = isReconnect ? 'Account Reconnected Successfully' : 'Account Created Successfully'
  const options = {
    method: 'POST',
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      'X-API-KEY': config.UNIPILE_ACCESS_TOKEN
    },
    body: JSON.stringify({
      provider: 'LINKEDIN',
      username: linkedInEmail,
      password: linkedInPassword
    })
  }
  let url = `${config.UNIPILE_DSN}/api/v1/accounts`
  if (isReconnect) {
    url = url + `/${reconnectingAccountId}`
  }
  const response = await fetch(url, options)
  const data = await response.json()

  if (data?.account_id && data.object === connectionSuccessString) {
    toast.success(connectionSuccessMessage)
    setOpenConnectLinkedInAccountModal(false)
    if (!isReconnect) clearFields()
  } else {
    if (data.object === 'Checkpoint' && data.checkpoint.type === 'IN_APP_VALIDATION') {
      toast(
        'Open your linkedin app and verify the connection',
        {
          duration: 10000
        }
      )
      setOpenConnectLinkedInAccountModal(false)
      if (!isReconnect) clearFields()
    } else if (data.object === 'Checkpoint' && ['2FA', 'OTP'].includes(data.checkpoint.type)) {
      const otp = window.prompt('Enter the OTP sent to your phone')
      const otpCheckpointResponse = await solveCodeCheckpoint(data.account_id, otp)
      if (otpCheckpointResponse?.object === connectionSuccessString) {
        toast.success(connectionSuccessMessage)
        setOpenConnectLinkedInAccountModal(false)
        if (!isReconnect) clearFields()
      } else toast.error(otpCheckpointResponse?.detail || 'please try again')
    } else if (data.object === 'Checkpoint' && data.checkpoint.type === 'CAPTCHA') {
      const publicKey = data.checkpoint.public_key
      const binary = data.checkpoint.data
      const captcha = new ArkoseCaptchaIntegration(publicKey, binary)
      captcha.onLoaded = () => {
        console.log('Captcha has been successfully loaded into the DOM !')
      }
      captcha.onSuccess = async (token) => {
        toast.success('Captcha has been resolved successfully !')
        const solvedCheckpointResponse = await solveCodeCheckpoint(data.account_id, token)
        if (solvedCheckpointResponse?.object === connectionSuccessString) {
          toast.success(connectionSuccessMessage)
          setOpenConnectLinkedInAccountModal(false)
          if (!isReconnect) clearFields()
        } else if (solvedCheckpointResponse?.object === 'Checkpoint' && solvedCheckpointResponse?.checkpoint?.type === 'OTP') {
          const otp = window.prompt('Please enter the OTP sent to your email')
          const otpCheckpointResponse = await solveCodeCheckpoint(solvedCheckpointResponse.account_id, otp)
          if (otpCheckpointResponse?.object === connectionSuccessString) {
            toast.success(connectionSuccessMessage)
            setOpenConnectLinkedInAccountModal(false)
            if (!isReconnect) clearFields()
          } else toast.error(otpCheckpointResponse?.detail || 'please try again')
        } else if (solvedCheckpointResponse?.object === 'Checkpoint' && solvedCheckpointResponse?.checkpoint?.type === 'PHONE_REGISTER') {
          const phone = window.prompt('Enter the associated phone number with country code (e.g. (+1)1234567890)')
          const phoneCheckpointResponse = await solveCodeCheckpoint(solvedCheckpointResponse.account_id, phone)
          if (phoneCheckpointResponse?.object === 'Checkpoint' && ['2FA', 'OTP'].includes(phoneCheckpointResponse?.checkpoint?.type)) {
            const otp = window.prompt('Enter the OTP sent to your phone')
            const otpCheckpointResponse = await solveCodeCheckpoint(phoneCheckpointResponse.account_id, otp)
            if (otpCheckpointResponse?.object === connectionSuccessString) {
              toast.success(connectionSuccessMessage)
              setOpenConnectLinkedInAccountModal(false)
              if (!isReconnect) clearFields()
            } else toast.error(otpCheckpointResponse?.detail || 'please try again')
          } else if (phoneCheckpointResponse?.object === connectionSuccessString) {
            toast.success(connectionSuccessMessage)
            setOpenConnectLinkedInAccountModal(false)
            if (!isReconnect) clearFields()
          } else toast.error(phoneCheckpointResponse?.detail || 'please try again')
        } else if (solvedCheckpointResponse?.object === 'Checkpoint' && solvedCheckpointResponse?.checkpoint?.type === '2FA') {
          const otp = window.prompt('Enter the OTP sent to your phone')
          const otpCheckpointResponse = await solveCodeCheckpoint(solvedCheckpointResponse.account_id, otp)
          if (otpCheckpointResponse?.object === connectionSuccessString) {
            toast.success(connectionSuccessMessage)
            setOpenConnectLinkedInAccountModal(false)
            if (!isReconnect) clearFields()
          } else toast.error(otpCheckpointResponse?.detail || 'please try again')
        } else if (solvedCheckpointResponse?.object === 'Checkpoint' && solvedCheckpointResponse?.checkpoint?.type === 'IN_APP_VALIDATION') {
          toast(
            'Open your linkedin app and verify the connection',
            {
              duration: 10000
            }
          )
          setOpenConnectLinkedInAccountModal(false)
          if (!isReconnect) clearFields()
        } else toast.error('please try again')
        captcha.unload()
      }

      captcha.onError = () => {
        toast.error('Captcha has been failed !')
      }
      captcha.load('captcha-frame')
    } else {
      toast.error(data?.title || 'something went wrong. please try again later')
    }
  }
}
