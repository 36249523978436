import { createSvgIcon } from '@mui/material/utils'

export const ExportIcon = createSvgIcon(
  <svg viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M14 8V10.8C14 11.9201 14 12.4802 13.782 12.908C13.5903 13.2843 13.2843 13.5903 12.908 13.782C12.4802 14 11.9201 14 10.8 14H5.2C4.07989 14 3.51984 14 3.09202 13.782C2.71569 13.5903 2.40973 13.2843 2.21799 12.908C2 12.4802 2 11.9201 2 10.8V8M10.6667 4.66667L8 2M8 2L5.33333 4.66667M8 2V10' stroke='#8181B0' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round' />
  </svg>
)

export const ExportBlueIcon = createSvgIcon(
  <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='22' height='22' rx='3' fill='#E7F0FF' />
    <path d='M16.25 11V13.45C16.25 14.4301 16.25 14.9201 16.0593 15.2945C15.8915 15.6238 15.6238 15.8915 15.2945 16.0593C14.9201 16.25 14.4301 16.25 13.45 16.25H8.55C7.56991 16.25 7.07986 16.25 6.70552 16.0593C6.37623 15.8915 6.10852 15.6238 5.94074 15.2945C5.75 14.9201 5.75 14.4301 5.75 13.45V11M13.3333 8.08333L11 5.75M11 5.75L8.66667 8.08333M11 5.75V12.75' stroke='#3F4FF8' stroke-width='1.4' stroke-linecap='round' stroke-linejoin='round' />
  </svg>
)
