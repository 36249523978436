import React from 'react'
import { useTheme } from '@mui/material'

export const EDSGrowthIcon = () => {
  const theme = useTheme()
  return (
    <>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M2.39844 2.3999V21.5999H21.5984M7.19844 14.4L11.3984 10.2L14.3984 13.2L20.9984 6.60003M16.5082 5.9999H21.5993V11.0911'
          stroke={theme.palette.primary.primaryText}
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </svg>
    </>
  )
}
