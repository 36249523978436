export function LightningSvg({ stroke = "#3F4FF8" }) {
  return (
    <svg width="24" height="24" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.7057 1.66797H5.90935C5.75978 1.66797 5.685 1.66797 5.61898 1.69074C5.56059 1.71088 5.50741 1.74375 5.46329 1.78696C5.4134 1.83583 5.37995 1.90272 5.31306 2.03649L1.81306 9.03649C1.65333 9.35596 1.57346 9.51569 1.59265 9.64554C1.6094 9.75892 1.67211 9.86039 1.76603 9.92608C1.87359 10.0013 2.05217 10.0013 2.40935 10.0013H7.58066L5.08066 18.3346L15.2416 7.79739C15.5844 7.44189 15.7558 7.26414 15.7658 7.11204C15.7745 6.98002 15.72 6.85171 15.6189 6.76633C15.5025 6.66797 15.2555 6.66797 14.7617 6.66797H8.83066L10.7057 1.66797Z"
        stroke={stroke}
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export function FreeIcon({ stroke = "#3F4FF8" }) {
  return (
    <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.9987 1.33203V14.6654M12.9987 2.9987L2.9987 12.9987M14.6654 7.9987H1.33203M12.9987 12.9987L2.9987 2.9987"
        stroke={stroke}
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export function GrowthIcon({ stroke = "#3F4FF8" }) {
  return (
    <svg width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.99829 8.99841H9.00663M11.9446 11.9447C8.03933 15.8499 3.55441 17.6967 1.92722 16.0695C0.30004 14.4423 2.14677 9.95737 6.05201 6.05213C9.95726 2.14689 14.4422 0.300158 16.0694 1.92734C17.6965 3.55453 15.8498 8.03945 11.9446 11.9447ZM11.9445 6.05198C15.8498 9.95722 17.6965 14.4421 16.0693 16.0693C14.4421 17.6965 9.95722 15.8498 6.05198 11.9445C2.14674 8.0393 0.300007 3.55438 1.92719 1.92719C3.55438 0.300007 8.0393 2.14674 11.9445 6.05198ZM9.41496 8.99841C9.41496 9.22853 9.22841 9.41508 8.99829 9.41508C8.76817 9.41508 8.58163 9.22853 8.58163 8.99841C8.58163 8.76829 8.76817 8.58174 8.99829 8.58174C9.22841 8.58174 9.41496 8.76829 9.41496 8.99841Z"
        stroke={stroke}
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export function InfinityIcon({ stroke = "#3F4FF8" }) {
  return (
    <svg width="24" height="24" viewBox="0 0 28 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.9513 4.54936C13.7131 8.57834 12.0521 12.1532 8.30165 14.0701C6.87346 14.8001 5.0063 15.0143 3.40477 15.0143C3.14168 15.0143 1.91659 14.7521 2.0045 14.7468C6.68809 14.4622 10.4921 18.4399 12.2192 22.2375C12.7802 23.4712 13.4335 24.6465 13.6923 25.9671C13.8879 26.9656 13.8056 28.0035 13.8056 29.0201C13.8056 29.2146 13.8845 30.3341 13.7732 29.9013C13.633 29.3561 13.6599 28.8301 13.6599 28.2647C13.6599 27.0659 13.45 25.8383 13.5466 24.6452C13.7941 21.5862 15.1772 18.0005 18.0307 16.3362C19.2382 15.6319 21.1886 15.829 22.539 15.754C23.4131 15.7054 24.2407 15.9041 25.1048 15.99C25.1569 15.9952 26.1842 15.9972 25.9709 16.1631C25.772 16.3178 25.6027 16.3047 25.3476 16.3047C24.0198 16.3047 22.8283 16.2828 21.6082 15.7225C19.0939 14.5677 17.4383 12.8813 15.991 10.5766C14.9566 8.92942 14.0363 6.88316 13.9513 4.92704C13.9152 4.09683 13.2939 2.74618 13.8056 2"
        stroke={stroke}
        stroke-width="3"
        stroke-linecap="round"
      />
    </svg>
  );
}
