import { useTheme } from '@mui/material'

export const OnRadioCustomIcon = () => {
  const theme = useTheme()
  return (
    <>
      <svg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <rect x='0.5' y='1' width='19' height='19' rx='9.5' fill={theme.palette.primary.light} />
        <rect x='0.5' y='1' width='19' height='19' rx='9.5' stroke={theme.palette.primary.main} />
        <circle cx='10' cy='10.5' r='4' fill={theme.palette.primary.main} />
      </svg>
    </>
  )
}
