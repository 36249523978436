import { Box } from '@mui/material'

export const sections = [
  {
    title: 'Credits',
    Points: [
      'Email Sending',
      'Contact Lookups',
      'Saved Profiles (Person or Company ALL assiciated emails, phones, etc)',
      'Uploaded Contacts',
      'Contact Exports',
      'AI Writer Credits',
      'Data Enrichment'
    ],
    featuresValue: [
      ['Unlimited', 'Unlimited', '20,000 /mo', '1,000 /mo', '100 /mo', '300 /mo', '✖'],
      ['Unlimited', 'Unlimited', '50,000 / mo', '20,000 /mo', '1,200 /mo', '5,000 /mo', '✔'],
      ['Unlimited', 'Unlimited', '100,000 /mo', '100,000 /mo', '5,000 /mo', ' 10,000 /mo', '✔'],
      ['Unlimited', 'Unlimited', 'Unlimited', 'Unlimited', 'Unlimited', 'Unlimited', '✔']
    ]
  },
  {
    title: 'Lead Finder',
    Points: [
      'Buying Intent Data (Topics)',
      'Keywords',
      'Revenue',
      'Funding',
      'Technologies',
      'Custom Lead Lists',
      'CSV Export'
    ],
    featuresValue: [
      ['1', '✖', '✖', '✖', '✖', 'Unlimited', '✔'],
      ['6', '✔', '✔', '✖', '✖', 'Unlimited', '✔'],
      ['6', '✔', '✔', '✔', '✔', 'Unlimited', '✔'],
      ['12', '✔', '✔', '✔', '✔', 'Unlimited', '✔']
    ]
  },
  {
    title: 'Campaigns & Sequences',
    Points: [
      'User Brought Mailboxes',
      'A/B Testing',
      'A/Z Testing',
      'Spintax',
      'Sender Rotation',
      'Time-Delayed Sending',
      'SMTP Mailbox Connection',
      'Custom Domain Tracking',
      'Global Blocklist'

    ],
    featuresValue: [
      ['1', '✔', '✖', '✖', '✖', '✔', '✔', '✖', '✖'],
      [
        '5',
        '✔',
        '✖',
        '✔',
        '✖',
        '✔',
        '✔',
        '✔',
        '✔'
      ],
      [
        '20',
        '✔',
        '✔',
        '✔',
        '✔',
        '✔',
        '✔',
        '✔',
        '✔'
      ],
      [
        '100',
        '✔',
        '✔',
        '✔',
        '✔',
        '✔',
        '✔',
        '✔',
        '✔'
      ]
    ]
  },
  {
    title: 'Multi Channel Outreach',
    Points: [
      'LinkedIn Accounts',
      'SMS Accounts',
      'WhatsApp Accounts'
    ],
    featuresValue: [
      ['Add-On', 'Add-On', 'Add-On'],
      ['1', 'Add-On', 'Add-On'],
      ['1', '1', 'Add-On'],
      ['1', '1', '1']
    ]
  },
  {
    title: 'Inbox & CRM',
    Points: [
      'Unified Inbox',
      'AI Auto Replies'

    ],
    featuresValue: [
      ['✔', '✖'],
      ['✔', '✖'],
      ['✔', '✔'],
      ['✔', '✔']
    ]
  },
  {
    title: 'Deal Pipeline',
    Points: [
      'Pipeline(s)',
      'Custom Variables',
      'Custom Column',
      'Deal Pipeline Activity'

    ],
    featuresValue: [
      ['1', '✖', '✖', '✖'],
      ['5', '✖', '✖', '✖'],
      ['10', '✖', '✖', '✖'],
      ['Unlimited', '✔', '✔', '✔']
    ]
  },
  {
    title: 'Analytics & Reporting',
    Points: ['Global Analytics', 'Campaign Level Analytics'],
    featuresValue: [
      ['✔', '✔'],
      ['✔', '✔'],
      ['✔', '✔'],
      ['✔', '✔']
    ]
  },
  {
    title: 'Additional Features',
    Points: ['Warm Up (limited by email accounts)', 'Workspaces', 'Team Functionality'],
    featuresValue: [
      ['Unlimited', '✖', '✖'],
      ['Unlimited', '✔', '✔'],
      ['Unlimited', '✔', '✔'],
      ['Unlimited', '✔', '✔']
    ]
  },
  {
    title: 'Integrations',
    Points: ['Zapier', 'Webhooks', 'API', 'Hubspot', 'Salesforce', 'Other Integrations'],
    featuresValue: [
      ['✖', '✖', '✖', '✖', '✖', '✖'],
      ['✔', '✖', '✖', '✖', '✖', '✔'],
      ['✔', '✔', '✔', '✔', '✖', '✔'],
      ['✔', '✔', '✔', '✔', '✔', '✔']
    ]
  },
  {
    title: 'Support & Knowledge',
    Points: ['Live Chat Support', 'Help Center'],
    featuresValue: [
      ['✔', '✔'],
      ['✔', '✔'],
      ['✔', '✔'],
      ['✔', '✔']
    ]
  }
]
export const plans = [
  {
    name: (
      <>
        <Box
          component='img'
          src={`${process.env.PUBLIC_URL}/assets/SettingsImages/Icon.png`}
          alt='Free Plan Icon'
          sx={{
            width: 15,
            height: 15,
            verticalAlign: 'middle',
            mr: 1
          }}
        />
        Free
      </>
    ),
    price: '$0',
    features: ['✖', '✖', '✖', '✖', '✖', '✖'],
    currentPlan: true
  },
  {
    name: (
      <>
        <Box
          component='img'
          src={`${process.env.PUBLIC_URL}/assets/SettingsImages/Icon-start.png`}
          alt='Free Plan Icon'
          sx={{
            width: 15,
            height: 15,
            verticalAlign: 'middle',
            mr: 1
          }}
        />
        Starter
      </>
    ),
    price: '$35',
    features: ['Unlimited', '1,200', '1,200', '✔', '✖', '✔']
  },
  {
    name: (
      <>
        <Box
          component='img'
          src={`${process.env.PUBLIC_URL}/assets/SettingsImages/Icon-growth.png`}
          alt='Free Plan Icon'
          sx={{
            width: 15,
            height: 15,
            verticalAlign: 'middle',
            mr: 1
          }}
        />
        Growth
      </>
    ),

    price: '$59',
    features: ['Unlimited', '5,000', '5,000', '✔', '✔', '✔']
  },
  {
    name: (
      <>
        <Box
          component='img'
          src={`${process.env.PUBLIC_URL}/assets/SettingsImages/Vector 389.png`}
          alt='Free Plan '
          sx={{
            width: 15,
            height: 15,
            verticalAlign: 'middle',
            mr: 1
          }}
        />
        Infinity
      </>
    ),

    price: '$89',
    features: ['Unlimited', 'Unlimited', 'Unlimited', '✔', '✔', '✔'],
    bestValue: true
  }
]
