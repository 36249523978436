import { createSlice } from '@reduxjs/toolkit'
import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from 'src/utils/base-query.js'

export const dnsSlice = createSlice({
  name: 'dns',
  initialState: {
    vitals: {}
  },
  reducers: {
    setDnsVitals (state, action) {
      state.vitals = action.payload
    }
  }
})

export const { setDnsVitals } = dnsSlice.actions

export const dnsApi = createApi({
  reducerPath: 'dnsApi',
  baseQuery,
  endpoints: (builder) => ({
    getDnsVitals: builder.mutation({
      query: (data) => {
        return {
          url: 'dns/vitals',
          method: 'POST',
          body: data
        }
      },
      transformResponse: ({ dnsRecord }) => {
        const { failure_list: failureList, success_list: successList, inProgress_list: inProgressList } = dnsRecord
        const records = {}
        failureList.forEach((item) => (records[item.domain] = item))
        successList.forEach((item) => (records[item.domain] = item))
        inProgressList.forEach((item) => (records[item.domain] = item))
        return {
          records,
          failureCount: failureList.length,
          successCount: successList.length,
          pending: inProgressList.length
        }
      }
    }),
    checkCname: builder.mutation({
      query: (data) => {
        return {
          method: 'POST',
          url: 'dns/check_domain_dns',
          body: data
        }
      }
    }),
    checkSsl: builder.mutation({
      query: (data) => {
        return {
          method: 'POST',
          url: 'dns/check_domain_ssl',
          body: data
        }
      }
    })
  })
})

export const { useGetDnsVitalsMutation, useCheckCnameMutation, useCheckSslMutation } = dnsApi
