export const LABounceIcon = () => {
  return (
    <>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M8 4.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0-.5.5v6a.5.5 0 0 0 1 0V5.707l6.397 6.397a.5.5 0 0 0 .707 0l5.75-5.75a.5.5 0 0 0-.708-.707L8.75 11.043L2.707 5H7.5a.5.5 0 0 0 .5-.5'
          stroke='#00AA38'
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </svg>
    </>
  )
}
