import React, { useState } from 'react'
import {
  Box,
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress
} from '@mui/material'
import DataNotFoundImage from '../../assets/no_data_found_image.png'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useCnameLookUpMutation } from 'src/services/tool-service.js'
import { Helmet } from 'react-helmet-async'

const CnamePage = () => {
  const [domainDetails, setDomainDetails] = useState([])
  const [cnameLookUp] = useCnameLookUpMutation()

  const validationSchema = () => {
    return Yup.object({
      domain: Yup.string()
        .max(255)
        .required('Domain is required')
        .matches(
          /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/,
          'Please add a valid Domain address'
        )
    })
  }

  const handleDomainDataSubmit = async (values, helpers) => {
    try {
      const result = await cnameLookUp(values).unwrap()
      if (result.message === 'Fetched Error') {
        setDomainDetails((prevDetails) => [
          ...prevDetails,
          { domain: formik?.values?.domain, points_to: '' }
        ])
      } else {
        setDomainDetails((prevDetails) => [
          ...prevDetails,
          { domain: formik?.values?.domain, points_to: result.cnameRecords }
        ])
      }
    } catch (err) {
      helpers.setErrors({ submit: err.data.error.message })
    }
  }

  const formik = useFormik({
    initialValues: {
      domain: ''
    },
    validationSchema,
    onSubmit: async (values, helpers) => {
      try {
        await handleDomainDataSubmit(values, helpers)
      } catch (err) {
        helpers.setErrors({ submit: err.data.error.message })
      }
      formik.resetForm()
    }
  })

  return (
    <Box sx={{ px: { sm: '7%' }, py: { sm: 5 }, p: 4, fontFamily: 'Inter' }}>
      <Helmet>
        <title>CNAME Check Tool - Find Your Domain's Canonical Name</title>
        <meta name='description' content='Easily find your domain’s CNAME record with Success.ai’s CNAME Check Tool. Enter your domain to view the canonical name instantly!' />
      </Helmet>
      <Typography variant='h5' sx={{ fontWeight: 'bold', color: 'primary.main' }}>
        CNAME Check Tool: Discover Your Domain's Canonical Name Instantly
      </Typography>
      <Typography color='primary.main' sx={{ fontSize: '0.8rem' }}>
        Find the canonical name associated with your domain using the CNAME record.
      </Typography>

      {/* Cname Form */}
      <form noValidate onSubmit={formik.handleSubmit}>
        <Box sx={{ display: 'flex', alignItems: 'baseline', mt: 3, flexDirection: { xs: 'column', sm: 'row' } }}>
          <Box sx={{ display: 'flex', gap: { sm: 2 }, flexDirection: { xs: 'column', sm: 'row' }, mb: { xs: 3, sm: 0 } }}>
            <TextField
              variant='outlined'
              placeholder='Enter a domain address'
              onChange={formik.handleChange}
              error={!!(formik.touched.domain && formik.errors.domain)}
              helperText={formik.touched.domain && formik.errors.domain}
              onBlur={formik.handleBlur}
              name='domain'
              value={formik?.values?.domain}
              sx={{
                borderColor: 'gray.300',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  width: { sm: '21vw', xs: '55vw' },
                  maxHeight: '44px',
                  color: 'primary.main'
                },
                mb: { xs: 3, sm: 0 }
              }}
            />
          </Box>
          <Button
            variant='contained'
            color='primary'
            sx={{
              ml: [0, 4],
              px: 4,
              py: 2,
              width: { sm: 'fit-content', xs: '100%' },
              maxHeight: '44px',
              cursor: formik?.values?.domain ? 'pointer' : 'not-allowed',
              padding: '10px',
              marginLeft: { xs: '7px', sm: '4' }
            }}
            disabled={!formik?.values?.domain}
            type='submit'
          >
            {formik.isSubmitting
              ? (
                <CircularProgress size={20} color='inherit' />
                )
              : (
                <>Look up</>
                )}
          </Button>
        </Box>
      </form>

      {/* Result Table */}
      <Box
        sx={{
          height: 'auto',
          mt: { sm: 6, xs: 6 },
          border: 8,
          borderColor: '#ECEDF5',
          borderRadius: '8px'
        }}
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow sx={{ backgroundColor: '#F6F6FA', color: '#9295A7', fontSize: '1.2rem', fontWeight: 'medium' }}>
                <TableCell sx={{ px: 4, py: 2 }}>DOMAIN NAME</TableCell>
                <TableCell sx={{ px: 4, py: 2 }}>POINTS TO</TableCell>
                <TableCell sx={{ px: 4, py: 2 }}>TEST</TableCell>
                <TableCell sx={{ px: 4, py: 2 }}>RESULT</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(domainDetails) && domainDetails.length === 0
                ? (
                  <TableRow>
                    <TableCell colSpan={5} sx={{ px: 4, py: 2, textAlign: 'center' }}>
                      <Typography sx={{ mt: { sm: '5vh', xs: '2vh' }, fontSize: '0.8rem' }}>
                        Simply enter a domain or IP address, click &quot;Look up&quot;, and get instant results.
                      </Typography>
                      <Box sx={{ display: 'flex', justifyContent: 'center', mt: '10vh' }}>
                        <img src={DataNotFoundImage} alt='Doc Icon' />
                      </Box>
                    </TableCell>
                  </TableRow>
                  )
                : (
                    Array.isArray(domainDetails) && domainDetails.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell className='text-link'>
                          {row.domain}
                        </TableCell>
                        <TableCell>
                          <Box component='span' sx={{ display: 'flex', alignItems: 'center' }}>
                            {row.points_to ? row.points_to : '---'}
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box component='span' sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            {row.points_to
                              ? (
                                <CheckCircleIcon sx={{ color: '#34B24E' }} />
                                )
                              : (
                                <CancelIcon sx={{ color: '#FF0000' }} />
                                )}
                            DNS Record Published
                          </Box>
                        </TableCell>
                        <TableCell>
                          DNS Record {row.points_to ? 'Found' : 'Not Found'}
                        </TableCell>
                      </TableRow>
                    ))
                  )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  )
}

export default CnamePage
