import React from 'react'
import { MenuItem, Select, TableCell } from '@mui/material'
import { useUpdateAssignedPlanMutation } from 'src/services/partnerUser-service'
import { useSelector } from 'react-redux'
import { useGetCustomPlansQuery } from 'src/services/customPlan-service'
import toast from 'react-hot-toast'

const PlanSelector = ({ row, setRefetchUser }) => {
  const user = useSelector((state) => state.user)
  const { data: planNames } = useGetCustomPlansQuery({ PartnerId: user._id })
  const [updateAssignedPlan] = useUpdateAssignedPlanMutation()
  const currentPlan = row.plan?.customPlanId || ''

  const handlePlanChange = async (event) => {
    const newPlanId = event.target.value
    try {
      const { message } = await updateAssignedPlan({
        userId: row.id,
        planId: newPlanId
      }).unwrap()

      setRefetchUser(true)
      toast.success(message || 'Plan Assigned')
    } catch ({ data: error }) {
      toast.error(`${error.status || 'Error'}: ${error.message || 'Falied to update Plan!'}`)
    }
  }

  return (
    <TableCell align='center'>
      <Select
        value={currentPlan}
        onChange={handlePlanChange}
        size='small'
        displayEmpty
        sx={{
          fontSize: '14px',
          minWidth: '120px',
          '& .MuiSelect-select': {
            padding: '6px 10px'
          }
        }}
      >
        <MenuItem value='' sx={{ fontSize: '14px' }} disabled>
          Choose Plan
        </MenuItem>
        {planNames?.customPlan?.map((plan) => (
          <MenuItem
            key={plan._id}
            value={plan._id}
            sx={{ fontSize: '14px' }}
          >
            {plan.planName}
          </MenuItem>
        ))}
      </Select>

    </TableCell>
  )
}

export default PlanSelector
