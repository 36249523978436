import { createSvgIcon } from '@mui/material/utils'

export const Starwhite = createSvgIcon(
  <svg width='' height='' viewBox='0 0 46 39' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M15.8446 1.75H30.1554C32.1113 1.75 33.933 2.74421 34.9912 4.3891L42.1462 15.5113C43.3081 17.3175 43.3666 19.6208 42.2977 21.4837L34.9089 34.3616C33.8839 36.1482 31.9814 37.25 29.9216 37.25H16.0784C14.0186 37.25 12.1161 36.1482 11.0911 34.3616L3.70225 21.4837C2.6334 19.6208 2.69186 17.3175 3.85385 15.5113L11.0088 4.3891C12.067 2.74421 13.8887 1.75 15.8446 1.75Z' stroke='#fff' stroke-width='2.5' />
    <path d='M16.7071 13.7929L13.436 17.064C12.8494 17.6506 13.1911 18.6554 14.0138 18.7627L22.6293 19.8864C23.1274 19.9514 23.5 20.3757 23.5 20.878V28.5858C23.5 29.4767 24.5771 29.9229 25.2071 29.2929L29.2071 25.2929C29.3946 25.1054 29.5 24.851 29.5 24.5858V22.3699C29.5 22.1312 29.5854 21.9004 29.7407 21.7191L32.2593 18.7809C32.4146 18.5996 32.5 18.3688 32.5 18.1301V11C32.5 10.4477 32.0523 10 31.5 10H24.9142C24.649 10 24.3946 10.1054 24.2071 10.2929L21.2929 13.2071C21.1054 13.3946 20.851 13.5 20.5858 13.5H17.4142C17.149 13.5 16.8946 13.6054 16.7071 13.7929Z' fill='#fff' stroke='#fff' />
    <path d='M14.8392 24.3039L14.2392 27.3039C14.1155 27.9227 14.5888 28.5 15.2198 28.5H18.1802C18.6569 28.5 19.0673 28.1635 19.1608 27.6961L19.7608 24.6961C19.8845 24.0773 19.4112 23.5 18.7802 23.5H15.8198C15.3431 23.5 14.9327 23.8365 14.8392 24.3039Z' fill='#fff' stroke='#fff' />
  </svg>

)
