import React, { useState } from 'react'
import {
  Box,
  Typography,
  TextField,
  Container,
  Button,
  useTheme
} from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { setSEODetails, useCreateSEOMutation } from 'src/services/theme-service'
import toast from 'react-hot-toast'

const SEOMetadataForm = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [createSEO] = useCreateSEOMutation()
  const { seo } = useSelector((state) => state.partnerDetails)

  // Formik setup
  const formik = useFormik({
    initialValues: {
      title: seo.title || '',
      description:
        seo.description || ''
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      title: Yup.string().required('Meta title is required')
    }),
    onSubmit: async (values) => {
      const payload = {
        title: values.title,
        description: values.description
      }
      setLoading(true)
      try {
        const { message, seo: updatedData } = await createSEO({ seo: payload }).unwrap()
        if (updatedData) {
          dispatch(setSEODetails({ ...updatedData }))
        }
        toast.success(`${message || 'SEO Metadata updated successfully!'}`)
      } catch ({ data: error }) {
        toast.error(`${error.status || 'Error'}: ${error.message || 'Falied to update SEO metadata!'}`)
        return
      } finally {
        setLoading(false)
      }
    }
  })

  return (
    <Container maxWidth='md'>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          gap: { xs: 3, md: 4 }
        }}
        component='form'
        onSubmit={formik.handleSubmit}
      >
        {/* Left column */}
        <Box
          sx={{
            width: { xs: '100%', md: '40%' }
          }}
        >
          <Typography
            variant='h5'
            component='h1'
            sx={{
              color: theme.palette.primary.main,
              fontSize: { xs: '1.25rem', md: '1.5rem' },
              mb: 1
            }}
          >
            SEO
          </Typography>
          <Typography
            sx={{
              color: 'text.secondary',
              fontSize: '0.875rem',
              mb: { xs: 2, md: 6 }
            }}
          >
            How your Data Storefront's home page will be displayed on search
            engine result pages
          </Typography>
        </Box>

        {/* Right column */}
        <Box
          sx={{
            width: { xs: '100%', md: '60%' },
            display: 'flex',
            flexDirection: 'column',
            gap: 3
          }}
        >
          <Box>
            <Typography
              sx={{
                color: theme.palette.primary.main,
                fontSize: '0.875rem',
                mb: 1
              }}
            >
              Meta title
            </Typography>
            <TextField
              fullWidth
              size='small'
              name='title'
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title}
              sx={{
                backgroundColor: 'white',
                '& .MuiOutlinedInput-root': {
                  fontSize: '14px',
                  borderRadius: '6px',
                  backgroundColor: 'white'
                }
              }}
            />
          </Box>

          <Box>
            <Typography
              sx={{
                color: theme.palette.primary.main,
                fontSize: '0.875rem',
                mb: 1
              }}
            >
              Meta description
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={4}
              name='description'
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
              sx={{
                backgroundColor: 'white',
                '& .MuiOutlinedInput-root': {
                  fontSize: '14px',
                  borderRadius: '6px',
                  backgroundColor: 'white'
                }
              }}
            />
          </Box>

          <Button
            variant='contained'
            type='submit'
            disabled={loading}
            sx={{
              alignSelf: 'flex-end',
              bgcolor: theme.palette.primary.main,
              '&:hover': {
                bgcolor: theme.palette.primary.dark
              }
            }}
          >
            {loading ? 'Saving...' : 'Save'}
          </Button>
        </Box>
      </Box>
    </Container>
  )
}

export default SEOMetadataForm
