import { useCallback, useEffect, useState } from 'react'
import {
  TableContainer,
  Box,
  InputAdornment,
  OutlinedInput,
  SvgIcon,
  Table,
  Typography,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Button,
  Pagination,
  Tab
  , Tabs
} from '@mui/material'
// import SearchMdIcon from "@untitled-ui/icons-react/build/esm/SearchMd";
import { CustomDropdown } from './statusList'
import { LanguageOutlined } from '@mui/icons-material'
import SearchIcon from '@mui/icons-material/Search'
import Socialicon from 'src/icons/socialicon.png'
import checkIcon from 'src/icons/checkIcon.png'
import PendingIcon from 'src/icons/pendingIcon.png'
import unCheckIcon from 'src/icons/unCheckIcon.png'
import axios from 'axios'
import { config } from 'src/config'
import { useNavigate } from 'react-router-dom'
import { paths } from 'src/paths'
import moment from 'moment'
import { Stack } from '@mui/system'
// import { getAuthEmail } from "src/services/auth-service";
import { useGetMeQuery } from 'src/services/user-service'
function CustomTabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

const statusList = [
  {
    label: 'All Status',
    // icon: checkIcon,
    color: '#12B981',
    value: ''
  },
  {
    label: 'Completed',
    icon: checkIcon,
    color: '#12B981',
    value: 'Completed'
  },
  {
    label: 'Pending',
    icon: PendingIcon,
    color: '#FF5C1F',
    value: 'Pending'
  },
  {
    label: 'Canceled',
    icon: unCheckIcon,
    color: '#E20E0E',
    value: 'cancelled'
  }
]

const tabs = [
  {
    label: 'Domains',
    value: 'domains'
  },
  {
    label: 'Emails',
    value: 'emails'
  }
]
const scrollBarStyle = {
  '&::-webkit-scrollbar': {
    width: '14px',
    height: '14px'
  },
  '&::-webkit-scrollbar-track': {
    borderRadius: '60px'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#E4E4E5',
    borderRadius: '10px',
    border: '4px solid rgba(0, 0, 0, 0)',
    backgroundClip: 'padding-box'
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#d5d5d5'
  }
}

const OrderStatus = () => {
  const { data: user } = useGetMeQuery()
  const API_SERVICE_BACKEND = config.API_BASE_URL
  const [searchText, setSearchText] = useState('')

  const [status, setStatus] = useState('')
  const [debouncedSearchText, setDebouncedSearchText] = useState(searchText)
  const clientEmail = user?.email
  const [orders, setOrders] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [rowsPerPage] = useState(10)
  const [totalCount, setTotalCount] = useState(0)
  const [currentTab, setCurrentTab] = useState('domains')

  const navigate = useNavigate()
  const handleSearchChange = async (e) => {
    const query = e.target.value.toLowerCase()
    setSearchText(query)
  }

  const handleStatusChange = (e, value) => {
    const updatedStatusValue = e.target.value
    setStatus(updatedStatusValue)
  }

  const handleTabChangeFromTabs = (e) => {
    setCurrentTab(e.currentTarget.textContent.toLowerCase())
    setPage(1)
  }

  const fetchOrders = useCallback(async () => {
    let baseUrl = `${API_SERVICE_BACKEND}/dfy-setup/getOrders?email=${user?.email}`
    if (status !== 'all' && status !== '') {
      baseUrl = baseUrl + `&status=${status}`
    }
    if (searchText !== '') {
      baseUrl = baseUrl + `&query=${searchText}`
    }
    if (page) {
      baseUrl = baseUrl + `&page=${page}`
    }

    baseUrl = baseUrl + `&limit=${rowsPerPage}`
    const response = await axios.get(baseUrl)
    setTotalCount(response.data?.total ?? 0)
    setOrders(response.data?.data ?? [])
    setIsLoading(false)
  }, [status, debouncedSearchText, clientEmail, page, rowsPerPage])
  // Debounce the search text change
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchText(searchText)
    }, 2000)

    return () => {
      clearTimeout(handler)
    }
  }, [searchText])

  // Fetch orders when status or debouncedSearchText changes
  useEffect(() => {
    setIsLoading(true) // Set loading before fetching
    fetchOrders() // Call fetchOrders with the debounced search text
  }, [status, debouncedSearchText, fetchOrders, page])
  return (
    <div style={{ padding: '10px 20px' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', my: '20px' }}>
        <Typography sx={{ fontSize: '32px', fontWeight: 700, color: '#111927' }}>
          Domains Order Status
        </Typography>
        <Button
          variant='contained'
          onClick={() => navigate(paths.emailAccounts.add)}
          sx={{
            marginLeft: 'auto',
            color: '#ffffff',
            textTransform: 'none',
            fontSize: '14px',
            fontWeight: '500'
          }}
        >
          {' '}
          Add New Order
        </Button>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <OutlinedInput
          onChange={handleSearchChange}
          size='small'
          // disabled={loader}
          placeholder='Search'
          startAdornment={
            <InputAdornment position='start'>
              <SvgIcon>
                <SearchIcon />
              </SvgIcon>
            </InputAdornment>
          }
          value={searchText}
        />

        <CustomDropdown
          label={status === '' ? 'All Status' : status}
          options={statusList}
          selected={status}
          handleSelect={(value) => handleStatusChange({ target: { value } })}
        />
      </Box>
      <Tabs
        indicatorColor='primary'
        onChange={handleTabChangeFromTabs}
        scrollButtons='auto'
        textColor='primary'
        value={currentTab}
        variant='scrollable'
      >
        {tabs.map((tab) => (
          <Tab key={tab.value} label={tab.label} value={tab.value} />
        ))}
      </Tabs>
      <CustomTabPanel value={currentTab} index={tabs[0].value}>
        <TableContainer sx={{ borderRadius: '0px', ...scrollBarStyle }}>
          <Table
            sx={{
              height: 'fit-content',
              minWidth: '100%',
              borderCollapse: 'separate',
              borderSpacing: '0px 15px !important '
            }}
            aria-label='simple table'
            stickyHeader
          >
            <TableHead sx={{ background: '#E9E9EC', borderRadius: '10px' }}>
              <TableRow
                sx={{
                  '& .MuiTableCell-root': {
                    backgroundColor: '#E9E9EC',
                    borderBottom: '1px solid #E9E9EC',
                    color: '#61616F'
                  },
                  '& .MuiTableCell-root::after': {
                    content: '""',
                    position: 'absolute',
                    top: 8,
                    right: 0,
                    bottom: 18,
                    borderRight: '2px solid #CECED3',
                    height: '50%',
                    backgroundColor: '#CECED3'
                  },
                  '& .MuiTableCell-root:last-child::after': {
                    content: 'none'
                  }
                }}
              >
                <TableCell align='left'>Domain Name</TableCell>
                <TableCell align='left'>Provider</TableCell>
                <TableCell align='left'>Forward Domain</TableCell>
                <TableCell align='left'>Purchase Date</TableCell>
              </TableRow>
            </TableHead>
            {!isLoading && orders.length > 0
              ? (
                <TableBody>
                  {orders?.map((data, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          cursor: 'pointer',
                          borderRadius: '10px',
                          background: 'white',
                          marginBottom: '10px',
                          '& td': {
                            borderTop: '1px solid rgba(224, 224, 229, 1)',
                            borderBottom: '1px solid rgba(224, 224, 229, 1)',
                            borderRight: 'none'
                          },
                          '& td:first-child': {
                            borderTopLeftRadius: '10px',
                            borderBottomLeftRadius: '10px',
                            borderLeft: '1px solid rgba(224, 224, 229, 1)',
                            borderRight: 'none'
                          },
                          '& td:last-child': {
                            borderTopRightRadius: '10px',
                            borderBottomRightRadius: '10px',
                            borderRight: '1px solid rgba(224, 224, 229, 1)',
                            borderLeft: 'none'
                          }
                        }}
                      >
                        <TableCell align='left' sx={{ borderBottom: 'none', width: '40%' }}>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              fontSize: '14px',
                              fontWeight: 500
                            }}
                          >
                            <LanguageOutlined
                              sx={{
                                color: 'rgba(88, 98, 255, 1)',
                                marginRight: '10px'
                              }}
                            />
                            {data.domain}
                          </Box>
                        </TableCell>
                        <TableCell align='left'>
                          <Typography
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              gap: '6px',
                              borderRadius: '10px',
                              p: '6px 12px',
                              border: '1px solid #E0E0E5',
                              width: 'fit-content'
                            }}
                          >
                            {data.provider === 'google'
                              ? (
                                <>
                                  <img src={Socialicon} />
                                  <Typography sx={{ fontSize: '0.875rem', fontWeight: '500' }}>
                                    Google
                                  </Typography>
                                </>
                                )
                              : null}
                          </Typography>
                        </TableCell>
                        <TableCell align='left'>{data?.forwardDomain ?? '-'}</TableCell>
                        <TableCell align='left'>
                          {data.startDate ? moment(data.startDate).format('DD-MM-YYYY') : null}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
                )
              : isLoading
                ? (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={5} align='center'>
                        <CircularProgress sx={{ width: '20px', height: '20px', padding: '10px' }} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                  )
                : (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={6} align='center'>
                        <Typography
                          sx={{
                            fontSize: '1.5rem',
                            fontWeight: 500,
                            color: '#61616F',
                            textAlign: 'center'
                          }}
                        >
                          No Orders Found
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                  )}
          </Table>
        </TableContainer>
      </CustomTabPanel>
      <CustomTabPanel value={currentTab} index={tabs[1].value}>
        <TableContainer sx={{ borderRadius: '0px', ...scrollBarStyle }}>
          <Table
            sx={{
              height: 'fit-content',
              minWidth: '100%',
              borderCollapse: 'separate',
              borderSpacing: '0px 15px !important '
            }}
            aria-label='simple table'
            stickyHeader
          >
            <TableHead sx={{ background: '#E9E9EC', borderRadius: '10px' }}>
              <TableRow
                sx={{
                  '& .MuiTableCell-root': {
                    backgroundColor: '#E9E9EC',
                    borderBottom: '1px solid #E9E9EC',
                    color: '#61616F'
                  },
                  '& .MuiTableCell-root::after': {
                    content: '""',
                    position: 'absolute',
                    top: 8,
                    right: 0,
                    bottom: 18,
                    borderRight: '2px solid #CECED3',
                    height: '50%',
                    backgroundColor: '#CECED3'
                  },
                  '& .MuiTableCell-root:last-child::after': {
                    content: 'none'
                  }
                }}
              >
                <TableCell align='left'>Emails</TableCell>
                <TableCell align='left'>Provider</TableCell>
                <TableCell align='left'>Forward Domain</TableCell>
                <TableCell align='left'>Purchase Date</TableCell>
                <TableCell align='left'>Status</TableCell>
              </TableRow>
            </TableHead>
            {!isLoading && orders.length > 0
              ? (
                <TableBody>
                  {orders?.map((data, index) => {
                    return data?.contactDetails?.map((contact) => {
                      const statusInfo = statusList.find(
                        (status) => status?.value?.toLowerCase() === contact?.status?.toLowerCase() // Match the status with the label
                      )
                      return (
                        <TableRow
                          key={index}
                          sx={{
                            cursor: 'pointer',
                            borderRadius: '10px',
                            background: 'white',
                            marginBottom: '10px',
                            '& td': {
                              borderTop: '1px solid rgba(224, 224, 229, 1)',
                              borderBottom: '1px solid rgba(224, 224, 229, 1)',
                              borderRight: 'none'
                            },
                            '& td:first-child': {
                              borderTopLeftRadius: '10px',
                              borderBottomLeftRadius: '10px',
                              borderLeft: '1px solid rgba(224, 224, 229, 1)',
                              borderRight: 'none'
                            },
                            '& td:last-child': {
                              borderTopRightRadius: '10px',
                              borderBottomRightRadius: '10px',
                              borderRight: '1px solid rgba(224, 224, 229, 1)',
                              borderLeft: 'none'
                            }
                          }}
                        >
                          <TableCell align='left'>{contact.email}</TableCell>
                          <TableCell align='left'>
                            <Typography
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '6px',
                                borderRadius: '10px',
                                p: '6px 12px',
                                border: '1px solid #E0E0E5',
                                width: 'fit-content'
                              }}
                            >
                              {data.provider === 'google'
                                ? (
                                  <>
                                    <img src={Socialicon} />
                                    <Typography
                                      sx={{
                                        fontSize: '0.875rem',
                                        fontWeight: '500'
                                      }}
                                    >
                                      Google
                                    </Typography>
                                  </>
                                  )
                                : null}
                            </Typography>
                          </TableCell>

                          <TableCell align='left'>{data?.forwardDomain ?? '-'}</TableCell>
                          <TableCell align='left'>
                            {data.startDate ? moment(data.startDate).format('DD-MM-YYYY') : null}
                          </TableCell>
                          <TableCell align='left'>
                            {statusInfo && (
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  border: '1px solid',
                                  borderColor: statusInfo.color,
                                  borderRadius: '10px',
                                  padding: '6px 12px',
                                  width: 'fit-content'
                                }}
                              >
                                <img src={statusInfo.icon} style={{ color: statusInfo.color }} />{' '}
                                {/* Render icon */}
                                <Typography
                                  sx={{
                                    fontSize: '0.875rem',
                                    color: statusInfo.color,
                                    marginLeft: '8px'
                                  }}
                                >
                                  {statusInfo.label}
                                </Typography>
                              </div>
                            )}
                          </TableCell>
                        </TableRow>
                      )
                    })
                  })}
                </TableBody>
                )
              : isLoading
                ? (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={5} align='center'>
                        <CircularProgress sx={{ width: '20px', height: '20px', padding: '10px' }} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                  )
                : (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={6} align='center'>
                        <Typography
                          sx={{
                            fontSize: '1.5rem',
                            fontWeight: 500,
                            color: '#61616F',
                            textAlign: 'center'
                          }}
                        >
                          No Orders Found
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                  )}
          </Table>
        </TableContainer>
      </CustomTabPanel>

      <Stack spacing={3}>
        {!isLoading && orders.length
          ? (
            <Pagination
              count={Math.ceil(totalCount / rowsPerPage)}
              page={page}
              onChange={(event, page) => setPage(page)}
              showFirstButton
              showLastButton
              siblingCount={1}
              boundaryCount={1}
              variant='outlined'
              shape='rounded'
              size='large'
              sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}
              rowsPerPage={rowsPerPage}
            />
            )
          : (
              ''
            )}
      </Stack>
    </div>
  )
}

export default OrderStatus
