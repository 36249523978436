import { Box, Typography, Tabs, Tab } from '@mui/material'
import PropTypes from 'prop-types'
import Profile from 'src/components/settings/profile.js'
import { useLocation } from 'react-router-dom'
import BillingAndUsage from 'src/components/settings/BillingAndUsage.js'
import BlockList from 'src/components/settings/BlockList'
import { useState, useEffect } from 'react'
import HeaderWithPopover from 'src/components/HeaderWithPopover'
import { useAuth } from 'src/auth/auth'
import { useGetMeAndWorkspaceQuery } from 'src/services/user-service'
import { property } from 'lodash'
import BillingPage from 'src/components/settings/BillingPage'
function CustomTabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ width: '100%' }}
    >
      {value === index && (
        <Box sx={{}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

function a11yProps (index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

const Page = ({ tab }) => {
  const location = useLocation()
  const fromCreateWorkspace = location.state?.fromCreateWorkspace || false
  const [value, setValue] = useState(
    tab === 'billing' ? 0 : tab === 'account' ? 1 : tab === 'blocklist' ? 2 : 0
  )
  const { data, refetch } = useGetMeAndWorkspaceQuery()
  const [selectedTimeline, setSelectedTimeline] = useState(data?.workspace)

  const handleChange = (event, newValue) => {
    setValue(newValue)
    if (newValue === 0) {
      refetch()
    }
  }
  useEffect(() => {
    setValue(tab === 'billing' ? 0 : tab === 'account' ? 1 : tab === 'blocklist' ? 2 : 0)
  }, [tab])

  const handleWorkspaceChange = (timeline) => {
    setSelectedTimeline(timeline)
  }
  const { canEdit, canDelete } = useAuth()

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center ',
          width: '100%',
          height: '100%',
          flexDirection: 'column'
          //   p: 2,
        }}
      >
        {fromCreateWorkspace
          ? (
            <Box
              sx={{
                width: '90%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                mb: 4
              }}
            >
              <BillingAndUsage fromCreateWorkspace={fromCreateWorkspace} />
            </Box>
            )
          : (
            <Box
              sx={{
                width: '90%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center'
              }}
            >
              <HeaderWithPopover
                title='Settings'
                onWorkspaceChange={handleWorkspaceChange}
                selectedTimeline={selectedTimeline}
              />
              <Box
                sx={{
                  boxShadow: '0px 12px 15px 0px #4B71970D',
                  borderRadius: '12px',
                  backgroundColor: 'white',
                  width: '100%',
                  p: { xs: 1, sm: 2, md: 3 },
                  mt: 3
                }}
              >
                {' '}
                <Box
                  sx={{
                    width: '100%',
                    border: '1px solid rgba(228, 228, 229, 1)',
                    borderRadius: '8px'
                  }}
                >
                  {' '}
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label='basic tabs example'
                    variant='fullWidth'
                    sx={{ borderRadius: '8px' }}
                  >
                    <Tab
                      label='Billings & Usage'
                      sx={{
                        fontSize: '14px',
                        fontWeight: 700,
                        lineHeight: '20px'
                      }}
                      {...a11yProps(0)}
                    />
                    <Tab
                      label='Account'
                      sx={{
                        fontSize: '14px',
                        fontWeight: 700,
                        lineHeight: '20px'
                      }}
                      {...a11yProps(1)}
                    />
                    <Tab
                      label='Blocklist'
                      sx={{
                        fontSize: '14px',
                        fontWeight: 700,
                        lineHeight: '20px'
                      }}
                      {...a11yProps(2)}
                    />
                  </Tabs>
                </Box>
              </Box>
              <Box
                sx={{
                  mb: 4,
                  mt: 3,
                  width: '100%'
                }}
              >
                <CustomTabPanel value={value} index={0}>
                  <BillingAndUsage
                    fromCreateWorkspace={fromCreateWorkspace}
                    canEdit={canEdit}
                    canDelete={canDelete}
                  />
                  <BillingPage
                    fromCreateWorkspace={fromCreateWorkspace}
                    canEdit={canEdit}
                    canDelete={canDelete}
                  />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <Profile
                    selectedTimeline={selectedTimeline}
                    canEdit={canEdit}
                    canDelete={canDelete}
                    setSelectedTimeline={setSelectedTimeline}
                  />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                  <BlockList campaign={[]} canEdit={canEdit} />
                </CustomTabPanel>
              </Box>
            </Box>
            )}
      </Box>
    </>
  )
}

Page.propTypes = {
  tab: property.toString.isRequired
}

export default Page
