import * as React from 'react'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'

const ITEM_HEIGHT = 48

export default function ActionMenu (props) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const { options, actionId } = props
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (_event, option) => {
    setAnchorEl(null)
    if (option !== 'backdropClick') {
      if (option?.name === 'Change AppSumo plan') {
        option?.onChange(props.data)
      } else {
        option?.onChange(actionId, props.data)
      }
    }
  }

  return (
    <div>
      <IconButton
        aria-label='more'
        id='long-button'
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        onClick={handleClick}
      >
        <SettingsOutlinedIcon />
      </IconButton>
      <Menu
        id='long-menu'
        MenuListProps={{
          'aria-labelledby': 'long-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch'
          }
        }}
      >
        {options?.map((option) => (
          <MenuItem key={option.name} onClick={(event) => handleClose(event, option)}>
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
