import React from 'react'
import { Box, Typography, Grid, useTheme } from '@mui/material'
import InfoOutlined from '@mui/icons-material/InfoOutlined'
import ReactApexChart from 'react-apexcharts'
import { InfoTooltip } from './campaignSchedule'

const EstimatedSchedule = ({
  handleUpdateClick,
  totalAccounts,
  message,
  options,
  series,
  campaignStatus
}) => {
  const theme = useTheme()
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          mb: 2,
          mt: 2,
          color: `${theme.palette.primary.primaryText}`
        }}
      >
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 700
          }}
        >
          Estimated Sending Schedule
          <InfoTooltip
            arrow
            placement='top'
            enterTouchDelay={0}
            title={
              <Box
                sx={{
                  width: '100%',
                  backgroundColor: 'white',
                  borderRadius: '12px',
                  boxShadow: '0px 12px 15px 0px #4B71970D',
                  display: 'flex',

                  flexDirection: 'column',
                  gap: 1
                }}
              >
                <Typography
                  sx={{
                    fontSize: '13px',
                    fontWeight: 400,
                    lineHeight: '20px',
                    letterSpacing: '0em',
                    color: '#8181B0'
                  }}
                >
                  The following analytics are just predictions of how your campaign will perform
                  and the number of emails sent to leads per day. In case of any account errors,
                  these statistics will change. These stats are provided solely for
                  your reference on how campaigns work.
                </Typography>
              </Box>
            }
          >
            <InfoOutlined fontSize='20px' sx={{ cursor: 'pointer', ml: 1 }} />
          </InfoTooltip>
        </Typography>
      </Box>
      <Grid item xs={12}>
        {' '}
        <Box
          sx={{
            width: '100%',
            borderRadius: '12px',
            p: 3,
            border: '1px solid #E4E4E5',
            height: '100%',
            backgroundColor: 'white'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-evenly'
            }}
          >
            {campaignStatus === 'completed'
              ? (
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: '18px',
                    color: '#00AA38'
                  }}
                >
                  Your Campaign is Already Completed
                </Typography>
                )
              : (
                <>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 500,
                      lineHeight: '18px',
                      color: totalAccounts === 0 ? 'red' : `${theme.palette.primary.primaryText}`
                    }}
                  >
                    Total Email Accounts Selected: <strong>{totalAccounts}</strong>
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 500,
                      lineHeight: '18px',
                      color: `${theme.palette.primary.primaryText}`
                    }}
                  >
                    {message}
                  </Typography>
                  {/* <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "18px",
                    color: totalAccounts === 0 ? "red" : "#28287B",
                  }}
                >
                  Timezone: <strong>{}</strong>
                </Typography> */}
                </>
                )}
          </Box>
          <ReactApexChart options={options} series={series} type='bar' height={280} />
        </Box>
      </Grid>
    </>
  )
}

export default EstimatedSchedule
