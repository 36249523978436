import { createSvgIcon } from '@mui/material/utils'

export const InfoIcon = createSvgIcon(
  <svg viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clip-path='url(#clip0_3475_17547)'>
      <path d='M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z' stroke='#8181B0' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
      <path d='M7.5 7.5C7.63261 7.5 7.75979 7.55268 7.85355 7.64645C7.94732 7.74021 8 7.86739 8 8V10.5C8 10.6326 8.05268 10.7598 8.14645 10.8536C8.24021 10.9473 8.36739 11 8.5 11' stroke='#8181B0' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
      <path d='M7.75 6C8.16421 6 8.5 5.66421 8.5 5.25C8.5 4.83579 8.16421 4.5 7.75 4.5C7.33579 4.5 7 4.83579 7 5.25C7 5.66421 7.33579 6 7.75 6Z' fill='#8181B0' />
    </g>
    <defs>
      <clipPath id='clip0_3475_17547'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>

)
