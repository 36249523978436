// import ChartPieIcon from "@heroicons/react/24/solid/ChartPieIcon";
// import CogIcon from "@heroicons/react/24/solid/CogIcon";
// import DocumentTextIcon from "@heroicons/react/24/solid/DocumentTextIcon";
// import ExclamationTriangleIcon from "@heroicons/react/24/solid/ExclamationTriangleIcon";
// import ShoppingCartIcon from "@heroicons/react/24/solid/ShoppingCartIcon";
// import StarIcon from "@heroicons/react/24/solid/StarIcon";
import { SvgIcon } from '@mui/material'
// import MagnifyingGlassIcon from "@heroicons/react/24/outline/MagnifyingGlassIcon";
// import {
//   ContactSupport,
//   ContactSupportOutlined,
//   Email,
//   HelpOutline,
//   Interests,
//   InterestsOutlined,
//   Inventory,
//   Send,
//   SendOutlined,
//   Timeline,
// } from "@mui/icons-material";
import { SBSearch } from 'src/assets/sidebar/SBSearch'
import { SBEmail } from 'src/assets/sidebar/SBEmail'
import { SBSend } from 'src/assets/sidebar/SBSend'
import { SBChart } from 'src/assets/sidebar/SBChart'
import { SBInbox } from 'src/assets/sidebar/SBInbox'
import { SBSettings } from 'src/assets/sidebar/SBSettings'
import { SBIntegration } from 'src/assets/sidebar/SBIntegration'
import { SBSupport } from 'src/assets/sidebar/SBSupport'
import { SBOnboardingHub, SBOnboardingHubUpgrade } from 'src/assets/sidebar/SBOnboardingHub'
import { Enterprise } from 'src/assets/sidebar/SBEnterprise'
import TimelineIcon from '@mui/icons-material/Timeline'
import { BrandingWatermarkOutlined, PeopleOutline, Search, Public, Payment, ManageAccountsOutlined } from '@mui/icons-material'

export const items = [
  // {
  //   id: 'Lead Finder New',
  //   href: '/leadFinderNew',
  //   icon: (active) => (
  //     <SvgIcon>
  //       <SBSearch color={active ? '#FFFFFF' : '#FFFFFF'} />
  //     </SvgIcon>
  //   ),
  //   label: 'Lead Finder New'
  // },
  {
    id: 'Lead Finder',
    href: '/leadFinder',
    icon: (active) => (
      <SvgIcon>
        <SBSearch color={active ? '#FFFFFF' : '#FFFFFF'} />
      </SvgIcon>
    ),
    label: 'Lead Finder'
  },
  // {
  //   id: 'Lists',
  //   href: '/lists',
  //   icon: (active) => (
  //     <SvgIcon>
  //       <Enterprise color={active ? '#FFFFFF' : '#FFFFFF'} />
  //     </SvgIcon>
  //   ),
  //   label: 'Lists'
  // },
  {
    id: 'Accounts',
    href: '/accounts',
    icon: (active) => (
      <SvgIcon>
        <SBEmail color={active ? '#FFFFFF' : '#FFFFFF'} />
      </SvgIcon>
    ),
    label: 'Accounts'
  },
  {
    id: 'Campaigns',
    href: '/campaigns',
    icon: (active) => (
      <SvgIcon>
        <SBSend color={active ? '#FFFFFF' : '#FFFFFF'} />
      </SvgIcon>
    ),
    label: 'Campaigns'
  },
  {
    id: 'Analytics',
    href: '/analytics',
    icon: (active) => (
      <SvgIcon>
        <SBChart color={active ? '#FFFFFF' : '#FFFFFF'} />
      </SvgIcon>
    ),
    label: 'Analytics'
  },

  {
    id: 'InboxHub',
    href: '/inboxhub',
    icon: (active) => (
      <SvgIcon>
        <SBInbox color={active ? '#FFFFFF' : '#FFFFFF'} />
      </SvgIcon>
    ),
    label: 'InboxHub'
  },
  // {
  //   id: 'dealPipeline',
  //   href: '/dealPipeline',
  //   icon: (active) => (
  //     <>
  //       <TimelineIcon />
  //     </>
  //   ),
  //   label: 'Deal Pipeline'
  // },
  // {
  //   id: 'InboxCRM',
  //   href: '/inboxCrm',
  //   icon: (active) => (
  //     <SvgIcon>
  //       <SBInbox color={active ? '#FFFFFF' : '#FFFFFF'} />
  //     </SvgIcon>
  //   ),
  //   label: 'InboxCRM'
  // },
  // {
  //   id: 'Settings',
  //   href: '/settings/billing',
  //   icon: (active) => (
  //     <SvgIcon>
  //       <SBSettings color={active ? '#FFFFFF' : '#FFFFFF'} />
  //     </SvgIcon>
  //   ),
  //   label: 'Settings'
  // },
  {
    id: 'Settings',
    href: '/settingsUpdated',
    icon: (active) => (
      <SvgIcon>
        <SBSettings color={active ? '#FFFFFF' : '#FFFFFF'} />
      </SvgIcon>
    ),
    label: 'Settings'
  },

  {
    id: 'Integrations',
    href: '/integrations',
    icon: (active) => (
      <SvgIcon>
        <SBIntegration color={active ? '#FFFFFF' : '#FFFFFF'} />
      </SvgIcon>
    ),
    label: 'Integrations'
  },
  {
    id: 'Support Center',
    href: '/support',
    icon: (active) => (
      <SvgIcon>
        <SBSupport color={active ? '#FFFFFF' : '#FFFFFF'} />
      </SvgIcon>
    ),
    label: 'Support Center'
  },
  {
    id: 'Partner/White Label',
    href: '#',
    icon: (active) => (
      <SvgIcon>
        <PeopleOutline color={active ? '#FFFFFF' : '#FFFFFF'} />
      </SvgIcon>
    ),
    label: 'Partner/White Label',
    subItems: [
      {
        id: 'Manage Accounts',
        href: 'manage-accounts',
        label: 'Manage Accounts',
        icon: () => (
          <SvgIcon>
            <ManageAccountsOutlined color='#FFFFFF' />
          </SvgIcon>
        )
      },
      {
        id: 'Branding Management',
        href: 'branding-management',
        label: 'Branding Management',
        icon: () => (
          <SvgIcon>
            <BrandingWatermarkOutlined color='#FFFFFF' />
          </SvgIcon>
        )
      },
      {
        id: 'SEO Setting',
        href: 'seo-setting',
        label: 'SEO Setting',
        icon: () => (
          <SvgIcon>
            <Search color='#FFFFFF' />
          </SvgIcon>
        )
      },
      {
        id: 'Custom Domain',
        href: 'custom-domain',
        label: 'Custom Domain',
        icon: () => (
          <SvgIcon>
            <Public color='#FFFFFF' />
          </SvgIcon>
        )
      },
      {
        id: 'plans',
        href: 'plans',
        label: 'Plans',
        icon: () => (
          <SvgIcon>
            <Payment color='#FFFFFF' />
          </SvgIcon>
        )
      }
    ]
  }
  // {
  //   id: "DFY Domains",
  //   href: "/emailaccounts/dfydomains",
  //   icon: (active) => (
  //     <SvgIcon>
  //       <SBEmail color={active ? "#FFFFFF" : "#FFFFFF"} />
  //       {/* <SBSupport color={active ? "#FFFFFF" : "#FFFFFF"} /> */}
  //     </SvgIcon>
  //   ),
  //   label: "DFY Domains",
  // },

  // {
  //   href: "/",
  //   icon: (
  //     <SvgIcon>
  //       <ChartPieIcon />
  //     </SvgIcon>
  //   ),
  //   label: "Home",
  // },
  // {
  //   href: "/orders",
  //   icon: (
  //     <SvgIcon>
  //       <ShoppingCartIcon />
  //     </SvgIcon>
  //   ),
  //   label: "Orders",
  // },

  // {
  //   href: "/theme",
  //   icon: (
  //     <SvgIcon>
  //       <DocumentTextIcon />
  //     </SvgIcon>
  //   ),
  //   label: "Theme",
  // },
  // {
  //   href: "/icons",
  //   icon: (
  //     <SvgIcon>
  //       <StarIcon />
  //     </SvgIcon>
  //   ),
  //   label: "Icons",
  // },
  // {
  //   href: "/404",
  //   icon: (
  //     <SvgIcon>
  //       <ExclamationTriangleIcon />
  //     </SvgIcon>
  //   ),
  //   label: "Error",
  // },
]

export const OnboardingHub = {
  icon: (active, color, size) => (
    <SvgIcon>
      <SBOnboardingHub color={color} size={size} />
    </SvgIcon>
  ),
  label: 'Onboarding Hub'
}

export const OnboardingHubUpgrade = {
  icon: (active) => (
    <SvgIcon>
      <SBOnboardingHubUpgrade color={active ? '#FFFFFF' : '#FFFFFF'} />
    </SvgIcon>
  ),
  label: 'Onboarding Hub'
}
