import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Select,
  MenuItem,
  Typography,
  Avatar,
  Tabs,
  Tab,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  Menu,
  IconButton,
  Autocomplete
} from '@mui/material'
import MoreHoriz from '@mui/icons-material/MoreHoriz'
import PropTypes from 'prop-types'
import { useTheme } from '@emotion/react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { useGetMeQuery, useGetWorkSpaceUserMutation } from 'src/services/user-service'
import { timezoneArray } from 'src/assets/data'
import {
  useDeleteMemberMutation,
  useGetCurrentWorkSpaceMutation,
  useGetMembersMutation,
  useSendInvitationMutation,
  useUpdateMemberRoleMutation,
  useUpdateWorkSpaceNameMutation
} from 'src/services/workSpace-service'
import toast from 'react-hot-toast'
import { CopyAll } from '@mui/icons-material'

import { useSelector } from 'react-redux'
import Pagination from '../Pagination'
function CustomTabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ width: '100%' }}
    >
      {value === index && (
        <Box sx={{}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

const WorkspacePage = ({ tab, setSelectedTimeline }) => {
  const workspace = useSelector((state) => state.workspace)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  // const { data, refetch } = useGetMeAndWorkspaceQuery();
  const [getCurrentWorkSpace] = useGetCurrentWorkSpaceMutation()
  const [updateWorkSpaceName] = useUpdateWorkSpaceNameMutation()
  const [getWorkSpaceUser] = useGetWorkSpaceUserMutation()
  const [getMembers] = useGetMembersMutation()
  const [sendInvitation] = useSendInvitationMutation()
  // { isLoading: isLoadingInvitation }
  const [deleteMember] = useDeleteMemberMutation()
  // { isLoading: isDeletingMember }
  const [updateMemberRole] = useUpdateMemberRoleMutation()
  const { data: userData } = useGetMeQuery()
  const [originalWorkspaceName, setOriginalWorkspaceName] = useState('')
  const workspaceID = workspace?._id
  const [timezone, setTimezone] = useState('')
  const [user, setUser] = useState({})
  const [pendingMembers, setPendingMembers] = useState([])
  const [teamMembers, setTeamMembers] = useState([])
  const [page, setPage] = useState(1)
  const [total] = useState(0)
  const [limit, setLimit] = useState(10)

  const [value, setValue] = useState(
    tab === 'Team Members' ? 0 : tab === 'Pending Invitations' ? 1 : 0
  )

  const handleChange = (event, newValue) => {
    setValue(newValue)
    if (newValue === 0) {
      // refetch();
    }
  }
  useEffect(() => {
    setValue(tab === 'Team Members' ? 0 : tab === 'Pending Invitations' ? 1 : 0)
  }, [tab])

  const openDialog = () => {
    setDialogOpen(true)
  }

  const closeDialog = () => {
    setDialogOpen(false)
  }
  // const handleRemove = async (member) => {
  //   if (member._id) {
  //     setAnchorEl(null)
  //     await deleteMember(member?._id)
  //     toast.success('Member deleted from workspace.')
  //     fetchTeamMembers()
  //     fetchPendingMembers()
  //   }
  // }
  const theme = useTheme()

  const currentWorkspace = async () => {
    if (userData?._id) {
      const currentWorkspace = await getCurrentWorkSpace(userData?.currentWorkspace)
      setOriginalWorkspaceName(currentWorkspace?.data?.name || '')
      setTimezone(currentWorkspace?.data?.timezone)
    }
  }

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(workspaceID).then(() => {
      toast.success('Workspace ID copied to clipboard')
    })
  }

  const getUser = async () => {
    const user = await getWorkSpaceUser(workspace?.createdBy).unwrap()
    setUser(user)
  }
  useEffect(() => {
    currentWorkspace()
    fetchPendingMembers()
  }, [])

  useEffect(() => {
    if (workspace?._id) {
      getUser()
      fetchTeamMembers()
      fetchPendingMembers()
      setTimezone(workspace?.timezone)
    }
  }, [workspace])

  const fetchTeamMembers = async () => {
    try {
      const { data } = await getMembers({ id: workspaceID, status: 'accepted' })
      setTeamMembers(data)
    } catch (error) {
      console.error('Error fetching members:', error)
    }
  }

  const fetchPendingMembers = async () => {
    try {
      const { data } = await getMembers({ id: workspace?._id, status: 'pending' })
      setPendingMembers(data)
    } catch (error) {
      console.error('Error fetching members:', error)
    }
  }

  const handleRemoveMemberClick = async (member) => {
    if (member._id) {
      await deleteMember(member?._id)
      toast.success('Member deleted from workspace.')
      fetchTeamMembers()
      fetchPendingMembers()
    }
  }

  const extractNameFromEmail = (email) => {
    const name = email.split('@')[0]
    return name.charAt(0).toUpperCase() + name.slice(1)
  }

  const handleViewAccessChange = async (event, memberId) => {
    const role = event?.target?.value
    const { message } = await updateMemberRole({
      id: memberId,
      data: {
        role
      }
    }).unwrap()
    toast.success(message)
    const updatedPendingMembers = pendingMembers.map((member) =>
      member._id === memberId ? { ...member, role } : member
    )

    const updatedTeamMembers = teamMembers.map((member) =>
      member._id === memberId ? { ...member, role } : member
    )

    setPendingMembers(updatedPendingMembers)
    setTeamMembers(updatedTeamMembers)
  }

  const handleLimitChange = (event) => {
    setLimit(event.target.value)
    setPage(1)
  }

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email format').required('Email is required'),
    role: Yup.string()
      .oneOf(['Admin', 'Editor', 'Viewer'], 'Invalid role')
      .required('Role is required')
  })

  const handleSubmit = async (values, { setSubmitting, resetForm, setFieldError }) => {
    const { email, role } = values
    const trimmedEmail = email.trim()

    // Check if the user is trying to invite themselves
    if (user?.email === trimmedEmail) {
      toast.error("You can't send an invite to the workspace owner.")
      setSubmitting(false)
      return
    }

    try {
      // Call the API to send an invitation
      const { data } = await sendInvitation({
        id: workspaceID,
        email: trimmedEmail,
        role
      })

      if (data?.message === 'Already invited') {
        setFieldError('email', 'This member has already been invited.')
      } else {
        toast.success(data?.message)
        fetchPendingMembers()
        resetForm()
        closeDialog() // Close the dialog
      }
    } catch (error) {
      toast.error('Failed to send the invitation. Please try again.')
    } finally {
      setSubmitting(false) // Stop the submitting state
    }
  }

  const initialValuesWorkSpace = {
    workspaceName: originalWorkspaceName || '',
    timezone: timezoneArray.find((option) => option?.value === timezone) || null
  }

  const validationSchemaWorkSpace = Yup.object({
    workspaceName: Yup.string()
      .trim()
      .required('Workspace name is required')
      .min(3, 'Workspace name must be at least 3 characters long'),
    timezone: Yup.object().nullable().required('Default Timezone is required')
  })

  const handleSubmitWorkSpace = async (values, { setSubmitting, setFieldError }) => {
    const { workspaceName, timezone } = values
    const trimmedName = workspaceName.trim()
    try {
      const { message, workSpace } = await updateWorkSpaceName({
        id: workspaceID,
        data: {
          name: trimmedName === originalWorkspaceName ? '' : trimmedName,
          timezone: timezone?.value
        }
      }).unwrap()

      if (message === 'Workspace with same name already exist') {
        toast.error('Workspace with the same name already exists')
        return
      }
      setSelectedTimeline(workSpace)
      setOriginalWorkspaceName(trimmedName)
      setTimezone(timezone?.value)
      toast.success(message)
    } catch (err) {
      console.log('err', err)
      toast.error('An error occurred while updating the workspace')
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <>
      <Box
        sx={{
          padding: 4,
          backgroundColor: '#fff',
          minHeight: '100vh',
          border: '1px solid #D7D9E6',
          borderRadius: '8px'
        }}
      >
        <Box sx={{ alignItems: 'center', gap: '8px' }}>
          <Typography variant='h6' sx={{ mb: 2 }}>
            Workspace
          </Typography>
        </Box>

        <Formik
          initialValues={initialValuesWorkSpace}
          validationSchema={validationSchemaWorkSpace}
          onSubmit={handleSubmitWorkSpace}
          enableReinitialize
        >
          {({ values, errors, touched, handleChange, handleBlur, setFieldValue, isSubmitting }) => (
            <Form>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '20px',
                  marginTop: 5
                }}
              >
                <TextField
                  label='Workspace Name'
                  variant='outlined'
                  size='small'
                  name='workspaceName'
                  value={values.workspaceName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  sx={{ width: '300px' }}
                  error={touched.workspaceName && Boolean(errors.workspaceName)}
                  helperText={touched.workspaceName && errors.workspaceName}
                />

                {/* Timezone Autocomplete */}
                <Autocomplete
                  freeSolo
                  id='timezone-autocomplete'
                  disableClearable
                  options={timezoneArray}
                  getOptionLabel={(option) => option.label}
                  value={
                    timezoneArray.find((option) => option.value === values.timezone?.value) || null
                  }
                  onChange={(event, value) => setFieldValue('timezone', value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Default Timezone'
                      variant='outlined'
                      size='small'
                      sx={{ width: '300px' }}
                      error={touched.timezone && Boolean(errors.timezone)}
                      helperText={touched.timezone && errors.timezone}
                    />
                  )}
                />
              </Box>
              <Box>
                <Button
                  type='submit'
                  variant='contained'
                  disabled={isSubmitting || (values?.workspaceName.trim() === originalWorkspaceName && values?.timezone?.value === timezone)}
                  sx={{
                    bgcolor: `${theme.palette.primary.main}`,
                    color: 'white',
                    textTransform: 'none',
                    fontWeight: 700,
                    borderRadius: '8px',
                    marginTop: '10px',
                    '&:hover': {
                      backgroundColor: `${theme.palette.primary.main}`
                    },
                    mb: 2
                  }}
                >
                  {isSubmitting ? 'Saving...' : 'Save'}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>

        <Typography
          sx={{ color: '#8181B0', fontWeight: 500, fontSize: '13px', textAlign: 'start', mt: 2 }}
        >
          Workspace ID : {workspaceID}
          <IconButton size='small' onClick={handleCopyToClipboard}>
            <CopyAll fontSize='small' />
          </IconButton>
        </Typography>
        <Divider
          sx={{
            //   margin: "50px auto 0 auto",
            my: 4,
            borderColor: '#e0e0e0',
            borderWidth: '1px',
            width: '100%',

            position: 'relative',
            '::before, ::after': {
              borderColor: '#e0e0e0'
            },
            color: '#555',
            fontWeight: 500
          }}
        />
        {/* Header Section */}
        <Box>
          <Typography variant='h6' fontWeight='bold' sx={{ marginBottom: '10px' }}>
            Members
          </Typography>
          <Typography sx={{ fontWeight: 400, fontSize: '13px', color: '#8181B0' }}>
            Manage all workspace members and pending invitations.
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '16px',
            mt: 2
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            sx={{
              marginBottom: '16px',
              background: '#E1E3EC',
              border: '1ps solid #D7D9E6',
              borderRadius: '8px',
              '.MuiTabs-flexContainer': { borderBottom: '1px solid #ddd' },
              '.MuiTab-root': {
                textTransform: 'none',
                minWidth: '400px',
                color: 'black'
              },
              '& .Mui-selected': {
                backgroundColor: '#ffffff',
                color: `${theme.palette.primary.main}`,
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
              },
              '& .MuiTabs-indicator': {
                display: 'none'
              }
            }}
          >
            <Tab
              sx={{
                my: '5px',
                mx: '5px',
                px: 6,
                py: '4px'
              }}
              label='Team Members'
            />
            <Tab sx={{ my: '5px', mx: '5px', px: 6, py: '4px' }} label='Pending Invitations' />
          </Tabs>
          <Box sx={{}}>
            {/* <TextField
              variant="outlined"
              size="small"
              placeholder="Search member..."
              sx={{ backgroundColor: "#fff", borderRadius: "20px", width: "400px", mr: 2 }}
            /> */}
            <Button
              variant='contained'
              onClick={openDialog}
              disabled={user.isPartner || user.partnerAccountId}
              sx={{
                bgcolor: `${theme.palette.primary.main}`,
                color: `${theme.palette.primary.contrastText}`,
                '&:hover': {
                  bgcolor: `${theme.palette.primary.main}`
                }
              }}
            >
              Invite Member
            </Button>
          </Box>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Table sx={{ backgroundColor: '#FFFFFF', border: 'none', borderCollapse: 'collapse' }}>
            <TableBody>
              <TableRow>
                <TableCell colSpan={3} sx={{ border: 'none' }}>
                  <Box>
                    <Typography variant='body1'>
                      {user?.name?.first && user?.name?.last
                        ? `${user.name.first} ${user.name.last}`
                        : 'Super Admin'}
                    </Typography>
                    <Typography variant='body2'>{user?.email}</Typography>
                  </Box>
                </TableCell>
                <TableCell sx={{ textAlign: 'right', border: 'none' }}>
                  <Typography variant='body2' sx={{ mt: { xs: 2, sm: 0 } }}>
                    Owner
                  </Typography>
                </TableCell>
              </TableRow>
              {teamMembers?.map((member, index) => (
                <TableRow key={index} sx={{ bgcolor: '#FFFFFF' }}>
                  <TableCell colSpan={3} sx={{ border: 'none' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '16px'
                      }}
                    >
                      <Avatar>
                        {extractNameFromEmail(member?.email)?.charAt(0).toUpperCase() || '?'}
                      </Avatar>
                      <Box>
                        <Typography sx={{ fontWeight: 700, fontSize: '13px', color: '#101010' }}>
                          {extractNameFromEmail(member?.email) || `Member Name ${index + 1}`}{' '}
                          <span style={{ fontWeight: 400, fontSize: '13px', color: '#8181B0' }}>
                            • {member?.email}
                          </span>
                        </Typography>

                        <Typography sx={{ color: '#8181B0', fontWeight: 400, fontSize: '13px' }}>
                          {new Date(member?.createdAt).toLocaleString() || 'Date not provided'}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell align='right' sx={{ border: 'none' }}>
                    <Box sx={{ display: 'flex', gap: '8px', justifyContent: 'flex-end' }}>
                      <Select
                        defaultValue={index % 2 === 0 ? 'Admin' : 'Editor'}
                        size='small'
                        sx={{
                          borderRadius: '8px',
                          fontSize: '14px',
                          fontWeight: 600
                        }}
                        onChange={(e) => handleViewAccessChange(e, member._id)}
                      >
                        <MenuItem value='Admin'>Admin</MenuItem>
                        <MenuItem value='Editor'>Editor</MenuItem>
                        <MenuItem value='Viewer'>Viewer</MenuItem>
                      </Select>
                      <Button
                        variant='outlined'
                        size='small'
                        color='error'
                        onClick={() => handleRemoveMemberClick(member)}
                        sx={{
                          textTransform: 'none',
                          border: '1px solid #E4E4E5',
                          color: '#FF4545',
                          fontSize: '14px',
                          fontWeight: 600
                        }}
                      >
                        Remove
                      </Button>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Box sx={{ mt: 2 }}>
            <Pagination
              page={page}
              setPage={setPage}
              total={total}
              length={teamMembers?.length}
              limit={limit}
              handleLimitChange={handleLimitChange}
            />
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Table sx={{ backgroundColor: '#FFFFFF', border: 'none', borderCollapse: 'collapse' }}>
            <TableBody>
              {pendingMembers.map((member, index) => (
                <TableRow key={member._id} sx={{ bgcolor: '#FFFFFF', border: '1px solid #D7D9E6' }}>
                  <TableCell
                    sx={{ border: 'none', '&:last-child td, &:last-child th': { border: 'none' } }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '16px'
                      }}
                    >
                      <Avatar>
                        {extractNameFromEmail(member?.email)?.charAt(0).toUpperCase() || '?'}
                      </Avatar>

                      <Box>
                        <Typography sx={{ fontWeight: 700, fontSize: '13px', color: '#101010' }}>
                          {extractNameFromEmail(member?.email) || `Member Name ${index + 1}`}{' '}
                          <span style={{ fontWeight: 400, fontSize: '13px', color: '#8181B0' }}>
                            • {member?.email}
                          </span>
                        </Typography>

                        <Typography sx={{ color: '#8181B0', fontWeight: 400, fontSize: '13px' }}>
                          {new Date(member?.createdAt).toLocaleString() || 'Date not provided'}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>

                  <TableCell align='right' sx={{ border: 'none' }}>
                    <Box sx={{ display: 'flex', gap: '8px', justifyContent: 'flex-end' }}>
                      <Select
                        defaultValue={member?.role || 'Viewer'}
                        size='small'
                        sx={{
                          borderRadius: '8px',
                          fontSize: '14px',
                          fontWeight: 600
                        }}
                        onChange={(e) => handleViewAccessChange(e, member._id)}
                      >
                        <MenuItem value='Admin'>Admin</MenuItem>
                        <MenuItem value='Editor'>Editor</MenuItem>
                        <MenuItem value='Viewer'>Viewer</MenuItem>
                      </Select>
                      <Button
                        variant='outlined'
                        size='small'
                        color='error'
                        sx={{
                          textTransform: 'none',
                          border: '1px solid #E4E4E5',
                          color: '#FF4545',
                          fontSize: '14px',
                          fontWeight: 600
                        }}
                        onClick={() => handleRemoveMemberClick(member)}
                      >
                        Cancel invitation
                      </Button>
                      <IconButton
                        size='small'
                        onClick={(event) => setAnchorEl(event.currentTarget)}
                        sx={{ border: '1px solid #D7D9E6', borderRadius: '20%', padding: '4px' }}
                      >
                        <MoreHoriz />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={() => setAnchorEl(null)}
                        sx={{
                          '& .MuiPaper-root': {
                            border: '1px solid #D7D9E6',
                            borderRadius: '8px',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
                          }
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            // handleResendInvitation(member._id);
                            setAnchorEl(null) // Close menu after click
                          }}
                          sx={{ padding: '8px 16px' }}
                        >
                          Resend invitation email
                        </MenuItem>
                      </Menu>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Box sx={{ mt: 2 }}>
            <Pagination
              page={page}
              setPage={setPage}
              total={total}
              length={pendingMembers?.length}
              limit={limit}
              handleLimitChange={handleLimitChange}
            />
          </Box>
        </CustomTabPanel>
      </Box>

      <Dialog open={dialogOpen} onClose={closeDialog} maxWidth='xs' fullWidth>
        <DialogTitle>Invite Member</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{ email: '', role: '' }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, values, handleChange, touched, errors }) => (
              <Form>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    maxWidth: 400,
                    margin: 'auto',
                    mt: 2
                  }}
                >
                  <TextField
                    id='email'
                    name='email'
                    label='Email'
                    variant='outlined'
                    fullWidth
                    value={values.email}
                    onChange={handleChange}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                  />

                  <TextField
                    id='role'
                    name='role'
                    label='Role'
                    variant='outlined'
                    select
                    fullWidth
                    value={values.role}
                    onChange={handleChange}
                    error={touched.role && Boolean(errors.role)}
                    helperText={touched.role && errors.role}
                  >
                    <MenuItem value='' disabled>
                      Select role
                    </MenuItem>
                    <MenuItem value='Admin'>Admin</MenuItem>
                    <MenuItem value='Editor'>Editor</MenuItem>
                    <MenuItem value='Viewer'>Viewer</MenuItem>
                  </TextField>

                  <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                    {/* Cancel Button */}
                    <Button
                      type='button'
                      sx={{ marginRight: '10px' }}
                      variant='contained'
                      color='primary'
                      onClick={closeDialog}
                      disabled={isSubmitting}
                    >
                      Cancel
                    </Button>
                    {/* Submit Button */}
                    <Button
                      type='submit'
                      variant='contained'
                      color='primary'
                      disabled={isSubmitting}
                    >
                      Send Invitation
                    </Button>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default WorkspacePage
